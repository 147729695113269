import React, { Component } from "react";
import { connect } from "react-redux";
import Item from "./Item";
//import PropTypes from "prop-types";
import * as actions from "../../routes/actions/Account";

class List extends Component {
  componentDidMount() {
    const authenticated = this.props.user.id ? true : false;
    if (!this.props.showCloseButton && authenticated && !this.props.favorites) {
      this.props.getFavorites();
    }
  }

  isFavorite = id => {
    const { favorites } = this.props;
    const products = favorites && favorites.products ? favorites.products : [];
    return products.some(p => p.id === id);
  };

  render() {
    const { items,showMoreList, favorites, showCloseButton, ...rest } = this.props;
    return (
      <div className={`product-group mb-4 ${showMoreList ? "active" : ""}`}>
        {items.map((product, i) => {
          return (
            <Item
              id={i}
              key={product.id}
              product={product}
              favorite={showCloseButton || this.isFavorite(product.id)}
              showCloseButton={showCloseButton}
              handleRemove={this.props.handleRemove}
              {...rest}
            />
          );
        })}
      </div>
    );
  }
}

List.propTypes = {};
const mapStateToProps = ({ AccountReducer }) => ({
  favorites: AccountReducer.favorites,
  user: AccountReducer.user
});

const mapDispatchToProps = {
  getFavorites: actions.getFavorites
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
