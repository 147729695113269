import React from "react";
import { Link } from "react-router-dom";
import { ProgressBar, ScoreBadge } from "../../components";
import classNames from "classnames";
import PropTypes from "prop-types";
import { calc } from "../../helpers/mathExtensions";

const Book = (props, { className }) => {
  const productPrice = props
    ? ((props.total * (100 + props.tax)) / 100 || 0).toFixed(2)
    : 0;

  return props.digitalGroupId === null || props.digitalGroupId === 0 ? (
    <div className={classNames("card card-book border-0", className)}>
      {props.id ? (
        <Link to={`/dijitalkitap/book/lessons/${props.digitalBookId}`}>
          <div className="text-center img-wrapper">
            <img src={props.imageUrl} className="img-fluid" alt={props.name} />
            <img
              src={require("../../assets/images/new.png")}
              alt=""
              className="ekDokuman"
              style={{
                visibility: props.dosyaVarmi ? "visible" : "hidden",
              }}
              //width="125"
              //height="125"
            />
          </div>
          <div className="card-book-operations">
            <div className="title" title={props.name}>
              {props.bookName || props.name}
            </div>
            <ProgressBar
              value={props.progress}
              className="mt-3 d-none d-sm-flex"
            />
            <div className="d-flex flex-column d-sm-none text-center align-items-center">
              <ScoreBadge
                icon="point"
                title={`+${Math.round(
                  calc(productPrice, "*", props.activityType.Score)
                )}  Puan`}
                className="badge-score-custom mb-2"
                value={calc(
                  productPrice,
                  "*",
                  props.activityType.Score
                ).toFixed(0)}
              />
              <ScoreBadge
                icon="coin"
                title={`+${Math.round(
                  calc(productPrice, "*", props.activityType.Coins) / 10
                ).toFixed(0)}  Jeton`}
                className="badge-coin"
                value={calc(
                  productPrice,
                  "*",
                  props.activityType.Coins
                ).toFixed(0)}
              />
            </div>
          </div>
        </Link>
      ) : null}
    </div>
  ) : (
    <div
      className={classNames(
        "card card-book box-shadow-none p-xs-0 border-success mb-4 mb-sm-2",
        className
      )}
    >
      {props.id ? (
        <Link to={`/dijitalkitap/book/lessons/${props.digitalBookId}`}>
          <div
            className="text-center position-relative product-image"
            style={{ minHeight: "320px" }}
          >
            <img src={props.imageUrl} className="img-fluid" alt={props.name} />
            <img
              src={require("../../assets/images/new.png")}
              alt=""
              className="ekDokuman"
              style={{
                visibility: props.dosyaVarmi ? "visible" : "hidden",
              }}
              //width="125"
              //height="125"
            />
          </div>

          <div className="title" title={props.name}>
            {props.name}
          </div>
          <ProgressBar value={props.progress} className="mt-3" />
        </Link>
      ) : null}
    </div>
  );
};

Book.propTypes = {
  className: PropTypes.array,
};

Book.defaultPropTypes = {
  className: [""],
};

export default Book;
