import React, { PureComponent } from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import * as actions from "../../routes/actions/Account";

const status = {
    waiting: "waiting",
    ok: "ok",
    error: "error"
};

class Verification extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            status: status.waiting,
            message: "Kontrol ediliyor lütfen bekleyiniz"
        };
    }

    async componentDidMount() {
        const model = queryString.parse(this.props.location.search);

        const { success, error } = await this.props.verify(model);

        if (success) {
            this.setState({
                status: status.ok,
                message: "Doğrulama işlemi başarılıyla sonuçlandı."
            });
        }
        else {
            this.setState({ status: status.error, message: error });
        }
    }

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <h1>Mail Adresi Doğrulama</h1>
                    <div
                        className="d-flex flex-column justify-content-center"
                        style={{ minHeight: 400 }}>
                        {this.state.status === status.waiting ? (
                            <>
                                <div className="alert alert-info fs-16 mb-0" role="alert">
                                    {this.state.message}
                                </div>
                            </>
                        ) : null}
                        {this.state.status === status.ok ? (
                            <>
                                <FontAwesomeIcon
                                    size="5x"
                                    icon={faCheckCircle}
                                    style={{ margin: "0 auto 40px", color: "#59a022" }}
                                />
                                <div className="alert alert-success fs-16 mb-0" role="alert">
                                    {this.state.message}
                                </div>
                            </>
                        ) : null}
                        {this.state.status === status.error ? (
                            <>
                                <FontAwesomeIcon
                                    size="5x"
                                    icon={faTimes}
                                    style={{ margin: "0 auto 40px", color: "#85221c" }}
                                />
                                <div className="alert alert-danger fs-16 mb-0" role="alert">
                                    {this.state.message}
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

Verification.propTypes = {};

const mapStateToProps = ({ AccountReducer }) => ({
    user: AccountReducer.user
});

const mapDispatchToProps = {
    verify: actions.verify
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Verification);
