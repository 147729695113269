export function getVisitProducts() {
    if (typeof window === "undefined") {
        return [];
    }

    const data = window.localStorage.getItem("VISIT_PRODUCTS");
    return data ? JSON.parse(data) : [];
}

export function setVisitProducts(item) {
    if (typeof window === "undefined") {
        return;
    }

    const quantity = 10;

    const products = getVisitProducts();

    const hasItemIndex = products.findIndex(m => m.id === item.id);
    if (hasItemIndex > -1) {
        products.splice(hasItemIndex, 1);
    }
        
    products.push(item);

    window.localStorage.setItem("VISIT_PRODUCTS", JSON.stringify(products.slice(-quantity)));
}