import actionTypes from "../../../actions/Desk/Chapters/types";
// import {
//     defaultValues,
//     keys,
//     reservationStatus
// } from "../../../../services/constants";
// import storage from "../../../../services/storage";

export const initialState = {
    bransAd: '',
    unites: [],
    tests: [],
    branslar: [],
    testId: ''
};


export default (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.GET_BOOKS_BRANS_TEST:
            return { ...state, tests: action.payload.tests, bransAd: action.payload.bransAd, unites: action.payload.unites };
        case actionTypes.GET_BOOKS_BRANS:
            return { ...state, tests: action.payload.tests, branslar: action.payload.branslar, unites: action.payload.unites };
        case actionTypes.CREATE_RANDOM_TEST:
            return { ...state, testId: action.payload.testId };
        default:
            return state

    }

};



