import React from "react";
import ExpireDate from "../../Select/ExpireDate";

const ExpireDateWidget = ({ name, value, onChange }) => {
  const handleChange = value => {
    onChange(name, value);
  };
  return <ExpireDate name={name} onChange={handleChange} value={value || ""} />;
};

export default ExpireDateWidget;
