import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import * as contentActions from "../../routes/actions/Content";
import { connect } from "react-redux";
import Icon from "../../components/Icon/Index";
import { Link } from "react-router-dom";
import apiCall from "../../services/api";
import { toast } from "react-toastify";
import Skeleton from '@material-ui/lab/Skeleton';

const CardBanner = forwardRef(({ mobile, mode, onChange, ...props }, ref) => {
  const [page, setPage] = useState([]);
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    newDataFromParent(newData) {
      setPage(newData);
    },
  }));

  useEffect(() => {
    if (props.bannerName) {
      setLoading(true);
      apiCall(`/contents/content/${props.bannerName}/1`).then((res) => {
        setPage(res.data);
      }).finally(()=> {setLoading(false);});
    }
  }, [props.bannerName]);

  const handleClick = (event) => {
    if (onChange) {
      onChange(event.target.name, props.bannerName, null, event.target.value);
    }
  };

  const toggleShowBanner = async (active) => {
    const newState = {
      ...page,
      active,
    };
    setPage(newState);
    await props.savePage(newState.id, newState);
    const result = await props.publishPage(newState.id);
    if (result.success && active === 1) {
      toast.success("Banner canlıya alındı.");
    }
    if (result.success && active === 0) {
      toast.success("Banner canlıdan çıkarıldı.");
    }
  };

  if (mode !== "draft" && page.active === 0) {
    return null;
  }

  return (
    <>{loading ? 
      (<div className="col-lg-4 col-md-6 col-12 mb-4">
          <Skeleton variant="rect" width={330} height={312} />
      </div>
      )
      :
      (<div className="col-lg-4 col-md-6 col-12 mb-4">
      <div className="main-card">
        {mode === "draft" && (
          <div className="editable-container">
            <button
              className="btn"
              name="editableBanner"
              value="cardBanner"
              onClick={handleClick}
            >
              <Icon icon="iEditable" />
            </button>
            {page?.active === 1 && (
              <button className="btn" onClick={() => toggleShowBanner(0)}>
                <Icon icon="iEyeOff" />
              </button>
            )}
            {page?.active === 0 && (
              <button className="btn" onClick={() => toggleShowBanner(1)}>
                <Icon icon="iEyeOn" />
              </button>
            )}
          </div>
        )}
        <div className="main-card-header">
          <h2>{page.content?.title}</h2>
          <div className="more-arrow"></div>
        </div>
        {page.content?.target ? (
          <a href={page.content?.target || "/"} target="_blank">
            <img
              // srcSet={`${page.content?.mobile} 768w, ${page.content?.web} 1078w`}
              // sizes={`(max-width: 768px) 768w, 100%`}
              src={`${page.content?.image}`}
              className="img-fluid"
              alt={page.content?.title}            
            />
          </a>
        ) : (
          <img
            // srcSet={`${page.content?.mobile} 768w, ${page.content?.web} 1078w`}
            // sizes={`(max-width: 768px) 768w, 100%`}
            src={`${page.content?.image}`}
            className="img-fluid"
            alt=""
          />
        )}
      </div>
    </div>)
    }</>
    
  );
});

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = {
  savePage: contentActions.savePage,
  publishPage: contentActions.publishPage,
};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(CardBanner);
