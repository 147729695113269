import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import classNames from "classnames";
import { customStylesAccount } from "../../helpers";
import { customFilterOption } from "./Utility";
import { getSchools } from "../../routes/actions/Account";

let options = [];
let selected = {};

class School extends Component {
  state = { cityId: null };

  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.cityId !== prevState.cityId) {
      options = [];
      selected = {};
      nextProps.getSchools(nextProps.cityId);
      return { cityId: nextProps.cityId };
    }
    else if (nextProps.cityId == null) {
      return { cityId: null };
    }
    return null;
  }

  shouldComponentUpdate() {
    return true;
  }

  render() {

    const filterOption = (option, inputValue) => {
      const { label, value } = option;
      const otherKey = options.filter(
        opt => opt.label === label && opt.value.includes(inputValue)
      );
      return value.includes(inputValue) || otherKey.length > 0;
    };

    const { schools, onChange, valid, value, readOnly } = this.props;

    if (schools && schools.length && this.state.cityId) {
      options = schools.map((town) => {
        return { value: town.value, label: town.label };
      });
    }
    else {
      options = [];
    }

    selected = this.state.cityId ? options.find((p) => p.value === value) : { value: '', label: 'Lütfen İl ve İlçe seçiniz' };

    return (
      <Select
        inputProps={{ autoComplete: 'off', autoCorrect: 'off', spellCheck: 'off' }}
        isSearchable={true}
        placeholder={options.length === 0 ? 'Lütfen İl ve ilçe seçiniz' : "Seçiniz..."}
        className={classNames(valid === false ? "is-invalid" : "valid")}
        options={options.length ? options : [{ value: '', label: 'Lütfen İl ve İlçe seçiniz' }]}
        styles={customStylesAccount}
        onChange={onChange}
        value={selected || { value: '', label: 'Seçiniz' }}
        filterOption={customFilterOption.customFilterOptionIncludesOption}
        isDisabled={readOnly}
      />
    );
  }
}

School.propTypes = {};

const mapStateToProps = ({ AccountReducer }) => ({
  schools: AccountReducer.schools,
});

const mapDispatchToProps = {
  getSchools: getSchools,
};

export default connect(mapStateToProps, mapDispatchToProps)(School);
