import React from "react";
import {StarO, Start} from "../../assets/images";
//import PropTypes from 'prop-types';
import Rating from "react-rating";

const RatingSelect = (props) => {
    return (
        <Rating
            emptySymbol={<StarO className="icon"/>}
            fullSymbol={<Start className="icon"/>}
            {...props}
        />
    );
};

RatingSelect.propTypes = {};

export default RatingSelect;
