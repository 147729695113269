import actionTypes from "../../actions/Product/types";
import studyActionTypes from "../../actions/Study/types";

import {
  defaultValues,
  keys,
  reservationStatus,
} from "../../../services/constants";
import { getAndCalculateCountsOfTaxonomies } from "../../../services/helpers";
import storage from "../../../services/storage";

const basketKey = storage.getItem(keys.Basket);
const basketCount = basketKey && storage.getItem(keys.BasketCount);

export const initialState = {
  basket: [],
  basketCount: basketCount || 0,
  products: [],
  product: {
    price: 0,
    total: 0,
    images: [],
    thumbnails: [],
    videos: [],
  },
  relatedProducts: [],
  videos: [],
  samplePages: [],
  brands: [],
  allBrands: [],
  coins: [],
  comments: [],
  wallet: {},
  taxonomies: [],
  taxonomiesFooter: [],
  basketTaxonomies: [],
  keyword: "",
  searchResult: [],
  shipping: {
    price: 0,
  },
  basketKey: basketKey || defaultValues.BASKET_KEY,
  notifications: [],
  stores: [],
  reservations: [],
  reservation: {
    storeAddress: {
      lines: "",
      town: {
        name: "",
      },
      city: {
        name: "",
      },
      mobile: "",
    },
    products: [],
  },
  activityTypes: [],
  storeReviews: [],
  pageProducts: [],
  allProducts: [],
  allProductsLoaded: false,
  metadata: [],
  lastAddBuyLaterProductId: "",
  bookAnalysis: [],
  searchIndex: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGOUT:
      return { ...state, notifications: [] };

    case actionTypes.GET_BASKET: {
      const countBasket =
        action.payload.basket && action.payload.basket.items
          ? action.payload.basket.items.length
          : 0;
      storage.setItem(keys.BasketCount, countBasket);

      return action.payload
        ? {
          ...state,
          basket: action.payload.basket.items,
          notifications: action.payload.notifications,
          basketCount: countBasket,
        }
        : {
          ...state,
          basket: [],
          basketCount: countBasket,
          notifications: [],
        };
    }

    case actionTypes.LOGIN:
      return { ...state, notifications: action.payload.notifications };

    case actionTypes.GET_NOTIFICATIONS:
      return { ...state, notifications: action.payload };

    case actionTypes.GET_STORES:
      return { ...state, stores: action.payload };

    case actionTypes.GET_FOOTER_TAXONOMIES:
      return {
        ...state,
        taxonomiesFooter: action.payload.taxonomies,
        allBrands: action.payload.brands,
      };

    case actionTypes.GET_TAXONOMIES:
      return {
        ...state,
        taxonomies: action.payload.taxonomies,
        brands: action.payload.brands,
        metadata: action.payload.metadata,
        taxonomies: getAndCalculateCountsOfTaxonomies(
          action.payload.taxonomies,
          state.allProducts
        ),
      };
    case actionTypes.GET_JUST_TAXONOMIES:
      return {
        ...state,
        taxonomies: getAndCalculateCountsOfTaxonomies(
          action.payload.taxonomies,
          state.allProducts
        ),
      };

    case actionTypes.GET_ALL_PRODUCTS:
      return {
        ...state,
        allProducts: action.payload.products,
        metadata: action.payload.metadata,
        allProductsLoaded: true,
        taxonomies: getAndCalculateCountsOfTaxonomies(
          state.taxonomies,
          action.payload.products
        ),
      };

    case actionTypes.GET_PRODUCTS_BY_ID: {
      let data = {
        ...state,
        products: action.payload.products,
        keyword: action.payload.keyword,
        searchResult: action.payload.keyword.length ? state.searchResult : [],
        product: initialState.product,
        searchIndex: action.payload.searchIndex
          ? action.payload.searchIndex
          : state.searchIndex,
        filters: action.payload.filters,
      };

      if (action.payload.metadata && action.payload.metadata.length) {
        data.metadata = action.payload.metadata;
      }

      return data;
    }

    case actionTypes.GET_PAGE_PRODUCTS:
      return {
        ...state,
        pageProducts: action.payload.products,
      };

    case actionTypes.GET_RELATED_PRODUCTS:
      return {
        ...state,
        relatedProducts: action.payload.relatedProducts,
      };

    case actionTypes.GET_PRODUCT_BY_ID:
      return {
        ...state,
        product: action.payload.product,
        comments: action.payload.comments,
        samplePages: action.payload.samplePages,
        videos: action.payload.videos,
      };

    case actionTypes.GET_BRANDS:
      return {
        ...state,
        brands: action.payload,
        product: initialState.product,
      };

    case actionTypes.GET_COINS:
      return {
        ...state,
        coins: action.payload.coins,
        wallet: action.payload.wallet,
      };

    case actionTypes.GET_COMMENTS:
      return { ...state, comments: action.payload };

    case actionTypes.ADD_PRODUCT_TO_BASKET: {
      let _basketKey;
      if (
        state.basketKey === defaultValues.BASKET_KEY &&
        action.payload.key !== defaultValues.BASKET_KEY
      ) {
        _basketKey = action.payload.key;
        storage.setItem(keys.Basket, _basketKey);
      } else {
        _basketKey = action.payload.key;
      }
      storage.setItem(keys.BasketCount, action.payload.items.length);

      return {
        ...state,
        basket: action.payload.items,
        basketCount: action.payload.items.length,
        basketKey: _basketKey,
      };
    }

    case actionTypes.DELETE_PRODUCT_TO_BASKET:
      storage.setItem(keys.BasketCount, action.payload.items.length);
      return {
        ...state,
        basket: action.payload.items,
        basketCount: action.payload.items.length,
      };

    case actionTypes.DELETE_ALL_BASKET:
      storage.removeItem(keys.BasketCount);
      return {
        ...state,
        basket: [],
        basketCount: 0,
        basketKey: defaultValues.BASKET_KEY,
      };

    case actionTypes.GET_SHIPPING:
      return { ...state, shipping: action.payload };

    case actionTypes.SEARCH:
      return {
        ...state,
        searchResult: action.payload.result,
        keyword: action.payload.keyword,
        searchIndex: action.payload.searchIndex
          ? action.payload.searchIndex
          : state.searchIndex,
      };

    case studyActionTypes.SEARCH_BY_KEYWORD:
      return {
        ...state,
        searchResult: [],
        keyword: action.payload.keyword,
        searchIndex: action.payload.searchIndex
          ? action.payload.searchIndex
          : state.searchIndex,
      };

    case actionTypes.RESET_SEARCH:
      return { ...state, searchResult: [], keyword: "", searchIndex: null };

    case actionTypes.DO_RESERVATION: {
      const newState = { ...state, reservation: action.payload.reservation };

      if (action.payload.resetBasket) {
        storage.setItem(keys.Basket, defaultValues.BASKET_KEY);
        storage.setItem(keys.BasketCount, 0);
        newState.basket = [];
        newState.basketKey = defaultValues.BASKET_KEY;
        newState.basketCount = 0;
      }

      return newState;
    }

    case actionTypes.CANCEL_RESERVATION: {
      const reservations = Object.assign([], state.reservations);
      const reservation =
        state.reservation.id === action.payload.id
          ? initialState.reservation
          : state.reservation;

      reservations.forEach((r) => {
        if (r.id === action.payload.id) {
          r.status = reservationStatus.Canceled;
        }
      });

      return {
        ...state,
        reservations,
        reservation,
      };
    }

    case actionTypes.GET_RESERVATIONS:
      return { ...state, reservations: action.payload };

    case actionTypes.GET_RESERVATION:
      return { ...state, reservation: action.payload };

    case actionTypes.GET_ACTIVITY_TYPES:
      return { ...state, activityTypes: action.payload };

    case actionTypes.GET_STORE_REVIEWS:
      return { ...state, storeReviews: action.payload };

    case actionTypes.GET_BASKET_TAXONOMIES:
      return { ...state, basketTaxonomies: action.payload };

    case actionTypes.ADD_BUY_LATER_PRODUCT: {
      return {
        ...state,
        lastAddBuyLaterProductId: action.payload,
      };
    }
    case actionTypes.GET_BOOK_ANALYSIS: {
      return {
        ...state,
        bookAnalysis: action.payload,
      };
    }
    default:
      return state;
  }
};
