import React, {Component} from "react";
//import PropTypes from &apos;prop-types&apos;;
import {formatPrice} from "../../helpers";
import {connect} from "react-redux";
import * as actions from "../../routes/actions/Account";
import queryString from "query-string";

const paymentMethods = {
    1: "Kredi Kartı",
    2: "Havale/EFT",
    3: "Kapıda Ödeme"
};

class SaleContract extends Component {

    componentDidMount() {
        let qs = queryString.parse(this.props.location.search);
        if (qs && qs.o) {
            this.props.getOrder(qs.o);
        }
    }

    render() {
        const {order, user} = this.props;
        return (
            <>
                <h3 className="mt-2">Mesafeli Satış Sözleşmesi</h3>
                <div className="card">
                    <div className="card-header"></div>
                    <div className="card-body pt-0 fs-14">
                        <h6>
                            1. SATICI 
                        </h6>

                        <table width="100%" style={{marginBottom: "1rem"}}>
                            <tbody>
                                <tr>
                                    <td width="20%">
                                        Ticari Unvanı : 
                                    </td>
                                    <td>
                                        Doğru Kaynak Dağıtım Teknoloji AŞ.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Adresi :
                                    </td>
                                    <td>
                                    Yeşilce Mah. Göktürk Cad. No:20 K.2 Kağıthane/İstanbul
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Telefon : 
                                    </td>
                                    <td>
                                        0 212 963 35 35
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Satıcı Mersis No : 
                                    </td>
                                    <td>
                                        0308092681000001
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Satıcı E-Posta Adresi : 
                                    </td>
                                    <td>
                                        <a href="mailto:destek@dogrukaynak.com">destek@dogrukaynak.com</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table> 

                        <h6>
                            2. ALICI
                        </h6>

                        {
                            order.id && user.id ? (
                                <table width="100%" style={{marginBottom: "1rem"}}>
                                    <tbody>
                                        <tr>
                                            <td width="20%">
                                                Adı - Soyadı / Ünvanı:
                                            </td>
                                            <td>
                                                {user.firstname && `${user.firstname } ${user.lastname}`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Adresi :
                                            </td>
                                            <td>
                                                {order.deliveryAddress && `${order.deliveryAddress.lines} ${order.deliveryAddress.town.name} / ${order.deliveryAddress.city.name}`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Telefon : 
                                            </td>
                                            <td>
                                                {order.deliveryAddress && order.deliveryAddress.mobile}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                E-Posta : 
                                            </td>
                                            <td>
                                                <a href={`mailto:${user.email && user.email}`}>{user.email && user.email}</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> 
                            ) : null
                        }                       

                        <p><strong>3.</strong> İşbu Mesafeli Satış Sözleşmesi ("Sözleşme") 6502 Sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği&apos;ne uygun olarak düzenlenmiştir. İşbu Sözleşme&apos;nin tarafları işbu Sözleşme tahtında 6502 Sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği&apos;nden kaynaklanan yükümlülük ve sorumluluklarını bildiklerini ve anladıklarını kabul ve beyan ederler. İşbu Sözleşmenin konusunu; Alıcı&apos;nın, Doğru Kaynak Dağıtım Teknoloji A.Ş. (&quot;Doğru Kaynak&quot;)&apos;ye ait [www.dogrukaynak.com] alan adlı web sitesinden (&quot;Web sitesi&quot;), Satıcı&apos;ya ait Mal/Hizmetin satın alınmasına yönelik elektronik olarak sipariş verdiği, Sözleşmede belirtilen niteliklere sahip Mal/Hizmetin satışı ve teslimi ile ilgili olarak 6502 Sayılı Tüketicinin Korunması Hakkındaki Kanun ve Mesafeli Sözleşmeler Yönetmeliği hükümleri gereğince tarafların hak ve yükümlülüklerinin saptanması oluşturur.</p>

                        <p><strong>4.</strong> Sözleşme konusu mal ve hizmetin temel nitelikleri ve fiyatı (kdv dahil)</p>

                        {
                            order.id ? (
                                <>
                                    <b>Toplam Satış Bedeli:</b>
                            
                                    {order.total && formatPrice(order.total)}
                                    
                                    <br/>
                                    
                                    <b>Ürünler: </b>

                                    <br/>

                                    <ul>
                                        {
                                            order.lines && order.lines.map((item, i) => {
                                                return (
                                                    <li key={i}>
                                                        {`${item.product.name} : ${formatPrice(item.total + item.taxTotal)}`}
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>

                                    <b>Ödeme Şekli : </b>

                                    {order.paymentMethod && paymentMethods[order.paymentMethod]}

                                    <br/>
                                    
                                    <b>Teslim Edilecek Adres : </b>
                                    
                                    {order.deliveryAddress && `${order.deliveryAddress.lines} ${order.deliveryAddress.town.name} / ${order.deliveryAddress.city.name}`}
                                    
                                    <br/>
                                    
                                    <b>Fatura Edilecek Kişi/Kurum : </b>
                                    
                                    {order.billAddress && `${order.billAddress.firstname} ${order.billAddress.lastname}`}
                                    
                                    <br/>
                                    
                                    <b>Fatura Adresi : </b>
                                    
                                    {order.billAddress && `${order.billAddress.lines} ${order.billAddress.town.name} / ${order.billAddress.city.name}`}
                                    
                                    <br/><br/>
                                </>
                            ) : null
                        }

                        <p><strong>5.</strong> 18 yaşından küçük kişiler Doğru Kaynak&apos;tan alışveriş yapamaz. www.dogrukaynak.com çocuklara yönelik ürünler satışa sunmaktadır; ancak bunları yetişkinlere satmaktadır.</p>

                        <p><strong>6.</strong> Doğru Kaynak&apos;tan kredi kartı (Visa, MasterCard), banka havalesi, kapıda ödeme, BKM Express ile alışveriş yapılabilir. Bir hafta içinde havalesi yapılmayan siparişler iptal edilir. Ödemeli gönderi ya da posta çeki gibi belirtilmeyen ödeme yöntemleri kabul edilmez.</p>

                        <p><strong>7.</strong> Doğru Kaynak tüm siparişleriniz sonucu yapmış olduğunuz ödemeyi tarafınıza elektronik fatura olarak üyelik halinde kaydetmiş olduğunuz e-posta adresine yollamaktadır. Ayrıca ürün, irsaliyeli fatura eşliğinde teslim edilmektedir. Yapılan alışverişlerdeki tüm fiyatlar TL olarak ifade edilmiştir.</p>

                        <p><strong>8.</strong> Sözleşmenin imzalandığı tarihte var olmayan ve ön görülmeyen, tarafların kontrolleri dışında gelişen, ortaya çıkmasıyla taraflardan birinin ya da her ikisinin de sözleşme ile yüklendikleri borç ve sorumluluklarını kısmen ya da tamamen yerine getirmelerini ya da bunları zamanında yerine getirmelerini imkansızlaştıran haller, mücbir sebep (Tabii afet, savaş, terör, ayaklanma, mevzuat hükümleri, el koyma veya grev, lokavt, üretim ve iletişim tesislerinde önemli mahiyette arıza, hava muhalefeti ve benzeri hallerde) olarak kabul edilecektir. Mücbir sebep ile sözleşme konusu ürünün/ürünlerin süresi içinde teslim edilememesi halinde durumu Doğru Kaynak 3 (üç) gün içinde bildirecek olup, bu takdirde siparişin iptal edilmesi, sözleşmeye konu ürünün/ürünlerin varsa emsali ile değiştirilmesi ve/veya teslimat süresinin engelleyici durumun ortadan kalkmasına kadar ertelenmesi haklarından biri kullanabilir. Siparişin iptal edilmesi halinde ödenen tutar 14 (ondört) gün içinde Doğru Kaynak tarafından iade edilecektir.</p>

                        <p><strong>9.</strong> Doğru Kaynak sattığı yüzbinlerce ürün için tüm sanal alışveriş siteleri gibi stok tutmamaktadır. Doğru Kaynak kendi stok yapısınca, ürünleri müşterilerinin siparişlerine göre tedarik etmektedir. Tüm ürünler için listeler her gün güncellense de sipariş verilen bazı ürünler tedarikçi kuruluşların stoklarında tükenmiş olabilir. Böyle durumlarda ilgili bölümümüz tarafından size e-posta ile bilgi verilir ve ürün bedeli kartınıza iade edilir. Eğer siparişinizi banka havalesi seçeneğini seçerek verdiyseniz, ürünlerin bulunamama durumunda sadece ürün bedelleri iade edilir. Bankalar tarafından uygulanan havale masrafları ile Doğru Kaynak&apos; in bir ilgisi yoktur. O yüzden havale ödemelerinizi bankaların internet şubeleri ve ATM&apos;ler üzerinden yapmanızı öneririz.</p>

                        <p><strong>10.</strong> Tahsilatı yapılmış bir siparişin teslimatı www.dogrukaynak.com tarafından anlaşmalı kargo şirketleri ile gerçekleştirilecektir. Kargo hakkında detaylı bilgiyi aşağıda bulabilirsiniz.</p>

                        <p><strong>11.</strong> Paketlerinizin, alışverişiniz sırasında size belirtilen süreler içinde teslim edilmemesi durumunda <a href="mailto:destek@dogrukaynak.com">destek@dogrukaynak.com</a>&apos;a e-posta gönderebilirsiniz. Kargo Şirketi müşteri tarafından aranarak paket takibi yapılabilir. Paket teslim alındıktan sonra kargo firmasının görevini layıkıyla yaptığı kabul edilmiş sayılır. Bu yüzden; Teslimat sırasında hasarlı olduğunu tespit edilen paketler kabul edilmeyip tutanak tutturulmalıdır. Daha sonra, tutanak ile birlikte durum <a href="mailto:destek@dogrukaynak.com">destek@dogrukaynak.com</a>&apos;a bir e-posta aracılığıyla bildirilmelidir. Doğru Kaynak, ürünü yeniden temin edip, en kısa zamanda teslimatın tekrarlanmasını sağlayacaktır.</p>

                        <p><strong>12.</strong> Hiçbir hukuki ve cezai sorumluluk üstlenmeksizin ve hiçbir gerekçe göstermeksizin teslim aldığınız veya sözleşmenin imzalandığı tarihten itibaren ondört gün içerisinde cayma hakkı kullanılamayacak ürünler hariç olarak ürünü/ürünleri sözleşmeden cayarak iade edebilirsiniz. Cayma hakkının kullanılması için bu süre içinde ürünü/ürünleri işleyişine, teknik özelliklerine ve kullanım talimatlarına uygun bir şekilde kullanmış olmak kaydıyla Doğru Kaynak&apos;a cayma hakkı ve iade koşullarında    detayları belirtilen iletişim bilgileri aracılığı ile yazılı olarak bildirimde bulunulması gerekmektedir. Bu bildirimin Doğru Kaynak&apos;a ulaşmasını takiben bildirimin ulaştığına ilişkin teyit bilgisi tarafınızla paylaşılacak ve bildirimin ulaşma tarihini takiben de 14 (ondört) gün içinde cayma hakkına konu ürünün/ürünlerin bedeli ödeme şekline uygun bir şekilde tarafınıza iade edilecektir. Cayma hakkının kullanılması halinde, ürünün/ürünlerin vergi mevzuatı gereği fatura aslının ibrazı ile beraber teslimatı gerçekleştiren kargo firması aracılığı ile iadesi zorunludur. Ancak, müşteri tarafından <strong>fatura aslının gönderilmemesi halinde KDV ve varsa sair yasal kesintiler tarafınıza iade edilemez.</strong> Ürün iadesi hakkında detaylı bilgiyi aşağıda bulabilirsiniz.</p>

                        <p><strong>13.</strong> Doğru Kaynak’tan yapmış olduğunuz alışverişler ve sitede yapılması gereken bazı talimatlandırmalar sonucu almış olduğunuz puanların Jeton’a dönüştürülerek hediye kazanmanız halinde, satın alma işlemi gerçekleştirilen tutar avans, hesabınızda alacak olarak süre sınırı olmaksızın saklanmaktadır. Alacağınızdaki tutar için istediğiniz zaman iade talebinde bulunarak ödemenin yapıldığı kartınıza iadeyi sağlayabilirsiniz. Alımınız karşılığında verilen hediye tutarlar sadece www.dogrukaynak.com web sitesinde alışverişlerde kullanılabilir. İade durumunda hediye olarak tanımlanan miktar iptal edilir. Hediyeler iadeye yansıtılmaz.</p>

                        <p><strong>14.</strong> Kredi kartı bilgileriniz sistemde kayıtlı olarak tutulmamaktadır. </p>

                        <p><strong>15.</strong> Sözleşme konusu ürün/ürünler, yasa ve yönetmeliklerle belirlenen siparişin SATICI&apos;ya ulaşmasını takip eden, yasal 30 günlük süreyi aşmamak koşulu ile her bir ürün için ALICI&apos;nın yerleşim yerinin uzaklığına bağlı olarak işbu 30 (otuz) günlük süre içinde ALICI veya gösterdiği adresteki kişi/kuruluşa teslim edilir. Şu kadar ki, ön sipariş ürününde(lerinde) işbu 30 günlük yasal süre ilgili ürüne ait satış sayfasında ilan edilen satış tarihinden itibaren başlayacak olup, işbu satış tarihinde tedarikçi firma kaynaklı gecikmeler yaşanabilir. İşbu halde SATICI ALICI&apos;yı öncesinde yazılı olarak bilgilendirecektir. Bu halde de ALICI siparişin iptal edilmesini veya teslimat süresini bekleyerek siparişe konu ürün(lerin) teslim edilmesi haklarından birini kullanabilir. ALICI&apos;nın siparişi iptal etmesi halinde ödediği tutar 14 (ondört) gün içinde kendisine nakden ve defaten ödenir. E-kitap satışlarında ürün(ler) yasa ve yönetmeliklerle belirlenen siparişin SATICI&apos;ya ulaşmasını takip eden yasal 30 günlük süreyi aşmamak koşulu ile işbu 30 günlük süre içinde ALICI tarafından bildirilen e-mail adresine teslim edilir.</p>

                        <p><strong>16.</strong> www.dogrukaynak.com internet sitesinde, sözleşme konusunu oluşturan tüm ürünlerin esaslı nitelikleri, özellikleri, KDV dahil satış fiyatı, ödeme ve teslimat şekli ile tedarik ve teslim sürelerine ilişkin esaslı bilgileri okuyup ürünler hakkında tüm bilgilere sahip olduğunuzu ve bu bilgiler doğrultusunda elektronik ortamda satın almaya ilişkin onayı verdiğinizi kabul ve beyan etmektesiniz.{" "}
                        www.dogrukaynak.com&apos;dan alışveriş yapan kişi yukarıdaki tüm maddeleri elektronik ortamda okuyup, kabul edilip, teyit etmiş sayılır. <strong>Bu sözleşmede düzenlenmemiş hususlarda 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği hükümleri uygulanır.</strong></p>

                        <h6>KARGO</h6> 
                        
                        <p><strong>1.</strong> Kargo seçenekleri sipariş sırasında müşterilerimize sunulmaktadır.</p>
                        <p><strong>2.</strong> Doğru Kaynak gerek duyduğunda daha hızlı gönderimde bulunmak amacıyla kargo firması değişikliği yapabilir. Doğru Kaynak&apos;ta müşteri memnuniyeti nedeniyle yapılan değişiklik için ek bir ücret talep edilmez.</p>
                        <p><strong>3.</strong> Ortalama teslim süreleri: Özel Kargo Şirketi&apos;yle tahmini 3-7 gün ve her hâlükârda 30 günlük yasal süre içerisinde teslimat yapılmaktadır. Türkiye dışına gönderim durumunda bu süreler, ülkeye göre değişiklik göstermektedir.</p>
                        <p><strong>4.</strong> Türkiye içi gönderilerde seçtiğiniz teslimatçı firma, sipariş sürecinde kargo firması ve ücret seçenekleri adres aşamasından sonra kargo sayfasında bildirilmektedir. Şu kadar ki, satış anında yürüttüğümüz ve/veya www.dogrukaynak.com&apos;da şartlarını ilan ettiğimiz kampanyaların sonucuna bağlı olarak söz konusu kargo ücretinin tamamını ya da bir kısmını tarafınıza yansıtmayabiliriz.</p>


                        <h6>İADE</h6>

                        <p>Doğru Kaynak iade işlemlerinde 6502 sayılı Tüketicinin Koruması Hakkında Kanunu çerçevesinde hareket eder.</p>
                        <p>Hiçbir gerekçe belirtmeksizin yapabileceğiniz ürün iadesi ve koşulları: Doğru Kaynak&apos;tan satın aldığınız her ürünü/ürünleri -cayma hakkı kullanılamayacak ürünler hariç olarak-, teslim veya sözleşmenin imzalandığı tarihinden itibaren 14 (ondört) gün içerisinde ürün hasarlı olmasa dahi -hiçbir gerekçe belirtmeksizin- iade edebilirsiniz.</p>
                        <p>İade için, satın aldığınız ürünün kullanılmamış, tahrip edilmemiş olması ve cayma hakkına dair yazılı bildirimde bulunulması gerekmektedir. Bu bildirimin Doğru Kaynak&apos;a ulaşmasını takiben bildirimin ulaştığına ilişkin teyit bilgisi tarafınızla paylaşılacak ve bildirimin ulaşma tarihini takiben de 14 (ondört) gün içinde cayma hakkına konu ürünün/ürünlerin bedeli ödeme şekline uygun bir şekilde tarafınıza iade edilecektir. İade edilecek ürünlerin mutlaka ürün faturasının orijinali ile birlikte teslimi gerekmektedir.</p>
                        
                        <p><strong>Cayma hakkının kullanılması halinde, fatura aslının iadesi zorunludur. Ancak, fatura aslının gönderilmemesi halinde KDV ve varsa sair yasal kesintiler iade edilemeyecektir. Aşağıda detayları belirtilen ürünler cayma hakkı kapsamında iade edilemez;</strong></p>

                        <ul>
                            <li>Fiyatı finansal piyasalardaki dalgalanmalara bağlı olarak değişen ve satıcı veya sağlayıcının kontrolünde olmayan mal veya hizmetlere ilişkin sözleşmeler.</li>
                            <li>Tüketicinin istekleri veya kişisel ihtiyaçları doğrultusunda hazırlanan mallara ilişkin sözleşmeler.</li>
                            <li>Çabuk bozulabilen veya son kullanma tarihi geçebilecek malların teslimine ilişkin sözleşmeler.</li>
                            <li>Tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olan mallardan; iadesi sağlık ve hijyen açısından uygun olmayanların teslimine ilişkin sözleşmeler.</li>
                            <li>Tesliminden sonra başka ürünlerle karışan ve doğası gereği ayrıştırılması mümkün olmayan mallara ilişkin sözleşmeler.</li>
                            <li><strong>Malın tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olması halinde maddi ortamda sunulan kitap, dijital içerik ve bilgisayar sarf malzemelerine ilişkin sözleşmeler.</strong></li>
                            <li>Abonelik sözleşmesi kapsamında sağlananlar dışında, gazete ve dergi gibi süreli yayınların teslimine ilişkin sözleşmeler.</li>
                            <li>Belirli bir tarihte veya dönemde yapılması gereken, konaklama, eşya taşıma, araba kiralama, yiyecek-içecek tedariki ve eğlence veya dinlenme amacıyla yapılan boş zamanın değerlendirilmesine ilişkin sözleşmeler.</li>
                            <li><strong>Elektronik ortamda anında ifa edilen hizmetler veya tüketiciye anında teslim edilen gayri maddi mallara ilişkin sözleşmeler.</strong></li>
                            <li>Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile ifasına başlanan hizmetlere ilişkin sözleşmeler.
                                İadesi yapılacak ürün/ürünler teslimatın yapıldığı Doğru Kaynak&apos; in çalıştığı kargo şirketleri aracılığı ile tarafımıza ulaştırılmalıdır. Ürün, paket koruyucu bir ambalaj içerisine yerleştirilmeli ve kargo şirketinin etiketi ürün ambalajının üzerinde bulunmamalıdır. Ürünün ambalajında meydana gelecek olan deformasyon, ürünün tekrar satılmasını engelleyen durumlar arasında yer almaktadır.
                                Arızalı/hasarlı/bozuk (ayıplı) ürünlere ilişkin iade koşulları</li>
                        </ul>

                        <p><strong>Arızalı/hasarlı/bozuk (ayıplı) ürünlere ilişkin iade koşulları</strong></p>

                        <ul>
                            <li>Ambalajında, etiketinde, tanıtma ve kullanma kılavuzunda ya da reklâm ve ilanlarında yer alan veya satıcı tarafından bildirilen veya standardında veya teknik düzenlemesinde tespit edilen nitelik veya niteliği etkileyen niceliğine aykırı olan ya da tahsis veya kullanım amacı bakımından değerini veya tüketicinin ondan beklediği faydaları azaltan veya ortadan kaldıran maddi, hukuki veya ekonomik eksiklikler içeren mallar, ayıplı mal olarak kabul edilmektedir.</li>
                            <li>Tüketici, malın teslimi tarihinden itibaren 6 (altı) ay içerisinde ayıbı satıcıya bildirmekle yükümlüdür.</li>
                            <li>Tüketici bu durumda, satılanı geri vermeye hazır olduğunu bildirerek sözleşmeden dönme, satılanı alıkoyup ayıp oranında satış bedelinden indirim isteme, aşırı bir masraf gerektirmediği takdirde, bütün masrafları satıcıya ait olmak üzere satılanın ücretsiz onarılmasını isteme ve imkân varsa, satılanın ayıpsız bir misli ile değiştirilmesini isteme haklarına sahiptir.</li>
                            <li>Doğru Kaynak&apos;tan satın aldığınız Garanti Belgesi Yönetmeliği&apos;ne tabii olan tüm sanayi malları, imalatçı veya ithalatçı firmanın garantisi altındadır.</li>
                            <li>Satın aldığınız ürünün ayıplı olması halinde durumu teslim tarihinden itibaren en geç 6 (altı) ay içerisinde yazılı olarak Doğru Kaynak&apos;a bildiriniz.</li>
                            <li>Size en yakın yetkili teknik servise giderek ürününüzün tamirini gerçekleştirebilir ve bu sayede zaman tasarrufu da sağlayabilirsiniz. (Ürüne ilişkin garanti belgesi ve tanıtma ve kullanma kılavuzunu inceleyiniz.)</li>
                        </ul>

                        <p><strong>Satın aldığınız ürünün ayıplı olduğu gerekçesi ile Doğru Kaynak&apos;a ulaştırılması halinde aşağıda belirtilen işlemler uygulanmaktadır:</strong></p>

                        <ul>
                            <li>Ayıplı olduğu gerekçesi ile Doğru Kaynak&apos;a ulaştırılan ürünler, ulaştığı tarihten itibaren en geç 3 iş günü içerisinde işleme alınmaktadır.</li>
                            <li>Ayıplı olduğu gerekçesi ile gönderilen ürünler sorumlusu tarafından teslim alınmakta ve şirket kayıtlarına işlenmektedir.</li>
                            <li>Ürün ile ilgili hata tespit edilmekte, ürünün bir teknoloji ürünü olması ve belirgin bir hatasının mevcut bulunması halinde ise teknoloji dükkanı yetkilileri tarafından test edilmektedir.</li>
                            <li>Ayıplı olduğu gerekçesi ile gönderilen ürünler, Doğru Kaynak&apos; e ulaştığı tarihten en geç 3 iş günü sonra yetkili teknik servise veya teminci şirkete gönderilmektedir. Ürünün yetkili teknik serviste veya teminci şirkette kaldığı süre ürünün özelliklerine göre değişim gösterebilmektedir. Ancak Garanti Belgesi Yönetmeliği&apos;ne tabii üründe/ürünlerde azami tamir süresi 20 iş günüdür.</li>
                            <li>Ürüne ilişkin yetkili teknik servis veya tedarikçi kuruluştan gelen bilgi ve sonuçlar (tamir veya değişim) telefon veya e-posta ile tarafınıza iletilmektedir.</li>
                            <li>Yetkili teknik serviste geçen sürenin sonunda tamir edilen ürün tarafınıza tamir edilmiş hali ile gönderilmekte, iadesinin uygun olacağı yönünde görüş belirtilen ürün ise talebiniz kapsamında birebir yenisi ile değiştirilmekte veya bedel iadesi yapılmaktadır. Bedel iadesinin talep edilmesi halinde ayıplı ürün bedeli 14 gün içerisinde ödeme şekline uygun olarak iade edilmektedir. Şu kadar ki, kullanıcı hatası sonucu ayıplı hale geldiği tespit edilen ürün/ürünler için bu hakların kullanımı söz konusu olmayacaktır.</li>
                        </ul>

                        <p><strong>Ayıplı olduğu gerekçesi ile Doğru Kaynak&apos;a yapılan ürün gönderilerinin aşağıda belirtilen şartları taşıması gerekmektedir:</strong></p>

                        <ul>
                            <li>Sipariş ile birlikte size gönderilen iade formu eksiksiz olarak doldurulmalıdır.</li>
                            <li>Üründeki arıza veya sorun net bir şekilde anlatılmalıdır.</li>
                            <li>Ürünün, tüm aksesuarları ve orijinal kutusu ile birlikte gönderilmesi gerekmektedir.</li>
                            <li>Ürün, paket koruyucu uygun bir ambalaj içerisine yerleştirilmeli ve kargo şirketinin etiketi ürün ambalajının üzerinde bulunmamalıdır.</li>
                            <li>Ürün, Doğru Kaynak&apos;ın çalıştığı teslimatı gerçekleştiren kargo şirketleri aracılığı ile tarafımıza ulaştırılmalıdır. Bu ürüne/ürünlere ait kargo ücreti Doğru Kaynak tarafından karşılanacaktır.</li>
                        </ul>

                        <p><strong>Sanayi mallarına ilişkin garanti belgesi ve süresi</strong></p>

                        <ul>
                            <li>İmalatçı veya ithalatçılar, ithal ettikleri veya ürettikleri sanayi malları için Gümrük ve Ticaret Bakanlığı tarafından onaylı garanti belgesi düzenlemek zorundadırlar. Garanti süresi, malın teslim tarihinden itibaren başlar ve asgari iki yıldır.</li>
                            <li>Doğru Kaynak&apos;tan satın aldığınız Garanti Belgesi Yönetmeliği&apos;ne tabii olan tüm sanayi malları, imalatçı veya ithalatçı firmanın garantisi altındadır.</li>
                            <li>Lütfen satın aldığınız ürünün, fatura tarih ve sayısını da içeren garanti belgesini kontrol ediniz.</li>
                        </ul>

                        <p><strong>Sanayi mallarına ilişkin tanıtma ve kullanma kılavuzu</strong></p>
                        
                        <ul>
                            <li>Yurt içinde üretilen veya ithal edilen sanayi mallarının tanıtım, kullanım, bakım ve basit onarımına ilişkin Türkçe kılavuzla ve gerektiğinde uluslararası sembol ve işaretleri kapsayan etiket ile satılması zorunludur.</li>
                            <li>Lütfen satın aldığınız sanayi ürününe ilişkin tanıtma ve kullanma kılavuzunu kontrol ediniz.</li>
                            <li>Ürünün, tanıtma ve kullanma kılavuzunda yer alan hususlara aykırı kullanılmasından kaynaklanan arızalar garanti kapsamı dışında kalmaktadır.</li>
                        </ul>

                        <p><strong>Teslimattan kaynaklanan problemler</strong></p>

                        <ul>
                            <li>Özel Kargo Firması ile yapılan gönderilerde, taşıma sırasında paketin zarar görmesi durumunda zarar Doğru Kaynak tarafından telafi edilir. (Yurtdışı Posta Servisi sigortasız gönderi yaptığı için bu teminat söz konusu değildir).</li>
                        </ul>

                        <p><strong>Zarar görmüş paket durumunda;</strong></p>

                        <ul>
                            <li>Zarar görmüş paketler teslim alınmayarak teslimatçı firmaya tutanak tutturulmalıdır.</li>
                            <li>Eğer teslimatçı firma yetkilisi paketin hasarlı olmadığını düşünüyorsa, orada paketin açılarak ürünlerin hasarsız teslim edildiğini kontrol ettirme ve durumun yine bir tutanakla tespit edilmesini isteme hakkınız vardır.</li>
                            <li>Paket tarafınızdan teslim alındıktan sonra teslimatçı firmanın görevini layıkıyla yaptığı kabul edilmiş sayılır.</li>
                            <li>Paket kabul edilmemiş ve tutanak tutulmuş ise, durum, tutanağın sizde kalan kopyasıyla birlikte en kısa zamanda Doğru Kaynak&apos;a bildirilmelidir. Doğru Kaynak en kısa sürede yeni ve sağlıklı bir teslimatın gerçekleşmesini sağlayacaktır.</li>
                        </ul>

                        <p>Size teslim edilen ürünler ilgili herhangi bir probleminiz durumunda 0 212 963 3535 numaralı telefon ya da <a href="mailto:destek@dogrukaynak.com">destek@dogrukaynak.com</a> adresinden bize ulaşabilirsiniz!</p>
                        <p>Dikkat: Siparişinizle ilgili her tür iletişiminizde mutlaka sipariş numaranızı bildiriniz.</p>
                    </div>
                </div>
            </>
        );
    }
}

SaleContract.propTypes = {};
const mapStateToProps = ({AccountReducer}) => ({
    order: AccountReducer.order,
    user: AccountReducer.user
});

const mapDispatchToProps = {
    getOrder: actions.getOrder
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SaleContract);
