import React from "react";
import { tongucAkademiEndpoint } from "../services/constants";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { loginRequired } from "../routes/actions/Account";
import { Helmet } from "react-helmet";


function TopNavbar({ token, user, ...props }) {
  const isDeskMode = window.location.pathname.startsWith("/dijitalkitap");
  return (
    <nav class="navbar navbar-expand-lg bg-light topnavbar ">
      <Helmet>
        <script src="//cdn.segmentify.com/f0c4fac6-6aa0-4fd2-af95-7d45041524cb/segmentify.js"></script>
        <meta charset="UTF-8" />
      </Helmet>
      <div class="container-fluid d-flex justify-content-center">
        <div>
          <ul class="navbar-nav">
            <li class="nav-item">
              <a
                class="nav-link"
                href={
                  tongucAkademiEndpoint +
                  (token && token.length
                    ? "lwt?token=" + token
                    : "lwt?sessionEnded=true")
                }
              >
                ONLINE EĞİTİM
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link disabled" aria-current="page" href="#">
                |
              </a>
            </li>
            <li class="nav-item">
              <Link
                class={!isDeskMode ? "nav-link disabled active" : "nav-link"}
                to="/"
              >
                KİTAP MAĞAZASI
              </Link>
            </li>

            <li class="nav-item">
              <a class="nav-link disabled" aria-current="page" href="#">
                |
              </a>
            </li>
            <li class="nav-item">
              {user && user.id ? (
                <Link
                  class={isDeskMode ? "nav-link disabled active" : "nav-link"}
                  to="/dijitalkitap"
                >
                  DİJİTAL KİTAP
                </Link>
              ) : (
                <a
                  class={isDeskMode ? "nav-link disabled active" : "nav-link"}
                  href="javascript:;"
                  onClick={() => props.loginRequired(true, "/dijitalkitap")}
                >
                  DİJİTAL KİTAP
                </a>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
const mapStateToProps = ({ AccountReducer }) => ({
  user: AccountReducer.user,
  token: AccountReducer.token,
});

const mapDispatchToProps = {
  loginRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TopNavbar));
