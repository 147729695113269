import React, { PureComponent } from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import * as actions from "../../routes/actions/Account";
import { BaseForm, Modal } from "../../components";
import FormSchemas from "../../variables/FormSchemas";
import { toast } from "react-toastify";
import Icon from "../../components/Icon/Index";
import CloseButton from "../../components/Button/Close";

const status = {
  waiting: "waiting",
  ok: "ok",
  error: "error",
};

class ResetPassword extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      status: status.ok,
      message: "",
      show: false,
    };
  }

  async componentDidMount() {
    const model = queryString.parse(this.props.location?.search);
    if (model.d) {
      const result = await this.props.checkResetPaswordKey(model.d);
      this.setState({ show: true });
      if (result.success) {
        this.setState({
          status: status.ok,
          message: "",
          data: {
            encryptedData: model.d,
          },
        });
      } else {
        this.setState({ status: status.error, message: result.error });
      }
    } else {
      this.setState({
        status: status.error,
        message:
          "Bu sayfaya sadece mailinize gönderilen linke tıklayarak ulaşabilirsiniz.",
      });
    }
  }

  async handleSubmit(data) {
    var result = await this.props.changePasswordWithKey(data);
    if (result.success) {
      toast.success("Şifreniz değiştirildi. Artık giriş yapabilirsiniz.");
      this.props.history.push("/");
    } else {
      toast.error(result.error);
    }
  }
  closeModal = () => {
    this.setState({ show: false });
  };
  render() {
    return (
      <div>
        <Modal
          className="resetPasswordModal modal-container non-modal-height"
          show={this.state.show} onCloseClick={this.closeModal}
        >
          <div className="modal-header">
            <span />
            <Icon icon="iLock" className="mr-2" />
            <h6 className="fs-20 font-weight-bold">Şifrenizi Yenileyin</h6>
            <CloseButton onClick={this.closeModal} />
          </div>
          <div className="modal-body">
            <div className="d-flex flex-column justify-content-center">
              {this.state.status === status.waiting ? (
                <>
                  <div className="alert-info fs-16 mb-0" role="alert">
                    {this.state.message}
                  </div>
                </>
              ) : null}
              {this.state.status === status.ok ? (
                <div className="fs-16 mb-0" role="alert">
                  <BaseForm
                    schema={FormSchemas.resetPassword.schema}
                    uiSchema={FormSchemas.resetPassword.uiSchema}
                    formData={this.state.data}
                    onSubmit={(data) => this.handleSubmit(data)}
                  />
                </div>
              ) : null}
              {this.state.status === status.error ? (
                <>
                  <FontAwesomeIcon
                    size="5x"
                    icon={faTimes}
                    style={{ margin: "0 auto 40px", color: "#85221c" }}
                  />
                  <div className="alert-danger fs-16 mb-0" role="alert">
                    {this.state.message}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

ResetPassword.propTypes = {};

const mapStateToProps = ({ AccountReducer }) => ({
  user: AccountReducer.user,
});

const mapDispatchToProps = {
  checkResetPaswordKey: actions.checkResetPaswordKey,
  changePasswordWithKey: actions.changePasswordWithKey,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
