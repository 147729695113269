import React, { Component } from 'react';

import * as actions from "../../routes/actions/Account";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

class UnlockUser extends Component {
    state = {
        token: null,
        status: false
    }
    constructor(props) {
        super(props);
        const token = this.props.location.search.split("=")[1];
        this.state = {
            token: token,
        };
    }
    async handleSubmit(token) {
        var result = await this.props.unlockUser(token);
        this.setState(
         {
                status: result.success,
                message: result.message
            }
        );

        if (result.success) {
            // todo timeout
            this.interval = setInterval(() => {
                this.props.history.push('/');
            }, 5000);

        } else {
            if (!this.state.token)
                toast.error(result.message);
        }
    }

    async componentDidMount() {
        if (this.state.token) {
            this.handleSubmit(this.state.token);
        }
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }


    render() {
        return (
            <>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <br />
                        <h1>Kilit kaldırma</h1>
                        <br />
                        {
                            this.state.status &&
                            <div>
                                <div className="row">
                                    <div className="col-12">
                                        <p> Kullanıcı giriş bloğu kaldırıldı, ana sayfaya yönlendiriliyorsunuz.</p>
                                    </div>
                                    <div className="col-12">
                                        <a className="btn-primary p-2" href={window.location.origin.toString()}>Ana Sayfa</a>
                                    </div>
                                </div>
                            </div>

                        }
                        {
                            this.state.token && !this.state.status &&
                            <div>
                                <div className="row">
                                    <div className="col-12">
                                        <p> {this.state.message}</p>
                                    </div>
                                </div>
                            </div>

                        }

                    </div>
                </div>
            </>

        )
    }


}

UnlockUser.propTypes = {};

const mapStateToProps = () => ({
});

const mapDispatchToProps = {
    unlockUser: actions.unlockUser
};


export default connect(mapStateToProps, mapDispatchToProps)(UnlockUser);