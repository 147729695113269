import React, { Component } from "react";
//import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { ProductList } from "../../components";
import * as actions from "../../routes/actions/Product";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import Select from "react-select";
import { customStyles } from "../../helpers";
// const options = [
//   {
//     value: "chocolate",
//     label: "Yeni Nesil Türkçe - Matematik (ISBN: 978-605-7568-52-6)"
//   },
//   {
//     value: "strawberry",
//     label: "LGS Nutuk Denemeleri (ISBN: 978-605-7568-52-7)"
//   },
//   {
//     value: "vanilla",
//     label:
//       "TYT Matematik Problemler Geometri MPG Seti (ISBN: 978-605-7568-52-8)"
//   }
// ];

class RelatedProducts extends Component {
  state = {
    selectedProduct: 0,
    flag: true,
    gpFlag: true,
  };

  async componentDidUpdate() {
    if (this.state.flag === true && this.props.values.length > 0) {
      await this.getProducts();
      this.setState({ flag: false });
    }
    if (this.props.editable && this.state.gpFlag === true) {
      let brandId = this.props.user.brandId;
      if (this.props.page && this.props.page.brandId) {
        brandId = this.props.page.brandId;
      }

      this.props.getPageProducts("", brandId || "", "", "");

      this.setState({ gpFlag: false });
    }
  }

  getProducts = async () => {
    if (this.props.values.length > 0) {
      this.props.getRelatedProducts("", "", "", this.props.values.join(","));
    }
  };

  handleAdd = async () => {
    await this.props.addOrRemoveProduct(this.state.selectedProduct);
    this.getProducts();
  };

  handleRemove = async (id) => {
    await this.props.addOrRemoveProduct(id);
    this.getProducts();
  };
  async componentDidUpdate(prevProps) {
    if (prevProps.values !== this.props.values) {
      await this.getProducts();
      if (this.props.editable && this.state.gpFlag === true) {
        let brandId = this.props.user.brandId;
        if (this.props.page && this.props.page.brandId) {
          brandId = this.props.page.brandId;
        }
  
        this.props.getPageProducts("", brandId || "", "", "");
  
        this.setState({ gpFlag: false });
      }
    }
  }
  render() {
    const { relatedProducts, pageProducts } = this.props;
    var options =
      pageProducts && pageProducts.map((x) => ({ value: x.id, label: x.name }));
    const show = this.props.editable || relatedProducts.length > 0;
    return (
      show && (
        <div className="row">
          <div className="container">
            <div className="col-xl-9 col-sm-6 mb-4 pt-1">
              <h6 className="fs-20 color-484848 font-weight-500">
                İlgili Ürünler
              </h6>
            </div>
            {this.props.editable && (
              <div className="col-xl-3 col-sm-6 mb-4">
                <Select
                  options={options}
                  styles={customStyles}
                  placeholder="Ürün Ekle"
                  className="w-100"
                  isSearchable
                  onChange={(item) =>
                    this.setState({ selectedProduct: item.value })
                  }
                />
                <button
                  type="button"
                  className="btn btn-primary mt-2 text-capitalize w-100"
                  onClick={this.handleAdd}
                  name="draft"
                >
                  <FontAwesomeIcon className="mr-2" icon={faPlusCircle} /> Ekle
                </button>
              </div>
            )}
            <div className="col-12 ilgiliUrunler">
              <ProductList 
                items={
                  relatedProducts.length > 0 && this.props.values.length > 0
                    ? relatedProducts
                    : []
                }
                showCloseButton={this.props.editable}
                handleRemove={this.handleRemove}
              />
            </div>
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = ({ ProductReducer, AccountReducer }) => ({
  relatedProducts: ProductReducer.relatedProducts,
  pageProducts: ProductReducer.pageProducts,
  user: AccountReducer.user,
});

const mapDispatchToProps = {
  getProductsById: actions.getProductsById,
  getPageProducts: actions.getPageProducts,
  getRelatedProducts: actions.getRelatedProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(RelatedProducts);
