import React, { Component } from "react";
import PropTypes from "prop-types";
import Menu from "./Menu";

class EditableItem extends Component {
  render() {
    return this.props.mode === "draft" && this.props.enable === true ? (
      <Menu items={this.props.items} onItemClick={this.props.onItemClick}>
        {this.props.children}
      </Menu>
    ) : (
      this.props.children
    );
  }
}

EditableItem.defaultProps = {
  enable: true
};

EditableItem.propTypes = {
  mode: PropTypes.oneOf(["draft", "live"]),
  items: PropTypes.array,
  enable: PropTypes.bool
};

export default EditableItem;
