import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useEffect } from "react";
import Slider from "react-slick";
import apiCall from "../../services/api";
import { toast } from "react-toastify";
import * as actions from "../../routes/actions/Product";
import * as recommendationProductActions from "../../routes/actions/RecommendationProducts";
import * as productActions from "../../routes/actions/Product";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { replaceDotWithComma } from "../../helpers";

const tempBookList = [
  {
    id: 1,
    name: "TYT Kitap Setleri",
    image: require(`../../assets/images/images/kitap.png`),
    price: 20,
  },
  {
    id: 2,
    name: "7 Adımda TYT Full Tekrar",
    image: require(`../../assets/images/images/kitap.png`),
    price: 20,
  },
  {
    id: 3,
    name: "7 Adımda TYT Full Tekrar",
    image: require(`../../assets/images/images/kitap.png`),
    price: 20,
  },
  {
    id: 4,
    name: "TYT Kitap Setleri",
    image: require(`../../assets/images/images/kitap.png`),
    price: 20,
  },
  {
    id: 5,
    name: "TYT Kitap Setleri",
    image: require(`../../assets/images/images/kitap.png`),
    price: 20,
  },
  {
    id: 6,
    name: "TYT Kitap Setleri",
    image: require(`../../assets/images/images/kitap.png`),
    price: 20,
  },
];

const ShoppingSlider = forwardRef(
  (
    {
      title,
      url,
      addProductToBasket,
      basketKey,
      basket,
      eventType,
      productId,
      user,
      loggedIn,
      allProducts,
      ...props
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      refresh: () => {
        getDatas();
      },
    }));

    const [bookList, setBookList] = useState([]);
    const [productCount, setProductCount] = useState(1);
    const [model, setModel] = useState([]);




    useEffect(() => {
      getDatas();
    }, [basket]);

    function getDatas() {
      if (url) {
        if (loggedIn == false && url == "/products/recently-viewed") {
          const data = window.localStorage.getItem("VISIT_PRODUCTS");
          if (!data) return;

          setBookList(
            JSON.parse(data)
              .reverse()
              .map((m) => ({
                id: m.id,
                name: m.name,
                image: m.imageUrl,
                price: m.price,
                total: m.total,
                discount: m.discount,
              }))
          );
        } else {
          productActions.getRecentlyViewedProducts().then((data) => {
            var recentlyViewed = [];
            data.map((rvp) => {
              var product = allProducts.find((p) => p.id === rvp.id);
              if (product) {
                recentlyViewed.push({
                  id: product.id,
                  name: product.name,
                  image: product.imageUrl,
                  price: actions.calculateProductPrice(product),
                  total: product.total,
                  discount: product.discount,
                });
              }
            });
            setBookList(recentlyViewed);
          });
        }
      } else if (eventType) {
        const clientId = user ? user.id : "";
        const basket_ = basket.map((item) => ({
          uid: item.product.id,
          sepetfiyati: item.total,
          adet: item.quantity,
        }));
        let model = {
          eventtipi: eventType,
          satiskanali: 1,
          urun: {
            uid: productId,
          },
          sepeturunleri: basket_,
          musteri: {
            mid: clientId,
          },
        };
        props.getRecommendationProducts(model);
      } else {
        setBookList(tempBookList);
      }
    }

    useEffect(() => {
      if (eventType) {
        setBookList(props.recommendationProducts);
      }
    }, [JSON.stringify(props.recommendationProducts)]);

    const basketSubmit = async (product) => {
      const item = {
        key: basketKey,
        productId: product.id,
        quantity: productCount,
        update: false,
        ...product,
        isDigital: product.isDigital,
        product: allProducts.filter((t) => t.id == product.id),
      };
      const { success, error } = await addProductToBasket(
        item,
        props.allTaxonomies
      );

      if (success) {
        toast.success("Ürün sepetinize eklendi.");
      } else {
        toast.error(error.message);
      }
    };

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToScroll: 1,
      swipeToSlide: true,
      slidesToShow: bookList.length < 4 ? bookList.length + 1 : 4,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: bookList.length < 3 ? bookList.length + 1 : 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            arrows: false,
            variableWidth: true,
          },
        },
      ],
    };
    return (
      <div
        className="shopping-slider"
        style={{
          display: bookList.length ? "block" : "none",
        }}
      >
        <div className="d-flex align-items-center">
          <h2>{title}</h2>
        </div>
        <Slider
          {...settings}
          // slidesToShow={bookList.length < 4 ? bookList.length : 4}
        >
          {bookList.map((book) => (
            <div
              key={book.id}
              className="book-detail-container"
              title={book.name}
            >
              <div className="book-detail-box border-0">
                <Link to={`/urunler/detay/${book.id}`}>
                  <div className="img-box">
                    <img
                      className="img-fluid"
                      src={book.image || book.imageUrl}
                      alt=""
                    />
                  </div>
                </Link>
                <div className="book-detail-body">
                  <div className="book-detail-title">{book.name}</div>
                  <div className="d-flex justify-content-around book-sub-detail align-items-center">
                    <div>

                          {book.discount > 0 && (
                            <div className="slide-price-old price-card-slider">
                              {replaceDotWithComma((book.total / (1 - book.discount / 100) || 0).toFixed(2))} ₺
                            </div>
                          )}

                       <div>
                         <span>
                      {" "}
                      {replaceDotWithComma(book.total.toFixed(2))} ₺{" "}
                        </span>
                       </div>
                    </div>

                    <button className="btn" onClick={() => basketSubmit(book)}>
                      Sepete Ekle
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
);

const mapStateToProps = ({
  ProductReducer,
  RecommendationProductsReducer,
  AccountReducer,
}) => ({
  basketKey: ProductReducer.basketKey,
  basket: ProductReducer.basket,
  user: AccountReducer.user,
  recommendationProducts: RecommendationProductsReducer.products,
  allTaxonomies: ProductReducer.taxonomies,
  allProducts: ProductReducer.allProducts,
});
const mapDispatchToProps = {
  addProductToBasket: actions.addProductToBasket,
  getRecommendationProducts:
    recommendationProductActions.getRecommendationProducts,
  getBasket: productActions.getBasket,
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ShoppingSlider);
