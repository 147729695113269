import BaseField from "./BaseField";
import Region from "./Region";
import Checkbox from "./Checkbox";
import LinkField from "./LinkField";
import EmailField from "./EmailField";
import PhoneField from "./PhoneField";
import SchoolField from "./SchoolField";

export default {
    default: BaseField,
    region: Region,
    checkbox: Checkbox,
    link: LinkField,
    email: EmailField,
    phone: PhoneField,
    school: SchoolField
};