import React, { Component } from "react";
//import PropTypes from "prop-types";
import { BlogCategorySelect } from "../..";

class BlogCategory extends Component {
    handleChange = (selected) => {
        const { name, onChange } = this.props;
        onChange(name, selected.value);
    };

    render() {
        const { name, valid, value } = this.props;
        return <BlogCategorySelect name={name} onChange={this.handleChange} value={value} valid={valid} />;
    }
}

BlogCategory.propTypes = {};

export default BlogCategory;
