import React, { Component } from 'react';
import { Switch, Route } from "react-router";
import UnderConstruction from "../views/Message/UnderConstruction";

export default class MessageLayout extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/bakim" component={UnderConstruction} />
            </Switch>
        )
    }
}
