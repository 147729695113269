import React, { Component } from "react";
//import PropTypes from 'prop-types';
import { connect } from "react-redux";
import classNames from "classnames";
import * as actions from "../../routes/actions/Desk/Answer";
import * as studyActions from "../../routes/actions/Study";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  CircleButton,
  Breadcrumb,
  LinkButton,
  ReactPlyr,
} from "../../components";
import queryString from "query-string";
import { InteractionWrapper } from "../../components";
import Icon from "../../components/Icon/Index";
import DislikeModal from "../../components/Modal/DislikeModal";

class Play extends Component {
  constructor(props) {
    super(props);
    const { testId } = this.props.match.params || 0;
    // const { questionNo } = this.props.match.params || 1;

    const search = queryString.parse(this.props.location.search);
    const questionNo = search.q || 1;
    const isVirtual = search.v || false;

    this.testId = parseInt(testId, 10);
    this.questionNo = parseInt(questionNo, 10);
    this.state = {
      question: null,
      testId: this.testId,
      questionNo: this.questionNo,
      isVirtual,
      showDislikeModal: false,
    };
  }

  componentDidMount() {
    this.props.getTestsQuestions(this.testId, this.state.isVirtual);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { testId } = this.props.match.params || 0;
    this.testId = parseInt(testId, 10);
    if (prevState.testId !== this.testId) {
      this.setState({ testId: this.testId });
      this.props.getTestQuestions(this.testId, this.state.isVirtual);
    }
  }
  changePage = (questionNo) => {
    var ordinal = this.props.questions.findIndex((x) => x.No == questionNo);
    if (ordinal == -1) {
      questionNo = this.props.questions[0].No;
    }

    const que = this.props.questions.find((x) => x.No == questionNo);
    this.setState({
      questionNo: questionNo,
      question: que,
    });
  };

  findVimeoId = (link) => {
    const pattern = new RegExp(/\d+/);
    const match = link.match(pattern);
    return match && match.length ? match[0] : "";
  };

  parseVideoUrl = (cndLink) => {
    const {
      query: { url },
    } = queryString.parseUrl(cndLink);
    return url ? url : "";
  };

  render() {
    const { questions, yayinID } = this.props;
    this.state.question =
      questions.length > 0 &&
      questions.find((x) => x.No == this.state.questionNo);

    const questionOptions = studyActions.questionOptions2(
      this.state.question && this.state.question.cevapSecenek
    );

    const breadcrumbs = [
      {
        title: "Kütüphanem",
        url: "/dijitalkitap",
      },
      ...(this.state.isVirtual
        ? []
        : [
            {
              title: this.state.question && this.state.question.YayinAd,
              url: `/dijitalkitap/book/lessons/${yayinID}`,
            },
          ]),
      {
        title: this.state.isVirtual
          ? "Hata Kutusu Testi"
          : this.state.question && this.state.question.TestAd,
        url: `/dijitalkitap/book/lesson/subject/quiz/answers/${this.state.testId}${
          this.state.isVirtual ? "?v=true" : ""
        }`,
      },
      {
        title: "Online Çöz",
        active: true,
      },
    ];

    return (
      <div className="row play-page">
        <div className="col-12 p-xs-0">
          <Breadcrumb items={breadcrumbs} />
        </div>
        <div className="col-12 mb-2 d-flex flex-column flex-md-row justify-content-md-between flex-wrap align-items-center">
          <h1 className="flex-1 w-100">
            {this.state.isVirtual
              ? "Hata Kutusu Testi"
              : this.state.question && this.state.question.TestAd}{" "}
            - Soru {this.state.questionNo}
          </h1>
        </div>
        <div className="col-12 p-xs-0">
          <div className="card mb-2">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-12 col-lg-8 p-xs-0">
                  <div className="d-flex d-lg-none quiz-time-container quiz-time-border flex-row">
                    <div className="d-flex flex-column justify-content-center justify-content-lg-start">
                      <div className="time-box">
                        <img
                          src={require("../../assets/images/icons/icon-clock.svg")}
                          alt="..."
                          width="17"
                        />
                        <span
                          className="color-202124 font-weight-600"
                          style={{ fontSize: 20, marginLeft: 5 }}
                        >
                          {this.state.timePretty}
                        </span>
                      </div>
                    </div>
                    <div className="questions-list">
                      {questions.map((item, index) => (
                        <CircleButton
                          className={classNames(
                            "question-count mx-1 fs-20 font-weight-600",
                            {
                              "btn-active-blue":
                                index + 1 === this.state.questionNo,
                              "btn-not-active-blue":
                                index + 1 !== this.state.questionNo,
                            }
                          )}
                          onClick={() => this.changePage(item.No)}
                          key={item.No}
                        >
                          {/* {this.state.isVirtual ? item.NewNo : item.No} */}
                          {index + 1}
                        </CircleButton>
                      ))}
                    </div>
                    <div className="d-flex">
                      <CircleButton
                        className="btn-default mx-1 arrow-btn d-flex align-items-center justify-content-center wh-40 p-0"
                        icon={faChevronLeft}
                        onClick={() =>
                          this.changePage(this.state.questionNo - 1)
                        }
                      >
                        {"<"}
                      </CircleButton>
                      <CircleButton
                        className="btn-default mx-1 small wh-40"
                        icon={faChevronRight}
                        onClick={() =>
                          this.changePage(this.state.questionNo + 1)
                        }
                      />
                    </div>
                  </div>

                  <Question
                    question={this.state.question}
                    user={this.props.user}
                    parseVideoUrl={this.parseVideoUrl}
                  />

                  <div className="d-flex justify-content-between flex-wrap quiz-time-border py-2">
                    <div className="d-flex mx-md-4">
                      {questionOptions.map((opt) => (
                        <CircleButton
                          className={
                            this.state.question
                              ? classNames(
                                  "wh-40 p-0 mx-1 mx-lg-2 answers-button",
                                  this.state.question.ACevap === opt
                                    ? "selected-btn "
                                    : opt !== this.state.question.OCevap
                                    ? "btn-default"
                                    : "btn-default"
                                )
                              : ""
                          }
                          onClick={() => this.setState({ selected: opt })}
                          key={opt}
                        >
                          {opt}
                        </CircleButton>
                      ))}
                    </div>

                    <div className="mt-2 mt-sm-0 ml-auto d-none d-lg-flex">
                      <CircleButton
                        className="btn-default mx-1 arrow-btn d-flex align-items-center justify-content-center wh-40 p-0"
                        onClick={() =>
                          this.changePage(this.state.questionNo - 1)
                        }
                        icon={faChevronLeft}
                      />
                      <CircleButton
                        className="btn-default mx-1 arrow-btn d-flex align-items-center justify-content-center wh-40 se p-0"
                        onClick={() =>
                          this.changePage(this.state.questionNo + 1)
                        }
                        icon={faChevronRight}
                      />
                    </div>
                  </div>
                  {/* <div className="d-flex flex-column flex-sm-row justify-content-between mt-2">
                    <div className="mx-md-0 mx-lg-4">
                      {questionOptions.map((opt) => (
                        <CircleButton
                          className={
                            this.state.question
                              ? classNames(
                                  "wh-40 p-0 mx-1 mx-lg-2 answers-button",
                                  this.state.question.ACevap === opt
                                    ? "selected-btn "
                                    : opt !== this.state.question.OCevap
                                    ? "btn-default"
                                    : "btn-default"
                                )
                              : ""
                          }
                          onClick={() => this.setState({ selected: opt })}
                          key={opt}
                        >
                          {opt}
                        </CircleButton>
                      ))}
                    </div>
                    <div className="rating-container d-flex">
                      <CircleButton
                        className="wh-40 bg-f4f4f4 mr-1"
                        icon="iLike"
                      />
                      <CircleButton
                        className="wh-40 bg-f4f4f4"
                        icon="iDislike"
                        onClick={() =>
                          this.setState({ showDislikeModal: true })
                        }
                        onCloseClick={() => {
                          this.setState({ showDislikeModal: false });
                        }}
                      />
                    </div>
                    <div className="mt-2 mt-sm-0 ml-auto d-none d-lg-block">
                      <CircleButton
                        className="btn-default mx-1 wh-40 p-0"
                        icon="iChevronLeftNew"
                        onClick={() =>
                          this.changePage(this.state.questionNo - 1)
                        }
                      />
                      <CircleButton
                        className="btn-default mx-1 wh-40 p-0"
                        icon="iChevronRightNew"
                        onClick={() =>
                          this.changePage(this.state.questionNo + 1)
                        }
                      />
                    </div>
                  </div> */}
                </div>

                <div className="col-12 col-lg-3 p-xs-0">
                  <div className="quiz-time-container pt-0 d-flex">
                    <div className="align-items-center d-none d-lg-flex">
                      <div className="font-weight-600 fs-16 color-545454 mr-3">
                        Soru {this.state.questionNo}
                      </div>
                      <div className="time-box">
                        <img
                          src={require("../../assets/images/icons/icon-clock.svg")}
                          alt="..."
                          width="17"
                        />
                        <span
                          className="color-202124 font-weight-600"
                          style={{ fontSize: 20, marginLeft: 5 }}
                        >
                          {this.state.timePretty}
                        </span>
                      </div>
                    </div>
                    {/* <hr className="d-block d-lg-none w-100" /> */}
                    <div className="font-weight-500 fs-500 color-484848 py-0 pb-sm-3 py-lg-3">
                      Doğru Çözme:&nbsp;
                      <span className="font-weight-600">%56</span>
                    </div>
                    <div className="rating-container">
                      <div className="d-flex">
                        <Icon icon="iLike" />
                        115
                      </div>
                      <span />
                      <div className="d-flex">
                        <Icon icon="iDislike" />
                        38
                      </div>
                    </div>
                  </div>

                  <div className="d-none d-lg-flex flex-wrap">
                    {questions.map((item, index) =>
                      questions && questions[index].isDijital === 1 ? (
                        <CircleButton
                          className={classNames("p-0 p-1 ml-2 mr-2 borrad-30", {
                            "btn-success text-white":
                              item === this.state.question,
                            "btn-default": item !== this.state.question,
                          })}
                          onClick={() => this.changePage(item.No)}
                          key={item.No}
                        >
                          {/* {this.state.isVirtual ? item.NewNo : item.No} */}
                          <span
                            style={{
                              width: "76px",
                              height: "16px",
                              margin: "0 0 1px",
                              fontFamily: "inherit",
                              fontSize: "16px",
                              fontWeight: "600",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "1",
                              letterSpacing: "normal",
                              textAlign: "center",
                              display: "block",
                            }}
                          >
                            {index + 1}
                          </span>
                          <span
                            style={{
                              width: "53px",
                              height: "11px",
                              margin: "1px 10px 0 12px",
                              fontFamily: "inherit",
                              fontSize: "9px",
                              fontWeight: "bold",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                              display: "block",
                            }}
                          >
                            Dijital Soru
                          </span>
                        </CircleButton>
                      ) : questions &&
                        questions[index].isCevapMetin === true ? (
                        <CircleButton
                          className={classNames(
                            "wh-40 p-0 wh-60 p-1 ml-2 mr-2 borrad-30",
                            {
                              "btn-success text-white":
                                item === this.state.question,
                              "btn-default": item !== this.state.question,
                            }
                          )}
                          onClick={() => this.changePage(item.No)}
                          key={item.No}
                        >
                          {/* {this.state.isVirtual ? item.NewNo : item.No} */}
                          <span
                            style={{
                              width: "76px",
                              height: "16px",
                              margin: "0 0 1px",
                              fontFamily: "inherit",
                              fontSize: "16px",
                              fontWeight: "600",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "1",
                              letterSpacing: "normal",
                              textAlign: "center",
                              display: "block",
                            }}
                          >
                            {index + 1}
                          </span>
                        </CircleButton>
                      ) : (
                        <CircleButton
                          className={classNames("wh-40 p-0", {
                            "btn-success text-white":
                              item === this.state.question,
                            "btn-default": item !== this.state.question,
                          })}
                          onClick={() => this.changePage(item.No)}
                          key={item.No}
                        >
                          {/* {this.state.isVirtual ? item.NewNo : item.No} */}
                          {item.No}
                        </CircleButton>
                      )
                    )}
                  </div>
                  <LinkButton
                    className="btn-primary fs-14 font-weight-600 border-radius-8 px-5 text-capitalize mt-3 d-block w-100"
                    to={
                      this.state.isVirtual
                        ? `/dijitalkitap/book/lesson/subject/quiz/summary/${this.state.testId}?v=true`
                        : `/dijitalkitap/book/lessons/${this.props.questions && this.props.questions[0] && this.props.questions[0].yayinID}`
                    }
                  >
                    {this.state.isVirtual ? "Sonuca Dön" : "Kitaba Dön"}
                  </LinkButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DislikeModal
          show={this.state.showDislikeModal}
          onCloseClick={() => {
            this.setState({ showDislikeModal: false });
          }}
        />
      </div>
    );
  }
}

Play.propTypes = {};
const Question = ({ question, user, parseVideoUrl }) => {
  const hasQuestion = question && question.ID > 0;
  const showDigitalWarning =
    question && question.isDijital === 1 && question.OCevap.length === 0;
  const isVideoFromCdn =
    question &&
    question.videoLink &&
    question.videoLink.indexOf("source=cdn") > -1;
  return !hasQuestion ? null : showDigitalWarning ? (
    <DigitalWarning question={question} />
  ) : isVideoFromCdn ? (
    <InteractionWrapper
      key={question.ID}
      videoProvider="cloudfront"
      contentType="soru-cozum"
      contentId={question.ID}
      userId={(user && user.id) || 0}
    >
      {(markAsWatched) => (
        <ReactPlyr
          url={question == null ? "" : parseVideoUrl(question.videoLink)}
          type={"video"}
        />
      )}
    </InteractionWrapper>
  ) : (
    <InteractionWrapper
      key={question.ID}
      videoProvider="vimeo"
      contentType="soru-cozum"
      contentId={question.ID}
      userId={(user && user.id) || 0}
    >
      {(markAsWatched) => (
        <div className="v-wrapper">
          <iframe
            title="iframe"
            className="v-video"
            src={
              question.videoLink
                ? `${parseVideoUrl(
                    question.videoLink
                  )}?autoplay=1&color=f34a53&title=0&byline=0&portrait=0`
                : ""
            }
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </InteractionWrapper>
  );
};
const DigitalWarning = ({ question }) => {
  return (
    <div style={{ height: "440px", backgroundColor: "#fffae6" }}>
      <div className="bubbleContainer">
        <div class="col-12  bubble bubble-bottom-left" contenteditable>
          Bu soru bir Dijital Sorudur.{" "}
          <img
            src={require("../../assets/images/icons/video-yt.svg")}
            alt="..."
            width="25"
          />{" "}
          Çözüm videosunu izlemeden önce Dijital Soruyu çözmen gerekir. Önce Çöz
          butonuna tıklayarak soruyu çözebilirsin.
        </div>
        <div className="col-12">
          <button
            onClick={() =>
              (window.location.href =
                "/dijitalkitap/book/lesson/subject/quiz/" +
                question.TestID +
                "?q=" +
                question.No)
            }
            className="text-capitalize px-4 py-1 mr-2 fs-14 digital-question-btn"
            style={{ border: "none", margin: "150px 0 0 0" }}
          >
            <span className="digital-question-btn-text">Önce Çöz</span>
          </button>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({ AnswerReducer, AccountReducer }) => ({
  questions: AnswerReducer.questions,
  yayinID: AnswerReducer.yayinID,
  detail: AnswerReducer.detail,
  classId: AnswerReducer.classId,
  user: AccountReducer.user,
});

const mapDispatchToProps = {
  getTestsQuestions: actions.getTestsQuestions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Play);
