import actionTypes from "../../../actions/Desk/Quiz/types";
// import {
//     defaultValues,
//     keys,
//     reservationStatus
// } from "../../../../services/constants";
// import storage from "../../../../services/storage";

export const initialState = {
  time: 0,
  questions: [],
  answers: {},
  detail: {},
  emoji: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TEST_QUESTIONS:
      return {
        ...state,
        questions: action.payload.questions,
        detail: action.payload.detail,
      };
    case actionTypes.GET_EMOJI:
      return { ...state, emoji: action.payload };
    default:
      return state;
  }
};
