import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getTaxonomies } from "../routes/actions/Product";
import Dropdown from "../components/Dropdown";
import { queryStringParameters } from "../services/constants";

class TopMenu extends PureComponent {

    componentDidMount() {
        this.props.getTaxonomies();
    }

    getMetaData = (taxonomy, taxon) => {

        const currentMetadata = {
            title: "Tonguç'la çalış, Başarıya alış!",
            description: null,
            heading: null,
            content: null,
            slug: ""
        };

        let queryStringValues = [];

        const data = this.props.metadata.find(m => !m.brand && m.categories.length === 1 && m.categories.find(c => c === Number(taxon.id)));

        if (data) {
            currentMetadata.title = data.title;
            currentMetadata.heading = data.heading;
            currentMetadata.description = data.metaDescription;
            currentMetadata.content = data.content;
            currentMetadata.slug = data.slug;
        }

        let taxonParameters = queryStringParameters.filter(q => q.type === "taxon");
        let taxonParameter = taxonParameters.find(tp => taxonomy.code.includes(tp.parentCode));
        queryStringValues.push(`${taxonParameter.key}=${taxon.id}`);

       
        //TM-1873 i=n removed.
        // if (!currentMetadata.content) {
        //     let keywordParameters = queryStringParameters.find(q => q.type === "index");
        //     queryStringValues.push(`${keywordParameters.key}=n`);
        // }

        return currentMetadata.slug.length ? `/urunler/${currentMetadata.slug}?${queryStringValues.join('&')}` : `/urunler?${queryStringValues.join('&')}`;
    }

    render() {
        return (<div className="container-fluid d-none mb-4 bt-dedede bg-white">
            <div className="row">
                <div className="nav-scroller bg-white container ml-auto mr-auto mb-1 mt-4 d-none d-lg-block">
                    {!this.props.isPaymentPage && (
                        <ul className="nav nav-underline" id="classes">
                            <li className="nav-item">
                                <Link className="nav-link text-uppercase" to="/urunler">
                                    Tüm ürünler
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-uppercase" to="/markalar">
                                    Markalar
                                </Link>
                            </li>
                            <li className="nav-item active">
                                <Dropdown
                                    getMetaData={this.getMetaData}
                                    taxonomy={this.props.taxonomies.find(
                                        (t) => t.code === "siniflar"
                                    )}
                                    title="Sınıflar"
                                />
                            </li>
                            <li className="nav-item">
                                <Dropdown
                                    getMetaData={this.getMetaData}
                                    taxonomy={this.props.taxonomies.find(
                                        (t) => t.code === "sinavlar"
                                    )}
                                    title="Sınavlar"
                                />
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link text-uppercase"
                                    to="/urunler?t=59">
                                    Çok satanlar
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link text-uppercase"
                                    to="/urunler?t=61">
                                    Fırsatlar
                                </Link>
                            </li>
                        </ul>
                    )}
                </div>
            </div>
        </div>
        );
    }
}

const mapStateToProps = ({ ProductReducer }) => ({
    taxonomies: ProductReducer.taxonomies,
    metadata: ProductReducer.metadata
});

const mapDispatchToProps = {
    getTaxonomies: getTaxonomies
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TopMenu);
