import React from "react";
import { Link } from "react-router-dom";
import RadialChart from "../Charts/RadialChart";
import { ScoreBadge } from "..";
import Tooltip from "../Tooltip/Tooltip";

const MetricHorizontal = ({
  showFirstLetter,
  title,
  value,
  awardCount,
  series,
  score,
  currentScore,
  totalScore,
  actual,
  expected,
  trueCount,
  falseCount,
  netCount,
  emptyCount,
  onButtonClick,
}) => {
  return (
    <div className="card card-metric my-2 h-100">
      <div className="card-body">
        <div className="row">
          <div className="col-12 col-sm-4">
            <h5 className="fs-20 color-484848 font-weight-600">{title}</h5>
            <RadialChart
              series={[series]}
              labels={["Seviye"]}
              prefix={value}
              chartHeight={200}
              background="#c0cbfc"
              primaryColor="#6e87fe"
            />
            <div
              className="text-center color-919191 position-relative"
              style={{ marginTop: 0 }}
            >
              {/* <Tooltip
                placement="top"
                trigger="hover"
                tooltip={`${series || 0}. Seviyede toplam ${
                  currentScore || 0
                } puan kazandın`}
              >
                {currentScore || 0}
              </Tooltip>{" "}
              /{" "}
              <Tooltip
                placement="top"
                trigger="hover"
                tooltip={`Bir sonraki seviye için ${
                  totalScore || 0
                } puana erişmelisin`}
              >
                {totalScore || 0}
              </Tooltip> */}
              Seviye
            </div>
          </div>

          <div className="col-12 col-sm-7">
            <div
              className="mt-2 mt-sm-5"
              style={{ borderBottom: "1px solid #979797" }}
            >
              <h6 className="color-919191 fs-16 font-weight-500 mb-3">
                {trueCount} Doğru
              </h6>
              <h6 className="color-919191 fs-16 font-weight-500 mb-3">
                {falseCount} Yanlış
              </h6>
              {/* <h6 className="color-919191 fs-16 mb-3">{netCount} Net</h6> */}
              <h6 className="color-919191 fs-16 font-weight-500 mb-3 d-flex align-items-center justify-content-between">
                {emptyCount} Boş
                <button
                  className="btn btn-primary text-capitalize border-radius-4 py-1 font-weight-600 fs-12"
                  onClick={(e) => {
                    e.preventDefault();
                    onButtonClick();
                  }}
                >
                  Hata kutuma git
                </button>
              </h6>
            </div>

            <div className="mt-4">
              <ScoreBadge
                className="badge-score-custom mr-2"
                icon="point"
                title={`${score || 0} Puan`}
                value={score}
              />
              <ScoreBadge
                className="badge-rosette"
                icon="badge"
                title={`${awardCount || 0} Rozet`}
                value={awardCount}
              />
            </div>
          </div>
        </div>
        {/*           
        <div className="row no-gutters">
          <div className="col-auto">

            <h1
              style={{
                width: "120px",
                height: "72px",
                position: "absolute",
                left: "50%",
                marginLeft: "-36px",
                top: "50%",
                marginTop: "-36px",
                verticalAlign: "baseline",
                fontSize: "3.6rem"
              }}
            >
              
            </h1>
            <span
              className="badge badge-secondary"
              style={{
                width: "120px",
                position: "absolute",
                left: "50%",
                marginLeft: "-60px",
                top: 0,
                verticalAlign: "baseline",
                fontSize: "1rem"
              }}
            >
              {awardCount || 0} Rozet
            </span>
            <span
              className="badge badge-secondary"
              style={{
                width: "120px",
                position: "absolute",
                left: "50%",
                marginLeft: "-60px",
                bottom: 0,
                verticalAlign: "baseline",
                fontSize: "1rem"
              }}
            >
              {score || 0} Puan
            </span>
          </div>
          <div className="col">
            <h5 className="card-title">{title}</h5>
            <p className="card-text">5. Seviye</p>
            <p className="card-text">50 net</p>
            <p className="card-text">36000 puan</p>
            <p className="card-text">12 rozet</p>
          </div>
        </div>
          */}
      </div>
    </div>
  );
};

export default MetricHorizontal;
