import React from "react";
import { calc } from "../../helpers/mathExtensions";
import { ScoreBadge } from "../../components";
const EmptyComment = ({
  productPrice,
  activityType,
  onToggle,
  // show,
  // handleSubmit,
  // initialRating,
  // handleRating,
  // errors,
  // comment,
  // product,
}) => (
  <>
    <div className="empty-comment-page">
      <div className="first-title">
        Arkadaşlarına fikir vermek hem de puan ve jeton kazanmak için
        değerlendirme yap
      </div>
      <div className="d-flex">
        <ScoreBadge
          icon="point"
          title={`+${activityType.Score} Puan`}
          className="badge-score mr-2"
          value={activityType.Score}
          productDetailBadge
        />
        <ScoreBadge
          icon="coin"
          title={`+${(calc(productPrice, "*", activityType.Coins) / 10).toFixed(
            0
          )}  Jeton`}
          className="badge-coin"
          value={calc(productPrice, "*", activityType.Coins).toFixed(0)}
          productDetailBadge
        />
      </div>
      <button
        className="comment-button btn-empty-comment-page mx-0"
        title="Yorum Yaz"
        onClick={onToggle}
      >
        Değerlendirme Yap
      </button>
    </div>
  </>
);
export default EmptyComment;
