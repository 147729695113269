import actionTypes from "./types";
import { HTTP } from "../../../../services/constants";
import { tatsApiCall } from "../../../../services/api";
import { async } from "regenerator-runtime";

export const getBooksBransTest = (bransKod, ) => async (dispatch, getState) => {


    const model = {
        bransKod
    }
    const { data } = await tatsApiCall(
        `/BookOperations/bransTest`,
        model,
        null,
        HTTP.POST,
        null,
        true
    );

    let unites = [];
    let tests = [];
    if (data) {


        tests = data.Testler;
        let bransAd;
        data.Testler.map((test) => {

            if (!bransAd) {
                bransAd = test.bransAd;
            }

            const { testCozum, testKonu, testKonuAd, testSoruSayisi, toplamDogru, toplamYanlis, toplamBos } = test;

            let uix = unites.findIndex(p => p.id === test.testUnite);
            if (uix === -1) {

                const { testUnite, testUniteAd } = test;

                unites.push(
                    {
                        id: testUnite,
                        title: testUniteAd,
                        subjects: [
                            {
                                id: testKonu,
                                title: testKonuAd,
                                cozulen: testCozum ? 1 : 0,
                                toplam: 1,
                                testSoruSayisi,
                                toplamDogru,
                                toplamYanlis,
                                toplamBos
                            }
                        ],
                        cozulen: testCozum ? 1 : 0,
                        toplam: 1,
                        testSoruSayisi,
                        toplamDogru,
                        toplamYanlis,
                        toplamBos
                    }
                );
            } else {
                let six = unites[uix].subjects.findIndex(s => s.id === test.testKonu);

                unites[uix].toplam += 1;
                unites[uix].testSoruSayisi += testSoruSayisi;

                unites[uix].toplamDogru += toplamDogru;
                unites[uix].toplamYanlis += toplamYanlis;
                unites[uix].toplamBos += toplamBos;

                if (six === -1) {
                    unites[uix].subjects.push(
                        {
                            id: testKonu,
                            title: testKonuAd,
                            cozulen: testCozum ? 1 : 0,
                            toplam: 1,
                            testSoruSayisi,
                            toplamDogru,
                            toplamYanlis,
                            toplamBos
                        }
                    );

                    unites[uix].cozulen += testCozum ? 1 : 0;
                }
                else {
                    unites[uix].subjects[six].cozulen += testCozum ? 1 : 0;
                    unites[uix].subjects[six].toplam += 1;

                    unites[uix].subjects[six].toplamDogru += toplamDogru;
                    unites[uix].subjects[six].toplamYanlis += toplamYanlis;
                    unites[uix].subjects[six].toplamBos += toplamBos;
                    unites[uix].subjects[six].testSoruSayisi += testSoruSayisi;
                }
            }
        });


        dispatch({
            type: actionTypes.GET_BOOKS_BRANS_TEST,
            payload: {
                unites,
                bransAd,
                tests
            }
        });
    }

};

export const getBransTest = (bransKod) => async (dispatch, getState) => {


    const model = {
        bransKod
    }
    const { data } = await tatsApiCall(
        `/BookOperations/bransTest`,
        model,
        null,
        HTTP.POST,
        null,
        true
    );

    let unites = [];
    let tests = [];

    let bransTests = [];
    let brans;

    if (data) {


        tests = data.Testler;
        brans = tests[0] ? tests[0].bransAd : '';

        let { unites, branslar } = createUnites(tests);

        // data.Testler.map((test) => {


        //     const { testCozum, testKonu, testKonuAd, testSoruSayisi, toplamDogru, toplamYanlis, toplamBos, yayinId, testBrans, bransAd } = test;
        //     brans = bransAd;

        //     let uix = unites.findIndex(p => p.id === test.testUnite && p.bransId == testBrans);
        //     if (uix === -1) {

        //         const { testUnite, testUniteAd } = test;

        //         unites.push(
        //             {
        //                 id: testUnite,
        //                 title: testUniteAd,
        //                 subjects: [
        //                     {
        //                         id: testKonu,
        //                         title: testKonuAd,
        //                         cozulen: testCozum ? 1 : 0,
        //                         toplam: 1
        //                     }
        //                 ],
        //                 cozulen: testCozum ? 1 : 0,
        //                 toplam: 1,
        //                 testSoruSayisi,
        //                 toplamDogru,
        //                 toplamYanlis,
        //                 toplamBos,
        //                 yayinId,
        //                 bransId: testBrans,
        //                 bransAd

        //             }
        //         );
        //     } else {
        //         let six = unites[uix].subjects.findIndex(s => s.id === test.testKonu);

        //         unites[uix].toplam += 1;
        //         unites[uix].cozulen += testCozum ? 1 : 0;
        //         unites[uix].testSoruSayisi += testSoruSayisi;

        //         unites[uix].toplamDogru += toplamDogru;
        //         unites[uix].toplamYanlis += toplamYanlis;
        //         unites[uix].toplamBos += toplamBos;

        //         if (six === -1) {
        //             unites[uix].subjects.push(
        //                 {
        //                     id: testKonu,
        //                     title: testKonuAd,
        //                     cozulen: testCozum ? 1 : 0,
        //                     toplam: 1
        //                 }
        //             );

        //         }
        //         else {
        //             unites[uix].subjects[six].cozulen += testCozum ? 1 : 0;
        //             unites[uix].subjects[six].toplam += 1;
        //         }
        //     }
        // });


        dispatch({
            type: actionTypes.GET_BOOKS_BRANS_TEST,
            payload: {
                unites,
                bransAd: brans,
                tests,
                branslar
            }
        });
    }

};


export const getBransWithAllTests = () => async (dispatch) => {
    const model = {
        bransKod: 0
    }
    const { data } = await tatsApiCall(
        `/BookOperations/bransTest`,
        model,
        null,
        HTTP.POST,
        null,
        true
    );

    let unites = [];
    let tests = [];

    let bransTests = [];
    let brans;

    if (data) {


        tests = data.Testler;

        // const branslar = tests.reduce(function (branslar, test) {

        //     const { testBrans, testKonu, testKonuAd } = test;
        //     let index = branslar.findIndex(p => p.bransId == testBrans);
        //     if (index === -1) {
        //         branslar.push(
        //             {
        //                 bransId: test.testBrans,
        //                 bransAd: test.bransAd,
        //                 tests: [
        //                     test
        //                 ],
        //                 subjects: [
        //                     {
        //                         testKonu,
        //                         testKonuAd
        //                     }
        //                 ]
        //             }
        //         );
        //     } else {
        //         branslar[index].tests.push(test);
        //         const konuIndex = branslar[index].subjects.findIndex(p => p.testKonu == test.testKonu);
        //         if (konuIndex === -1) {
        //             branslar[index].subjects.push(
        //                 {
        //                     testKonu,
        //                     testKonuAd
        //                 }
        //             );
        //         }
        //     }

        //     // r[a.testBrans] = r[a.testBrans] || [];
        //     // r[a.testBrans].push(a);
        //     return branslar;
        // }, []);


        let { unites, branslar } = createUnites(tests);



        dispatch({
            type: actionTypes.GET_BOOKS_BRANS,
            payload: {
                branslar,
                tests,
                unites
            }
        });
    }
}


export const createRandomTest = (model) => async (dispatch) => {

    // const model = {
    //     secilenSoruSayisi,
    //     secilenBranslar,
    //     seciliKonular
    // }
    const { data } = await tatsApiCall(
        `/studentsigns/createrandomtest `,
        model,
        null,
        HTTP.POST,
        null,
        true
    );

    if (data) {
        const { sanalTestNo } = data;

        dispatch({
            type: actionTypes.CREATE_RANDOM_TEST,
            payload: {
                testId: sanalTestNo
            }
        });
        return { success: true, testId: sanalTestNo };
    }
}


export const getRandomTest = (sanalTestNo) => async (dispatch) => {
    const model = {
        sanalTestNo
    }
    const { data } = await tatsApiCall(
        `/studentsigns/getrandomtest  `,
        model,
        null,
        HTTP.POST,
        null,
        true
    );

    if (data) {
        const { Sorular } = data;

        dispatch({
            type: actionTypes.GET_RANDOM_TEST,
            payload: {
                sorular: Sorular
            }
        });
    }
}


function createUnites(tests) {

    let unites = [];
    let branslar = [];

    tests.map((test) => {


        const { testCozum, testKonu, testKonuAd, testSoruSayisi, toplamDogru, toplamYanlis, toplamBos, yayinId, testBrans, bransAd } = test;

        let uix = unites.findIndex(p => p.id === test.testUnite && p.bransId == testBrans);
        if (uix === -1) {

            const { testUnite, testUniteAd } = test;

            unites.push(
                {
                    id: testUnite,
                    title: testUniteAd,
                    subjects: [
                        {
                            id: testKonu,
                            title: testKonuAd,
                            cozulen: testCozum ? 1 : 0,
                            toplam: 1
                        }
                    ],
                    cozulen: testCozum ? 1 : 0,
                    toplam: 1,
                    testSoruSayisi,
                    toplamDogru,
                    toplamYanlis,
                    toplamBos,
                    yayinId,
                    bransId: testBrans,
                    bransAd

                }
            );
        } else {
            let six = unites[uix].subjects.findIndex(s => s.id === test.testKonu);

            unites[uix].toplam += 1;
            unites[uix].cozulen += testCozum ? 1 : 0;
            unites[uix].testSoruSayisi += testSoruSayisi;

            unites[uix].toplamDogru += toplamDogru;
            unites[uix].toplamYanlis += toplamYanlis;
            unites[uix].toplamBos += toplamBos;

            if (six === -1) {
                unites[uix].subjects.push(
                    {
                        id: testKonu,
                        title: testKonuAd,
                        cozulen: testCozum ? 1 : 0,
                        toplam: 1
                    }
                );

            }
            else {
                unites[uix].subjects[six].cozulen += testCozum ? 1 : 0;
                unites[uix].subjects[six].toplam += 1;
            }
        }


        let bix = branslar.findIndex(p => p.bransId == testBrans);
        if (bix === -1) {
            branslar.push(
                {
                    bransId: test.testBrans,
                    bransAd: test.bransAd,
                    tests: [
                        test
                    ],
                    subjects: [
                        {
                            testKonu,
                            testKonuAd,
                            toplamYanlis
                        }
                    ]
                }
            );
        } else {
            branslar[bix].tests.push(test);
            const konuIndex = branslar[bix].subjects.findIndex(p => p.testKonu == test.testKonu);
            if (konuIndex === -1) {
                branslar[bix].subjects.push(
                    {
                        testKonu,
                        testKonuAd,
                        toplamYanlis
                    }
                );
            }
            else {
                branslar[bix].subjects[konuIndex].toplamYanlis += toplamYanlis;
            }

        }



    });
    return { unites, branslar };
};