import React, { PureComponent } from "react";
//import Rating from "react-rating";
import { connect } from "react-redux";
import * as actions from "../../routes/actions//Coin";
import {
  loginRequired
} from "../../routes/actions/Account";
import bsCustomFileInput from "bs-custom-file-input";
import {
  Breadcrumb
} from "../../components";
import ImageGallery from "react-image-gallery";

import { setPrerenderTimeout } from "../../helpers";

// class ContentDetail extends React.Component {
//   constructor(props) {
//     super(props);
//     this.productDetailRef = React.createRef();
//     this.state = {
//       done: false,
//     };
//   }

//   componentDidMount() {
//     // this.productDetailRef.addEventListener('DOMSubtreeModified', this.appendEmbedDiv);
//   }
//   componentWillUnmount() {
//     // if (this.productDetailRef)
//     //     this.productDetailRef.removeEventListener('DOMSubtreeModified', this.appendEmbedDiv);
//   }
//   componentDidUpdate() {
//     setPrerenderTimeout();
//   }
//   replaceYtHttp(str) {
//     str = str.replace(new RegExp("//www.youtu", "g"), "https://www.youtu");
//     str = str.replace(new RegExp("//youtu", "g"), "https://youtu");
//     str = str.replace(new RegExp("http://youtu", "g"), "https://youtu");
//     str = str.replace(new RegExp("http://www.youtu", "g"), "https://www.youtu");
//     return str;
//   }

//   render() {
//     const { product } = this.props.coin;
//     const { coin } = this.props;
//     coin.body = coin.body && this.replaceYtHttp(coin.body);
//     return (
//       <>
//         <div className="product-detail-container">
//           <h2 className="product-detail-title mb-4">Ürün Hakkında</h2>
//           <h5 className="product-detail-name mb-4">{coin.name}</h5>
//           <div className="product-detail-content">
//             <p
//               dangerouslySetInnerHTML={{ __html: product.body }}
//               ref={(r) => (this.productDetailRef = r)}
//             />
//           </div>
//         </div>
//       </>
//     );
//   }
// }

class CoinShow extends PureComponent {
  constructor(props) {
    super(props);
    const { coinId } = this.props.match.params || 0;
    this.productId = parseInt(coinId, 10);

    this.productDetailRef = React.createRef();
  }

  componentDidMount() {
    bsCustomFileInput.init();
    this.props.getCoinById(this.productId);
  }

  componentWillUnmount() {

  }

  componentDidUpdate() {
    const { productId } = this.props.match.params || 0;
    this.productId = parseInt(productId, 10);

  }




  render() {
    const { product } = this.props.coin;
    const coin = this.props.coin;

    //    const pages = samplePages.map((page) => ({ src: page.url }));

    // const userComments = [];

    // this.props.comments.forEach((comment) => {
    //   !comment.repliedTo && userComments.push(comment);
    // });

    const breadcrumbs = [
      {
        title: "Anasayfa",
        url: "/",
      },
      {
        title: "Jetonlarım",
        url: "/hesabim/jetonlarim",
      },
      {
        title: coin.name,
        active: true,
      },
    ];

    // const productPrice = product ? ((coin.total * (100 + coin.tax)) / 100 || 0).toFixed(2) : 0;
    const productPrice = 125;

    const images = [
      {
        original: coin.image,
        thumbnail: coin.image,
      }
    ];

    return (
      <>
        <div className="row">
          <div className="col-12">
            <Breadcrumb items={breadcrumbs} />
          </div>
          <div className="col-12 col-lg-6 col-md-12 mb-4">
            <div className="product-image-gallery">
              {<ImageGallery
                items={images}
                thumbnailPosition="bottom"
                showThumbnails={images.length > 1}
                showFullscreenButton={false}
                showPlayButton={false}
              />}
            </div>
          </div>
          <div className="col-12 col-lg-6 col-md-12">
            <div className="card product-detail-card mb-4">
              <h1 className="product-detail-card-title">{coin.name} </h1>
              <h5 className="product-detail-price">{`${coin.price} ₺`}</h5>

              <div className="product-order-detail">
                <a
                  className="btn btn-primary add-basket-button"
                  href={`/odeme/jeton/${coin.id}`}>
                  HEMEN AL
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="mb-4" id="details">
            <ul className="nav nav-tabs product-detail-tabs mb-3">
              <li className="nav-item">
      
              </li>
            </ul>
            <p
              dangerouslySetInnerHTML={{ __html: coin.body }}
              ref={(r) => (this.productDetailRef = r)}
            />
          </div>
        </div>

      </>
    );
  }
}

CoinShow.propTypes = {};

const mapStateToProps = ({ AccountReducer, CoinReducer }) => ({
  coin: CoinReducer,
  user: AccountReducer.user,
});

const mapDispatchToProps = {
  loginRequired: loginRequired,
  getCoinById: actions.getCoinById,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoinShow);
