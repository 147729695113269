import { NumberFormat } from "intl";
import "intl/locale-data/jsonp/tr-TR.js";

export const formatPrice = (price) => {
    const value = price || 0;
    let formattedOutput = new NumberFormat("tr-TR", {
        style: "currency",
        currency: "TRY",
        minimumFractionDigits: 2
    });
    return formattedOutput.format(value);
};

export const replaceDotWithComma = str => {
    return str.replace(/\./g, ',');
}

export const splitPrice = (price) =>{
  return price.split(".");
}