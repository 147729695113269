import React from "react";
//import PropTypes from 'prop-types';
import { BaseForm } from "../../components";
import FormSchemas from "../../variables/FormSchemas";

const CreditCard = ({ card, onChange }) => {
  return (
    <BaseForm
      className ="z-index-2"
      schema={FormSchemas.crediCard.schema}
      uiSchema={FormSchemas.crediCard.uiSchema}
      onChange={onChange}
      formData={card}
    >
      <span />
    </BaseForm>
  );
};

CreditCard.propTypes = {};

export default CreditCard;
