import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import classNames from "classnames";
import Icon from "../Icon/Index";

class Link extends Component {

    handleOnClick = (event) => {

        event.preventDefault();
        if (!this.props.noRedirect) {
            const { history, to } = this.props;
            history.push(to);
        }
        if (this.props.onClick) {
            this.props.onClick(event);
        }
    };

    render() {
        return (
            <button
                type="button"
                className={classNames(
                    "list-group-item d-flex align-items-center",
                    this.props.className
                )}
                onClick={this.handleOnClick}>
                <Icon className="mx-3" icon={this.props.icon} />
                <span>{this.props.children}</span>
            </button>
        );
    }
}

Link.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    className: PropTypes.string,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    listGroupItem: PropTypes.bool,
    onClick: PropTypes.func
};

export default withRouter(Link);
