import React, { Component } from "react";

class FilterLesson extends Component {
  state = {
    searchValue: "",
  };

  searchInput = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  render() {
    const { filters, taxonomy, applyFilter, isFilterModal, prefix } =
      this.props;
    const filterTaxon = taxonomy.taxons.filter((taxon) => {
      return (
        (taxon.name
          .toLocaleLowerCase()
          .indexOf(this.state.searchValue.toLocaleLowerCase()) !== -1 &&
          taxon.count > 0) ||
        taxonomy.taxons.some((t) =>
          this.props.filters.some((f) => f.id == t.id)
        )
      );
    });
    return (
      <div>
        <div className="sidebar--item pt-2">
          {!isFilterModal && <h6>Hangi Ders?</h6>}

          <div className="filter-lesson-container">
            <input
              className="filter-lesson-input form-control"
              onChange={this.searchInput}
              placeholder="Ders Ara"
            />
          </div>
          <div className="sidebar--item-detail">
            {filterTaxon.map((taxon) => (
              <div
                key={taxon.id}
                className="form-group form-check brand-checkbox"
              >
                <input
                  onChange={(e) => applyFilter(e, false)}
                  checked={filters?.some((filter) => filter.id == taxon.id)}
                  value={taxon.id}
                  id={`taxon-${taxon.id}${prefix ? `-${prefix}` : ""}`}
                  name={taxon.name}
                  type="checkbox"
                />
                <label
                  htmlFor={`taxon-${taxon.id}${prefix ? `-${prefix}` : ""}`}
                  className="d-flex flex-column ml-2 mb-0"
                >
                  <h5 className="checkbox-label d-flex"> {taxon.name} </h5>
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default FilterLesson;
