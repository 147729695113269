import InputWidget from "./Input";
import Mask from "./Mask";
import DateWidget from "./Date";
import Gender from "./Gender";
import Multiline from "./Multiline";
import Password from "./Password";
import ClassroomWidget from "./Classroom";
import SchoolWidget from "./School";
import EmailWidget from "./Email";
import PhoneWidget from "./Phone";
import Editor from "./Editor";
import City from "./City";
import Town from "./Town";
import File from "./File";
import Url from "./Url";
import ExpireDateWidget from "./ExpireDate";
import BlogCategory from "./BlogCategory";
import ImageOrientation from "./ImageOrientation";

export default {
    string: InputWidget,
    mask: Mask,
    date: DateWidget,
    gender: Gender,
    textarea: Multiline,
    password: Password,
    classroom: ClassroomWidget,
    blogCategory: BlogCategory,
    imageOrientation: ImageOrientation,
    school: SchoolWidget,
    email: EmailWidget,
    phone: PhoneWidget,
    editor: Editor,
    city: City,
    town: Town,
    file: File,
    url: Url,
    expireDate: ExpireDateWidget
};