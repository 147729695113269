export const customStylesAccount = {

    control: (base, state) => ({
        ...base,
       height:48,
        borderColor: "transparent",
        boxShadow:  state.isFocused &&"0 2px 6px 0 rgba(153, 153, 153, 0.5)",
        borderBottomLeftRadius: state.isFocused ?0: 4,
        borderBottomRightRadius: state.isFocused ?0: 4,
        background:"#f4f4f4",
        "&:hover": {
            borderColor: "transparent"
        },

    }),
    option: (base, state) => ({
        ...base,
        background: state.isSelected ? "rgba(255, 113, 0, 0.75)!important" : "",
        backgroundColor: state.isFocused ? "#ffb880" : "",
        fontSize: 14,
        fontWeight: 600,

        "&:hover": {
            background: "#ffb880",
            color: "#ffffff"
        },

    }),
    singleValue: (base) => ({
        ...base,
        fontSize: 14,
        fontWeight: 600,

      }),
    indicatorSeparator: (base) => ({
        ...base,
        display: "none",
    }),
    menuList: (base)=>({
        ...base,

        "&::-webkit-scrollbar-thumb": {
            borderRadius: 3,
            background:" #d8d8d8",
          },
         " &::-webkit-scrollbar": {
            width:3,
            borderRadius: 1,
          },
    }),
    menu: (provided) => ({
        ...provided,

        margin:0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderColor: "transparent",
        boxShadow: "0 4px 6px 0 rgba(153, 153, 153, 0.5)",

      }),
    placeholder:(base)=>({
        ...base,
        color: "#202124",
        fontSize: 14,
        fontWeight: 600,
    }),
    dropdownIndicator: (base) => ({
        ...base,
        color:"#202124",
        opacity:1,
    }),
};

export const customStylesLessons = {

    control: (base, state) => ({
        ...base,
        background: state.isFocused ? "#ffffff":"#f4f4f4",


        borderColor: "transparent",
        boxShadow:  state.isFocused &&"0 2px 6px 0 rgba(153, 153, 153, 0.5)",
        borderBottomLeftRadius: state.isFocused ?0: 4,
        borderBottomRightRadius: state.isFocused ?0: 4,
        "&:hover": {
            borderColor: "transparent"
        },

    }),
    multiValueLabel: (styles) => ({
        ...styles,
        backgroundColor: "#c0cbfc",
        border:0,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius:8,
      }),
      multiValueRemove: (styles) => ({
        ...styles,
        color: "#484848",
        backgroundColor:"#c0cbfc",
        border:0,
        borderTopRightRadius: 8,
        borderBottomRightRadius:8,
        ':hover': {
            backgroundColor:"#c0cbfc",
            color: "#484848",
        },
      }),
    option: (base, state) => ({
        ...base,
        background: state.isSelected ? "rgba(255, 113, 0, 0.75) !important" : "#fff",
        fontSize: 12,
        fontWeight: 500,


        "&:hover": {
            background: "#f4f4f4",
            color: "#484848"
        }
    }),
    indicatorSeparator: (base) => ({
        ...base,
        display: "none",
    }),
    menuList: (base)=>({
        ...base,
        borderTop:"1px solid #cecece",

        "&::-webkit-scrollbar-thumb": {
            borderRadius: 3,
            background:" #d8d8d8",
          },
         " &::-webkit-scrollbar": {
            width:3,
            borderRadius: 1,
          },
    }),
    menu: (provided) => ({
        ...provided,
        margin:0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderColor: "transparent",
        boxShadow: "0 4px 6px 0 rgba(153, 153, 153, 0.5)",


      }),
    placeholder:(base)=>({
        ...base,
        color: "#202124",
        fontSize: 14,
        fontWeight: 600,
    }),
    dropdownIndicator: (base) => ({
        ...base,
        color:"#202124",
        opacity:1,
    }),
};

export const customClassroomStyles = {

    control: (base, state) => ({
        ...base,
        background: state.isFocused ? "#ffffff":"#f4f4f4",

        minHeight:48,
        borderColor: "transparent",
        boxShadow:  state.isFocused &&"0 2px 6px 0 rgba(153, 153, 153, 0.5)",
        borderBottomLeftRadius: state.isFocused ?0: 4,
        borderBottomRightRadius: state.isFocused ?0: 4,
        "&:hover": {
            borderColor: "transparent"
        },

    }),
    option: (base, state) => ({
        ...base,
        background: state.isSelected ? "rgba(255, 113, 0, 0.75) !important" : "#fff",
        fontSize: 12,
        fontWeight: 500,


        "&:hover": {
            background: "#ffb880",
            color: "#ffffff"
        }
    }),
    singleValue: (base) => ({
        ...base,
        fontSize: 14,
        fontWeight:600,
      }),
    indicatorSeparator: (base) => ({
        ...base,
        display: "none",
    }),
    menuList: (base)=>({
        ...base,
        "&::-webkit-scrollbar-thumb": {
            borderRadius: 3,
            background:" #d8d8d8",
          },
         " &::-webkit-scrollbar": {
            width:3,
            borderRadius: 1,
          },
    }),

    menu: (provided,state) => ({
        ...provided,
        margin:0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderColor: "transparent",
        boxShadow: "0 4px 6px 0 rgba(153, 153, 153, 0.5)",
        bottom: state.isFocused ? "100%" : "",


      }),
    placeholder:(base)=>({
        ...base,
        color: "#202124",
        fontSize: 14,
        fontWeight: 600,
    }),
    dropdownIndicator: (base) => ({
        ...base,
        color:"#202124",
        opacity:1,
    }),
};

export const customClassroomStylesResponsive = {
    menu: (provided,state) => ({
        ...provided,
        margin:0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderColor: "transparent",
        bottom: state.isFocused ? "100%" : "",

            top:"auto",
            bottom:"100%",
            position:"absolute",
            boxShadow: "0 -2px 6px 0 rgba(153, 153, 153, 0.5)"

      }),
      control: (base, state) => ({
        ...base,
        background: state.isFocused ? "#ffffff":"#f4f4f4",

        minHeight:48,
        borderColor: "transparent",
        boxShadow:  state.isFocused &&"0 2px 6px 0 rgba(153, 153, 153, 0.5)",
        borderBottomLeftRadius: state.isFocused ?0: 4,
        borderBottomRightRadius: state.isFocused ?0: 4,
        "&:hover": {
            borderColor: "transparent"
        },

    }),
    option: (base, state) => ({
        ...base,
        background: state.isSelected ? "rgba(255, 113, 0, 0.75) !important" : "#fff",
        fontSize: 12,
        fontWeight: 500,


        "&:hover": {
            background: "#ffb880",
            color: "#ffffff"
        }
    }),
    singleValue: (base) => ({
        ...base,
        fontSize: 14,
        fontWeight:600,
      }),
    indicatorSeparator: (base) => ({
        ...base,
        display: "none",
    }),
    menuList: (base)=>({
        ...base,
        "&::-webkit-scrollbar-thumb": {
            borderRadius: 3,
            background:" #d8d8d8",
          },
         " &::-webkit-scrollbar": {
            width:3,
            borderRadius: 1,
          },
    }),
    placeholder:(base)=>({
        ...base,
        color: "#202124",
        fontSize: 14,
        fontWeight: 600,
    }),
    dropdownIndicator: (base) => ({
        ...base,
        color:"#202124",
        opacity:1,
    }),
}

export const customStyles = {

    control: (base, state) => ({
        ...base,

        borderColor: "transparent",
        boxShadow:  state.isFocused &&"0 2px 6px 0 rgba(153, 153, 153, 0.5)",
        borderBottomLeftRadius: state.isFocused ?0: 4,
        borderBottomRightRadius: state.isFocused ?0: 4,
        "&:hover": {
            borderColor: "transparent"
        },
        background:state.isFocused?"#ffffff":"#f4f4f4",
        height:48
    }),
    option: (base, state) => ({
        ...base,
        background: state.isSelected ? "rgba(255, 113, 0, 0.75) !important" : "#fff",
        fontSize: 12,
        fontWeight: 500,


        "&:hover": {
            background: "#ffb880",
            color: "#ffffff"
        }
    }),
    singleValue: (base) => ({
        ...base,
        fontSize: 14,

      }),
    indicatorSeparator: (base) => ({
        ...base,
        display: "none",
    }),
    menuList: (base)=>({
        ...base,

        "&::-webkit-scrollbar-thumb": {
            borderRadius: 3,
            background:" #d8d8d8",
          },
         " &::-webkit-scrollbar": {
            width:3,
            borderRadius: 1,
          },
    }),
    menu: (provided) => ({
        ...provided,

        margin:0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderColor: "transparent",
        boxShadow: "0 4px 6px 0 rgba(153, 153, 153, 0.5)",

      }),
    placeholder:(base)=>({
        ...base,
        color: "#202124",
        fontSize: 14,
        fontWeight: 600,
    }),
    dropdownIndicator: (base) => ({
        ...base,
        color:"#202124",
        opacity:1,
    }),
};

export const customSelectStyle = {
    control: (base, state) => ({
        ...base,
        background: "#f4f4f4",
        borderColor: "transparent",
        boxShadow:  0,
        borderRadius: 8,
        "&:hover": {
            borderColor: "transparent"
        },

    }),
    container: (base, state) => ({
        ...base,
        background: state.isFocused ? "transparent" : "#ffffff" ,
        borderRadius: 9,
        borderBottomLeftRadius: state.isFocused ? 0 : 9,
        borderBottomRightRadius: state.isFocused ? 0 : 9,
        padding: 4,
        boxShadow: state.isFocused ?  "0 -1px 3px 0 rgba(117, 114, 114, 0.5)": 0,
    }),
    option: (base, state) => ({
        ...base,
        background: state.isSelected ? "#ffecde" : "#fff",

        "&:hover": {
            background: "#ffecde"
        },

        borderRadius:7,
        fontSize: 14,
        fontWeight: 500,
        color: "#202124"


    }),

    indicatorSeparator: (base) => ({
        ...base,
        display: "none",
    }),
    dropdownIndicator: (base) => ({
        ...base,
        color:"#202124",
        opacity:1,
    }),
    menuList: (base)=>({
        ...base,
        "&::-webkit-scrollbar-thumb": {
            borderRadius: 3,
            background:" #d8d8d8",
          },
         " &::-webkit-scrollbar": {
            width:3,
            borderRadius: 1,
          },
    }),

    menu: (provided) => ({
        ...provided,
        margin:0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderRadius:9,
        borderColor: "transparent",
        boxShadow: "0 2px 3px 0 rgba(117, 114, 114, 0.5)",
        left:0,
        padding: 4,
      }),
    placeholder:(base)=>({
        ...base,
        color: "#202124",
        fontSize: 14,
        fontWeight: 600,
    }),
    singleValue:(base)=>({
        ...base,
        color: "#202124",
        fontSize: 14,
        fontWeight: 600,
    })
}