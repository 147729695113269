import Ajv from "ajv";
import moment from "moment";
import { isPasswordValid } from "../../helpers";

const ajv = new Ajv({
    errorDataPath: "property",
    allErrors: true,
    multipleOfPrecision: 8
});
// add custom formats
ajv.addFormat("customdate", { 
    validate: (value) => {
        const formattedValue = value.split('/').map(part => part.padStart(2, '0')).join('/');
        return moment(formattedValue, "DD/MM/YYYY", true).isValid();
    }
});

ajv.addFormat(
    "data-url",
    /^data:([a-z]+\/[a-z0-9-+.]+)?;(?:name=(.*);)?base64,(.*)$/
);

ajv.addFormat(
    "address-area",
    /\w*(\S\s*){10,}/,
);

ajv.addFormat(
    "color",
    /^(#?([0-9A-Fa-f]{3}){1,2}\b|aqua|black|blue|fuchsia|gray|green|lime|maroon|navy|olive|orange|purple|red|silver|teal|white|yellow|(rgb\(\s*\b([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\b\s*,\s*\b([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\b\s*,\s*\b([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\b\s*\))|(rgb\(\s*(\d?\d%|100%)+\s*,\s*(\d?\d%|100%)+\s*,\s*(\d?\d%|100%)+\s*\)))$/
);

ajv.addKeyword("compare", {
    modifying: false,
    schema: false,
    errors: true,
    validate: function compareValidation(
        data,
        dataPath,
        parentData,
        parentDataProperty
    ) {
    
        if (compareValidation.errors === null) {
            compareValidation.errors = [];
        }

        compareValidation.errors.push({
            keyword: "compare",
            message: "Şifre alanları uyuşmuyor.",
            params: {
                keyword: parentDataProperty
            }
        });

        return parentData["newPassword"] === parentData["confirmPassword"];
    }
});

ajv.addKeyword("passwordPolicy", {
    modifying: false,
    schema: false,
    errors: true,
    validate: function passwordPolicyValidation(
        data,
        dataPath,
        parentData,
        parentDataProperty
    ) {
        if (passwordPolicyValidation.errors === null) {
            passwordPolicyValidation.errors = [];
        }

        passwordPolicyValidation.errors.push({
            keyword: "passwordPolicy",
            message: "Şifreniz ardışık sayılar, ardışık harfler, birbirini tekrar eden karakterler içermemelidir!",
            params: {
                keyword: parentDataProperty
            }
        });

        return isPasswordValid(data);
    }
});

export default ajv;
