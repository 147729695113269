export const isPasswordValid = (password) => {
    // repeating characters, and sequential numbers are not allowed regex
    for (let i = 0; i < password.length - 2; i++) {
        // Check for consecutive numbers, letters, and repeating characters
        if ((parseInt(password[i]) + 1 === parseInt(password[i + 1]) && parseInt(password[i + 1]) + 1 === parseInt(password[i + 2])) ||
            (password[i].charCodeAt(0) + 1 === password[i + 1].charCodeAt(0) && password[i + 1].charCodeAt(0) + 1 === password[i + 2].charCodeAt(0)) ||
            (password[i] === password[i + 1])) {
            return false; // Password is weak
        }
    }
    return true; // Password is strong
}