import React, { useEffect, useState } from "react";

const ClassList = (props) => {
  const [filterTaxonList, setFilterTaxonList] = useState([]);

  useEffect(() => {
    const filterTaxonomies = props.taxonomies
      ?.filter((item) => {
        return item.code == "siniflar" || item.code == "sinavlar";
      })
      .sort((a, b) => a.id - b.id);
    const list = filterTaxonomies.map((item) => {
      return item.taxons;
    });

    setFilterTaxonList(list);
  }, [props.taxonomies]);

  const labelHtml = (i) => {
    const splitValue = i.name.split(".");
    return (
      <label htmlFor={`taxon-${i.id}`} className="d-flex flex-column mb-0">
        {splitValue[0]}
        <span>{splitValue[1]}</span>
      </label>
    );
  };
  return (
    <div className="class-list-container">
      <ul className="class-list">
        {filterTaxonList.map((item) =>
          item.map((i) => {
            return (
              i.count > 0 ?
              (<li
                key={i.id}
                className={`class-list-item ${props.filters.some(
                  (filter) => filter.id === i.id
                )}`}
              >
                <input
                  onChange={(e) => props.applyFilter(e, false)}
                  checked={
                    props.filters &&
                    props.filters.some((filter) => filter.id === i.id)
                  }
                  value={i.id}
                  type="checkbox"
                  id={`taxon-${i.id}`}
                  name={i.name}
                />
                {labelHtml(i)}
              </li>) : null
            );
          })
        )}
      </ul>
    </div>
  );
};

export default ClassList;
