import React from "react";
import { Modal } from "..";
import CloseButton from "../Button/Close";
//import PropTypes from 'prop-types';

const Success = ({ bookName, show, onCloseClick ,aktivasyonAy}) => {
  return (
    <Modal
      className="modal-container success-modal non-modal-height"
      show={show}
    >
      <div className="modal-body text-center">
        <CloseButton onClick={onCloseClick} />

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <g fill="none" fillRule="nonzero">
            <path
              fill="#5AD363"
              d="M8.214 12.01a1.307 1.307 0 0 0-1.839.01c-.504.507-.5 1.324.011 1.825l2.837 2.782a1.307 1.307 0 0 0 1.827 0l7.564-7.419c.51-.5.515-1.318.01-1.825a1.307 1.307 0 0 0-1.838-.01l-6.65 6.523-1.922-1.886z"
            />
            <path
              stroke="#5AD363"
              strokeWidth="2"
              d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11z"
            />
          </g>
        </svg>
        <div className="fs-16 text-success font-weight-600 mt-1">
          Kitabınız aktivite edildi
        </div>
        <div className="fs-14 color-919191 font-weight-600 mt-2">
          {bookName}
        </div>
        <div className="fs-14 color-919191 font-weight-500 mt-1">
          {aktivasyonAy !== null ? "Bu kitabın dijital içeriğini "+aktivasyonAy+" ay süre ile kullanma hakkına sahipsin. Başarına ortak olma dileğimizle." : ""}
       </div>
        <button
          className="btn btn-primary text-capitalize fs-14 font-weight-600 border-radius-4 fs-14 mt-2 px-5 py-1"
          onClick={onCloseClick}
        >
          Tamam
        </button>
      </div>
    </Modal>
  );
};

Success.propTypes = {};

export default Success;
