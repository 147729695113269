import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useMemo,
  useEffect,
} from "react";
import Slider from "react-slick";
import apiCall from "../../services/api";
import { connect } from "react-redux";
import * as recommendationProductActions from "../../routes/actions/RecommendationProducts";
import * as productActions from "../../routes/actions/Product";
import * as contentActions from "../../routes/actions/Content";
import Icon from "../../components/Icon/Index";
import { toast } from "react-toastify";
import Skeleton from "@material-ui/lab/Skeleton";
import { replaceDotWithComma } from "../../helpers";

const MainBookSlider = forwardRef(
  (
    {
      title,
      url,
      addProductToBasket,
      basketKey,
      basket,
      eventType,
      productId,
      user,
      mode,
      onChange,
      loggedIn,
      allProducts,
      ...props
    },
    ref
  ) => {
    const [bookList, setBookList] = useState([]);
    // const [productCount, setProductCount] = useState(1);
    const [page, setPage] = useState([]);

    const [initialLoad, setInitialLoad] = useState(true);
    useEffect(() => {
      if (initialLoad) {
        setInitialLoad(false);
      } else {
        getDatas();
      }
    }, [basket]);

    useImperativeHandle(ref, () => ({
      newDataFromParent(newData) {
        setPage(newData);
      },
    }));



    useEffect(() => {
      if (props.sliderName) {
        apiCall(`/contents/content/${props.sliderName}/1`).then((res) => {
          setPage(res.data);
        });
      }
    }, [props.sliderName]);

    const productList = useMemo(() => {
      let output = [];
      for (const id of page?.content?.products ?? []) {
        const item = props.products.find((m) => m.id === id);
        if (item) {
          output.push(item);
        }
      }
      return output;
    }, [page, props.products]);

    function getDatas() {
      if (url) {
        if (loggedIn === false && url === "/products/recently-viewed") {
          const data = window.localStorage.getItem("VISIT_PRODUCTS");
          if (data !== null) {
            setBookList(
              JSON.parse(data)
                .reverse()
                .map((m) => ({
                  id: m.id,
                  name: m.name,
                  image: m.imageUrl,
                  //price: m.price,
                  price: m.total || 0,
                  discount: m.discount,
                }))
            );
          }
        } else {
          productActions.getRecentlyViewedProducts().then((data) => {
            var recentlyViewed = [];
            data.map((rvp) => {
              var product = allProducts.find((p) => p.id === rvp.id);
              if (product) {
                recentlyViewed.push({
                  id: product.id,
                  name: product.name,
                  image: product.imageUrl,
                  discount: product.discount,
                  price: productActions.calculateProductPrice(product),
                });
              }
            });
            setBookList(recentlyViewed);
          });
        }
      } else if (eventType) {
        const clientId = user ? user.id : "";
        const basket_ = basket.map((item) => ({
          uid: item.product.id,
          sepetfiyati: item.total,
          adet: item.quantity,
        }));
        let model = {
          eventtipi: eventType,
          satiskanali: 1,
          urun: {
            uid: productId,
          },
          sepeturunleri: basket_,
          musteri: {
            mid: clientId,
          },
        };
        props.getRecommendationProducts(model);
      }
    }

    useEffect(() => {
      if (eventType) {
        setBookList(props.recommendationProducts);
      }
    }, [JSON.stringify(props.recommendationProducts)]);

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: bookList.length < 5 ? bookList.length : 5,
      slidesToScroll: 1,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: bookList.length < 4 ? bookList.length : 4,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: bookList.length < 3 ? bookList.length : 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: bookList.length < 2 ? bookList.length : 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: bookList.length < 2 ? bookList.length : 2,
            // arrows: false,
            // variableWidth: true,
          },
        },

      ],
    };

    const handleClick = (event) => {
      if (onChange) {
        onChange(event.target.name, props.sliderName, false);
      }
    };

    const toggleShowSlider = async (active) => {
      const newState = {
        ...page,
        active,
      };
      setPage(newState);
      await props.savePage(newState.id, newState);
      const result = await props.publishPage(newState.id);
      if (result.success && active === 1) {
        toast.success("Slider canlıya alındı.");
      }
      if (result.success && active === 0) {
        toast.success("Slider canlıdan çıkarıldı.");
      }
    };

    if (mode !== "draft" && page.active === 0) {
      return null;
    }
    return (
      <>
        {bookList.length === 0 ? (
          <div
            className="main-book-slider"
            style={{
              display: bookList.length ? "block" : "none",
            }}
          >
            <Skeleton variant="rect" width={238} height={298} />
          </div>
        ) : (
          <div
            className="main-book-slider"
            style={{
              display: bookList.length ? "block" : "none",
            }}
          >
            <div className="d-flex align-items-center">
              <h2>{page?.content?.title ? page?.content?.title : title}</h2>
              {/* <div className="more-arrow" /> */}
            </div>
            {mode === "draft" && (
              <div className="editable-container">
                <button
                  className="btn"
                  name="editableSlider"
                  onClick={handleClick}
                >
                  <Icon icon="iEditable" />
                </button>
                {page?.active === 1 && (
                  <button className="btn" onClick={() => toggleShowSlider(0)}>
                    <Icon icon="iEyeOff" />
                  </button>
                )}
                {page?.active === 0 && (
                  <button className="btn" onClick={() => toggleShowSlider(1)}>
                    <Icon icon="iEyeOn" />
                  </button>
                )}
              </div>
            )}
            {props.sliderName ? (
              <Slider
                {...settings}
                slidesToShow={productList.length < 5 ? productList.length : 5}
              >
                {productList.map((book) => (
                  <a href={`/urunler/detay/${book.id}`} key={book.id}>
                    <div>
                      <img
                        className="img-fluid"
                        src={book.image || book.imageUrl}
                        alt={book.name}
                      />
                    </div>
                  </a>
                ))}
              </Slider>
            ) : url === "/products/recently-viewed" ? (
              <Slider {...settings}>
                {bookList.map((product, i) => (
                  <div
                    className="book-detail-container"
                    key={`${i}-${product.id}`}
                    title={product.name}
                  >
                    <div className="book-detail-box">
                      <a
                        className="img-box"
                        href={`/urunler/detay/${product.id}`}
                      >
                        <img
                          className="img-fluid"
                          src={product.image || product.imageUrl}
                          alt=""
                        />
                        <div className="book-detail-body text-left">
                          <div className="h-auto">{product.name}</div>
                          {product.discount > 0 && (
                            <div className="slide-price-old h-auto">
                              {replaceDotWithComma((product.price / (1 - product.discount / 100) || 0).toFixed(2))} ₺
                            </div>
                          )}
                            <div className="h-auto">
                              <span>
                                {replaceDotWithComma(
                                  (product.price || 0).toFixed(2)
                                )} ₺
                              </span>
                            </div>

                        </div>
                      </a>
                    </div>
                  </div>
                ))}
              </Slider>
            ) : (
              <Slider
                {...settings}
                slidesToShow={bookList.length < 5 ? bookList.length : 5}
              >
                {bookList.map((book) => (
                  <a href={`/urunler/detay/${book.id}`} key={book.id}>
                    <div>
                      <img
                        className="img-fluid"
                        src={book.image || book.imageUrl}
                        alt={book.name}
                      />
                    </div>
                  </a>
                ))}
              </Slider>
            )}
          </div>
        )}
      </>
    );
  }
);

const mapStateToProps = ({
  ProductReducer,
  RecommendationProductsReducer,
  AccountReducer,
}) => ({
  basketKey: ProductReducer.basketKey,
  basket: ProductReducer.basket,
  user: AccountReducer.user,
  recommendationProducts: RecommendationProductsReducer.products,
  // relatedProducts: ProductReducer.relatedProducts,
  products: ProductReducer.products,
  allProducts: ProductReducer.allProducts,
});
const mapDispatchToProps = {
  getRecommendationProducts:
    recommendationProductActions.getRecommendationProducts,
  // getRelatedProducts: productActions.getRelatedProducts,
  publishPage: contentActions.publishPage,
  savePage: contentActions.savePage,
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(MainBookSlider);
