import actionTypes from "../../actions/Store/types";

export const initialState = {
    isLoaded: false,
    stores: [],
    review: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_STORES_BY_CITY_ID:
            return {...state, stores: action.payload.storeList || [], storeCount: action.payload.storeCount || 0, isLoaded: true };

        case actionTypes.GET_STORES_BY_TOWN_ID:
            return {...state, stores: action.payload.storeList || [], storeCount: action.payload.storeCount || 0, isLoaded: true };

        case actionTypes.ADD_STORE_REVIEW:
            return {...state, review: action.payload};

        default:
            return state;
    }
};
