import React, {
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
} from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import apiCall from "../../services/api";
import Skeleton from "@material-ui/lab/Skeleton";

const MainTopSlider = forwardRef(({ taxonomies, ...props }, ref) => {
  const [page, setPage] = useState([]);
  const windowWidth = window.screen.width;
  useImperativeHandle(ref, () => ({
    newDataFromParent(newData) {
      setPage(newData);
    },
  }));

  const taxonIdList = new Set();
  taxonomies.forEach((item) =>
    item.taxons.forEach((taxon) => {
      if (taxon.count > 0) {
        taxonIdList.add(taxon.id);
      }
    })
  );
  useEffect(() => {
    if (props.storyName) {
      apiCall(`/contents/content/${props.storyName}/1`).then((res) => {
        setPage(res.data);
      });
    }
  }, [props.storyName]);

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow:
      page.content?.stories.length < 8 ? page.content?.stories.length + 1 : 8,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow:
            page.content?.stories.length < 7
              ? page.content?.stories.length + 1
              : 7,

          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow:
            page.content?.stories.length < 5
              ? page.content?.stories.length + 1
              : 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow:
            page.content?.stories.length < 4
              ? page.content?.stories.length + 1
              : 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow:
            page.content?.stories.length < 3
              ? page.content?.stories.length + 1
              : 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleStoryClick = (storyText) => {
    window.dataLayer.push({
      event: "story_click",
      story_text: storyText,
    });
  };
  if (props.mode !== "draft" && page.active === 0) {
    return null;
  }
  return (
    <>
      {page.content?.stories.length == undefined || taxonIdList.size === 0 ? (
        <div id="mainTopSlider" style={{ marginBottom: 29 }}>
          <Skeleton
            variant="rect"
            width={`${windowWidth > 768 ? 1200 : 355}`}
            height={150}
          />
        </div>
      ) : (
        page.content?.stories.length > 0 && (
          <div id="mainTopSlider">
            <Slider {...settings}>
              <div
                className="main-top-slider-item"
                onClick={() => handleStoryClick("Tüm Ürünler")}
              >
                <div className="slider-img-box mx-auto">
                  <Link to="/urunler">
                    <img
                      className="img-fluid"
                      src={require("../../assets/images/topSlider/tum-urunler-2.png")}
                      alt=""
                    />
                  </Link>
                </div>
                <Link className="main-top-slider-item--text" to="/urunler">
                  Tüm Ürünler
                </Link>
              </div>
              <div
                className="main-top-slider-item"
                onClick={() => handleStoryClick("Markalar")}
              >
                <div className="slider-img-box mx-auto">
                  <Link to="/markalar">
                    <img
                      className="img-fluid"
                      src={require("../../assets/images/topSlider/markalar-icon2.png")}
                      alt=""
                    />
                  </Link>
                </div>
                <Link className="main-top-slider-item--text" to="/markalar">
                  Markalar
                </Link>
              </div>
              {page.content?.stories.map((item, index) =>
                taxonIdList.has(item.value) ? (
                  <div
                    className="main-top-slider-item"
                    key={index}
                    onClick={() => handleStoryClick(item.label)}
                  >
                    <div className="slider-img-box mx-auto">
                      <Link to={`/urunler?t=${item.value}&i=n`}>
                        <img
                          className="img-fluid"
                          src={item.image}
                          alt={item.label}
                        />
                      </Link>
                    </div>
                    <Link
                      className="main-top-slider-item--text"
                      to={`/urunler?t=${item.value}&i=n`}
                    >
                      {item.label}
                    </Link>
                  </div>
                ) : null
              )}
            </Slider>
          </div>
        )
      )}
    </>
  );
});

const mapStateToProps = ({ ProductReducer }) => ({
  taxonomies: ProductReducer.taxonomies,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(MainTopSlider);
