import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { changePreferences } from "../../routes/actions/Account";
//import PropTypes from "prop-types";

class Preferences extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};

    this.changePreferences = this.changePreferences.bind(this);
  }

  changePreferences(preference) {
    const user = Object.assign({}, this.props.user);
    user[preference.name] = !preference.selected;
    this.props.changePreferences(
      user,
      preference.id,
      !preference.selected === true ? 1 : 0
    );
    toast.success(`${preference.title} tercihiniz değiştirildi.`);
  }

  render() {
    const items = [
      {
        id: 2,
        name: "mailPreference",
        title: "E-Mail",
        selected: this.props.user.mailPreference,
        description:
          "Yeni ürün ve kampanya fırsatlarından e-posta ile haberdar olmak istiyorum.",
      },
      {
        id: 1,
        name: "smsPreference",
        title: "SMS",
        selected: this.props.user.smsPreference,
        description:
          "Yeni ürün ve kampanya fırsatlarından SMS ile haberdar olmak istiyorum.",
      },
      {
        id: 3,
        name: "callPreference",
        title: "Telefon Görüşmesi",
        selected: this.props.user.callPreference,
        description:
          "Yeni ürün ve kampanya fırsatlarından arama ile haberdar olmak istiyorum.",
      },
    ];

    return (
      <div>
        <h4 className="fs-18 font-weight-bold mb-4">Duyuru Tercihlerim</h4>
        <div className="card preferences-card">
          <div className="card-body p-xs-0">
            <div className="list-group card-list-group">
              {items.map((item, i) => (
                <div key={i} className="d-flex">
                  <div className="form-group form-check">
                    <input
                      onChange={() => this.changePreferences(item)}
                      type="checkbox"
                      id={item.name}
                      defaultChecked={item.selected}
                      disabled={this.props.user.demo}
                    />
                    <label
                      htmlFor={item.name}
                      className="d-flex flex-column ml-3 mb-0"
                      style={{ marginTop: -5 }}
                    >
                      <h5 className="fs-14 font-weight-bold color-484848">
                        {item.title}
                      </h5>
                      <p className="fs-14 font-weight-500 mb-1">
                        {item.description}
                      </p>
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Preferences.propTypes = {};

const mapStateToProps = ({ AccountReducer }) => ({
  user: AccountReducer.user,
});

const mapDispatchToProps = {
  changePreferences: changePreferences,
};

export default connect(mapStateToProps, mapDispatchToProps)(Preferences);
