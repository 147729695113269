import React, {Component} from "react";
import {Link} from "react-router-dom";
//import PropTypes from 'prop-types';

class Privacy extends Component {
    render() {
        return (
            <>
                <h3 className="mt-2">Gizlilik Politikası</h3>
                <div className="card">
                    <div className="card-header">
                        {/* <h5>Gizlilik ve Çerez Politikası</h5> */}
                    </div>
                    <div className="card-body pt-0 fs-14">

                        <p>
                            İşbu Gizlilik Politikası&apos;nda kullanılan &quot;Kişisel Bilgiler&quot; terimi sizi tanımlayabilecek isminiz, doğum tarihiniz, TC kimlik numaranız, telefon numaranız, e- posta adresiniz veya posta adresiniz dahil fakat bunlarla sınırlı olmamak kaydıyla kredi kartı bilgileriniz, kimlik bilgileriniz gibi bilgileri ifade etmektedir.
                        </p>

                        <p>
                            Doğru Kaynak Dağıtım Teknoloji A.Ş. olarak biz, (aşağıda kısaca &quot;Doğru Kaynak&quot; olarak anılacaktır.) işbu gizlilik politikası ile tüm kullanıcılarımızı haklarında www.dogrukaynak.com adlı internet sitesinden toplanan Kişisel Bilgilerin kullanımı ve aktarılması hakkındabilgilendirmeyi hedeflemekteyiz. Doğru Kaynak olarak biz, kullanıcılarımızın gizliliğine değer vermekte olup, bizimle paylaşılan kişisel bilgilerin gizliliği ve bilgilerin güvenliğini ile ilgili kullanıcılarımızın kaygılarını saygıyla karşılamaktayız. Bu nedenle &quot;Gizlilik Politikası&quot; gizliliğinizi korumak ve bilgi temininde güvenli bir deneyim sağlamanız için tasarlamış olup, kullanıcıların onayı olmadan kişisel bilgilerini kullanmayacak ve Kişisel Bilgilerin kullanılmasında, tamamen uluslararası alanda kabul edilen mahremiyet koruma standartlarınauymayı taahhüt etmektedir.
                        </p>

                        <p>
                            Doğru Kaynak olarak www.dogrukaynak.com adlı internet sitesini kullanmadan veya bize Kişisel Bilgilerinizi iletmeden önce kullanıcıların mevcut Gizlilik Politikası&apos;nı okumasını rica ediyoruz. Bu gizlilik politikası herhangi bir sebepten dolayı bildirimde bulunmaksızın zaman zaman değiştirebilir veyahut da yenilenebilir. Bizler yenilenen sözleşmeler konusunda siz kullanıcıları uyarmayabiliriz. Ancak bu durumda Gizlilik Politikası&apos;nın söz konusu değişiklikleriyansıtan güncellenmiş halinin yükleyeceğini belirtmek isteriz. İşbu sebeple güncellenen Gizlilik Politikası hakkında kullanıcıların bilgi sahibi olduklarını periyodik olarak gözden geçirmesini öneririz. Sizlere önerimiz, yapılan değişikliklerden sorumlu olacağınız için, bu gizlilik politikasını her kullanım öncesi kontrol etmenizdir. Doğru Kaynak kullanıcıların izniyle Kişisel Bilgileri aşağıdaki amaçlar için kullanacaktır, hiçbir durumda öngörülen amacın dışında kullanmayacaktır.
                        </p>

                        <ul>
                            <li>Siparişlerinizi almak</li>
                            <li>Ürün ve hizmetlerimizi sunmak</li>
                            <li>Ödemelerinizi gerçekleştirmek</li>
                            <li>Siparişleriniz, ürünler ve hizmetler hakkında pazarlama amacıyla sizinle irtibata geçmek</li>
                            <li>Bilgilerinizi güncellemek</li>
                            <li>Üyeliğinizi yönetmek ve sürdürmek, İlginizi çekebilecek ürün ve hizmetleri önermek</li>
                            <li>3. şahısların teknik, lojistik ve benzeri diğer işlevlerini bizim adımıza yerine getirmelerini sağlamak</li>
                            <li>Yayınlar göndermek</li>
                            <li>Elektronik posta ile bülten göndermek ya da bildirimler de bulunmak</li>
                            <li>Sorularınızı cevaplamak ve etkin bir müşteri hizmeti sunmak</li>
                            <li>Yeni içerikler hakkında bilgi vermek.</li>
                        </ul>

                        <p>
                            Tarafınızca paylaşılan Kişiler Bilgiler&apos;in hiçbir durumda burada öngörülen amaçlar ve hizmetler dışında kullanılmayacağı belirtilmiş olup, Doğru Kaynak tarafından iletilen işbu ticari elektronik iletileri almak istemiyor ve almayı onaylamıyor iseniz Üyelik Profil linkini tıklayıp listeden çıkabilirsiniz. Doğru Kaynak bu şekilde gönderim listesinden çıkışınızı sağlayacaktır. Bu linkten ayrı olarak işbu ticari elektronik iletilerin herhangi bir zamanda da alımına son vermek istenmesi halinde Hesabım Sayfası altında yer alan Duyuru Tercihlerim sayfası aracılığı ile Doğru Kaynak ile irtibata geçerek isteklerini iletebilirsiniz.
                        </p>

                        <p>
                            Toplanan mali bilgiler de satın aldığınız ürün ve hizmetler ile ilgili satış süreci ve muhasebeleştirilmesi için kullanılmaktadır. www.dogrukaynak.com adlı internet sitemizde bir satın alma yaptığınızda size ait mali bilgilerin, işleminizi gerçekleştirmek için gerekli 3. şahıslara (bankalar, kredi kartı şirketleri vb.) verilmesini kabul etmektesiniz. Paylaşılacak bilgiler kredi kartı numarası, son kullanma tarihi, CVV de dahil gerekli tüm mali bilgileri içerir. 
                        </p>

                        <p>
                            Kişisel Bilgiler ile www.dogrukaynak.com adlı internet sitemizden size hizmetleri verebilmek için temin edilen işbu bilgiler hizmetin amacına yönelik olup bu hizmeti sunmaya yönelik işlemler dışında, ilke olarak, üçüncü kişilere satılmaz, kiralanmaz ya da başka şekilde kullandırılmaz ve siz bizzat aksini talep etmediğiniz sürece üçüncü kişilerle hiçbir suretle paylaşılmaz. Şu kadar ki, Doğru Kaynak yürürlükteki mevzuat uyarınca yetkili, idari ve resmi makamlardan usulüne uygun olarak talep gelmesi halinde size ait uhdesinde bulunan bilgilerini ilgili yetkili makamlarla paylaşacaktır.
                        </p>

                        <p>
                            www.dogrukaynak.com adlı internet sitemizi kullandığınız andan itibaren sizden toplanan tüm bilgileri bizim belirttiğimiz şekilde ve nedenlerle, bu gizlilik politikasında anlatıldığı gibi, kullanmamızı kabul etmiş olursunuz. Yukarıdaki amaç için elde edilen bilgiler, tamamen sizin özgür iradenizle tarafımıza sağlanmaktadır. Bu Kişisel Bilgileri bize verip vermemekte kullanıcılar serbesttir. Ancak, kullanıcılara daha çabuk ve kaliteli hizmet sunabilmemiz için, işbu www.dogrukaynak.com adlı internet sitesinde kullanıcılardan talep edilen bilgilerin tamamını vermelerini önermekteyiz. Ayrıca, talep edilen hizmetin gerektirdiği zorunlu bilgilerin verilmemesi durumunda talebin yerine getirilmesinin mümkün olamayacağını dikkatlerinize sunarız. Kullanıcılar tarafından verilen bilgilerin doğru ve eksiksiz olması kullanıcıların sorumluluğundadır. Yanlış, yanıltıcı veya eksik bilgi verilmemesi rica olunur. Böyle bir durumda Doğru Kaynak hiçbir sorumluluk kabul etmeyecektir. Yanlış, yanıltıcı veya eksik bilgi verilmesi nedeniyle Doğru Kaynak’ın bir zarara uğraması halinde bu zararı tazmin yükümlülüğü kullanıcılara aittir.
                        </p>

                        <p>
                            Eğer bu sözleşme ile ilgili herhangi bir sorunuz veya kişisel bilgilerinizin kullanımı veya ifşası ile ilgili herhangi bir düşünceniz var ise destek@dogrukaynak.com  adresinden ulaşabilirsiniz.
                            Kişisel verilerinizin korunmasına ilişkin talep ve şikayetlerinizi kişisel verilerin korunmasına ilişkin Başvuru Formunu doldurarak Doğru Kaynak&apos;a iletebilirsiniz. www.dogrukaynak.com adlı internet sitemiz Doğru Kaynak’ın kontrolü altında olmayan başka internet sitelerine bağlantı veya referans içerebilir. Bu bağlantıların www.dogrukaynak.com adlı internet sitesinde yer alması, bizim bu siteler ile herhangi bir bağımız bulunduğu anlamına gelmez. Doğru Kaynak, işbu internet sitelerinin içerikleri veya içerdikleri diğer bağlantılardan sorumlu değildir. Bu diğer internet siteleri kullanıcıların bilgisayarına kendi çerezlerini yükleyebilir ve bilgi toplayabilirler. Diğer internet siteleri kendi gizlilik ve kullanım sözleşmelerine sahiptir ve kullanıcılar tarafından paylaşılan bilgileri farklı amaçlar için kullanabilirler. Doğru Kaynak'ın tavsiyesi diğer internet sitelerini kullanmadan önce kullanıcıların sözleşmelerini okumalarıdır. Bu internet sitelerinden ayrı olarak Kişisel Bilgilere ek olarak www.dogrukaynak.com adlı internet sitesi kullanıcılar tarafından ziyaret edildiğinde kullanıcılar hakkında başkaca bilgiler de toplanabilir, derlenebilir.
                        </p>

                        <p>
                            Otomatik olarak derlenen teşhis edebilirlik niteliğine sahip olmayan bu bilgiler, cookies adı verilen çeşitli teknolojiler ile derlenmektedir. Cookies, web sitesinden bilgisayarların sabit diskine aktarılan küçük çaplı metin dosyalarıdır. Bu bilgileri sitemizi, ilgilendiğiniz ve ihtiyaç duyduğunuz ürünlerimizi değiştirebilmek için toplamaktayız. Tercihen "cookies" reddetmek veya "cookie" gönderildiğinde ikaz edilmek açısından kullanıcılar kendi web gezginlerini ayarlayabilirler. Girilen sitede "cookie"leri reddetmek, sitenin bazı alanlarını gezmeyi veya site ziyaret edildiğinde kişiselleştirilmiş bilgilerin alınmasını engelleyebilir. Sitemizi ziyaret ettiğiniz her zaman, sunucumuz tarayıcınız tarafından her sayfa görüntülemenizde yollanan bilgileri kaydeder ("Log bilgisi"). Bu log bilgisi IP adresiniz, tarayıcı türünüz, ziyarette bulunduğunuz domain, gezdiğiniz web siteleri ve arama kriterleriniz gibi bilgileri saklar. Çoğu kullanıcının IP adresi her çevirim içi oluşunda değişir. Biz log bilgilerini sitemizin teknik analizini yapabilmekte ve verdiğimiz hizmetin başarısını sağlayabilmekte kullanmaktayız. Biz edindiğimiz ip adresini, kullanım veya gizlilik politikamız ihlal edilmediği sürece, herhangi bir kişisel bilgi ile eşleştirmemekteyiz.
                        </p>

                        <p>
                            Doğru Kaynak toplanan ve aktarılan tüm Kişisel Bilgileri korumak için teknolojik ve kurumsal önlemleri alır ve bu önlemler teknolojik gelişmeye göre sürekli güncellenir ve uyarlanır. Internet üzerinden iletilen Kişisel Bilgiler'in gizliliği konusunda Doğru Kaynak mevzuattan kaynaklanan önlemleri alsa dahi bu konuda mutlak bir garanti verilmesinin söz konusu olmadığını, internet üzerinde Kişisel Bilgiler'in iletilirken kullanıcılara mümkün olan en üst düzey tedbir almalarını tavsiye ediyoruz. Doğru Kaynak tarafından, tüm kredi kartı ve kişisel bilgileriniz internet güvenlik standardı olan SSL Secure sistemi ile 256 mbit şifrelenmiştir. Bu şekilde internet üzerindeki dolaşımları sırasında herhangi bir şekilde bu bilgilerinizin istenilmeyen kişi veya kurumlarda ele geçirilmesi önlenmiştir.
                        </p>

                        <p>
                            Doğru Kaynak herhangi bir ihlali fark ettiği anda kullanıcıları bu konuda vakit kaybetmeksizin bilgilendirir, yasalara uygun bir şekilde takibinin yapılmasına olanak tanır ve bilgilerinin güvenliğini elinden gelen en iyi şekilde sağlar.
                        </p>

                        <p>
                            Doğru Kaynak vereceğiniz destek ile Kişisel Bilgilerinizi daima güncel ve doğru şekilde tutacaktır. Ancak Kişisel Bilgilerinizin silinmesi, değiştirilmesi veyahut da güncellenmesinin gerektirdiği hallerde Doğru Kaynak ile destek@dogrukaynak.com adresinden irtibata geçmenizi ve böylelikle isteklerinizi iletmenizi rica ederiz.
                        </p>

                        <p>
                            Gizlilik Politikamız ile ilgili her türlü soru ve öneriniz için www.dogrukaynak.com adlı internet sitemizdeki iletişim bölümünden bize ulaşabilirsiniz.
                        </p>

                        <p>
                            İletişim bilgilerimize ulaşmak için lütfen
                            {" "}
                            <Link to="/iletisim">tıklayınız</Link>.
                            {" "}
                            Sitemizin Kişisel Verilerin Korunması Aydınlatma Metni için 
                            {" "}
                            <Link to="/sozlesmeler/aydinlatma-ve-riza-metni">tıklayınız</Link>.
                        </p>
                    </div>
                </div>
            </>
        );
    }
}

Privacy.propTypes = {};

export default Privacy;
