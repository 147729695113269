import React, { Component } from "react";
import classNames from "classnames";
import { Breadcrumb } from "../../components";
import StatsErrorBox from "./StatsErrorBox";
import StatsLessons from "./StatsLessons";
import * as actions from "../../routes/actions/Desk/Chapters";
import { connect } from "react-redux";
import dersImages from "../../assets/images/dersler";
import { Link } from "react-router-dom";
import * as actionsStduy from "../../routes/actions/Study";

const filters = [
  { value: 0, title: "Tümü" },
  { value: 1, title: "Güçlü Yanlarım" },
  { value: 2, title: "Zayıf Yanlarım" },
  { value: 3, title: "Çalışmadıklarım" },
];

class StatsLesson extends Component {
  state = {
    show: false,
    filter: 0,
    saatCalisilan: 0,
    basariDurumu: 0,
    soruStats: {
      toplamDogru: 0,
      toplamYanlis: 0,
      toplamBos: 0,
      soruHaznesi: 0,
      soruCozulen: 0,
    },
    breadcrumbs: [],
    bransId: null,
    uniteId: null,
    unite: null,
    bransTests: [],
    branslar: [],
  };

  async componentDidMount() {
    // this.props.getAnalizes();
    await this.loadPage();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      await this.loadPage();
    }
  }

  async loadPage() {
    var bransId = this.props.match.params.bransId;

    await this.props.getBransWithAllTests(); // bransId

    const soruStats = this.props.unites
      .filter((u) => u.bransId == bransId)
      .reduce(
        function (a, b) {
          a.toplamDogru += b.toplamDogru;
          a.toplamYanlis += b.toplamYanlis;
          a.toplamBos += b.toplamBos;
          a.soruHaznesi += b.testSoruSayisi;
          a.soruCozulen += b.toplamDogru + b.toplamYanlis + b.toplamBos;

          return a;
        },
        {
          toplamDogru: 0,
          toplamYanlis: 0,
          toplamBos: 0,
          soruHaznesi: 0,
          soruCozulen: 0,
        }
      );

    // const branslar = [...new Set(this.props.tests.map(test => test.testBrans))]

    // const branslar = this.props.tests.reduce(function (branslar, test) {

    //     const { testBrans, testKonu, testKonuAd } = test;
    //     let index = branslar.findIndex(p => p.bransId == testBrans);
    //     if (index === -1) {
    //         branslar.push(
    //             {
    //                 bransId: test.testBrans,
    //                 bransAd: test.bransAd,
    //                 tests: [
    //                     test
    //                 ],
    //                 subjects: [
    //                     {
    //                         testKonu,
    //                         testKonuAd
    //                     }
    //                 ]
    //             }
    //         );
    //     } else {
    //         branslar[index].tests.push(test);
    //         const konuIndex = branslar[index].subjects.findIndex(p => p.testKonu == test.testKonu);
    //         if (konuIndex === -1) {
    //             branslar[index].subjects.push(
    //                 {
    //                     testKonu,
    //                     testKonuAd
    //                 }
    //             );
    //         }
    //     }

    //     // r[a.testBrans] = r[a.testBrans] || [];
    //     // r[a.testBrans].push(a);
    //     return branslar;
    // }, []);

    // .map(p => {
    //     return {
    //         bransId: p,
    //         bransAd: this.props.tests.find(t => t.testBrans == bransId).bransAd
    //     };
    // }
    // );

    const bransAd =
      this.props.tests && this.props.tests.length > 0
        ? this.props.tests.find((t) => t.testBrans == bransId).bransAd
        : "";
    const breadcrumbs = [
      {
        title: "Anasayfa",
        url: "/dijitalkitap",
      },
      {
        title: "Analizci",
        url: "/dijitalkitap/analizci",
      },
      {
        title: bransAd,
        active: true,
      },
    ];

    this.setState({
      soruStats,
      breadcrumbs,
      bransId,
      filter: 0,
      bransAd,
    });

    this.props.getAnalizes();
  }

  handleHataGiderClick(unite) {
    this.setState({
      unite,
      show: true,
    });
  }

  handleHataOkClick = async (data) => {
    const result = await this.props.createRandomTest(data);
    if (result) {
      const { testId } = this.props;
      this.props.history.push(
        `/dijitalkitap/book/lesson/subject/quiz/${testId}?v=true`
      );
    }
  };

  render() {
    return (
      <div className="row">
        <div className="col-12">
          <Breadcrumb items={this.state.breadcrumbs} />
        </div>

        <div className="col-12 p-xs-0">
          <div className="card mb-3 px-3 py-4  p-sm-4 overflow-hidden">
            <div className="d-flex flex-row align-items-center stats-lesson-top-summary">
              <img
                src={
                  this.state.bransAd
                    ? dersImages[this.state.bransAd.charAt(0).toLowerCase()]
                    : dersImages["m"]
                }
                className="lesson-logo-stats"
                alt="..."
                width="140"
              />
              <div className="card-body ml-2">
                <p className="card-text fs-20 color-484848">
                  {this.state.soruStats.soruHaznesi} Soru Hazinesi
                </p>
                <span className="card-text color-919191 fs-16">
                  {this.state.soruStats.soruCozulen} Soru Çözüldü
                </span>
                <br />
                <span className="card-text color-919191 fs-16">
                  {this.state.saatCalisilan} Saat Çalışıldı
                </span>
              </div>
              <div className="p-1 text-center">
                <p className="fs-20 color-484848 font-weight-600 d-xs-none">
                  Başarı Durumu
                </p>
                <img
                  src={
                    this.state.soruStats.soruCozulen === 0
                      ? require("../../assets/images/question.svg")
                      : this.state.soruStats.toplamDogru /
                          (this.state.soruStats.toplamYanlis +
                            this.state.soruStats.toplamBos) >
                        0.7
                      ? require("../../assets/images/emoji_1.svg")
                      : require("../../assets/images/emoji_2.svg")
                  }
                  className="stats-lesson-emoji1"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 p-xs-0 my-4 unit-list">
          <h5 className="fs-20 color-484848 mb-2">Ünite Listesi</h5>
          <div className="tab-bar nowrap mt-2">
            <div className="tab-header">
              {filters.map((item) => (
                <button
                  type="button"
                  className={classNames("btn", {
                    active: this.state.filter === item.value,
                    disable: this.state.filter !== item.value,
                  })}
                  onClick={() => this.setState({ filter: item.value })}
                >
                  {item.title}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="col-12 p-xs-0">
          <div className="card unit-card-item p-3">
            {this.props.unites
              .filter((u) => u.bransId == this.state.bransId)
              .sort((a, b) =>
                a.title.toUpperCase().localeCompare(b.title.toUpperCase())
              )
              .filter((p) =>
                this.state.filter === 3
                  ? p.cozulen == 0
                  : this.state.filter === 2
                  ? p.toplamYanlis + p.toplamBos > p.toplamDogru
                  : this.state.filter === 1
                  ? p.toplamYanlis + p.toplamBos < p.toplamDogru
                  : true
              )
              .map((unite, i) => (
                <div className="my-2 bt-dedede pl-2 mobile-fix-content">
                  <div className="d-flex flex-column flex-lg-row flex-wrap align-items-lg-center justify-content-between">
                    <div className="unit-card-header d-flex justify-content-between align-items-center w-lg-100 flex-1">
                      <div className="d-flex">
                        <span className="mr-3 color-919191 fs-16 h-100">
                          {i + 1}
                        </span>
                        <div className="color-484848 font-weight-600 text">
                          {unite.title}
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="color-919191 fs-16 mr-3">{`${
                          unite.toplamDogru +
                          unite.toplamYanlis +
                          unite.toplamBos
                        }/${unite.testSoruSayisi}`}</span>
                        <div className="lesson-list-img">
                          <img
                            src={
                              unite.toplamDogru +
                                unite.toplamYanlis +
                                unite.toplamBos ===
                              0
                                ? require("../../assets/images/question.svg")
                                : unite.toplamDogru /
                                    (unite.toplamYanlis + unite.toplamBos) >
                                  0.7
                                ? require("../../assets/images/emoji_1.svg")
                                : require("../../assets/images/emoji_2.svg")
                            }
                            width="30"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mobile-badges-fix unit-card-body flex-1 font-weight-600 my-2 my-lg-0 text-lg-right">
                      <span className="badge small badge-success fs-14 badge-pill mr-2">
                        <span className="mr-1">{unite.toplamDogru}</span>{" "}
                        <span>D</span>
                        <span className="hidden-lg">oğru</span>
                      </span>
                      <span className="badge small badge-red fs-14 badge-pill mr-2">
                        <span className="mr-1">{unite.toplamYanlis}</span>{" "}
                        <span>Y</span>
                        <span className="hidden-lg">anlış</span>
                      </span>
                      <span className="badge small badge-gray fs-14 badge-pill mr-2">
                        <span className="mr-1">{unite.toplamBos}</span>{" "}
                        <span>B</span>
                        <span className="hidden-lg">oş</span>
                      </span>
                    </div>

                    {/* Web buttons */}
                    <div className="text-lg-right flex-1 unit-card-footer">
                      <Link
                        className="btn btn-primary text-capitalize fs-14 font-weight-600"
                        style={{ width: 126 }}
                        // to={`/dijitalkitap/book/lesson/subject/quiz/${unite.id}`}>
                        to={`/dijitalkitap/profile/chapters/${this.state.bransId}?uniteId=${unite.id}`}
                      >
                        Çalış
                      </Link>
                      {unite.toplamYanlis + unite.toplamBos > 0 && (
                        <button
                          className="btn bg-b91414 text-capitalize ml-3 fs-14 font-weight-600"
                          onClick={() => this.handleHataGiderClick(unite)}
                        >
                          Hatalarımı Gider
                        </button>
                      )}
                    </div>
                    {/* Web buttons */}
                  </div>
                </div>
              ))}
          </div>
          {/* -------Component-------- */}
          {(true || this.props.branslar) && (
            <StatsErrorBox
              show={this.state.show}
              onCloseClick={() => this.setState({ show: false })}
              onOkClick={this.handleHataOkClick}
              // branslar={this.state.branslar}
              bransId={this.state.bransId}
              // unites={this.props.unites}
              unite={this.state.unite}
              // tests={this.props.tests}
              branslar={this.props.branslar}
            />
          )}
        </div>

        <div className="row mt-4">
          <div className="col-xl-12 mt-0 mt-sm-5 mb-2">
            <h4 className="w-100 fs-16 font-weight-bold">Diğer Derslerim</h4>
          </div>
          {this.props.analize.bransAnaliz &&
            this.props.analize.bransAnaliz.map(
              (lesson) =>
                lesson.bransId != this.state.bransId && (
                  <div
                    className="col-lg-3 p-xs-0 col-6 branch-container"
                    key={lesson.bransId}
                  >
                    <StatsLessons lesson={lesson} />
                  </div>
                )
            )}
        </div>
      </div>
    );
  }
}

StatsLesson.propTypes = {};

const mapStateToProps = ({ ChaptersReducer, StudyReducer }) => ({
  unites: ChaptersReducer.unites,
  tests: ChaptersReducer.tests,
  branslar: ChaptersReducer.branslar,
  bransAd: ChaptersReducer.bransAd,
  analize: StudyReducer.analize,
  taxonomies: StudyReducer.taxonomies,
  testId: ChaptersReducer.testId,
});

const mapDispatchToProps = {
  getBransTest: actions.getBransTest,
  getBransWithAllTests: actions.getBransWithAllTests,
  getAnalizes: actionsStduy.getAnalizes,
  createRandomTest: actions.createRandomTest,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatsLesson);
