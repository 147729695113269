/* eslint-disable indent */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Countdown from "react-countdown-now";
import {
  getActivityTypes,
  getReservations,
  cancelReservation
} from "../../routes/actions/Product";
import { reservationStatus } from "../../services/constants";
import { ScoreBadge } from "../../components";
import {
   //GoogleMapsImage,
   GoogleMapsSearch } from "../../services/constants";

import classNames from "classnames";

const Completionist = () => <span>Rezervasyon zaman aşımına uğradı.</span>;

const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    return <Completionist />;
  } else {
    return (
      <span>
        {hours} Saat {minutes} Dakika {seconds} Saniye
      </span>
    );
  }
};

const StepNumber = ({ value }) => <div className="stepNumber">{value}</div>;

const Step1 = ({ reservation }) => (
  <div className="list-group-item border-0 bt-dedede:50">
    <div className="d-flex flex-row">
      <StepNumber value="1" />
      <div className="flex-grow-1">
        <h5 className="fs-15 font-weight-600 mt-1 color-484848">
          Kitapçıya Git
        </h5>
        <p className="mt-4 p-0 fs-14 font-weight-bold color-484848">
          {reservation.storeName}
        </p>
        <p className="fs-13 p-0 mt-2 mb-0 color-919191">
          {`${reservation.storeAddress.lines} ${reservation.storeAddress.town.name}/${reservation.storeAddress.city.name}`}
        </p>
        <p className="fs-13 p-0 m-0 color-919191">
          {reservation.storeAddress.mobile}
        </p>
      </div>

      <a
        href={GoogleMapsSearch(reservation.storeAddress.lines)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {/* <img
          className="card-img"
          src={GoogleMapsImage(reservation.storeAddress.lines, "240x180", 15)}
          alt={reservation.storeName}
          style={{ maxWidth: 240 }}
        /> */}
      </a>
    </div>
  </div>
);

const Step2 = ({ reservation }) => (
  <div className="list-group-item border-0 bt-dedede:50">
    <div className="d-flex flex-row">
      <StepNumber value="2" />
      <div className="flex-grow-1">
        <h5 className="fs-15 font-weight-600 mt-1 color-484848">
          {reservation.id} Rezervasyon numarasını kitapçıya göster.
        </h5>
      </div>
    </div>
  </div>
);

const Step3 = ({ reservation }) => (
  <div className="list-group-item border-0 bt-dedede:50">
    <div className="d-flex flex-row">
      <StepNumber value="3" />
      <div className="flex-grow-1">
        <h5 className="fs-15 font-weight-600 mt-1 color-484848">
          Kitapları Al
        </h5>

        <table className="table table-borderless fs-13 color-919191 w-sm-100 w-md-75">
          <tbody>
            {reservation.products.map(product => (
              <tr key={product.id}>
                <td>{product.name}</td>
                <td>{product.price.toFixed(2)} ₺</td>
                <td>{product.quantity} Adet</td>
                <td className="text-right">
                  {(product.price * product.quantity).toFixed(2)} ₺
                </td>
              </tr>
            ))}
            <tr style={{ borderTop: "1px solid #dedede" }}>
              <td />
              <td />
              <td />
              <td className="text-right text-primary">
                Toplam :{" "}
                {reservation.products
                  .reduce((p, c) => p + c.price * c.quantity, 0)
                  .toFixed(2)}{" "}
                ₺
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

const Step4 = ({ reservation, activity }) => {
  var products = (reservation ? reservation.products : null) || [];
  var total = products.reduce((p, c) => p + c.price * c.quantity, 0);
  var coins = ((activity.Coins / 100) * total).toFixed(0);
  return (
    <div className="list-group-item border-0 bt-dedede:50">
      <div className="d-flex flex-row">
        <StepNumber value="4" />
        <div className="flex-grow-1">
          <h5 className="fs-15 font-weight-600 mt-1 color-484848">
            Kitap Aktivasyonunu Yap
          </h5>
          <p className="fs-14 color-919191 font-weight-500">
            Aktivasyonu yaptığında puan ve jeton kazanacaksınız.
          </p>

          <div className="d-flex flex-column flex-sm-row">
            <h3 title="Kitap aktivasyonu yaptığında +1000PUAN ve +3JETON kazanacaksınız.">
              <ScoreBadge
                value={activity.Score}
                icon="point"
                title={`+${activity.Score} Puan`}
                className="badge-score mr-2"
              />
              <ScoreBadge
                value={coins}
                icon="coin"
                title={`+${coins} Jeton`}
                className="badge-coin"
              />
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

const Actions = ({ reservation, cancel }) => (
  <div className="list-group-item border-0 d-flex align-items-center justify-content-between">
    <button
      type="button"
      className="btn btn-danger text-capitalize fs-14 border-radius-8 px-5 py-2"
      onClick={event => cancel(event, reservation)}
      style={{ minWidth: 170 }}
    >
      İptal Et
    </button>

    <div
      className="alert float-right fs-18 mb-0 font-weight-600"
      title="Rezervasyonun birmesine 8 saat 25 dakika kaldı."
    >
      <span className="color-919191">Kalan Süre:</span>{" "}
      <Countdown
        date={reservation.expirationDate}
        renderer={countdownRenderer}
      />
    </div>
  </div>
);

const PendingReservation = ({ activity, reservation, cancel }) => (
  <>
    <div className="card card-list-group reservation-list mb-2 p-3">
      <Step1 reservation={reservation} />
      <Step2 reservation={reservation} />
      <Step3 reservation={reservation} activity={activity} />
      <Step4 reservation={reservation} activity={activity} />
      <Actions reservation={reservation} cancel={cancel} />
    </div>
  </>
);

const CompletedReservation = ({ reservation, show, onToggle }) => (
  <div className="card mb-3">
    <div
      className="card-header border-0 align-items-baseline bg-white d-flex flex-column flex-md-row justify-content-between"
      onClick={() => onToggle(reservation.id)}
    >
      <h6 className="card-title m-0 p-2 w-sm-100 d-flex align-items-center d-sm-block flex-wrap">
        <img
          src={require("../../assets/images/icons/icon-chevron-blue.svg")}
          className="mr-2"
          alt=""
        />
        <span className="badge bg-f4f4f4 border-radius-8 p-2 mr-2 color-484848 fs-14 d-block d-sm-inline-block  mb-sm-0">
          {reservation.id}
        </span>
        <span className="color-484848 fs-14">{reservation.storeName}</span>
      </h6>
      {reservation.status === reservationStatus.Canceled && (
        <div
          className="alert text-danger mb-0 py-1 fs-13 font-weight-600"
          role="alert"
        >
          İptal edildi.
        </div>
      )}
      {reservation.status === reservationStatus.TimedOut && (
        <div
          className="alert text-danger mb-0 py-1 fs-13 font-weight-600"
          role="alert"
        >
          Süre bitmiştir.
        </div>
      )}
      {reservation.status === reservationStatus.Completed && (
        <div
          className="alert text-success mb-0 py-1 fs-13 font-weight-600"
          role="alert"
        >
          Alişverişi tamamladınız 300puan ve 200 jeton kazandınız.
        </div>
      )}
    </div>

    <div className={classNames("collapse", { show: show })}>
      <div className="row no-gutters">
        <div className="col-md-8">
          <div className="card-body">
            <div className="bt-dedede:50 pb-2">
              <p className="fs-13 p-0 mt-2 mb-0 color-4a4a4a">
                {reservation.storeAddress && `${reservation.storeAddress.lines} ${reservation.storeAddress.town.name}/${reservation.storeAddress.city.name}`}
              </p>
              <p className="fs-13 p-0 mt-2 mb-0 color-4a4a4a">
                {reservation.storeAddress && reservation.storeAddress.mobile}
              </p>
            </div>

            <table className="table table-borderless fs-13 color-919191">
              <tbody>
                {reservation.products.map(product => (
                  <tr key={product.id}>
                    <td width="32%">{product.name}</td>
                    <td>{product.price.toFixed(2)} ₺</td>
                    <td>{product.quantity} Adet</td>
                    <td className="text-right">
                      {(product.price * product.quantity).toFixed(2)} ₺
                    </td>
                  </tr>
                ))}
                <tr style={{ borderTop: "1px solid #dedede" }}>
                  <td />
                  <td />
                  <td />
                  <td className="text-right text-primary">
                    Toplam :{" "}
                    {reservation.products
                      .reduce((p, c) => p + c.price * c.quantity, 0)
                      .toFixed(2)}{" "}
                    ₺
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        
        {reservation.storeAddress && <div className="col-md-4 p-2">
          <a
            href={GoogleMapsSearch(reservation.storeAddress.lines)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <img
              className="card-img"
              src={GoogleMapsImage(
                reservation.storeAddress.lines,
                "240x180",
                15
              )}
              alt={reservation.storeName}
              style={{ maxWidth: 240 }}
            /> */}
          </a>
        </div>}
      </div>
    </div>
  </div>
);

class Reservations extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { selected: 0 };
  }

  componentDidMount() {
    this.props.getReservations();
    this.props.getActivityTypes();
  }

  handleToggle = value => {
    if (this.state.selected === value) {
      this.setState({ selected: 0 });
    } else {
      this.setState({ selected: value });
    }
  };

  cancelReservation = async (e, reservation) => {
    e.preventDefault();

    const confirmed = window.confirm(
      `${reservation.storeName} - ${reservation.id} numaralı rezervasyonunuz iptal edilecek onaylıyor musunuz?`
    );

    if (confirmed) {
      const { success, error } = await this.props.cancelReservation(
        reservation
      );

      if (success) {
        toast.success("Reservasyon iptal edilmiştir.");
      } else {
        toast.error(error);
      }
    }
  };

  render() {
    const waiting = [];
    const completed = [];

    this.props.reservations.forEach(reservation => {
      switch (reservation.status) {
        case reservationStatus.Waiting:
          waiting.push(reservation);
          break;
        default:
          completed.push(reservation);
      }
    });

    var activity = { Score: 0, Coins: 0 };
    if (this.props.activityTypes && this.props.activityTypes.length > 0) {
      activity = this.props.activityTypes.find(
        x => x.Name === "complete-reservation"
      );
    }

    return (
      <>
        {this.props.reservations.length ? (
          <>
            {waiting.map(reservation => (
              <PendingReservation
                activity={activity}
                reservation={reservation}
                key={reservation.id}
                cancel={this.cancelReservation}
              />
            ))}
            {completed.map(reservation => (
              <CompletedReservation
                reservation={reservation}
                show={this.state.selected === reservation.id}
                onToggle={this.handleToggle}
                key={reservation.id}
              />
            ))}
          </>
        ) : (
          <div className="card card-list-group list-group mb-2 p-3">
            <div className="list-group-item border-0 bt-dedede:50">
              <div className="d-flex flex-row">
                <div className="flex-grow-1">
                  <h5 className="fs-15 font-weight-600 mt-1 color-484848">
                    Rezervasyonunuz bulunmamaktadır.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

Reservations.propTypes = {};

const mapStateToProps = ({ ProductReducer }) => ({
  reservations: ProductReducer.reservations,
  activityTypes: ProductReducer.activityTypes
});

const mapDispatchToProps = {
  getReservations,
  cancelReservation,
  getActivityTypes
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reservations);
