import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import { connect } from "react-redux";
import Icon from "../components/Icon/Index";
import apiCall from "../services/api";
import * as contentActions from "../routes/actions/Content";
import { toast } from "react-toastify";

const MainBanner = forwardRef(({ mobile, mode, onChange, ...props }, ref) => {
  const [page, setPage] = useState([]);

  useImperativeHandle(ref, () => ({
    newDataFromParent(newData) {
      setPage(newData);
    },
  }));

  useEffect(() => {
    if (props.bannerName) {
      apiCall(`/contents/content/${props.bannerName}/1`).then((res) => {
        setPage(res.data);
      });
    }
  }, [props.bannerName]);

  const handleClick = (event) => {
    if (onChange) {
      onChange(event.target.name, props.bannerName, null, event.target.value);
    }
  };

  const toggleShowBanner = async (active) => {
    const newState = {
      ...page,
      active,
    };
    setPage(newState);
    await props.savePage(newState.id, newState);
    const result = await props.publishPage(newState.id);
    if (result.success && active === 1) {
      toast.success("Banner canlıya alındı.");
    }
    if (result.success && active === 0) {
      toast.success("Banner canlıdan çıkarıldı.");
    }
  };

  if (mode !== "draft" && page.active === 0) {
    return null;
  }
  return (
    <>
      <div className="main-banner">
        {mode === "draft" && (
          <div className="editable-container">
            <button
              className="btn"
              name="editableBanner"
              value={"mainBanner"}
              onClick={handleClick}
            >
              <Icon icon="iEditable" />
            </button>
            {page?.active === 1 && (
              <button className="btn" onClick={() => toggleShowBanner(0)}>
                <Icon icon="iEyeOff" />
              </button>
            )}
            {page?.active === 0 && (
              <button className="btn" onClick={() => toggleShowBanner(1)}>
                <Icon icon="iEyeOn" />
              </button>
            )}
          </div>
        )}
        {page.content?.target ? (
          <a href={page.content?.target || "/"} target="_blank">
            <img
              srcSet={`${page.content?.mobile} 768w, ${page.content?.web} 1078w`}
              sizes={`(max-width: 768px) 768w, 100%`}
              src={`${page.content?.web}`}
              className="img-fluid w-100"
            />
          </a>
        ) : (
          <img
            srcSet={`${page.content?.mobile} 768w, ${page.content?.web} 1078w`}
            sizes={`(max-width: 768px) 768w, 100%`}
            src={`${page.content?.web}`}
            className="img-fluid w-100"
          />
        )}
      </div>
    </>
  );
});

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = {
  savePage: contentActions.savePage,
  publishPage: contentActions.publishPage,
};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(MainBanner);
