import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Select from "react-select";
//import PropTypes from 'prop-types';
//import classNames from "classnames";
//import {faClock} from "@fortawesome/free-regular-svg-icons";
//import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { OrdersAccordion, LinkButton } from "../../components";
import * as actions from "../../routes/actions/Account";
import { formatDate as dateFormatter, customSelectStyle } from "../../helpers";
import { replaceDotWithComma } from "../../helpers";
import OrderStep from "./OrderStep";
// import Item from "../../components/Product/Item";

const options = [
  {
    value: 1,
    label: "Son 1 Ay",
  },
  {
    value: 6,
    label: "Son 6 Ay",
  },
  {
    value: 12,
    label: "Son 1 Yıl",
  },
  {
    value: 1000,
    label: "Tüm Siparişler",
  },
];

class Orders extends PureComponent {
  state = { orderFilter: options[0] };

  componentDidMount() {
    this.props.getOrders();
  }

  formatDate(date) {
    const _date = new Date(date);
    return dateFormatter.dateCustom(_date);
  }

  filterOrders = (filter) => {
    this.setState({
      orderFilter: filter,
    });
  };

  render() {
    const date = new Date();
    date.setDate(date.getDate() - this.state.orderFilter.value * 30);
    const filteredOrders = this.props.orders.filter(
      (order) => order.parsedDate >= date
    );
    return (
      <>
        <div className="d-flex flex-row align-items-baseline justify-content-between w-100 mb-4">
          <h3 className="fs-18 color-484848 font-weight-bold d-flex flex-column flex-sm-row align-items-baseline">
            <span>Siparişlerim</span>
            <p className="color-484848 fs-14 font-weight-500 d-inline ml-0 ml-sm-3">
              {`${this.state.orderFilter.label} içinde verdiğiniz ${filteredOrders.length} adet sipariş listeleniyor`}
            </p>
          </h3>
          <Select
            isSearchable={false}
            options={options}
            defaultValue={options[0]}
            className="w-100 w-sm-25"
            styles={customSelectStyle}
            onChange={this.filterOrders}
          />
        </div>

        {filteredOrders.map((order) => (
          <div
            key={order.id}
            className="card mb-3 orders-details d-none d-md-block"
          >
            <div className="card-body">
              <ul className="list-group list-group-horizontal-md d-flex justify-content-between">
                <li className="list-group-item border-0 color-484848">
                  <h6 className="fs-15 font-weight-600">
                    Sipariş No: {order.id}
                  </h6>
                  <span className="fs-14 font-weight-600">
                    {this.formatDate(order.date)}
                  </span>
                </li>
                <li className="list-group-item border-0 color-484848">
                  <h6 className="fs-15 font-weight-600">Sipariş Özeti</h6>
                  <span className="fs-14 font-weight-600">
                    {order.lines.length} Ürün
                  </span>
                </li>
                <li className="list-group-item border-0 color-484848">
                  <h6 className="fs-15 font-weight-600">Alıcı:</h6>
                  <span className="fs-14 font-weight-600">
                    {order.fullname}
                  </span>
                </li>
                <li className="list-group-item border-0 color-484848">
                  <h6 className="fs-15 font-weight-600">Toplam:</h6>
                  <span className="fs-14 font-weight-600 color-ff7c1e">
                    {replaceDotWithComma(order.total.toFixed(2))} ₺
                  </span>
                </li>
              </ul>

              <OrderStep order={order} showOnlyLastStep={false} />

              <ul className="list-group list-group-horizontal py-2 mt-3 bt-dedede d-sm-none d-md-block flex-wrap">
                {order.lines.map((line) => (
                  <li
                    key={line.id}
                    className="list-group-item border-0 py-3 px-0 color-202124 d-flex"
                  >
                    <img
                      src={line.product.imageUrl}
                      alt=""
                      style={{ height: 70, width: 55, marginRight: 16 }}
                    />
                    <div className="d-flex flex-column justify-content-around">
                      <span className="d-block fs-16">
                        {line.product.name}
                        {line.price === 0 ? (
                          <>
                            <br />
                            <small
                              className="fs-12 font-weight-500"
                              style={{ color: "#ff0000" }}
                            >
                              <i>Hediye</i>
                            </small>
                          </>
                        ) : null}
                      </span>
                      <b className="text-primary">
                        {replaceDotWithComma((line.total + line.taxTotal).toFixed(2))} ₺
                      </b>
                    </div>
                  </li>
                ))}
              </ul>

              <LinkButton
                to={`/hesabim/siparislerim/${order.id}`}
                type="button"
                className="btn btn-order-status btn-primary"
              >
                Sipariş Detayı
              </LinkButton>
              {order.deliveries[0].statusHistory.length === 1 ? (
                <button
                  className="btn color-202124 btn-order-status"
                  style={{ marginLeft: 32 }}
                >
                  Ödeme Bekleniyor
                </button>) : null
              }

            </div>
          </div>
        ))}

        <OrdersAccordion
          filteredOrders={filteredOrders}
          formatDate={this.formatDate}
          parseOrder={this.parseOrder}
          order={filteredOrders}
        />
      </>
    );
  }
}

Orders.propTypes = {};

const mapStateToProps = ({ AccountReducer }) => ({
  orders: AccountReducer.orders,
});

const mapDispatchToProps = {
  getOrders: actions.getOrders,
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
