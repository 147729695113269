import React from "react";
import { ProgressBar, LinkButton } from "../../components";

const StatsLessons = ({ lesson }) => {
  return (
    <LinkButton
      to={`/dijitalkitap/analizci/ders/${lesson.bransId}`}
      className="p-0 w-100 mb-2"
    >
      <div className="card branch-card">
        <div className="card-header border-0 bg-transparent d-flex flex-row align-items-center justify-content-between">
          <h3 className="lesson-title m-0 text-truncate">{lesson.bransAd}</h3>
          <img
            src={
              lesson.dogru === 0
                ? require("../../assets/images/emoji_2.svg")
                : Math.round(
                    (lesson.dogru / lesson.dogru + lesson.yanlis + lesson.bos) *
                      100 >=
                      70
                  )
                ? require("../../assets/images/emoji_2.svg")
                : require("../../assets/images/emoji_1.svg")
            }
            alt={lesson.bransAd}
            width="40"
          />
        </div>
        <div className="card-body py-2 px-3 text-center">
          <p className="fs-16 color-9b9b9b font-weight-600 total-question">
            {lesson.cozulenSoru}/{lesson.toplamSoru}
          </p>

          <ProgressBar className="progress-thick mb-2" value={lesson.yuzde} />

          <h4 className="text-primary fs-20 font-weight-600 mt-3">
            {lesson.calismaSaati} Saat
          </h4>

          <hr style={{ borderColor: "#cecece" }} />
        </div>
        <div className="card-footer">
          <div className="d-flex flex-row stats-container justify-content-between fs-16 font-weight-600 color-9b9b9b">
            <div className="d-flex flex-column align-items-center">
              <p>Doğru</p>
              <span className="badge badge-success fs-14 font-weight-600 badge-pill">
                {lesson.dogru}
              </span>
            </div>
            <div className="d-flex flex-column align-items-center">
              <p>Yanlış</p>
              <span className="badge badge-red fs-14 font-weight-600 badge-pill">
                {lesson.yanlis}
              </span>
            </div>
            <div className="d-flex flex-column align-items-center">
              <p>Boş</p>
              <span className="badge badge-gray fs-14 font-weight-600 badge-pill">
                {lesson.bos}
              </span>
            </div>
          </div>
        </div>
      </div>
    </LinkButton>
  );
};

export default StatsLessons;
