import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { customStyles } from "../../helpers/index";
import { iconProductClose } from "../../assets/images/icons";
import * as actions from "../../routes/actions/Account";
import { orderList } from "../../services/constants";
import { customFilterOption } from "../../components/Select/Utility";
import { ProductList,LaterProductList } from "../../components";
import { favoriteTypes } from "../../services/constants";
//import PropTypes from 'prop-types';
import apiCall from "../../services/api";

const brandOrderList = [
    {
        value: "order",
        label: "Sırala"
    },
    {
        value: "atoz",
        label: "A-Z"
    },
    {
        value: "ztoa",
        label: "Z-A"
    }
];

class Favorites extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            productOrder: orderList[0],
            brandOrder: brandOrderList[0],
            products: [],
            brands: [],
            laterProducts : []
        };
    }

    componentDidMount() {
        if (!this.props.favorites.length) {
            this.props.getFavorites();
        }
        if (!this.props.laterProducts.length) {
            this.props.getLaterProducts();
        }
    }

    orderProducts = (order) => {
        let products = this.props.favorites.products.concat();

        switch (order.value) {
            case orderList[1].value:
                products = products.sort((a, b) => a.total - b.total);
                break;
            case orderList[2].value:
                products = products.sort((a, b) => b.total - a.total);
                break;
            case orderList[3].value:
                products = products
                    .concat()
                    .sort((a, b) =>
                        a.name.toUpperCase().localeCompare(b.name.toUpperCase()));
                break;
            case orderList[4].value:
                products = products
                    .concat()
                    .sort((a, b) =>
                        b.name.toUpperCase().localeCompare(a.name.toUpperCase()));
                break;
            default:
                products = this.props.favorites.products;
                break;
        }

        this.setState({ productOrder: order, products });
    };

    orderBrands = (order) => {
        let brands = [];

        switch (order.value) {
            case orderList[1].value:
                brands = this.props.favorites.brands
                    .concat()
                    .sort((a, b) => a.total - b.total);
                break;
            case orderList[2].value:
                brands = this.props.favorites.brands
                    .concat()
                    .sort((a, b) => b.total - a.total);
                break;
            case orderList[3].value:
                brands = this.props.favorites.brands
                    .concat()
                    .sort((a, b) =>
                        a.name.toUpperCase().localeCompare(b.name.toUpperCase()));
                break;
            case orderList[4].value:
                brands = this.props.favorites.brands
                    .concat()
                    .sort((a, b) =>
                        b.name.toUpperCase().localeCompare(a.name.toUpperCase()));
                break;
            default:
                brands = this.props.favorites.brands;
                break;
        }

        this.setState({ brandOrder: order, brands });
    };

    removeFromFavorites = async (brand) => {
        const { success, error } = await this.props.removeFromFavorites(
            brand,
            favoriteTypes.brand
        );

        if (success) {
            toast.success(`${brand.name} favorilerinizden çıkarıldı`);
        }
        else {
            toast.error(error.message);
        }
    };


    render() {
        const products = this.state.products.length
            ? this.state.products
            : this.props.favorites.products;
        const brands = this.state.brands.length
            ? this.state.brands
            : this.props.favorites.brands;
        const laterProducts = this.state.laterProducts.length
            ? this.state.laterProducts
            : this.props.laterProducts.products;
        return (
            <>
                {

                <>

                <div className="row mt-4 mb-4">
                <div className="col-12 d-flex flex-sm-row flex-column align-items-baseline justify-content-between">
                    <h3 className="flex-1 fs-18 color-484848 font-weight-600">
                        Favori Ürünlerim
                      {/* {products.length > 0 && (
                            <span className="fs-12 ml-3">
                                {products.length}
                                {" "}
                                adet
                          </span>
                        )} */}
                    </h3>
                    {/* {products.length > 0 && (
                        <Select
                            isSearchable={false}
                            className="w-100 w-sm-25"
                            options={orderList}
                            defaultValue={this.state.productOrder}
                            value={this.state.productOrder}
                            styles={customStyles}
                            onChange={this.orderProducts}
                            filterOption={customFilterOption}
                        />
                    )} */}
                </div>
                 {(this.props.favorites.isLoaded === true && products.length < 1) && (
                    <div className="col-12">
                        <p>Favori ürününüz bulunmamaktadır.</p>
                    </div>
                )}
            </div>

            <ProductList items={products} showCloseButton />


                <div className="row mb-4 pt-4">
                    <div className="col-12 d-flex flex-sm-row flex-column align-items-baseline justify-content-between">
                        <h3 className="flex-1 fs-18 color-484848 font-weight-600">
                            Favori Markalarım
                          {/* {brands.length ? (
                                <span className="fs-12 ml-3">
                                    {brands.length}
                                    {" "}
                                    adet
                              </span>
                            ) : null} */}
                        </h3>
                        {/* {brands.length ? (
                            <Select
                                isSearchable={false}
                                className="w-100 w-sm-25"
                                options={brandOrderList}
                                defaultValue={this.state.brandOrder}
                                value={this.state.brandOrder}
                                styles={customStyles}
                                onChange={this.orderBrands}
                                filterOption={customFilterOption}
                            />
                        ) : null} */}
                    </div>
                    {brands.length ? (
                        brands.map((brand, i) => (
                            
                            <div key={brand.id} className="col-6 col-md-4 col-xl-3">
                                <div className="card w-100 mb-2 mt-2 favoriteBrand">
                                    <figure className="mt-5">
                                        <Link to={`/markalar/detay/${brand.code}?b=${brand.id}`}>
                                            <img
                                                className="d-block mx-auto"
                                                src={brand.logo}
                                                alt={brand.name}
                                            />
                                        </Link>
                                    </figure>
                                    <div className="card-body">
                                        <h5 className="card-title d-block text-center">
                                            {brand.name}
                                        </h5>
                                    </div>
                                    <button
                                        onClick={() =>
                                            this.removeFromFavorites(brand, favoriteTypes.brand)
                                        }
                                        className="btn btn-link position-absolute card-close-btn"
                                        style={{  width: '38px', height: '38px' }}>
                                        <img src={iconProductClose} alt="" />
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                            <div className="col-12">
                                <p>Favori markanız bulunmamaktadır.</p>
                            </div>
                        )}
                </div>


                </>

                }

                <div className="row mt-4 mb-4">
                    <div className="col-12 d-flex flex-sm-row flex-column align-items-baseline justify-content-between">
                        <h3 className="flex-1 pt-3 fs-18 color-484848 font-weight-600">
                            Daha Sonra Alacaklarım

                        </h3>

                    </div>
                     {(this.props.laterProducts.isLoaded === true && laterProducts.length < 1) && (
                        <div className="col-12">
                            <p>Daha Sonra Alacaklarımda ürününüz bulunmamaktadır.</p>
                        </div>
                    )}
                </div>

                <LaterProductList items={laterProducts} showCloseButton />

            </>
        );
    }
}


Favorites.propTypes = {};

const mapStateToProps = ({ AccountReducer }) => ({
    favorites: AccountReducer.favorites,
    laterProducts : AccountReducer.laterProducts
});

const mapDispatchToProps = {
    getFavorites: actions.getFavorites,
    getLaterProducts: actions.getLaterProducts,
    removeFromLaterProducts: actions.removeFromLaterProducts,
    removeFromFavorites: actions.removeFromFavorites
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Favorites);
