import React, { PureComponent } from "react";
//import PropTypes from "prop-types";
import DateSelect from "../../Select/Date";

class Date extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { value: props.value };
  }

  handleChange = event => {
    const { onChange } = this.props;
    const target = event.target;
    onChange(target.name, target.value);

    this.setState({
      value: target.value
    });
  };

  render() {
    const { name, readOnly } = this.props;

    return (
      <DateSelect
        name={name}
        value={this.state.value}
        onChange={this.handleChange}
        readOnly={readOnly}
      />
    );
  }
}

Date.propTypes = {};

export default Date;
