import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import like from "../../assets/images/icons/like.svg";
import { LinkButton } from "..";
import OrderStep from "../../views/Account/OrderStep";
import { replaceDotWithComma } from "../../helpers";

export default function OrdersAccordion({
  filteredOrders,
  formatDate,
  parseOrder,
  order,
}) {
  const [active, setActive] = useState("");

  return (
    <>
      {filteredOrders.map((order) => (
        <div
          className={`order-mobile-item ${active === order.id ? "show" : ""}`}
          onClick={() =>
            active === order.id ? setActive("") : setActive(order.id)
          }
          key={order.id}
        >
          <div className="card-header">
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column fs-14 mb-2">
                <h6 className="fs-14 m-0 font-weight-600 color-484848">
                  Sipariş No: {order.id}
                </h6>
                <span className="color-545454 font-weight-500">
                  {formatDate(order.date)}
                </span>
              </div>
              <FontAwesomeIcon
                icon={faChevronRight}
                className="accordion-icon ml-auto"
              />
            </div>
            <OrderStep order={order} showOnlyLastStep={true} />
            <hr />
          </div>
          <div className="card-body">
            {order.lines.map((line, i) => (
              <div className="d-flex mb-3" key={i}>
                <img
                  src={require("../../assets/images/images/kitap.png")}
                  alt=""
                  style={{ height: 70, width: 55, marginRight: 16 }}
                />
                <div className="d-flex flex-column justify-content-around">
                  <span className="d-block fs-14 font-weight-500">
                    {line.product.name}
                    {line.price === 0 ? (
                      <>
                        <br />
                        <small
                          className="fs-14 font-weight-bold"
                          style={{ color: "#ff0000" }}
                        >
                          <i>Hediye</i>
                        </small>
                      </>
                    ) : null}
                  </span>
                  <b className="text-primary">
                    {replaceDotWithComma((line.total + line.taxTotal).toFixed(2))} ₺
                  </b>
                </div>
              </div>
            ))}
            <hr />
            <div>
              <ul>
                <li className="list-group-item border-0 color-484848">
                  <h6 className="fs-15 font-weight-600">Sipariş Özeti</h6>
                  <span className="fs-14 font-weight-600">
                    {order.lines.length} Ürün
                  </span>
                </li>
                <li className="list-group-item border-0 color-484848">
                  <h6 className="fs-15 font-weight-600">Alıcı:</h6>
                  <span className="fs-14 font-weight-600">
                    {order.fullname}
                  </span>
                </li>
                <li className="list-group-item border-0 color-484848">
                  <h6 className="fs-15 font-weight-600">Toplam:</h6>
                  <span className="fs-14 font-weight-bold color-ff7c1e">
                    {replaceDotWithComma(order.total.toFixed(2))} ₺
                  </span>
                </li>
              </ul>
              <hr />
            </div>
          </div>
          <div className="card-footer">
            <LinkButton
              to={`/hesabim/siparislerim/${order.id}`}
              type="button"
              className="btn btn-order-status btn-primary mr-1"
            >
              Sipariş Detayı
            </LinkButton>

            <button className="btn color-202124 btn-order-status">
              Ödeme Bekleniyor
            </button>
          </div>
        </div>
      ))}
    </>
  );
}
