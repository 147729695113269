import React from "react";
import Modal from "../../components/Modal/Index";
import { BaseForm } from "../../components";
import FormSchemas from "../../variables/FormSchemas";
import { connect } from "react-redux";
import { updateClass } from "../../routes/actions/Account";

const UpdateClassWarning = (props) => {
  const handleUpdateClass = (formData) => {
    props.updateClass(props.user.id, formData);
  };

  return (
    <Modal
      className="loginModal modal-container non-modal-height"
      show={props.show}
      // onCloseClick={props.onCloseClick}
    >
      <div className="modal-body">
        <div className="row">
          <div className="col-md-12">
            <blockquote className="blockquote text-center">
              <p className="mb-0">
                Yeni Eğitim-Öğretim yılına geçtik. Sınıfını şimdi güncelle!
              </p>
            </blockquote>
          </div>
        </div>
        <BaseForm
          id="login"
          schema={FormSchemas.updateClass.schema}
          uiSchema={FormSchemas.updateClass.uiSchema}
          onSubmit={handleUpdateClass}
          formData={{}}
        >
          <button
            className="btn btn-primary btn-block py-2 fs-13 border-radius-8"
            type="submit"
          >
            Kaydet
          </button>
        </BaseForm>
      </div>
    </Modal>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  updateClass,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateClassWarning);
