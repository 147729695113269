import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class Button extends PureComponent {
    render() {
        return (
            <button
                type="button"
                className={classNames("btn", this.props.className)}
                onClick={this.props.onClick}>
                {this.props.children}
            </button>
        );
    }
}

Button.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

export default Button;
