import React, { PureComponent } from "react";

import { connect } from "react-redux";

import { toast } from "react-toastify";
import PropTypes from "prop-types";

import { Link, withRouter } from "react-router-dom";
import classNames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LinkButton, MenuItem, Button } from "../components";
import IconButton from "../components/Button/Icon";

import { faChevronRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import * as actions from "../routes/actions/Account";

import { getBasket, search } from "../routes/actions/Product";
import Icon from "../components/Icon/Index";


const formatDate = (date) => {
  const options = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
    timeZone: "Europe/Istanbul",
  };

  return new Intl.DateTimeFormat("tr-TR", options).format(new Date(date));
};

const ProfileMenu = ({
  authenticated,
  routes,
  onClick,
  logout,
  user,
  loginRequired,
}) => {
  return authenticated ? (
    <>
      <div
        className="list-group dropdown-menu-list"
        style={{
          width: 300,
        }}
      >
        <h5 className="pl-3 pt-3">
          {user.firstname} {user.lastname}
        </h5>
        {routes.map(
          (item, i) =>
            item.hidden !== true &&
            ((item.roles || [""]).some((p) => p !== "editor") ||
              user.editor) && (
              <MenuItem
                to={item.path}
                icon={item.icon}
                key={i}
                noRedirect={
                  item.path.indexOf("/hesabim/hesap-bilgilerim") > -1 &&
                  user.demo
                }
                onClick={() => {
                  if (user.demo) {
                    toast.error(
                      "Bu işlemi DEMO modunda yapamazsınız. Devam etmek için giriş yapmanız gerekiyor."
                    );
                    loginRequired(true);
                  }
                }}
              >
                {item.title}
              </MenuItem>
            )
        )}
        <MenuItem onClick={logout} icon="iExit" className="mb-3" name="logout">
          Çıkış Yap
        </MenuItem>
      </div>
    </>
  ) : (
    <div className="card-body">
      <button
        className="btn btn-primary w-100 mb-2"
        onClick={onClick}
        name="login"
      >
        Giriş
      </button>
      <button
        className="btn btn-outline-secondary w-100"
        onClick={onClick}
        name="register"
      >
        Üye Ol
      </button>
    </div>
  );
};

const Notifications = ({ notifications }) => (
  <ul className="list-group list-group-flush">
    {notifications.length ? (
      notifications.map((notification) => (
        <li key={notification.id} className="list-group-item">
          <div className="d-flex w-100 align-items-center">
            <div className="flex-1">
              <Icon icon="iNotification" />
            </div>
            <div className="flex-5">
              <p className="mb-1"> {notification.description}</p>
              <small className="text-muted">
                {formatDate(notification.createDate)}
              </small>
            </div>
          </div>
        </li>
      ))
    ) : (
      <li className="list-group-item">
        <div className="d-flex w-100 align-items-center">
          <div className="flex-1">
            <Icon icon="iNotification" />
          </div>
          <div className="flex-5">
            <p className="mb-0">Bildiriminiz bulunmamaktadır.</p>
          </div>
        </div>
      </li>
    )}
  </ul>
);

const ShoppingCart = ({ basket, handleCreateOrder }) => {
  return (
    <div className="card-body shopping-cart">
      <h5
        className="color-484848"
        style={{
          fontSize: 14,
        }}
      >
        Sepetim
      </h5>
      <ul className="list-unstyled my-3">
        {basket.map((item, i) => (
          <li className="media my-3" key={item.productId}>
            <img
              src={item.product.imageUrl}
              alt="..."
              className="mr-3 basket-product-img"
            />
            <div className="media-body d-flex flex-column">
              <h6 className="cart-item-heading">
                {item.product.name}
                {item.price === 0 ? (
                  <>
                    <br />
                    <small
                      className="fs-12 font-weight-500"
                      style={{
                        color: "#ff0000",
                      }}
                    >
                      <i>Hediye</i>
                    </small>
                  </>
                ) : null}
              </h6>
              <strong className="cart-item-price">
                {(item.total || 0).toFixed(2)} ₺
              </strong>
            </div>
          </li>
        ))}
      </ul>
      <div className="d-flex">
        <LinkButton
          className="btn-secondary fs-12 mr-2 font-weight-600 text-capitalize nowrap"
          to="/sepetim"
          style={{
            cursor: "pointer",
          }}
        >
          Sepete Git
        </LinkButton>
        <Button
          className="btn-primary fs-12 font-weight-600 text-capitalize nowrap"
          onClick={() => handleCreateOrder()}
        >
          Siparişi Tamamla
        </Button>
      </div>
    </div>
  );
};

class NavbarBlog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      showLogin: false,
      showRegister: false,
      showForgotPassword: false,
      authenticated: props.user.id ? true : false,
      showClassrooms: false,
      isDeskMode: false,
      handleClick: false,
    };

    document.addEventListener("mousedown", this.handleClick, false);

    this.processing = false;
  }

  componentDidMount() {}

  fillBasketMenu = async () => {
    if (!this.proccessing && !this.props.basket.length) {
      this.processing = true;
      await this.props.getBasket();
      this.processing = false;
    }
  };

  handleClick = (event) => {
    const { name } = event.currentTarget || event.target;

    const state = {
      showLogin: name === "login",
      showRegister: name === "register",
      showForgotPassword: name === "forgotten",
      handleClick: true,
    };

    if (name) {
      if (name === "authenticate") {
        state.authenticated = true;
      } else if (name === "logout") {
        state.authenticated = false;
      }

      this.setState(state);
    }
  };

  handleCreateOrder = async () => {
    if (this.props.basket.length < 1) {
      toast.error(
        "Sepetinizde ürün yok. Lütfen ürün ekleyip öyle devam ediniz."
      );
      return;
    }

    if (this.props.user.demo) {
      toast.error(
        "Bu işlemi DEMO modunda yapamazsınız. Devam etmek için giriş yapmanız gerekiyor."
      );
      return;
    }

    let order = {};

    order.lines = this.props.basket
      .filter((x) => x.price !== 0)
      .map((b) => {
        return {
          productId: b.productId,
          quantity: b.quantity,
        };
      });
    let result = await this.props.createOrder(order);

    if (result.success) {
      this.props.history.push(`/odeme/sepet/$ {
                    result.data.id
                }

                `);
    } else {
      toast.error(result.error);
    }
  };

  login = async (formData) => {
    const { success, error } = await this.props.login(formData);

    if (success) {
      this.setState(
        {
          authenticated: true,
          showLogin: false,
          showRegister: false,
          showForgotPassword: false,
          handleClick: true,
        },

        () => {
          toast.success(`Hoş geldin $ {
                            this.props.user.firstname
                        }

                        $ {
                            this.props.user.lastname
                        }

                        `);
        }
      );
    } else {
      toast.error(error);
    }
  };

  logout = () => {
    this.props.logout(this.props.history);

    this.setState({
      authenticated: false,
      showLogin: false,
      showRegister: false,
      showForgotPassword: false,
      handleClick: true,
    });
  };

  register = async (user) => {
    const { success, error } = await this.props.register(user);

    if (success) {
      this.setState(
        {
          authenticated: false,
          showLogin: true,
          showRegister: false,
          showForgotPassword: false,
          handleClick: true,
        },

        () => {
          toast.success("Kaydınız başarıyla tamamlandı. Lütfen giriş yapınız.");
        }
      );
    } else {
      toast.error(error);
    }
  };

  remindPassword = async (email) => {
    const { success, error } = await this.props.remindPassword(email);

    if (success) {
      this.setState(
        {
          authenticated: false,
          showLogin: false,
          showRegister: false,
          showForgotPassword: false,
          handleClick: true,
        },

        () => {
          toast.success(
            "Hatırlatma maili gönderildi. Lütfen e-posta adresinizi kontrol ediniz."
          );
        }
      );
    } else {
      toast.error(error);
    }
  };

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  onMobileCloseClick = () => {
    this.setState({
      collapsed: true,
      showExams: false,
      showClassrooms: false,
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const locationPath = window.location.pathname;
    const isDeskMode = locationPath.indexOf("/dijitalkitap") > -1;

    let showLogin = prevState.showLogin;

    if (
      !prevState.handleClick &&
      nextProps.isLoginRequired &&
      !prevState.showLogin
    ) {
      showLogin = true;
      nextProps.loginRequired(false);
    } else if (!prevState.handleClick && nextProps.history.location.state) {
      showLogin = nextProps.history.location.state.showLogin;
    }

    let data = {
      ...prevState,
      isDeskMode,
      showLogin,
      authenticated:
        !prevState.handleClick && nextProps.history.location.state
          ? nextProps.user.id
            ? true
            : false
          : prevState.authenticated,
      handleClick: false,
    };

    return data;
  }

  render() {
    const { user } = this.props;
    const className =
      "container navbar navbar-expand-lg d-flex mt-4 ml-auto mr-auto navbar-light bg-light";
    const collapsed = this.state.collapsed;

    const isProd =
      window.location.origin.indexOf("//dogrukaynak") > -1 ||
      window.location.origin.indexOf("//www.dogrukaynak") > -1 ||
      window.location.origin.indexOf("//tongucmagaza") > -1 ||
      window.location.origin.indexOf("//www.tongucmagaza") > -1;

    const { isPaymentPage } = this.props;

    return (
      <div className="container-fluid bg-white">
        <div className="row">
          <header className={className}>
            <div
              className="vw-100 d-lg-flex align-items-center justify-content-between"
              style={{
                zIndex: 999,
              }}
            >
              <button
                onClick={this.toggleNavbar}
                className={classNames(
                  "navbar-toggler navbar-toggler-right float-left",
                  {
                    collapsed: collapsed,
                  }
                )}
                type="button"
                style={{
                  marginTop: 5,
                }}
              >
                <span className="navbar-toggler-icon" />
              </button>
              <Link className="navbar-brand py-0 mx-1 float-left" to="/">
                <img src={require("../assets/images/main/logo.png")} />
              </Link>
              <div
                style={{
                  backgroundColor: "#000",
                }}
              >
                {!this.props.isPaymentPage && (
                  <>
                    {/* Mobile Menu Begin */}
                    {!collapsed ? (
                      <div
                        className="menu-mobile-backdrop"
                        onClick={this.onMobileCloseClick}
                      />
                    ) : null}
                    <div
                      className={classNames("menuMobileBlog", {
                        active: !collapsed,
                      })}
                    >
                      <IconButton
                        className={classNames("closeMenuMobile")}
                        icon={faTimes}
                        title="Kapat"
                        onClick={this.onMobileCloseClick}
                      />
                      <ul className="menuMobileList">
                        <li>
                          <a href="#top">
                            <span>MENÜ</span>
                          </a>
                        </li>
                        {this.state.isDeskMode ? null : (
                          <>
                            <li>
                              <Link
                                to="/urunler"
                                onClick={this.onMobileCloseClick}
                              >
                                <span>TÜM ÜRÜNLER</span>
                              </Link>
                            </li>
                            <li>
                              <a href="/markalar">
                                <span>MARKALAR</span>
                              </a>
                            </li>
                            <li className="dropdown ddMblLesson">
                              <a
                                href="#item"
                                onClick={() =>
                                  this.setState({
                                    showClassrooms: !this.state.showClassrooms,
                                    showExams: false,
                                  })
                                }
                              >
                                <span>SINIFLAR</span>
                                <FontAwesomeIcon
                                  icon={faChevronRight}
                                  className="text-primary ml-2"
                                />
                              </a>
                              {this.state.showClassrooms ? (
                                <ul className="menu-dropdown">
                                  {this.props.taxonomies
                                    .find((t) => t.code === "siniflar")
                                    .taxons.map((taxon) => (
                                      <li key={taxon.id}>
                                        <Link
                                          to={`/urunler?t=$ {
                                                        taxon.id
                                                    }

                                                    `}
                                          onClick={this.onMobileCloseClick}
                                        >
                                          {taxon.name}
                                        </Link>
                                      </li>
                                    ))}
                                </ul>
                              ) : null}
                            </li>
                            <li className="dropdown ddMblLesson">
                              <a
                                href="#item"
                                onClick={() =>
                                  this.setState({
                                    showExams: !this.state.showExams,
                                    showClassrooms: false,
                                  })
                                }
                              >
                                <span>SINAVLAR</span>
                                <FontAwesomeIcon
                                  icon={faChevronRight}
                                  className="text-primary ml-2"
                                />
                              </a>
                              {this.state.showExams ? (
                                <ul className="menu-dropdown">
                                  {this.props.taxonomies
                                    .find((t) => t.code === "sinavlar")
                                    .taxons.map((taxon) => (
                                      <li key={taxon.id}>
                                        <Link
                                          to={`/urunler?t=$ {
                                                        taxon.id
                                                    }

                                                    `}
                                          onClick={this.onMobileCloseClick}
                                        >
                                          {taxon.name}
                                        </Link>
                                      </li>
                                    ))}
                                </ul>
                              ) : null}
                            </li>
                            <li>
                              <Link
                                to="/urunler?t=59"
                                onClick={this.onMobileCloseClick}
                              >
                                <span>ÇOK SATANLAR</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/urunler?t=61"
                                onClick={this.onMobileCloseClick}
                              >
                                <span>FIRSATLAR</span>
                              </Link>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                    {/* Mobile Menu END */}
                  </>
                )}
              </div>
            </div>
          </header>
        </div>
      </div>
    );
  }
}

NavbarBlog.propTypes = {
  light: PropTypes.bool,
};

const mapStateToProps = ({ AccountReducer, ProductReducer }) => ({
  isLoginRequired: AccountReducer.loginRequired,
  user: AccountReducer.user,
  basket: ProductReducer.basket,
  basketCount: ProductReducer.basketCount,
  taxonomies: ProductReducer.taxonomies,
  searchResult: ProductReducer.searchResult,
  notifications: ProductReducer.notifications,
});

const mapDispatchToProps = {
  login: actions.login,
  loginAsDemoUser: actions.loginAsDemoUser,
  logoutDemoUser: actions.logoutDemoUser,
  logout: actions.logout,
  loginRequired: actions.loginRequired,
  register: actions.register,
  remindPassword: actions.remindPassword,
  getBasket: getBasket,
  search: search,
  createOrder: actions.createOrder,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NavbarBlog));
