import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../routes/actions/Desk/Scheduler";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { compose } from "redux";
import { Modal, SchedulerTree } from "../../components";
import CloseButton from "../../components/Button/Close";
import { padStart } from "../../helpers";

// function Pomodoro() {
//     const [schedule, setSchedule] = useState(0);
//     const [time, setTime] = useState(0);

//     // Similar to componentDidMount and componentDidUpdate:
//     useEffect(() => {
//         // Update the document title using the browser API
//         // document.title = `You clicked ${count} times`;
//     });

//     return (
//         <div>
//             <p>{schedule}</p>
//             <button onClick={() => setSchedule(false)}>
//                 Pes Et
//       </button>
//         </div>
//     );
// }

const SurrenderModal = (props) => {
  return (
    <Modal show={props.show} className="modal-container non-modal-height">
      <CloseButton onClick={props.onCancelClick} className="z-index-4" />
      <div className="modal-body text-center p-5">
        <img
          alt=""
          src={require("../../assets/images/tree/tree-step_soil.svg")}
          style={{ width: 170, height: 170 }}
        />

        <p className="text-center color-484848 fs-14 my-3 font-weight-500">
          Hay aksi, programı tamamlasaydın <br />
          puan kazanacaktın
        </p>

        <button
          className="btn btn-primary border-radius-4 text-white text-capitalize mt-3 px-5 fs-14"
          onClick={props.onCloseClick}
        >
          Tamam
        </button>
      </div>
    </Modal>
  );
};

class Pomodoro extends Component {
  constructor(props) {
    super(props);
    // checkFocus(); // init

    this.state = {
      time: 0,
      remaningTime: 0,
      data: null,
      timePretty: "--:--",
      remaningTimePretty: "--:--",
      showSurrenderModal: false,
    };

    window.addEventListener("focus", () => this.checkFocus());
    window.addEventListener("blur", () => this.checkFocus());
  }

  checkFocus() {
    const hasFocus = document.hasFocus();

    if (
      this.props &&
      this.props.pomodoro &&
      this.props.pomodoro.isOn &&
      this.props.pomodoro.show &&
      !hasFocus
    ) {
      this.surrend();
    }
  }

  async surrend() {
    this.setState({
      showSurrenderModal: true,
    });
    const { id } = this.props;
    const model = {
      Schedule: {
        Id: id,
      },
    };
    this.stopTimer();
    //TODO surrend
    await this.props.cancelSchedule(model);

    this.setState({
      show: false,
      isOn: false,
    });

    // if (redirect) {
    //     // this.props.history.push(`/dijitalkitap/programci`);
    // }
    // else {
    //     this.setState({
    //         show: false,
    //         isOn: false,
    //     });
    // }
  }

  completeSchedule = async (id) => {
    const model = {
      Schedule: {
        Id: id,
      },
    };
    const { success, error } = await this.props.completeSchedule(model);
    if (success) {
      let { pomodoro } = this.props;
      pomodoro = { ...pomodoro, id: null, isOn: false, show: false };

      await this.props.updatePomodoroData(null);
      this.setState({
        isStart: false,
        isOn: false,
        id: null,
        isGiveup: false,
      });
      // await this.props.getSchedule();
      // this.changeFilter(this.state.filter);
    } else {
      if (error.length) {
        toast.error(error);
      }
    }
  };

  startTimer() {
    this.setState({
      isGiveup: false,
      isStart: true,
    });

    this.timer = setInterval(() => {
      let { time, remaningTime } = this.state;
      time += 1;
      remaningTime -= 1;

      const tp = this.secondsToTime(time);
      const rtp = this.secondsToTime(remaningTime);

      this.setState({
        time,
        remaningTime,
        timePretty: tp,
        remaningTimePretty: rtp,
      });

      if (time > 20) {
        this.setState({
          isGiveup: true,
        });
      }

      if (remaningTime === 0) {
        const { id } = this.props;
        this.stopTimer();
        // this.resetTimer();
        this.completeSchedule(id);
      }
    }, 1000);
  }

  stopTimer() {
    this.setState({ time: 0 });
    this.props.updatePomodoroData(null);
    clearInterval(this.timer);
  }

  secondsToTime(secs, showHours = false) {
    // let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(secs / 60) || 0;

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds) || 0;

    let obj = {
      // "h": hours,
      m: minutes,
      s: seconds,
    };
    let result = this.zeroPad(obj.m) + ":" + this.zeroPad(obj.s);
    return result;
  }

  zeroPad(number) {
    return padStart(number, 2, "0");
  }

  componentDidUpdate(prevProps, next) {
    const { pomodoro, id } = this.props;
    const pomodoroPrev = prevProps.pomodoro;
    const pomodoroPrevId = pomodoroPrev ? pomodoroPrev.id : null;

    if (pomodoro && (!pomodoroPrev || pomodoroPrevId != id)) {
      if (pomodoro.isOn && (!pomodoroPrev || !pomodoroPrev.isOn)) {
        // this.setState({ time: this.props.pomodoro.time, remaningTime: this.props.pomodoro.remaningTime })
        this.setState({ remaningTime: this.props.pomodoro.remaningTime }, () =>
          this.startTimer()
        );
      }

      if (!pomodoro.isOn) {
        this.stopTimer();
      }
    } else if (pomodoroPrev && !pomodoro) {
      this.stopTimer();
    }

    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (this.props.location.pathname == "/dijitalkitap/programci") {
        let { pomodoro } = this.props;
        let { time, remaningTime } = this.state;
        pomodoro = { ...pomodoro, show: false, time, remaningTime };
        this.props.updatePomodoroData(pomodoro);
      }

      if (
        pomodoro &&
        pomodoro.isOn &&
        !this.props.location.pathname.includes("/dijitalkitap")
      ) {
        this.surrend();
      }
    }
  }

  componentWillUnmount() {
    this.surrend();
  }

  render() {
    return (
      <div>
        {this.props.pomodoro &&
          this.props.pomodoro.show &&
          this.props.pomodoro.isOn && (
            <div className="row mt-4 absolute-row ">
              <div className="timer-card timer-card-desk d-flex flex-column">
                <div className="row mt-5">
                  <div className="col-6 col-sm-6 d-nonex">
                    <SchedulerTree
                      desk="narrow"
                      // selectedTime={this.props.pomodoro.selected}
                      time={this.state.time}
                      remaningTime={this.state.remaningTime}
                    />
                  </div>
                  <div className="col-6 col-sm-6">
                    <div className="d-flexs justify-content-around flex-column pomodoro-mobile  h-100">
                      {/* <h1>{this.props.id || '-'}</h1> */}
                      {/* <h2 className="font-weight-600" style={{ color: "#e74c3c", fontSize: "46px" }}></h2> */}

                      <div className="d-flex flex-column ">
                        <p
                          className="fs-18 font-weight-400 mb-0"
                          style={{ color: "#a7a7a7" }}
                        >
                          {/* <img
                        src={require("../../assets/images/icons/icon-clock.svg")}
                        alt="..."
                        width="35"
                      /> */}
                        </p>
                        <p className="mb-0">
                          <span className="text-danger font-weight-bold">
                            {this.state.remaningTimePretty}
                          </span>
                        </p>
                        <span className="fs-18 left-time color-a7a7a7 font-weight-300">
                          Kalan Süre:
                        </span>
                      </div>
                      <p className="fs-14 font-weight-500 color-9b9b9b my-3">
                        Yolu yarıladın/Birazdaha gayret ...
                      </p>

                      <button
                        className="btn btn-outline-dark text-capitalize fs-18 font-weight-600  px-4 border-radius-8"
                        disabled={!this.state.isStart || !this.state.isGiveup}
                        title={
                          this.state.isStart &&
                          !this.state.isGiveup &&
                          "Program başlatıldıktan sonra ilk 10 dk pes edilemez"
                        }
                        onClick={() => {
                          this.surrend();
                        }}
                      >
                        Pes Et
                      </button>

                      {/* <p className="color-484848 fs-14 text-center font-weight-500 m-0">
                        <span>{this.props.pomodoro.selected} dk</span>
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        <SurrenderModal
          show={this.state.showSurrenderModal}
          onCloseClick={() => {
            this.setState({ showSurrenderModal: false });
          }}
          onCancelClick={() => {
            this.setState({ showSurrenderModal: false });
          }}
        />
      </div>
    );
  }
}

Pomodoro.propTypes = {};

const mapStateToProps = ({ SchedulerReducer }) => ({
  // scheduler: SchedulerReducer.scheduler,
  topics: SchedulerReducer.topics,
  id: SchedulerReducer.id,
  startIntervalRequestId: SchedulerReducer.startIntervalRequestId,
  pomodoro: SchedulerReducer.pomodoro,
});

const mapDispatchToProps = {
  getSchedule: actions.getSchedule,
  getTopics: actions.getTopics,
  startSchedule: actions.startSchedule,
  cancelSchedule: actions.cancelSchedule,
  completeSchedule: actions.completeSchedule,
  cancelSchedulePomodoro: actions.cancelSchedulePomodoro,
  updatePomodoroData: actions.updatePomodoroData,
};

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(Pomodoro);

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Pomodoro);
