export const imgPuan = require("./Puan.svg");
export const imgProductPoint = require("./img-product-point.svg");

export const imgPuanSmall = require("./puan_small.svg");
export const imgJeton = require("./Jeton.svg");
export const imgProductJeton = require("./img-product-jeton.svg");
export const imgJetonSmall = require("./jeton_small.svg");
export const imgRozet = require("./Rozet.svg");
//export const Start = require( "./Star.svg");
export { ReactComponent as Start } from "./Star.svg";
export { ReactComponent as StarO } from "./StarO.svg";
export { ReactComponent as UpArrow } from "./up-arrow.svg";
export { ReactComponent as CreditCardImage } from "./payment/kredikarti.svg";
export { ReactComponent as TransferImage } from "./payment/havale.svg";
export { ReactComponent as PayCashImage } from "./payment/kapidaode.svg";
export { ReactComponent as PayCoinImage } from "./payment/jetonlaode.svg";
export { ReactComponent as SmsImage } from "./sms.svg";
export { ReactComponent as MainLogo } from "./main/logo.svg";
export const imgAdvertorialHighlight = require("./advertorial1.png");
export const imgAdvertorialImage = require("./advertorial3.png");
export { ReactComponent as PlaceHolder80x58 } from "./placeholder/80x58.svg";
export { ReactComponent as PlaceHolder540x328 } from "./placeholder/540x328.svg";
export { ReactComponent as PlaceHolder735x250 } from "./placeholder/735x250.svg";
export { ReactComponent as PlaceHolder359x250 } from "./placeholder/359x250.svg";
export { ReactComponent as PlaceHolder1110x328 } from "./placeholder/1110x328.svg";
