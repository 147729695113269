const GET_SCHEDULE = "SCHEDULER/GET_SCHEDULE";
const GET_TOPICS = "SCHEDULER/GET_TOPICS";
const START_SCHEDULE = "SCHEDULER/START_SCHEDULE";
const CANCEL_SCHEDULE = "SCHEDULER/CANCEL_SCHEDULE";
const COMPLETE_SCHEDULE = "SCHEDULER/CANCEL_SCHEDULE";
const CANCEL_SCHEDULE_POMODORO = "SCHEDULER/CANCEL_SCHEDULE_POMODORO";
const UPDATE_TIME = "SCHEDULER/UPDATE_TIME";
const START_INTERVAL = "SCHEDULER/START_INTERVAL";
const START_INTERVAL_REQUESTED = "SCHEDULER/START_INTERVAL_REQUESTED";
const UPDATE_POMODORO_DATA = "SCHEDULER/UPDATE_POMODORO_DATA";
const UPDATE_POMODORO_WARN_MODAL = "SCHEDULER/UPDATE_POMODORO_WARN_MODAL";

export default {
    GET_SCHEDULE,
    GET_TOPICS,
    START_SCHEDULE,
    CANCEL_SCHEDULE,
    COMPLETE_SCHEDULE,
    CANCEL_SCHEDULE_POMODORO,
    UPDATE_TIME,
    START_INTERVAL,
    START_INTERVAL_REQUESTED,
    UPDATE_POMODORO_DATA,
    UPDATE_POMODORO_WARN_MODAL,
};