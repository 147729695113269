import React, { useState, useEffect } from "react";

const RateBoard = ({ comments }) => {
  const [rateList, setRateList] = useState([]);

  useEffect(() => {
    let list = [];

    for (let i = 5; i > 0; i--) {
      const starComments = comments.filter((u)=>u.tur === 0).filter((m) => m.star === i * 10);
      list.push({
        starId: i,
        commentCount: starComments.length,
      });
    }

    setRateList(list);
  }, [comments]);

  return (
    <div>
      <div className="rateBoard">
        {rateList.map((item, index) => (
          <div className="rate-board-item" key={index}>
            <span> {item.starId} yıldız</span>
            <div className="rate-bar-container">
              <div
                className="rateBar"
                style={{
                  width: `${(item.commentCount / comments.filter((u)=>u.tur === 0).length) * 100}%`,
                }}
              ></div>
            </div>
            <span> {item.commentCount}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RateBoard;
