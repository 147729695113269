import React, { Component } from "react";
//import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import classNames from "classnames";
import { customStyles } from "../../helpers";
import * as actions from "../../routes/actions/Product";
import { customFilterOption } from "./Utility";


class ImageOrientation extends Component {

    render() {
        const { name, onChange, valid, value } = this.props;

        const items = [
            {
                value: 'right',
                label: 'Sağ Dayalı'
            },
            {
                value: 'left',
                label: 'Sola Dayalı'
            },
            {
                value: 'mobile-top',
                label: 'Sağ dayalı, Mobilde Üst'
            },
        ]
        const selected = items.find((p) => p.value === value);
        return (
            <Select
                id={name}
                isSearchable={false}
                placeholder="Seçiniz..."
                options={items}
                className={classNames(valid === false ? "is-invalid" : "valid")}
                styles={customStyles}
                onChange={onChange}
                value={selected}
                filterOption={customFilterOption}
            />
        );
    }
}

ImageOrientation.propTypes = {};

const mapStateToProps = ({ ProductReducer }) => ({
    // taxonomies: ProductReducer.taxonomies
});

const mapDispatchToProps = {
    // getTaxonomies: actions.getTaxonomies
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImageOrientation);
