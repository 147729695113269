const GET_STORES_BY_CITY_ID = "STORE/GETBYCITYID";
const GET_STORES_BY_TOWN_ID = "STORE/GETBYTOWNID";
const ADD_STORE_REVIEW = "STORE/ADDREVIEW";


export default {
    GET_STORES_BY_CITY_ID,
    GET_STORES_BY_TOWN_ID,
    ADD_STORE_REVIEW
};
