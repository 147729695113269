import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as iconList from "../../assets/images/icons";

class Icon extends Component {
  state = {
      hover: false
  };
  
  toggleHover = () => {
      this.setState({hover: !this.state.hover});
  };

  render() {
      const {className, icon, iconHover} = this.props;
      const name = this.state.hover && iconHover ? iconHover : icon;
      const SvgIcon =
      typeof iconList[name] === "undefined" ? iconList.iDoc : iconList[name];
      return (
          <figure onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>
              <SvgIcon className={classNames("icon", className)}/>
          </figure>
      );
  }
}

Icon.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string
};

export default Icon;
