import React from "react";
//import PropTypes from 'prop-types';
import classNames from "classnames";

const LinkField = ({className, name, schema, uiSchema, horizontal, onClick}) => {
    return (
        <div className={classNames("form-group", className)}>
            <button type="button" className="btn btn-link" onClick={onClick} name={name}>
                {schema.title}
            </button>
        </div>
    );
};

LinkField.propTypes = {};

export default LinkField;
