import React, { PureComponent } from "react";
// import classNames from "classnames";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCircle } from "@fortawesome/free-regular-svg-icons";
// import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
//import PropTypes from 'prop-types';

// const RadioButton = ({ className, selected, title, ...props }) => (
//   <button
//     type="button"
//     className={classNames("btn btn-light", className)}
//     {...props}
//   >
//     <FontAwesomeIcon
//       icon={selected ? faCheckCircle : faCircle}
//       className={selected ? "text-primary" : "text-muted"}
//     />
//     <span className="ml-2">{title}</span>
//   </button>
// );

class Gender extends PureComponent {
    constructor(props) {
        super(props);

        this.state = { value: props.value };
    }

    handleChange = (value) => {
        const { name, onChange } = this.props;
        onChange(name, value);

        this.setState({
            value
        });
    };

    render() {
        const { readOnly } = this.props;
        return (
            <div className="d-flex">
                <label className="radio-container">
                    Kadın
                  <input
                        type="radio"
                        name="gender"
                        onChange={() => this.handleChange("K")}
                        checked={this.state.value === "K"}
                        disabled={readOnly}
                    />
                    <span className="radio-checkmark" />
                </label>
                <label className="radio-container ml-3">
                    Erkek
                  <input
                        type="radio"
                        name="gender"
                        onChange={() => this.handleChange("E")}
                        checked={this.state.value === "E"}
                        disabled={readOnly}
                    />
                    <span className="radio-checkmark" />
                </label>
            </div>
        );
    }
}

Gender.propTypes = {};

export default Gender;
