import React from "react";

const FilterRating = (props) => {

  const itemHtmlId = (id) => {
    return `taxon-${id}${props.prefix ? `-${props.prefix}` : ""}`
  }

  const showFilterItem = (id) => {
    return props.taxonomy.taxons.find(m => m.id == id)?.count > 0;
  }

  return (
    <div>
      <div className={props.classNames}>
        <div className="sidebar--item border-0">
          {!props.isFilterModal && <h6>Puanlaması</h6>}
          {showFilterItem('p4') && (
              <div className="sidebar--item-detail">
                <div className="form-group form-check brand-checkbox">
                  <input
                      onChange={(e) => props.applyFilter(e, false)}
                      checked={props.filters?.some((filter) => filter.id == 'p4')}
                      value={'p4'}
                      id={itemHtmlId('p4')}
                      name={'4 ve üzeri'}
                      type="checkbox"
                  />
                  <label htmlFor={itemHtmlId('p4')}
                         className="d-flex flex-column ml-2 mb-0">
                    <h5 className="checkbox-label d-flex">
                      <div className="rating">
                        <div className="star-width">
                          <div className="star-full" />
                        </div>
                        <div className="star-width">
                          <div className="star-full" />
                        </div>
                        <div className="star-width">
                          <div className="star-full" />
                        </div>
                        <div className="star-width">
                          <div className="star-full" />
                        </div>
                        <div className="star-width">
                          <div className="star-empty" />
                        </div>
                      </div>
                      4 ve üzeri
                    </h5>
                  </label>
                </div>
              </div>
          )}

          {showFilterItem('p3') && (
              <div className="sidebar--item-detail">
                <div className="form-group form-check brand-checkbox">
                  <input
                      onChange={(e) => props.applyFilter(e, false)}
                      checked={props.filters?.some((filter) => filter.id == 'p3')}
                      value={'p3'}
                      id={itemHtmlId('p3')}
                      name={'3 ve üzeri'}
                      type="checkbox"
                  />
                  <label htmlFor={itemHtmlId('p3')}
                         className="d-flex flex-column ml-2 mb-0">
                    <h5 className="checkbox-label d-flex">
                      <div className="rating">
                        <div className="star-width">
                          <div className="star-full" />
                        </div>
                        <div className="star-width">
                          <div className="star-full" />
                        </div>
                        <div className="star-width">
                          <div className="star-full" />
                        </div>
                        <div className="star-width">
                          <div className="star-empty" />
                        </div>
                        <div className="star-width">
                          <div className="star-empty" />
                        </div>
                      </div>
                      3 ve üzeri
                    </h5>
                  </label>
                </div>
              </div>
          )}

          {showFilterItem('p2') && (
              <div className="sidebar--item-detail">
                <div className="form-group form-check brand-checkbox">
                  <input
                      onChange={(e) => props.applyFilter(e, false)}
                      checked={props.filters?.some((filter) => filter.id == 'p2')}
                      value={'p2'}
                      id={itemHtmlId('p2')}
                      name={'2 ve üzeri'}
                      type="checkbox"
                  />
                  <label htmlFor={itemHtmlId('p2')}
                         className="d-flex flex-column ml-2 mb-0">
                    <h5 className="checkbox-label d-flex">
                      <div className="rating">
                        <div className="star-width">
                          <div className="star-full" />
                        </div>
                        <div className="star-width">
                          <div className="star-full" />
                        </div>
                        <div className="star-width">
                          <div className="star-empty" />
                        </div>
                        <div className="star-width">
                          <div className="star-empty" />
                        </div>
                        <div className="star-width">
                          <div className="star-empty" />
                        </div>
                      </div>
                      2 ve üzeri
                    </h5>
                  </label>
                </div>
              </div>
          )}

          {showFilterItem('p1') && (
              <div className="sidebar--item-detail">
                <div className="form-group form-check brand-checkbox">
                  <input
                      onChange={(e) => props.applyFilter(e, false)}
                      checked={props.filters?.some((filter) => filter.id == 'p1')}
                      value={'p1'}
                      id={itemHtmlId('p1')}
                      name={'1 ve üzeri'}
                      type="checkbox"
                  />
                  <label htmlFor={itemHtmlId('p1')}
                         className="d-flex flex-column ml-2 mb-0">
                    <h5 className="checkbox-label d-flex">
                      <div className="rating">
                        <div className="star-width">
                          <div className="star-full" />
                        </div>
                        <div className="star-width">
                          <div className="star-empty" />
                        </div>
                        <div className="star-width">
                          <div className="star-empty" />
                        </div>
                        <div className="star-width">
                          <div className="star-empty" />
                        </div>
                        <div className="star-width">
                          <div className="star-empty" />
                        </div>
                      </div>
                      1 ve üzeri
                    </h5>
                  </label>
                </div>
              </div>
          )}

          {showFilterItem('p0') && (
              <div className="sidebar--item-detail">
                <div className="form-group form-check brand-checkbox">
                  <input
                      onChange={(e) => props.applyFilter(e, false)}
                      checked={props.filters?.some((filter) => filter.id == 'p0')}
                      value={'p0'}
                      id={itemHtmlId('p0')}
                      name={'Henüz Puanı Yok'}
                      type="checkbox"
                  />
                  <label htmlFor={itemHtmlId('p0')}
                         className="d-flex flex-column ml-2 mb-0">
                    <h5 className="checkbox-label d-flex">Henüz Puanı Yok</h5>
                  </label>
                </div>
              </div>
          )}

          {props.children}
        </div>
      </div>
    </div>
  );
};

export default FilterRating;
