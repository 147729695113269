import React, { useEffect, useState, useMemo } from "react";
import CloseButton from "../Button/Close";
import Modal from "./Index";
import { connect } from "react-redux";
import * as actions from "../../routes/actions/Content";
import {
  getPageProducts,
  getRelatedProducts,
} from "../../routes/actions/Product";

import Select from "react-select";
import { customStyles, moveSlider } from "../../helpers";
import Icon from "../Icon/Index";
import apiCall from "../../services/api";
import FormSchemas from "../../variables/FormSchemas";
import { BaseForm } from "..";

const EditableSlider = (props) => {
  const [selectedProduct, setSelectedProduct] = useState(0);
  const [mode, setMode] = useState("");
  const [page, setPage] = useState({});
  const [sliderImage, setSliderImage] = useState({});
  const [refreshSlider, setRefreshSlider] = useState(false);

  useEffect(() => {
    props.getRelatedProducts("", "", "", "");
  }, []);

  useEffect(() => {
    if (props.imageOnSlider) {
      setSliderImage({ image: page.content?.sliderImage });
    }
  }, [page, props.show]);

  //slidername e göre page geliyor
  useEffect(() => {
    if (props.editableSliderName && props.show) {
      apiCall(`/contents/content/${props.editableSliderName}/1`).then((res) => {
        setPage(res.data);
      });
    }
  }, [props.editableSliderName, props.show]);

  //select için ürünler geliyor
  useEffect(() => {
    let brandId = props.user.brandId;
    if (page && page.brandId) {
      brandId = page.brandId;
    }
    if (props.show) {
      props.getPageProducts("", brandId || "", "", "");
    }
  }, [props.show]);

  //select'de ki optionlar
  var options =
    props.pageProducts &&
    props.pageProducts.map((x) => ({ value: x.id, label: x.name }));

  const addOrRemoveProduct = (id) => {
    let { content } = { ...page };
    if (!content.products) {
      content.products = [];
    }
    let { products } = content;
    if (products.some((x) => x === id)) {
      products = products.splice(
        products?.findIndex((x) => x === id),
        1
      );
    } else {
      products.push(id);
    }

    setPage({
      ...page,
      content,
    });
    setMode("changed");
    // getProducts();
  };

  const updateTitle = (t) => {
    setMode("changed");

    setPage({
      ...page,
      content: {
        ...page.content,
        title: t,
      },
    });
  };

  const handleAdd = () => {
    if (selectedProduct !== 0) {
      addOrRemoveProduct(selectedProduct);
      // getProducts();
    }
  };

  const savePage = async () => {
    await props.savePage(page.id, page);
    // await props.getByName(props.editableSliderName, 0);
    setMode("saved");
  };

  const publishPage = () => {
    props.publishPage(page.id);
    props.onClose();
    props.onPublish(page);
  };

  const changeProductOrder = (command, index) => {
    let newProductList = moveSlider(getActiveProducts(), index, command);
    setMode("changed");
    setPage({
      ...page,
      content: {
        ...page.content,
        products: newProductList,
      },
    });
  };

  // slider sıralama yaparken aktif olmayan ürünlerinde dahil olduğu listeden yapıyordu dolayısı ile sıralama işlemi hatalı çalışıyordu
  // bu durumun önüne geçmek için aktif olmayan ürünler listeden çıkartılarak sıralama yapılması sağlandı
  const getActiveProducts = () => {
    let contentProducts = page.content.products;
    let products = [];

    contentProducts.forEach((product) => {
      const item = props.relatedProducts.find((p) => p.id === product);
      if (item) products = [...products, product];
    });

    return products;
  };

  //page de ki product değişimine göre ürünler filtrelenip liste haline geliyor
  const productList = useMemo(() => {
    setRefreshSlider(true);
    let output = [];
    if (page?.content?.products) {
      page.content.products.forEach((product) => {
        const item = props.relatedProducts.find((x) => x.id == product);
        if (item) {
          output = [...output, item];
        }
      });
    }
    setRefreshSlider(false);
    return output;
  }, [page.content, props.relatedProducts]);

  const handleSubmit = (sliderImage) => {
    setPage({
      ...page,
      content: {
        ...page.content,
        sliderImage: sliderImage.image,
      },
    });
    setMode("changed");
  };
  const handleRemove = () => {
    setPage({
      ...page,
      content: {
        ...page.content,
        sliderImage: null,
      },
    });
    setMode("changed");
  };

  return (
    <Modal
      className="editableSliderModal"
      show={props.show}
      onCloseClick={props.onClose}
    >
      <CloseButton onClick={props.onClose} />
      <div className="modal-header">
        <h5>{page.content?.title} Slider Edit</h5>
      </div>
      <div className="modal-body">
        <div className="title-container">
          <div className="input-wrapper">
            <label htmlFor="editableSliderTitle">Başlık</label>
            <input
              id="editableSliderTitle"
              type="text"
              value={page?.content?.title || ""}
              onChange={(e) => updateTitle(e.target.value)}
            />
          </div>
        </div>
        {props.imageOnSlider && (
          <div>
            <BaseForm
              schema={FormSchemas.sliderImage.schema}
              uiSchema={FormSchemas.sliderImage.uiSchema}
              formData={sliderImage}
              onChange={handleSubmit}
            >
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleRemove}
              >
                Image Kaldır
              </button>
            </BaseForm>
          </div>
        )}
        <div className="product-list">
          {!refreshSlider &&
            productList.length > 0 &&
            productList.map((product, i) => (
              <div className="product-list-item">
                <div className="product-content">
                  <span className="product-index">{i + 1}.</span>
                  <div className="product-img">
                    <img className="img-fluid" src={product.imageUrl} alt="" />
                  </div>
                  <div className="product-name text-truncate">
                    {product.name}
                  </div>
                </div>
                <div className="product-operations">
                  {i > 0 && (
                    <button
                      className="btn"
                      onClick={() => changeProductOrder("moveup", i)}
                    >
                      <Icon icon="iUp" />
                    </button>
                  )}
                  {i < productList.length - 1 && (
                    <button
                      className="btn"
                      onClick={() => changeProductOrder("movedown", i)}
                    >
                      <Icon icon="iDown" />
                    </button>
                  )}
                  <button
                    className="btn"
                    onClick={() => addOrRemoveProduct(product.id)}
                  >
                    <Icon icon="iDelete" />
                  </button>
                </div>
              </div>
            ))}
        </div>
        <div className="product-select-container">
          <Select
            options={options}
            styles={customStyles}
            placeholder="Ürün Seçiniz"
            className="w-100"
            isSearchable
            onChange={(item) => setSelectedProduct(item.value)}
          />
          <button type="button" className="btn w-100" onClick={handleAdd}>
            <Icon icon="iPlusWhite" />
            Ürün ekle
          </button>
        </div>
        <div className="modal-operations">
          <button
            type="button"
            className="btn btn-cancel"
            onClick={props.onClose}
          >
            İptal
          </button>
          <button
            type="button"
            className="btn btn-save"
            onClick={savePage}
            disabled={mode !== "changed"}
          >
            Kaydet
          </button>
          <button
            type="button"
            className="btn btn-save"
            onClick={publishPage}
            disabled={mode !== "saved"}
          >
            Yayınla
          </button>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ ProductReducer, AccountReducer }) => ({
  pageProducts: ProductReducer.pageProducts,
  user: AccountReducer.user,
  relatedProducts: ProductReducer.relatedProducts,
});

const mapDispatchToProps = {
  changePage: actions.changePage,
  savePage: actions.savePage,
  getPageProducts: getPageProducts,
  getRelatedProducts: getRelatedProducts,
  publishPage: actions.publishPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditableSlider);
