import React, {Component} from "react";
import {RatingSelect} from "../../components";
//import PropTypes from 'prop-types';

const emptyState = {
    comment: "",
    proximityReview: 0,
    findingProductReview: 0,
    genialityReview: 0,
    errors: {
        comment: false,
        genialityReview: false,
        findingProductReview: false,
        proximityReview: false
    }
};

class ReviewForm extends Component {

  state = emptyState;

  handleClick = () => {

      const errors = {comment: false, genialityReview: false, findingProductReview: false, proximityReview: false};

      errors.comment = !this.state.comment.length;
      errors.genialityReview = !this.state.genialityReview;
      errors.findingProductReview = !this.state.findingProductReview;
      errors.proximityReview = !this.state.proximityReview;

      if (!errors.comment && !errors.genialityReview && !errors.findingProductReview && !errors.proximityReview) {

          const {addReview, storeId, onCloseClick} = this.props;

          const payload = Object.assign({}, this.state);
          delete payload.errors;

          payload.genialityReview = payload.genialityReview * 10;
          payload.findingProductReview = payload.findingProductReview * 10;
          payload.proximityReview = payload.proximityReview * 10;

          addReview({storeId: storeId, ...payload});
          this.setState(emptyState);
          onCloseClick();
      }
      else {

          this.setState({errors});
      }
  };

  render() {
      return (
          <form className="w-100">
              <div className="form-group justify-content-between d-flex align-items-start align-items-md-center flex-column flex-sm-row mb-3">
                  <label htmlFor="exampleFormControlInput1" className="m-0">
            İlgi ve Güleryüz
                  </label>
                  <div>
                      <RatingSelect
                          onChange={(value) => this.setState({genialityReview: value})}
                          initialRating={this.state.genialityReview}
                      />
                      {
                          this.state.errors.genialityReview ? (
                              <>
                                  <small id="r1" className="form-text text-muted col-sm-12"></small>
                                  <div className="invalid-feedback">Lütfen puan seçin.</div>
                              </>
                          ) : null
                      }
                  </div>
              </div>
              <div className="form-group justify-content-between d-flex align-items-start align-items-md-center flex-column flex-sm-row mb-3">
                  <label htmlFor="exampleFormControlInput1" className="m-0">
            Aradığın ürünü bulma
                  </label>
                  <div>
                      <RatingSelect
                          onChange={(value) => this.setState({findingProductReview: value})}
                          initialRating={this.state.findingProductReview}
                      />
                      {
                          this.state.errors.findingProductReview ? (
                              <>
                                  <small id="r2" className="form-text text-muted col-sm-12"></small>
                                  <div className="invalid-feedback">Lütfen puan seçin.</div>
                              </>
                          ) : null
                      }
                  </div>
              </div>
              <div className="form-group justify-content-between d-flex align-items-start align-items-md-center flex-column flex-sm-row mb-3">
                  <label htmlFor="exampleFormControlInput1" className="m-0">
            Konum olarak yakınlığı
                  </label>
                  <div>
                      <RatingSelect
                          onChange={(value) => this.setState({proximityReview: value})}
                          initialRating={this.state.proximityReview}
                      />
                      {
                          this.state.errors.proximityReview ? (
                              <>
                                  <small id="r3" className="form-text text-muted col-sm-12"></small>
                                  <div className="invalid-feedback">Lütfen puan seçin.</div>
                              </>
                          ) : null
                      }
                  </div>
              </div>
              <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">Yorum</label>
                  <textarea
                      className="form-control"
                      rows="3"
                      onChange={({target}) => this.setState({comment: target.value})}
                      value={this.state.comment}
                  />
                  {
                      this.state.errors.comment ? (
                          <>
                              <small id="r3" className="form-text text-muted col-sm-12"></small>
                              <div className="invalid-feedback">Yorum bilgisi boş olamaz.</div>
                          </>
                      ) : null
                  }
              </div>
              <button
                  type="button"
                  className="btn btn-primary w-100 text-capitalize border-radius-8 py-3 fs-13 mb-3"
                  onClick={this.handleClick}>
          Gönder
              </button>
          </form>
      );
  }
}

ReviewForm.propTypes = {};

export default ReviewForm;
