import React from "react";
//import PropTypes from 'prop-types';
import classNames from "classnames";
import {
  CircleButton,
  Breadcrumb,
  Modal,
  InteractionWrapper,
  ReactPlyr
} from "../../components";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import CompleteModal from "./CompleteModal";
import { RadialChart } from "react-vis";
import CloseButton from "../../components/Button/Close";
import * as actions from "../../routes/actions/Desk/Answer";
import * as studyActions from "../../routes/actions/Study";
import * as accountActions from "../../routes/actions/Account";
import { padStart } from "../../helpers";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import queryString from "query-string";
import { Link } from "react-router-dom";
import '../../assets/bootstrap/digitaltest.scss'

const experience = [
  { theta: 3600, label: "Çalışma", color: 1 },
  { theta: 0, label: "Mola", color: 2 },
];

const SurrenderModal = (props) => {
  return (
    <Modal {...props} className="modal-container ">
      <CloseButton onClick={props.onCloseClick} className="z-index-4" />
      <div className="modal-body text-center p-5">
        <img
          alt=""
          src={require("../../assets/images/tree/tree-step_soil.svg")}
          style={{ width: 170, height: 170 }}
        />

        <p className="text-center color-484848 fs-14 my-3 font-weight-500">
          Hay aksi, programı tamamlasaydın <br />
          puan kazanacaktın
        </p>

        <button
          className="btn btn-primary border-radius-4 text-white text-capitalize mt-3 px-5 fs-14"
          onClick={props.onCloseClick}
        >
          Tamam
        </button>
      </div>
    </Modal>
  );
};

class Quiz extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      testId: 0,
      testAd: "",

      min: 0,
      max: 5,
      selected: "",
      show: false,
      data: experience,
      showSurrenderModal: false,
      showDislikeModal: false,

      question: 0,
      time: 0,
      detail: {},
      questions: [],
      answers: {},

      allowedTime: 30,
      extraTime: 0,
      remaningTime: 30 * 60, // second
      remaningTimePretty: "30:00",
      time: 0,
      isOn: false,
      start: 0,
      timePretty: "00:00",

      breadcrumbs: [],
      isVirtual: false,
      showVideoModal: false
    };
  }

  async componentDidMount() {
    var testId = this.props.match.params.id;

    const search = queryString.parse(this.props.location.search);
    const isVirtual = search.v || false;
    if(search.q && search.q > 0){
      this.setState({
        question : search.q - 1
      });
    }
    this.setState({
      isVirtual,
    });

    await this.props.getTestsQuestions(testId, isVirtual);
    let answers = {};
    if(!isVirtual){
      this.props.questions.forEach(q => {
        answers[q.Key] = q.OCevap;
      });
      
    }    
    let breadcrumbs = [
      {
        title: "Kütüphanem",
        url: "/dijitalkitap",
      },
      ...(!isVirtual
        ? [
          {
            title:
              (this.props.questions[0] && this.props.questions[0].YayinAd) ||
              "-",
            url: "/dijitalkitap/book/lessons/" + (this.props.questions[0] &&this.props.questions[0].yayinID),
          },
        ]
        : []),
      {
        title:
          (this.props.questions[0] && this.props.questions[0].TestAd) ||
          "Hata Kutusu Testi",
        url:
          "/dijitalkitap/book/lesson/subject/quiz/answers/" +
          testId +
          (isVirtual ? "?v=true" : ""),
      },
      {
        title: "Online Çöz",
        active: true,
      },
    ];

    this.setState({
      testId: testId,
      testAd: (this.props.questions[0] && this.props.questions[0].TestAd) || "",
      breadcrumbs,
      answers
    });
    this.startTimer();
  }

  // TIMER

  startTimer = () => {
    this.setState({
      isOn: true,
      time: this.state.time,
      timePretty: this.state.timePretty,
      start: Date.now() - this.state.time,
    });
    this.timer = setInterval(() => {
      // let curTime = (Date.now() - this.state.start);
      let time = this.state.time + 1;
      let timePretty = this.secondsToTime(time);
      let remaningTime = this.state.remaningTime - 1;
      let remaningTimePretty = this.secondsToTime(remaningTime);

      let extraTime = this.state.extraTime;
      if (remaningTime < 0) {
        extraTime += 1;
        remaningTimePretty = "-" + this.secondsToTime(extraTime);

        // this.stopTimer();
        // TODO süre bitiş işlemleri
      }

      this.setState({
        time: time,
        timePretty: timePretty,
        extraTime: extraTime,
        remaningTime: remaningTime,
        remaningTimePretty: remaningTimePretty,
        data: [
          { theta: remaningTime, label: "Çalışma", color: 1 },
          { theta: time, label: "Mola", color: 2 },
        ],
      });
    }, 1000);
  };

  stopTimer = () => {
    this.setState({ isOn: false });
    clearInterval(this.timer);
  };

  resetTimer = () => {
    this.setState({ time: 0, isOn: false });
  };

  secondsToTime(secs, showHours = false) {
    let hours = Math.floor(secs / (60 * 60)) || 0;

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60) || 0;

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds) || 0;

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    let result =
      showHours || obj.h > 0
        ? this.zeroPad(obj.h) +
        ":" +
        this.zeroPad(obj.m) +
        ":" +
        this.zeroPad(obj.s)
        : this.zeroPad(obj.m) + ":" + this.zeroPad(obj.s);
    return result;
  }

  zeroPad(number) {
    return padStart(number, 2, "0");
  }

  // PAGE

  handleChangeAnswer = (choise) => {
    let answers = { ...this.state.answers };
    if (answers[this.props.questions[this.state.question].Key] === choise) {
      delete answers[this.props.questions[this.state.question].Key];
    } else {
      answers[this.props.questions[this.state.question].Key] = choise;
    }
    this.setState({
      answers: answers,
    });
  };

  handleChangeQuestion = (question) => {
    if (question >= this.props.questions.length) {
      question = 0;
    } else if (question < 0) {
      question = this.props.questions.length - 1;
    }

    this.setState({
      question: question,
    });
  };
	
  handleChangeQuestionForDigitalQuestion = (question) => {
    this.setState({
      question: question,
    });
  }
  handleEvaluateClick = () => {
    if (this.props.user.demo) {
      toast.error(
        "Bu işlemi DEMO modunda yapamazsınız. Devam etmek için giriş yapmanız gerekiyor."
      );
      this.props.loginRequired(true);
      return;
    }
    this.setState({ show: true });
  };

  handleSaveClick = async () => {
    this.stopTimer();

    const testID = +this.state.testId;
    let req = {
      yayinID: this.props.yayinID,
      testSure: this.secondsToTime(
        this.state.time + this.state.extraTime,
        true
      ),
      test: {
        testID: testID,
        BransKod: this.props.questions[0].Brans,
        durum: "2",
        sorular: [],
      },
    };

    if (this.state.isVirtual) {
      req.sanalTestNo = testID;
    }

    this.props.questions.map((q) => {
      let answer = {
        konuKod: q.KonuKod,
        KazanimKod: q.KazanimKod,
        ACevap: q.ACevap,
        OCevap: this.state.answers[q.Key] || "",
        SoruKey: q.Key,
        No: q.No,
        testId: q.TestID,
      };
      req.test.sorular.push(answer);
    });

    const { success, error } = await this.props.saveTestsQuestionsAnswer(req);

    if (success) {
      this.setState(
        {
          show: false,
        },
        () => {
          this.props.history.push(
            "/dijitalkitap/book/lesson/subject/quiz/summary/" +
            testID +
            (this.state.isVirtual ? "?v=true" : "")
          );
        }
      );
    } else {
      if (error.length) {
        toast.error(error);
      }
    }
  };

  parseVideoUrl = (cndLink) => {
    const {
      query: { url },
    } = queryString.parseUrl(cndLink);
    return url ? url : "";
  };

  render() {
    const questionOrder = this.props.match.params.questionOrder && this.props.match.params.questionOrder > 0 ? parseInt(this.props.match.params.questionOrder, 10) : 0;
    const { questions, detail } = this.props;
    const questionOptions =
      (this.props.questions && this.props.questions[this.state.question] &&
        studyActions.questionOptions2(
          this.props.questions[this.state.question].cevapSecenek
        )) ||
      []; 
      
      if (questionOrder > 0 && questions && questions.length > 0 && questionOrder <= questions.length  ) {
        this.setState({
          question: questionOrder - 1
        });
      }

    const question = questions && questions.length && questions[this.state.question];
    const disableOptions = !this.state.isVirtual && // sanal test değilse
                          detail && // detay varsa (daha önce çözüldüğü anlamına gelir)
                          question && (
                            question.isDijital === 0 //soru dijital değilse çözülemesin
                            ||
                            (question.isDijital === 1 && question.OCevap.length > 0) // soru dijitalse ve cevaplandıysa yine çözülemesin
                          );
    return (
      <>
        <div className="row quiz-page">
          <div className="col-12">
            <Breadcrumb items={this.state.breadcrumbs} />
          </div>
          <div className="col-12 mb-2 d-flex flex-column flex-md-row justify-content-md-between flex-wrap align-items-center">
            <h1 className="flex-1 w-100">
              {this.state.isVirtual ? "Hata Kutusu Testi" : this.state.testAd} -
              Soru&nbsp;
              {this.state.question + 1}
            </h1>
            <div className="d-flex w-100 align-items-center justify-content-between flex-1">
              <div className="d-flex flex-row align-items-center d-block d-md-none border-radius-8 bg-f4f4f4 px-1">
                <img
                  src={require("../../assets/images/icons/icon-clock.svg")}
                  alt="..."
                  width="20"
                />
                <span
                  className="font-weight-700 color-919191"
                  style={{ fontSize: 26 }}
                >
                  {this.state.timePretty}
                </span>
              </div>

              <div className="d-flex d-md-none justify-content-between">
                <CircleButton
                  className="btn-default mx-1 small wh-40"
                  onClick={() =>
                    this.handleChangeQuestion(this.state.question - 1)
                  }
                  icon={faChevronLeft}
                />
                <CircleButton
                  className="btn-default mx-1 small wh-40"
                  onClick={() =>
                    this.handleChangeQuestion(this.state.question + 1)
                  }
                  icon={faChevronRight}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="card mb-2">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-8 col-lg-9">
                    {
                      question && 
                        <InteractionWrapper
                          key={question.ID}
                          videoProvider="cloudfront"
                          contentType="soru-resim"
                          contentId={question.ID}
                          userId={
                            (this.props &&
                              this.props.user &&
                              this.props.user.id) ||
                            0
                          }
                          dontMarkAsWatched={true}
                        >
                          {(markAsWatched) => (
                            <img
                              className="card-img-top mb-2 question-img"
                              style={{ border: "1px solid #c0cbfc" }}
                              src={question.soruImageLink}
                              alt="..."
                            />
                          )}
                        </InteractionWrapper>
                    }
                   

                    <div className="d-flex flex-column flex-sm-row justify-content-between">
                      {question && !question.isCevapMetin ? 
                        <div className="mx-auto mx-md-4">
                          {questionOptions.map((choise) => (
                            <CircleButton
                              className={classNames(
                                "wh-36 p-0 ml-2 mr-2",
                                question &&
                                  this.state.answers[
                                    question.Key
                                  ] &&
                                  this.state.answers[
                                    question.Key
                                  ] === choise
                                  ? "selected-btn"
                                  : "btn-default"
                              )}
                              onClick={() => !disableOptions && this.handleChangeAnswer(choise)}
                              key={choise}
                            >
                              {choise}
                            </CircleButton>
                          ))}
                        </div>
                        :
                        <div className="d-flex flex-column flex-sm-row justify-content-between align-self-center">
                          {question &&  question.videoLink != "" &&
                              <Link
                                onClick={() => this.setState({ showVideoModal: true })}>
                                  <span className="fs-18 mx-2 color-919191">
                                  Çözüm İzle
                                  </span>
                                  <img
                                    src={require("../../assets/images/icons/video-yt.svg")}
                                    alt="..."
                                    width="20"
                                    className="ml-2"
                                  />
                              </Link>
                          }
                        </div>
                      }
                      <div className="d-flex flex-column flex-sm-row justify-content-between">
                        {question?.video}
                      </div>

                      <div className="mt-2 mt-sm-0 ml-auto d-none d-md-block align-self-center">
                        <CircleButton
                          className="btn-default mx-1 wh-40 p-0"
                          onClick={() =>
                            this.handleChangeQuestion(this.state.question - 1)
                          }
                          icon={faChevronLeft}
                        />
                        <CircleButton
                          className="btn-default mx-1 wh-40 p-0"
                          onClick={() =>
                            this.handleChangeQuestion(this.state.question + 1)
                          }
                          icon={faChevronRight}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-4 col-lg-3 pl-4 pr-4">
                    <div className="flex-row align-items-center mb-3 mt-sm-4 d-none d-md-flex">
                      <img
                        src={require("../../assets/images/icons/icon-clock.svg")}
                        alt="..."
                        width="25"
                      />
                      <span className="fs-18 mx-2 color-919191">
                        Çözüm Süre
                      </span>
                      <span
                        className="text-primary font-weight-700"
                        style={{ fontSize: 32 }}
                      >
                        {this.state.timePretty}
                      </span>
                    </div>

                    <hr className="my-3" />

                    <div>
                      {questions.map((item, index) => (
                        item.isDijital === 1 ? (
                          <CircleButton
                            className={classNames("wh-60 p-1 ml-2 mr-2 borrad-30", {
                              "btn-active-blue": index === this.state.question,
                              "btn-not-active-blue":
                                index !== this.state.question,
                            })}
                            onClick={() => this.handleChangeQuestionForDigitalQuestion(index)}
                            key={index}
                          >
                            <span style={{"width":"76px","height":"16px","margin":"0 0 1px","fontFamily":"inherit","fontSize":"16px","fontWeight":"600","fontStretch":"normal","fontStyle":"normal","lineHeight":"1","letterSpacing":"normal","textAlign":"center", "display": "block"}}>
                              {index + 1}
                            </span>
                            <span style={{"width":"53px","height":"11px","margin":"1px 10px 0 12px","fontFamily":"inherit","fontSize":"9px","fontWeight":"bold","fontStretch":"normal","fontStyle":"normal","lineHeight":"normal","letterSpacing":"normal", "display": "block"}}>
                              Dijital Soru
                          </span>
                          </CircleButton>
                        ) :
                        item.isCevapMetin === true ? (

                          <CircleButton
                            className={classNames("wh-60 p-1 ml-2 mr-2 borrad-30", {
                              "btn-active-blue": index === this.state.question,
                              "btn-not-active-blue":
                                index !== this.state.question,
                            })}
                            onClick={() => this.handleChangeQuestion(index)}
                            key={index}
                          >
                              <span style={{"width":"76px","height":"16px","margin":"0 0 1px","fontFamily":"inherit","fontSize":"16px","fontWeight":"600","fontStretch":"normal","fontStyle":"normal","lineHeight":"1","letterSpacing":"normal","textAlign":"center", "display": "block"}}>
                              {index + 1}
                            </span>
                            <span style={{"width":"53px","height":"11px","margin":"1px 10px 0 12px","fontFamily":"inherit","fontSize":"9px","fontWeight":"bold","fontStretch":"normal","fontStyle":"normal","lineHeight":"normal","letterSpacing":"normal", "display": "block"}}>
                              Açık Uçlu 
                          </span>
                            </CircleButton>
                        ):
                          (
                            <CircleButton
                              className={classNames("wh-40 p-1 ml-2 mr-2", {
                                "btn-active-blue": index === this.state.question,
                                "btn-not-active-blue":
                                  index !== this.state.question,
                              })}
                              onClick={() => this.handleChangeQuestion(index)}
                              key={index}
                            >
                              {index + 1}
                            </CircleButton>
                          )
                      ))}

                      <button
                        disabled={Object.keys(this.state.answers).length === 0}
                        className="btn btn-primary fs-14 px-5 text-capitalize mt-3 d-block"
                        style={{ width: "100%" }}
                        onClick={() => this.handleEvaluateClick()}
                      >
                        Değerlendir
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.show &&
            <CompleteModal
              show={this.state.show}
              onCloseClick={() => this.setState({ show: false })}
              onSaveClick={this.handleSaveClick}
              isBlank={this.props.questions.some(
                (q) => !this.state.answers[q.Key] && !q.isCevapMetin
              )}
            // dialogClassName="modal-sm"
            />
          }
          
          {this.state.showVideoModal && 
            <Modal
              className="modal-container non-modal-height"
              dialogClassName="modal-lg"
              show={this.state.showVideoModal}
            >
              <CloseButton onClick={() => this.setState({ showVideoModal: false })} />
              <div className="modal-body">
              <Question question={question} user={this.props.user} parseVideoUrl={this.parseVideoUrl} />
              </div>
            </Modal>
          }
        </div>

        {/* <Pomodoro /> */}

        <div className="row mt-4 absolute-row d-none">
          <div className="timer-card d-flex flex-column flex-sm-row align-items-center justify-content-between">
            <RadialChart
              innerRadius={80}
              radius={100}
              getAngle={(d) => d.theta}
              data={this.state.data}
              width={240}
              height={240}
              padAngle={0.04}
              colorRange={["#22ff08", "#ff0909"]}
            >
              <img
                src={require("../../assets/images/tree/tree-step-6_4.svg")}
                alt="..."
                className="tree-chart-img"
              />
              <span className="tree-chart-badge s-120--0">
                {this.state.allowedTime}
              </span>
              <span className="tree-chart-badge s-120--30">
                {this.state.allowedTime / 4}
              </span>
              <span className="tree-chart-badge s-120--60">
                {this.state.allowedTime / 2}
              </span>
              <span className="tree-chart-badge s-120--90">
                {(this.state.allowedTime / 4) * 3}
              </span>
            </RadialChart>

            <div>
              <h2
                className="font-weight-600"
                style={{ color: "#e74c3c", fontSize: "46px" }}
              >
                {this.state.remaningTimePretty}
              </h2>
              <p className="fs-18 font-weight-400" style={{ color: "#a7a7a7" }}>
                Kalan Süre:
              </p>
              <p className="fs-14 font-weight-500 color-9b9b9b">
                Yolu yarıladın/Birazdaha gayret ...
              </p>

              <button
                className="btn btn-outline-dark text-capitalize fs-18 px-4 border-radius-8"
                onClick={() => {
                  this.setState({ showSurrenderModal: true });
                }}
              >
                Pes Et
              </button>
            </div>
          </div>
        </div>

        <SurrenderModal
          show={this.state.showSurrenderModal}
          onCloseClick={() => {
            this.stopTimer();
            this.setState({ showSurrenderModal: false });
          }}
        />
      </>
    );
  }
}

Quiz.propTypes = {};

const mapStateToProps = ({ AnswerReducer, AccountReducer }) => ({
  questions: AnswerReducer.questions,
  yayinID: AnswerReducer.yayinID,
  classId: AnswerReducer.classId,
  user: AccountReducer.user,
});

const mapDispatchToProps = {
  getTestsQuestions: actions.getTestsQuestions,
  saveTestsQuestionsAnswer: actions.saveTestsQuestionsAnswer,
  loginRequired: accountActions.loginRequired,
};

const Question = ({ question, user, parseVideoUrl }) => {
  const hasQuestion = question && question.ID > 0;
  const isVideoFromCdn = question && question.videoLink.indexOf("source=cdn") > -1;
  return !hasQuestion ? null : 
    isVideoFromCdn ? 
      <InteractionWrapper
      key={question.ID}
      videoProvider="cloudfront"
      contentType="soru-cozum"
      contentId={question.ID}
      userId={
        (user && user.id) ||
        0
      }
    >
      {(markAsWatched) => (
        <ReactPlyr
          url={
            question == null
              ? ""
              : parseVideoUrl(
                  question.videoLink
                )
          }
          type={"video"}
        />
      )}
    </InteractionWrapper> :
    <InteractionWrapper
      key={question.ID}
      videoProvider="vimeo"
      contentType="soru-cozum"
      contentId={question.ID}
      userId={
        (user && user.id) ||
        0
      }
    >
      {(markAsWatched) => (
        <div className="v-wrapper">
          <iframe title="iframe"
            className="v-video"
            src={`${parseVideoUrl(
              question.videoLink
            )}?autoplay=1&color=f34a53&title=0&byline=0&portrait=0`}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </InteractionWrapper>
};


export default connect(mapStateToProps, mapDispatchToProps)(Quiz);
