import React, { PureComponent } from "react";
import { ScoreBadge, CoinItem } from "../../components";
import { connect } from "react-redux";
import * as actions from "../../routes/actions/Product";
import * as accountActions from "../../routes/actions/Account";
import { formatDate as dateFormatter } from "../../helpers";
import { replaceDotWithComma } from "../../helpers";

class Wallet extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {
    this.props.getCoins();
    await this.props.getCoinOrders();
  }

  formatDate(date) {
    return dateFormatter.date(new Date(date));
  }

  render() {
    const { wallet } = this.props;
    return (
      <>
        <div className="container-fluid p-xs-0">
          <div className="row">
            <div className="col-12 p-xs-0">
              <h3 className="fs-18 color-484848 font-weight-bold">
                Jetonlarım
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12 p-xs-0">
              <div className="card border-e5e5e5 border-radius-16 box-shadow-none mb-2">
                <div className="card-body fs-14 font-weight-600">
                  <div className="d-flex flex-column flex-sm-row align-items-sm-center">
                    <span className="font-weight-bold">Hesabınızda</span>
                    <div className="wallet-score-box">
                      <ScoreBadge
                        title={`${wallet.coins + wallet.bonus} Jeton`}
                        icon="coin"
                        className="badge-coin align-middle bg-fff1da mr-2 mx-sm-2"
                        value={wallet.coins + wallet.bonus}
                      />
                      bulunmaktadır.
                    </div>
                  </div>

                  <div className="color-484848 fs-12 mt-3">
                    {`Satın alınan ${wallet.coins} jeton`}
                  </div>
                  <span className="color-484848 fs-12">
                    {`Hediye kazanılan ${wallet.bonus} jeton`}
                  </span>
                  <br />
                  <span className="mt-3 color-9d9d9d fs-12 d-block d-sm-inline">
                    {`Hediye jetonlarınız ${
                      wallet.bonusExpire && this.formatDate(wallet.bonusExpire)
                    } tarihinde kullanımı sona erecektir.`}
                  </span>

                  {/* {wallet.bonus > 0 && (
                                        <>
                                            <div className="color-484848 fs-13 mt-3">
                                                {`Satın alınan ${wallet.coins} jeton`}
                                            </div>
                                            <span className="color-484848 fs-13">
                                                {`Hediye kazanılan ${wallet.bonus} jeton`}
                                            </span>
                                            <br />
                                            <span className="mt-3 color-919191 d-block d-sm-inline">
                                                {`Hediye jetonlarınız ${wallet.bonusExpire &&
                                                    this.formatDate(
                                                        wallet.bonusExpire
                                                    )} tarihinde kullanımı sona erecektir.`}
                                            </span>
                                        </>
                                    )} */}
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            {this.props.coins.map((coin, i) => (
              <div
                key={coin.id}
                className="col-6 col-md-4 col-lg-3 p-1 p-sm-2 mt-4"
              >
                <CoinItem coin={coin} showBuy={this.props.user.demo !== true} />
              </div>
            ))}
          </div>
          {this.props.coinOrders.length > 0 && (
            <div className="row">
              <div className="col-12 p-xs-0">
                <div className="card mb-2 box-shadow-none border-e5e5e5">
                  <div className="card-body fs-14 font-weight-500">
                    <ul className="list-group">
                      <li className="list-group-item disabled border-0">
                        Jeton Alımlarım
                      </li>
                      {this.props.coinOrders.map((cO, i) => {
                        return (
                          <li
                            key={i}
                            className="list-group-item border-none d-flex flex-column flex-md-row align-items-md-center justify-content-between color-919191 fs-16 p-0 p-sm-2 mb-3"
                          >
                            <div className="d-flex flex-column-reverse flex-sm-row align-items-sm-center w-100 flex-2">
                              <div className="color-919191 fs-13">
                                {this.formatDate(cO.date)}
                              </div>
                            </div>
                            <div className="text-md-right flex-2 mt-1 mt-sm-0">
                              <div className="ml-0 ml-sm-3 color-484848">
                                {cO.pack} <br /> ({cO.coin} jeton + {cO.bonus}{" "}
                                hediye){" "}
                              </div>
                            </div>
                            <div className="text-md-right flex-2 mt-1 mt-sm-0">
                              <div className="ml-0 ml-sm-3 color-484848">
                                {replaceDotWithComma(cO.total.toFixed(2))} TL{" "}
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

Wallet.propTypes = {};

const mapStateToProps = ({ ProductReducer, AccountReducer }) => ({
  coins: ProductReducer.coins,
  wallet: ProductReducer.wallet,
  coinOrders: AccountReducer.coinOrders,
  user: AccountReducer.user,
});

const mapDispatchToProps = {
  getCoins: actions.getCoins,
  getCoinOrders: accountActions.getCoinOrders,
};

export default connect(mapStateToProps, mapDispatchToProps)(Wallet);
