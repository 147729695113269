import ReactGA from "react-ga";
import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
// import logger from "redux-logger";
import createRootReducer from "./reducers";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import reducers from "./routes/reducers";
import { loadingBarMiddleware } from "react-redux-loading-bar";

// Create redux store with history
export const history = createBrowserHistory();

history.listen(location => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
});

export default function configureStore(preloadedState) {

    const store = createStore(
        createRootReducer(history, reducers), // root reducer with router state
        preloadedState,
        compose(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                thunkMiddleware,
                //logger,
                loadingBarMiddleware()
                // ... other middlewares ...
            ),
            window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
        )
    );

    return store;
}
