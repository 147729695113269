import actionTypes from "../../actions/Account/types";
import storage from "../../../services/storage";

export const initialState = {
    loginRequired: false,
    token: storage.getAuth().token || null,
    user: storage.getAuth().user || {},
    reservations: [],
    addresses: [],
    orders: [],
    order: {
        itemTotal: 0,
        shipTotal: 0,
        taxTotal: 0,
        lines: []
    },
    favorites: {
        products: [],
        brands: [],
        isLoaded : false
    },
    laterProducts: {
        products: [],
        isLoaded : false
    },
    toBeNotifiedProductsWhenInStock: {
        products: []
    },
    cities: [],
    city: null,
    towns: [],
    issues: [],
    createdIssue: null,
    //issueCategories: [],
    shippingOptions: [],
    coinOrders: [],
    locationByTown: {
        id: 0,
        cityId: 0,
        name: ""
    },
    contracts: [],
    contractsNoContent: [],
    unsignedContracts: [],
    masterpassTokens: {
        msisdn: "",
        refNumber: "",
        token: ""
    },
    schools: [],
    showQrModal: false,
    handleModal: "",    
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_REQUIRED:
            return { ...state, loginRequired: action.payload };

        case actionTypes.UPDATE_CLASS:
            let user = action.payload.classId == null
                ? Object.assign({}, state.user, { isClassUpdated: false })
                : Object.assign({}, state.user, { classId: action.payload.classId, isClassUpdated: true });
            storage.setUser(user);
            return { ...state, user: user };
        case actionTypes.LOGIN:
            return { ...state, ...action.payload.login };

        case actionTypes.UPDATE_USER_PROFILE:
            return { ...state, user: action.payload };

        case actionTypes.SHOW_QR_SCANNER_MODAL:
            return { ...state, showQrModal: true };

        case actionTypes.GET_IS_EMAIL_VERIFIED:
            const userData = { ...state.user, isMailVerified: action.payload.isEmailVerified };
            if (action.payload.isEmailVerified)
                storage.removeWaitingForEmailVerification();
            storage.setUser(userData);
            return { ...state, user: userData };

        case actionTypes.HIDE_QR_SCANNER_MODAL:
            return { ...state, showQrModal: false };

        case actionTypes.VERIFIED: {
            const user = action.payload.mobile
                ? Object.assign({}, state.user, { isMobileVerified: true })
                : Object.assign({}, state.user, { isMailVerified: true });
            storage.setUser(user);
            return { ...state, user: user };
        }

        case actionTypes.GET_MASTERPASS_TOKENS: {

            return { ...state, masterpassTokens: action.payload }
        }

        case actionTypes.LOGOUT: {
            storage.removeAuth();
            var _ = { ...state, user: {}, token: null };
            return _;
        }
        case actionTypes.CHANGE_PREFERENCES:
            return { ...state, user: action.payload };

        case actionTypes.GET_RESERVATIONS:
            return { ...state, reservations: action.payload };

        case actionTypes.GET_ADDRESSES:
            return { ...state, addresses: action.payload };

        case actionTypes.GET_CONTRACTS:
            return { ...state, contracts: action.payload };

        case actionTypes.GET_CONTRACTS_NO_CONTENT:
            return { ...state, contractsNoContent: action.payload };

        case actionTypes.GET_UNSIGNED_CONTRACTS:

            return { ...state, unsignedContracts: action.payload };

        case actionTypes.SIGN_CONTRACT:
            return {
                ...state,
                unsignedContracts: state.unsignedContracts.filter(
                    (contract) => contract.contractId !== action.payload
                )
            };

        case actionTypes.CREATE_ADDRESS:
            return { ...state, addresses: [action.payload, ...state.addresses] };

        case actionTypes.UPDATE_ADDRESS:
            let newOrder = {};
            if (state.order && (state.order.deliveryAddress || state.order.billAddress)) {
                if (state.order.deliveryAddress.id === action.payload.address.id) {
                    newOrder.deliveryAddress = action.payload.address;
                }
                if (state.order.billAddress.id === action.payload.address.id) {
                    newOrder.billAddress = action.payload.address;
                }
            }
            return {
                ...state,
                addresses: Object.assign([], state.addresses, {
                    [action.payload.index]: action.payload.address
                }),
                order: Object.assign({}, state.order, newOrder)

            };

        case actionTypes.DELETE_ADDRESS:
            return {
                ...state,
                addresses: state.addresses.filter(
                    (address) => address.id !== action.payload.id
                )
            };

        case actionTypes.GET_ORDERS:
            {
                return { ...state, orders: action.payload };
            }

        case actionTypes.GET_COIN_ORDERS:
            return { ...state, coinOrders: action.payload };

        case actionTypes.GET_SHIPPING_OPTIONS:
            return { ...state, shippingOptions: action.payload };

        case actionTypes.GET_ORDER:
            return { ...state, order: action.payload };

        case actionTypes.UPDATE_ORDER:
            return { ...state, order: action.payload };

        case actionTypes.GET_FAVORITES:
            return { ...state, favorites: action.payload };

        case actionTypes.GET_LATER_PRODUCTS:
            return { ...state, laterProducts: action.payload };
        case actionTypes.GET_CITIES:
            return {
                ...state,
                cities: action.payload.cities,
                city: action.payload.city,
                towns: action.payload.towns
            };

        case actionTypes.GET_TOWNS:
            return {
                ...state,
                towns: action.payload.map((town) => {
                    return { value: town.id, label: town.name };
                })
            };

        case actionTypes.GET_TOWN_BY_NAME:
            return {
                ...state,
                locationByTown: action.payload
            };

        case actionTypes.CHANGE_TOWN:
            return {
                ...state,
                city: action.payload.city,
                towns: action.payload.towns
            };

        case actionTypes.GET_SCHOOLS:
            return {
                ...state,
                schools: action.payload.map((school) => {
                    return { value: school.id, label: school.name };
                })
            };

        case actionTypes.RESET_CITY_TOWN:
            return {
                ...state,
                cities: action.payload.cities,
                city: action.payload.city,
                towns: action.payload.towns
            };

        case actionTypes.ADD_PRODUCT_TO_NOTIFY_ME: {
            const toBeNotifiedObjects = Object.assign({}, state.toBeNotifiedProductsWhenInStock);
            toBeNotifiedObjects.products.push(action.payload);
            return { ...state, toBeNotifiedObjects };
        }

        case actionTypes.ADD_TO_FAVORITES: {
            const favorites = Object.assign({}, state.favorites);
            favorites.products.push(action.payload);
            return { ...state, favorites };
        }
        case actionTypes.ADD_TO_FAVORITES_BRAND: {
            const favorites = Object.assign({}, state.favorites);
            favorites.brands.push(action.payload);
            return { ...state, favorites };
        }
        case actionTypes.REMOVE_FROM_FAVORITES: {
            const favorites = Object.assign({}, state.favorites);
            favorites.products = favorites.products.filter(
                (p) => p.id !== action.payload.id
            );
            return { ...state, favorites };
        }
        case actionTypes.REMOVE_FROM_LATER_PRODUCTS: {
            const laterProducts = Object.assign({}, state.laterProducts);
            laterProducts.products = laterProducts.products.filter(
                (p) => p.id !== action.payload.ProductId
            );
            return { ...state, laterProducts };
        }
        case actionTypes.REMOVE_FROM_FAVORITES_BRAND: {
            const favorites = Object.assign({}, state.favorites);
            favorites.brands = favorites.brands.filter(
                (b) => b.id !== action.payload.id
            );
            return { ...state, favorites };
        }

        case actionTypes.GET_ISSUE_TYPES:
            return { ...state, issues: action.payload.issues };
        case actionTypes.CREATE_ISSUE:
            return { ...state, createdIssue: action.payload };

        case actionTypes.UPDATE_MASTERPASS_NUMBER: {
            storage.setUser(action.payload);
            return { ...state, user: action.payload }
        }

        case actionTypes.FETCH_PAYMENT: {
            return { ...state, ...action.payload }
        }

        case actionTypes.HANDLE_MODAL:
            return { ...state, handleModal: action.payload };
        default:
            return state;
    }
};
