import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Form } from "../../components";
import FormSchemas from "../../variables/FormSchemas";
import { changePassword } from "../../routes/actions/Account";
//import PropTypes from 'prop-types';

class ChangePassword extends PureComponent {
  state = {
    formData: {},
  };

  changePassword = async (formData) => {
    const { success, error } = await this.props.changePassword(
      this.props.user,
      formData
    );

    this.setState({ formData: {} }, () => {
      if (success) {
        toast.success("Şifreniz değiştirilmiştir.");
      } else {
        toast.error(error);
      }
    });
  };

  render() {
    const { user } = this.props;
    return (
      <>
        <h3 className="fs-18 color-484848 font-weight-600 mb-3">Şifre Değiştir</h3>
        <div className="change-password-container">
          {user.demo ? (
            <Form
              schema={FormSchemas.demoChangePassword.schema}
              uiSchema={FormSchemas.demoChangePassword.uiSchema}
              formData={this.state.formData}
              onSubmit={this.changePassword}
            />
          ) : (
            <Form
              schema={FormSchemas.changePassword.schema}
              uiSchema={FormSchemas.changePassword.uiSchema}
              formData={this.state.formData}
              onSubmit={this.changePassword}
            />
          )}
        </div>
      </>
    );
  }
}

ChangePassword.propTypes = {};

const mapStateToProps = ({ AccountReducer }) => ({
  user: AccountReducer.user,
});

const mapDispatchToProps = {
  changePassword: changePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
