import React, {Component} from "react";
import {connect} from "react-redux";
import Icon from "../../components/Icon/Index";
//import PropTypes from 'prop-types';

const formatDate = (date) => {
    const options = {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
        timeZone: "Europe/Istanbul"
    };

    return new Intl.DateTimeFormat("tr-TR", options).format(new Date(date));
};

class Notifications extends Component {
    render() {
        const notifications = this.props.notifications;
        return (
            <div className="row">
                {/* <div className="col-12">
          <Breadcrumb items={breadcrumbs} />
        </div> */}
                <div className="col-12">
                    <h2>Bildirimler</h2>
                </div>
                <div className="col-12">
                    <ul className="list-group">
                        {notifications.length ? (
                            notifications.map((notification) => (
                                <li className="list-group-item border-0 bt-dedede">
                                    <div className="d-flex w-100 align-items-center">
                                        <div className="flex-1">
                                            <Icon icon="iNotification"/>
                                        </div>
                                        <div className="flex-5">
                                            <p className="mb-1">{notification.description}</p>
                                            <small className="text-muted">
                                                {formatDate(notification.createDate)}
                                            </small>
                                        </div>
                                    </div>
                                </li>
                            ))
                        ) : (
                            <li className="list-group-item border-0 bt-dedede">
                                <div className="d-flex w-100 align-items-center">
                                    <div className="flex-1">
                                        <Icon icon="iNotification"/>
                                    </div>
                                    <div className="flex-5">
                                        <p className="mb-0">Bildiriminiz bulunmamaktadır.</p>
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        );
    }
}

Notifications.propTypes = {};

const mapStateToProps = ({AccountReducer, ProductReducer}) => ({
    notifications: ProductReducer.notifications
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Notifications);
