import React from "react";
//import PropTypes from "prop-types";
import { Modal } from "..";
import CloseButton from "../Button/Close";
import Countdown from "react-countdown-now";

const Verification = ({
  codeRef,
  errors,
  lastSmsDate,
  onCloseClick,
  phone,
  sendVerifySms,
  show,
  verify
}) => {

  const Completionist = () => (
      <a
        href="#resend"
        onClick={e => {
          e.preventDefault();
          sendVerifySms(phone);
        }}
        className="fs-12 text-decoration-none font-weight-500"
        style={{ color: "red" }}
      >
        Tekrar Gönder
      </a>
  );

  const countdownRenderer = ({ seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <span className="fs-12 text-decoration-none font-weight-500" style={{ color: "red" }}>
          {seconds} saniye sonra tekrar gönder
        </span>
      );
    }
  };

  return (
    <Modal show={show} className="modal-container verificationModal" onCloseClick={onCloseClick}>
      <CloseButton onClick={onCloseClick} />
      <div className="text-center px-3">
        <img
          src={require("../../assets/images/sms.svg")}
          alt="..."
          width="60"
          style={{
            marginTop: "6rem",
            marginBottom: "4rem"
          }}
        />
        <h3>Lüften hesabınızı doğrulayın</h3>
        {/* 2.Adım */}
        <p className="fs-14">
          {phone} Nolu telefonunuza SMS ile Doğrulama kodu gönderilmiştir. Kodu
          aşağıdaki alana giriniz.
        </p>
        <div className="my-4">
          <input
            type="text"
            className={`form-control bg-white b-dedede border-radius-8 text-center py-4 ${
              errors.code ? "is-invalid" : ""
            }`}
            id="code"
            name="code"
            placeholder="_ _ _ _ _"
            autoComplete="off"
            ref={codeRef}
            maxLength="5"
          />
        </div>
        <button
          type="button"
          onClick={verify}
          className="btn btn-primary fs-14 w-100 text-capitalize py-2"
        >
          Gönder
        </button>
        <p className="fs-12 color-919191 mt-3 mb-0">
          Doğrulama kodu ulaşmadı mı?
        </p>
        <div className="mb-5">
          <Countdown key={lastSmsDate} precision={3} date={lastSmsDate} renderer={countdownRenderer} />
        </div>
      </div>
    </Modal>
  );
};

Verification.propTypes = {};

export default Verification;
