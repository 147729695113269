import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneVolume,
  faBoxOpen,
  faUndoAlt,
  faUser,
  faBook,
  faThumbsUp,
  faBug,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import Icon from "../../components/Icon/Index";
import * as actions from "../../routes/actions/Account";
import SupportFrom from "./SupportFrom";
import { imgPuan, imgJeton, imgRozet } from "../../assets/images";
import { setPrerenderTimeout } from "../../helpers";
import { withRouter } from "react-router";

const deliveries = [
  { title: "alındı. Onay bekliyor." },
  { title: "onaylandı ve teslimat için hazırlanıyor." },
  { title: "kargoya verilmiştir." },
  { title: "teslim edildi." },
];

class Support extends Component {
  state = {
    search: "",
    isEmpty: false,
    invalid: false,
    selected: 0,
    category: "siparisler",
    show: true,
    disabled: false,
    subjects: [],
    toggleSelected: 0,
    accordionSelected: 0,
    order: null,
    components: [0],
    note: "",
    orderNumber: "",
  };

  componentDidMount() {
    if (this.props.user.id && this.props.user.id > 0) this.props.getOrders();

    if (this.props.location.hash) {
      const hashReplace = this.props.location.hash.replace("#", "");

      this.collapse(Number(hashReplace));

      if (hashReplace === "cargo-tracking") {
        this.goToElement(hashReplace);
      }
      if (hashReplace === "siparis-bilgi") {
        this.goToElement(hashReplace);
      }
    }
  }

  componentDidUpdate() {
    setPrerenderTimeout();
  }
  goToElement = (elementid) => {
    const goToInterval = setInterval(() => {
      const element = document.getElementById(elementid);
      if (element) {
        clearInterval(goToInterval);
        element.scrollIntoView();
      }
    }, 100);
  };  
  collapse = (index) => {
    const { selected } = this.state;
    if (selected === index) {
      this.setState({ selected: 0 });
      return;
    }
    this.setState({ selected: index });
  };

  handleChange = ({ target: { value } }) => {
    this.setState({ search: value, isEmpty: !value });
  };

  handleSearch = () => {
    if (this.state.search) {
      let order = this.props.orders.find(
        (o) => o.id === parseInt(this.state.search, 10)
      );
      if (order) {
        this.setState({ order, invalid: false });
      } else {
        this.setState({ order: null, invalid: true });
      }
    } else {
      this.setState({ isEmpty: true });
    }
  };

  render() {
    const { isEmpty, invalid, order, selected } = this.state;
    const { orders } = this.props;
    const statusHistory = order ? order.deliveries[0].statusHistory : [];
    const isCanceled = statusHistory.some((x) => x.isCancelStatus);
    return (
      <>
        <h3 className="fs-18 color-484848 font-weight-600">Yardım</h3>
        <div className="container-fluid p-0 p-sm-3">
          <div className="row">
            <div className="col-12 support-container">
              <div className="card mb-2">
                <div className="card-header" onClick={() => this.collapse(1)}>
                  <div className="card-header-icon">
                    <Icon icon="iShippingHover" />
                  </div>
                  <h5 className="title">Siparişler</h5>
                  <span
                    className={classNames("collapse-button", {
                      "rotate-180": selected === 1,
                    })}
                  >
                    <Icon icon="iChevronDown" />
                  </span>
                </div>

                <div
                  className={classNames("collapse", {
                    show: selected === 1,
                  })}
                >
                  <div className="card-body" style={{ marginTop: "-30px" }}>
                    <div className="accordion">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <button
                            className={classNames("btn", {
                              collapsed: this.state.accordionSelected !== 1,
                            })}
                            onClick={() => {
                              this.setState({
                                accordionSelected:
                                  this.state.accordionSelected !== 1 ? 1 : 0,
                              });
                            }}
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Siparişimi onaylar mısınız?
                          </button>
                        </div>

                        <div
                          id="collapseOne"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 1,
                          })}
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Kredi kartı ile verilen siparişler otomatik olarak onaylanmaktadır. Havale/EFT 
                            yöntemiyle verilen siparişlerin onaylanabilmesi için ödeme dekontunu 
                            destek@tongucmagaza.com adresine e-posta ile göndermen yeterli olacaktır. Kapıda 
                            Ödeme yöntemi ile verilen siparişler ise gerekli kontroller yapıldıktan sonra 
                            ekibimiz tarafından onaylanmaktadır.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingTwo">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 2,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 2 ? 2 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Sipariş ürün eklemek-çıkarmak istiyorum
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseTwo"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 2,
                          })}
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Siparişler üzerinde değişiklik yapamıyoruz. Yanlış ürün sipariş ettiğini 
                            düşünüyorsan ya da satın almaktan vazgeçtiysen ürünler kargoya verilmeden 
                            önce siparişini iptal edebilirsin. Mağaza tarafından kargoya verilen, yani 
                            “Kargoya Verildi” statüsünde gözüken siparişler için iptal işlemi yapılamıyor.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingThree">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 3,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 3 ? 3 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Ödeme yöntemini değiştirmek istiyorum
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseThree"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 3,
                          })}
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Siparişlerin ödeme yöntemi ile ilgili değişiklik yapamıyoruz. 
                            Yanlış ödeme yöntemi seçtiysen veya satın almaktan vazgeçtiysen 
                            ürünler kargoya verilmediği sürece siparişi iptal edebilirsin. 
                            Eğer siparişin “Kargoya Verildi” statüsünde ise iptal ve 
                            değişiklik yapılamaz.
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header" id="headingFour">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 4,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 4 ? 4 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              Siparişler hakkında önerim var
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseFour"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 4,
                          })}
                          aria-labelledby="headingFour"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Her türlü görüş ve önerini destek@tongucmagaza.com e-posta 
                            adresine mesaj göndererek veya Hesabım altındaki Yardım sayfasında 
                            bulunan iletişim formu üzerinden bize iletebilirsin.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingFive">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 5,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 5 ? 5 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseFive"
                              aria-expanded="false"
                              aria-controls="collapseFive"
                            >
                              Siparişim gecikti
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseFive"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 5,
                          })}
                          aria-labelledby="headingFive"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <a href="https://tongucmagaza.com/" target="_blank">
                            Tongucmagaza.com
                            </a>{" "}
                            ’a üye girişi yaparak Hesabım altındaki Siparişlerim sayfasından 
                            siparişinin son durumunu görüntüleyebilir ve kargo takibini yapabilirsin. 
                            Detaylı bilgiye ihtiyaç duyarsan, 0 212 963 45 15 nolu telefonu arayarak, 
                            destek@tongucmagaza.com e-posta adresine mesaj göndererek ya da Hesabım 
                            altındaki Yardım sayfasında bulunan iletişim formu üzerinden bizimle 
                            iletişime geçebilirsin. 
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingSix">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 6,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 6 ? 6 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseSix"
                              aria-expanded="false"
                              aria-controls="collapseSix"
                            >
                              Siparişim eksik/yanlış geldi
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseSix"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 6,
                          })}
                          aria-labelledby="headingSix"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Siparişinde eksik ya da yanlış gönderilen ürün varsa 0 212 963 45 15 
                            nolu telefonu arayarak, destek@tongucmagaza.com e-posta adresine 
                            mesaj göndererek ya da Hesabım altındaki Yardım sayfasında bulunan 
                            iletişim formu üzerinden bizimle iletişime geçebilirsin.
                          </div>
                        </div>
                      </div>
                      <div className="card" style={{ borderBottom: 0 }}>
                        <div className="card-header" id="headingSeven">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 7,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 7 ? 7 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseSeven"
                              aria-expanded="false"
                              aria-controls="collapseSeven"
                            >
                              Siparişim eksik/hatalı teslim edildi
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseSeven"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 7,
                          })}
                          aria-labelledby="headingSeven"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Siparişin eksik ya da hatalı teslim edildiyse, 
                            0 212 963 45 15 nolu telefonu arayarak, destek@tongucmagaza.com 
                            e-posta adresine mesaj göndererek ya da Hesabım altındaki Yardım 
                            sayfasında bulunan iletişim formu üzerinden bizimle iletişime geçebilirsin.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-header" onClick={() => this.collapse(13)}>
                  <div className="card-header-icon">
                    <Icon icon="iWallet2" />
                  </div>

                  <h5 className="title">Ödeme</h5>

                  <span
                    className={classNames("collapse-button", {
                      "rotate-180": selected === 13,
                    })}
                  >
                    <Icon icon="iChevronDown" />
                  </span>
                </div>

                <div
                  className={classNames("collapse", {
                    show: selected === 13,
                  })}
                >
                  <div className="card-body" style={{ marginTop: "-30px" }}>
                    <div className="accordion">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <button
                            className={classNames("btn", {
                              collapsed: this.state.accordionSelected !== 1,
                            })}
                            onClick={() => {
                              this.setState({
                                accordionSelected:
                                  this.state.accordionSelected !== 1 ? 1 : 0,
                              });
                            }}
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Kredi Kartı ile Ödeme
                          </button>
                        </div>

                        <div
                          id="collapseOne"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 1,
                          })}
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                              Tüm bankaların kredi kartları ile ödeme yapabilirsin. Kredi 
                              kartı ile yapılan tüm alışverişlerde 3D Secure fonksiyonu 
                              kullanılmaktadır.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingTwo">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 2,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 2 ? 2 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Havale/EFT ile Ödeme
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseTwo"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 2,
                          })}
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Havale/EFT ödemelerinde “Alıcı” bölümüne “Doğru Kaynak 
                            Dağıtım Teknoloji A.Ş.” ve “Açıklama” bölümüne de 
                            sipariş numarasının yazılması gereklidir.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingThree">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 3,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 3 ? 3 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              MasterPass ile Ödeme
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseThree"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 3,
                          })}
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            MasterPass sistemine kayıtlı kredi kartın ile kart 
                            bilgilerini tekrar tekrar girmek zorunda kalmadan 
                            ödemeni tek tıkla yapabilir ya da alışveriş sonrası 
                            kredi kartını sisteme kaydedebilirsin. MasterPass 
                            ödeme altyapısı MasterCard tarafından sağlanır, kredi 
                            kartı bilgileriniz Tonguç Mağaza tarafından saklanmaz. 
                            Tüm kredi kartları MasterPass’e kaydedilebilir.
                          </div>
                        </div>
                      </div>
                      <div className="card" style={{ borderBottom: 0 }}>
                        <div className="card-header" id="headingFour">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 4,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 4 ? 4 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              Jetonla Ödeme
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseFour"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 4,
                          })}
                          aria-labelledby="headingFour"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Hesabında biriken Jetonlar ile ödeme yapabilirsin. 
                            Eğer hesabında yeterli miktarda Jeton varsa ödemenin 
                            tamamını jetonla yapabilirsin. Ayrıca Jeton ile “kısmi 
                            ödeme” de yapılabilmektedir. Ödemenin bir kısmını Jeton 
                            ile yapıp kalan kısmını Kredi Kartı ile ödeyebilirsin.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-header" onClick={() => this.collapse(2)}>
                  <div className="card-header-icon">
                    <FontAwesomeIcon icon={faBook} />
                  </div>

                  <h5 className="title">Ürünler</h5>

                  <span
                    className={classNames("collapse-button", {
                      "rotate-180": selected === 2,
                    })}
                  >
                    <Icon icon="iChevronDown" />
                  </span>
                </div>

                <div
                  className={classNames("collapse", {
                    show: selected === 2,
                  })}
                >
                  <div className="card-body" style={{ marginTop: "-30px" }}>
                    <div className="accordion">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <button
                            className={classNames("btn", {
                              collapsed: this.state.accordionSelected !== 1,
                            })}
                            onClick={() => {
                              this.setState({
                                accordionSelected:
                                  this.state.accordionSelected !== 1 ? 1 : 0,
                              });
                            }}
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Yeni kaynak kitapları çıkacak mı?
                          </button>
                        </div>

                        <div
                          id="collapseOne"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 1,
                          })}
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                          Yeni yayınlanan tüm kitaplara {" "}
                            <a href="https://tongucmagaza.com/" target="_blank">
                            Tongucmagaza.com
                            </a>{" "}
                            üzerinden ulaşabilirsin. Yeni yayınlanan 
                            kaynak kitaplar siteye hemen eklenir.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingTwo">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 2,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 2 ? 2 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Örnek kitap gönderiyor musunuz?
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseTwo"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 2,
                          })}
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Örnek kitap gönderimi ile ilgili olarak 0 212 963 45 15 
                            nolu telefonu arayarak, destek@tongucmagaza.com e-posta 
                            adresine mesaj göndererek ya da Hesabım altındaki 
                            Yardım sayfasında bulunan iletişim formu üzerinden 
                            bizimle iletişime geçebilirsin.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingThree">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 3,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 3 ? 3 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Değişim yapmak istiyorum
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseThree"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 3,
                          })}
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Değişiklik taleplerin için 0 212 963 45 15 
                            nolu telefonu arayarak, destek@tongucmagaza.com 
                            e-posta adresine mesaj göndererek ya da Hesabım 
                            altındaki Yardım sayfasında bulunan iletişim 
                            formu üzerinden bizimle iletişime geçebilirsin. 
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header" id="headingFive">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 5,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 5 ? 5 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseFive"
                              aria-expanded="false"
                              aria-controls="collapseFive"
                            >
                              Ürünlerle ilgili önerim var
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseFive"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 5,
                          })}
                          aria-labelledby="headingFive"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Her türlü görüş ve önerini destek@tongucmagaza.com 
                            e-posta adresine mesaj göndererek veya Hesabım 
                            altındaki Yardım sayfasında bulunan iletişim formu 
                            üzerinden bize iletebilirsin.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-header" onClick={() => this.collapse(3)}>
                  <div className="card-header-icon">
                    <Icon icon="iOrder" />
                  </div>

                  <h5 className="title">Kargo ve Teslimat</h5>

                  <span
                    className={classNames("collapse-button", {
                      "rotate-180": selected === 3,
                    })}
                  >
                    <Icon icon="iChevronDown" />
                  </span>
                </div>
                <div
                  className={classNames("collapse", {
                    show: selected === 3,
                  })}
                >
                  <div className="card-body" style={{ marginTop: "-30px" }}>
                    <div className="accordion">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <button
                            className={classNames("btn", {
                              collapsed: this.state.accordionSelected !== 1,
                            })}
                            onClick={() => {
                              this.setState({
                                accordionSelected:
                                  this.state.accordionSelected !== 1 ? 1 : 0,
                              });
                            }}
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Siparişim hangi kargoyla gelecek?
                          </button>
                        </div>

                        <div
                          id="collapseOne"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 1,
                          })}
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                          Siparişlerimiz Aras Kargo ve Yurtiçi Kargo ile 
                          gönderilmektedir.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingTwo">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 2,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 2 ? 2 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Kargo takip numaram nedir?
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseTwo"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 2,
                          })}
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <a href="https://tongucmagaza.com/" target="_blank">
                              Tongucmagaza.com
                            </a>{" "}
                            ’a üye girişi yaparak Hesabım altındaki Siparişlerim 
                            sayfasından siparişine ait kargo takip numarasına 
                            ulaşabilirsin. Siparişin kargoya teslim edildiğinde 
                            tüm bilgiler üyelik e-posta adresine de gönderilmektedir. 
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingThree">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 3,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 3 ? 3 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Teslimatım ne zaman yapılacak?
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseThree"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 3,
                          })}
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <a href="https://tongucmagaza.com/" target="_blank">
                              Tongucmagaza.com
                            </a>{" "}
                            ’a üye girişi yaparak Hesabım altındaki Siparişlerim 
                            sayfasından siparişine ait kargo takip numarasına 
                            ulaşabilirsin. Siparişin kargoya teslim edildiğinde 
                            tüm bilgiler üyelik e-posta adresine de gönderilmektedir. 
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header" id="headingFive">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 5,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 5 ? 5 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseFive"
                              aria-expanded="false"
                              aria-controls="collapseFive"
                            >
                              Teslimat kişisini değiştirebilir miyim?
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseFive"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 5,
                          })}
                          aria-labelledby="headingFive"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Siparişin bizim tarafımızdan henüz kargoya 
                            verilmemiş ise, Hesabım altındaki Yardım sayfasında 
                            bulunan İletişim Formu üzerinden bizimle iletişime 
                            geçerek teslimat kişisini değiştirebilirsin.
                            <br />
                            <br />
                            Siparişin kargoya verilmiş ise bu değişikliği sadece 
                            kargo firmaları yapabilmektedir. Aras Kargo için 444 25 52, 
                            Yurtiçi Kargo için 444 99 99 nolu telefonları arayarak bu 
                            değişikliği talep edebilirsin. 
                            <br />
                            <br />
                            Her türlü destek için 0 212 963 45 15 nolu telefonu arayarak, 
                            destek@tongucmagaza.com e-posta adresine mesaj göndererek 
                            ya da Hesabım altındaki Yardım sayfasında bulunan iletişim 
                            formu üzerinden bizimle iletişime geçebilirsin.                            
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header" id="headingFour">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 4,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 4 ? 4 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              Teslimat adresimi değiştirmek istiyorum
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseFour"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 4,
                          })}
                          aria-labelledby="headingFour"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Siparişin bizim tarafımızdan henüz kargoya 
                            verilmemiş ise, Hesabım altındaki Yardım 
                            sayfasında bulunan İletişim Formu üzerinden 
                            bizimle iletişime geçerek teslimat kişisini 
                            değiştirebilirsin.
                            <br />
                            <br />
                            Siparişin kargoya verilmiş ise bu değişikliği 
                            sadece kargo firmaları yapabilmektedir. Aras 
                            Kargo için 444 25 52, Yurtiçi Kargo için 
                            444 99 99 nolu telefonları arayarak bu 
                            değişikliği talep edebilirsin.
                            <br />
                            <br />
                            Her türlü destek için 0 212 963 45 15 nolu 
                            telefonu arayarak, destek@tongucmagaza.com 
                            e-posta adresine mesaj göndererek ya da Hesabım 
                            altındaki Yardım sayfasında bulunan iletişim 
                            formu üzerinden bizimle iletişime geçebilirsin.
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header" id="headingSix">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 6,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 6 ? 6 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseSix"
                              aria-expanded="false"
                              aria-controls="collapseSix"
                            >
                              Kargo firmasını değiştirmek istiyorum
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseSix"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 6,
                          })}
                          aria-labelledby="headingSix"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                          Kargo firması ile ilgili değişiklik yapamıyoruz. 
                          Yanlış kargo seçeneğini seçtiysen veya satın 
                          almaktan vazgeçtiysen ürünler kargoya verilmediği 
                          sürece siparişi iptal edebilirsin. Eğer siparişin 
                          “Kargoya Verildi” statüsünde ise iptal ve 
                          değişiklik yapılamaz.
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header" id="headingSeven">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 7,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 7 ? 7 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseSeven"
                              aria-expanded="false"
                              aria-controls="collapseSeven"
                            >
                              Kargo firmasından şikayetçiyim
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseSeven"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 7,
                          })}
                          aria-labelledby="headingSeven"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Konu ile ilgili 0 212 963 45 15 nolu 
                            telefonu arayarak, destek@tongucmagaza.com 
                            e-posta adresine mesaj göndererek ya da 
                            Hesabım altındaki Yardım sayfasında bulunan 
                            iletişim formu üzerinden bizimle iletişime 
                            geçebilirsin.
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header" id="headingEight">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 8,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 8 ? 8 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseEight"
                              aria-expanded="false"
                              aria-controls="collapseEight"
                            >
                              Kargo şirketi siparişimi teslim etmiyor
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseEight"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 8,
                          })}
                          aria-labelledby="headingEight"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Konu ile ilgili 0 212 963 45 15 nolu 
                            telefonu arayarak, destek@tongucmagaza.com 
                            e-posta adresine mesaj göndererek ya da 
                            Hesabım altındaki Yardım sayfasında bulunan 
                            iletişim formu üzerinden bizimle iletişime 
                            geçebilirsin.
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header" id="headingNine">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 9,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 9 ? 9 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseNine"
                              aria-expanded="false"
                              aria-controls="collapseNine"
                            >
                              Kitabım hatalı basılmış
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseNine"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 9,
                          })}
                          aria-labelledby="headingNine"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Konu ile ilgili 0 212 963 45 15 nolu 
                            telefonu arayarak, destek@tongucmagaza.com 
                            e-posta adresine mesaj göndererek ya da 
                            Hesabım altındaki Yardım sayfasında bulunan 
                            iletişim formu üzerinden bizimle iletişime 
                            geçebilirsin.
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header" id="headingTen">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 10,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 10
                                      ? 10
                                      : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseTen"
                              aria-expanded="false"
                              aria-controls="collapseTen"
                            >
                              Kargoyla ilgili önerim var
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseNine"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 10,
                          })}
                          aria-labelledby="headingTen"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                           Her türlü görüş ve önerini destek@tongucmagaza.com 
                           e-posta adresine mesaj göndererek veya Hesabım altındaki 
                           Yardım sayfasında bulunan iletişim formu üzerinden 
                           bize iletebilirsin.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-header" onClick={() => this.collapse(4)}>
                  <div className="card-header-icon">
                    <Icon icon="iReturn" />
                  </div>

                  <h5 className="title">İade</h5>

                  <span
                    className={classNames("collapse-button", {
                      "rotate-180": selected === 4,
                    })}
                  >
                    <Icon icon="iChevronDown" />
                  </span>
                </div>

                <div
                  className={classNames("collapse", {
                    show: selected === 4,
                  })}
                >
                  <div className="card-body" style={{ marginTop: "-30px" }}>
                    <div className="accordion">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <button
                            className={classNames("btn", {
                              collapsed: this.state.accordionSelected !== 1,
                            })}
                            onClick={() => {
                              this.setState({
                                accordionSelected:
                                  this.state.accordionSelected !== 1 ? 1 : 0,
                              });
                            }}
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Aldığım ürünü nasıl iade edebilirim?
                          </button>
                        </div>

                        <div
                          id="collapseOne"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 1,
                          })}
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Bir ürünü iade etme süresi 15 gündür. 15 
                            günlük süre ürünü kargodan teslim aldığın 
                            gün başlar. İade gönderini, ürünleri sana 
                            teslim eden kargo firması ile yapman gerekir.
                            <br />
                            <br />
                            Tonguç Mağaza iade gerekçesini değerlendirecek ve 
                            koşullara uygun olması durumunda sorunlu ürünleri 
                            yenileriyle değiştirecektir. Böyle bir durumda iade 
                            ve yeniden gönderme aşamalarının tüm masrafları 
                            Tonguç Mağaza tarafından karşılanacaktır. İade 
                            koşullarına uygun bulunmaması durumunda ise iade 
                            talebi sürecindeki tüm masraflar müşteriye ait olacaktır.
                            <br />
                            <br />
                            Teslimatçı firmadan (kargodan) kaynaklanan sorunlarda: 
                            Zarar görmüş paketler teslim alınmayarak ürünü getiren 
                            kargo görevlisine tutanak tutturulmalıdır. Eğer kargo firma 
                            yetkilisi paketin hasarlı olmadığını düşünüyorsa, kargo 
                            görevlisi ile birlikte paketin açılarak ürünlerin hasarsız 
                            teslim edildiğini kontrol ettirme ve durumun yine bir tutanakla 
                            tespit edilmesini isteme hakkın var. Paketi teslim alırsan kargo 
                            firması görevini düzgün bir şekilde yapmış sayılır. Paket kabul 
                            edilmemiş ve tutanak tutulmuş ise, tutanağın fotoğrafını 
                            destek@tongucmagaza.com’a göndermen gerekmektedir.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingTwo">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 2,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 2 ? 2 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Siparişimi nasıl iptal edebilirim?
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseTwo"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 2,
                          })}
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                           Siparişin henüz kargoya verilmemiş ise, Hesabım 
                           altındaki Siparişlerim sayfasına giderek siparişini 
                           iptal edebilirsin. Kargoya verilmiş siparişler için 
                           ise iptal işlemi yapılamamaktadır. Bu durumda 
                           0 212 963 45 15 nolu telefonu arayarak, 
                           destek@tongucmagaza.com e-posta adresine mesaj göndererek 
                           ya da Hesabım altındaki Yardım sayfasında bulunan iletişim 
                           formu üzerinden bizimle iletişime de geçebilirsin. 
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingThree">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 3,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 3 ? 3 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Nasıl değişim yapabilirim?
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseThree"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 3,
                          })}
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Değişim talebinde bulunmak için 0 212 963 45 15 nolu 
                            telefonu arayarak, destek@tongucmagaza.com e-posta 
                            adresine mesaj göndererek ya da Hesabım altındaki 
                            Yardım sayfasında bulunan iletişim formu üzerinden 
                            bizimle iletişime de geçebilirsin. 
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header" id="headingFour">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 4,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 4 ? 4 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              İadem henüz hesabıma geçmedi
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseFour"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 4,
                          })}
                          aria-labelledby="headingFour"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            • İade edilen ürün süreci aşağıdaki gibidir:
                            <br />
                            <br />
                            - Ürün bize ulaştıktan sonra iade şartlarına 
                            uygunluğu kontrol edilir.
                            <br />
                            - Ürün iade şartlarına uygunsa 2-10 gün içerisinde 
                            iade süreci tamamlanır.
                            <br />
                            - Ürün iade şartlarına uygun değilse adresinize geri 
                            gönderilir.
                            <br />
                            <br />
                            • İaden tamamlanınca üyelik e-postanıza bir bilgilendirme 
                            mesajı gönderilir.
                            <br />
                            • Bu mesajı aldıysan bankana Tonguç Mağaza tarafından 
                            ücret iadesi yapılmış demektir. Bankandan iade kontrolü 
                            yapabilirsin.
                            <br />
                            • İade edilen tutarın kartınıza yansıma süresi bankana 
                            bağlıdır. Kredi kartı ile ödeme yaptıysan genelde birkaç 
                            günde yansır. Banka kartlarında ise iadenin hesabınıza 
                            yansıma süresi daha uzundur.
                            <br />
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header" id="headingSix">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 6,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 6 ? 6 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseSix"
                              aria-expanded="false"
                              aria-controls="collapseSix"
                            >
                              Siparişimi neden iade edemiyorum?
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseSix"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 6,
                          })}
                          aria-labelledby="headingSix"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                             1 - Sorunlu olarak gönderilmiş ürünler yenileriyle 
                             değiştirilmektedir. Sorunlu gönderilmiş ürünlerin 
                             tüm iade ve yeniden gönderim masrafları Tonguç Mağaza 
                             tarafından karşılanmaktadır. İade olarak gönderilen 
                             ürün yukarıda da belirttiğimiz iade koşullarına uygun 
                             değilse, iade gönderim masraflarının tamamı müşteriye 
                             ait olacaktır. İade adresinin yanlış girilmiş olmasından 
                             kaynaklanan masraflar da yine müşteriye ait olacaktır. 
                            <br />
                            <br />
                            2 - Sorunlu gönderimin teslimatçı firmadan, yani kargo 
                            firmasından kaynaklı olduğu durumlarda; zarar görmüş olan 
                            paketi lütfen teslim almayın ve teslim etmek üzere getiren 
                            kargo görevlisine tutanak tutturun. Kargo görevlisi paketin 
                            hasarlı olmadığını düşünüyorsa, paketi kargo görevlisine 
                            açtırıp ürünlerin hasarsız olduğunu kontrol ettirme ve 
                            paketin durumunu tutanakla tespit ettirme hakkın olduğunu 
                            unutma. Bu durumlarda hazırlanan tutanağın fotoğrafını 
                            destek@tongucmagaza.com adresine göndermen gerekiyor.
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header" id="headingSeven">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 7,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 7 ? 7 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseSeven"
                              aria-expanded="false"
                              aria-controls="collapseSeven"
                            >
                              Aktivasyon yapamıyorum
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseSeven"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 7,
                          })}
                          aria-labelledby="headingSeven"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Aktivasyon işlemi yapamadığın durumlarda 0 212 963 45 15 
                            nolu telefonu arayarak, destek@tongucmagaza.com e-posta 
                            adresine mesaj göndererek ya da Hesabım altındaki Yardım 
                            sayfasında bulunan iletişim formu üzerinden bizimle 
                            iletişime de geçebilirsin. 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-header" onClick={() => this.collapse(5)}>
                  <div className="card-header-icon">
                    <FontAwesomeIcon icon={faUser} />
                  </div>

                  <h5 className="title">Üyelik İşlemleri</h5>

                  <span
                    className={classNames("collapse-button", {
                      "rotate-180": selected === 5,
                    })}
                  >
                    <Icon icon="iChevronDown" />
                  </span>
                </div>

                <div
                  className={classNames("collapse", {
                    show: selected === 5,
                  })}
                >
                  <div className="card-body" style={{ marginTop: "-30px" }}>
                    <div className="accordion">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <button
                            className={classNames("btn", {
                              collapsed: this.state.accordionSelected !== 1,
                            })}
                            onClick={() => {
                              this.setState({
                                accordionSelected:
                                  this.state.accordionSelected !== 1 ? 1 : 0,
                              });
                            }}
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Nasıl üyelik oluşturabilirim?
                          </button>
                        </div>

                        <div
                          id="collapseOne"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 1,
                          })}
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Tonguç Mağaza web sitesine girerek sağ üst 
                            bölümde bulunan “Giriş Yap/Üye Ol” butonundaki 
                            “Üye Ol” kısmına tıklayarak üyelik işlemini 
                            başlatabilir ve açılan sayfadaki bilgileri 
                            eksiksiz olarak doldurduktan sonra üyeliğini 
                            oluşturabilirsin.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingTwo">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 2,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 2 ? 2 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Üyeliğimi nasıl iptal edebilirim?
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseTwo"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 2,
                          })}
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                           Üyeliğini iptal ettirmek için 0 212 963 45 15 
                           nolu telefonu arayarak, destek@tongucmagaza.com 
                           e-posta adresine mesaj göndererek ya da Hesabım 
                           altındaki Yardım sayfasında bulunan iletişim 
                           formu üzerinden bizimle iletişime de geçebilirsin. 
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingThree">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 3,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 3 ? 3 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Şifremi unuttum
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseThree"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 3,
                          })}
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                           Şifreni unuttuysan, üye giriş ekranında bulunan 
                           “Şifremi unuttum” alanına tıkla. Karşına gelecek 
                           ekrandaki ilgili alana üye olduğun e-posta adresini 
                           yazıp “Gönder” butonuna tıkla. Yeni şifre belirleyerek 
                           siteye giriş yapman için sana e-posta göndereceğiz. 
                           Bu e-postada bulunan yönergeleri izleyerek siteye 
                           kolayca giriş yapabilirsin.
                           <br />
                           <br />
                           Desteğe ihtiyaç duyarsan 0 212 963 45 15 nolu 
                           telefonu arayarak, destek@tongucmagaza.com e-posta 
                           adresine mesaj göndererek ya da Hesabım altındaki 
                           Yardım sayfasında bulunan iletişim formu üzerinden 
                           bizimle iletişime de geçebilirsin. 
                          </div>
                        </div>
                      </div>
                      
                      <div className="card">
                        <div className="card-header" id="headingSix">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 6,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 6 ? 6 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseSix"
                              aria-expanded="false"
                              aria-controls="collapseSix"
                            >
                              Şifremi değiştirmek istiyorum
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseSix"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 6,
                          })}
                          aria-labelledby="headingSix"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Şifre değişiklik işlemini “Hesabım” sayfasındaki 
                            “Şifre Değiştir” bölümüne tıklayarak istediğin 
                            zaman yapabilirsin.
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header" id="headingSeven">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 7,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 7 ? 7 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseSeven"
                              aria-expanded="false"
                              aria-controls="collapseSeven"
                            >
                              Telefon numaramı değiştirmek istiyorum
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseSeven"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 7,
                          })}
                          aria-labelledby="headingSeven"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Telefon numarası değişiklik işlemini “Hesabım” 
                            sayfasındaki “Hesap Bilgilerim” bölümüne tıklayarak 
                            istediğin zaman yapabilirsin.
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header" id="headingFour">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 4,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 4 ? 4 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              Üyelikle ilgili önerim var
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseFour"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 4,
                          })}
                          aria-labelledby="headingFour"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Her türlü görüş ve önerini destek@tongucmagaza.com 
                            e-posta adresine mesaj göndererek veya Hesabım altındaki 
                            Yardım sayfasında bulunan iletişim formu üzerinden 
                            bize iletebilirsin.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-header" onClick={() => this.collapse(7)}>
                  <div className="card-header-icon">
                    <img
                      src={imgJeton}
                      alt=""
                      style={{ filter: "grayscale(1)" }}
                    />
                  </div>

                  <h5 className="title">Jeton</h5>

                  <span
                    className={classNames("collapse-button", {
                      "rotate-180": selected === 7,
                    })}
                  >
                    <Icon icon="iChevronDown" />
                  </span>
                </div>

                <div
                  className={classNames("collapse", {
                    show: selected === 7,
                  })}
                >
                  <div className="card-body" style={{ marginTop: "-30px" }}>
                    <div className="accordion">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <button
                            className={classNames("btn", {
                              collapsed: this.state.accordionSelected !== 1,
                            })}
                            onClick={() => {
                              this.setState({
                                accordionSelected:
                                  this.state.accordionSelected !== 1 ? 1 : 0,
                              });
                            }}
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Jeton nedir ?
                          </button>
                        </div>

                        <div
                          id="collapseOne"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 1,
                          })}
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Tonguç Mağaza’da para yerine de kullanılabilen 
                            araçtır. Siparişlerinin ödemesini kısmen ya da 
                            tamamen Jeton ile de yapabilirsin.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body" style={{ marginTop: "-30px" }}>
                    <div className="accordion">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <button
                            className={classNames("btn", {
                              collapsed: this.state.accordionSelected !== 2,
                            })}
                            onClick={() => {
                              this.setState({
                                accordionSelected:
                                  this.state.accordionSelected !== 2 ? 2 : 0,
                              });
                            }}
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Jeton nasıl kazanılır ?
                          </button>
                        </div>

                        <div
                          id="collapseOne"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 2,
                          })}
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Tonguç Mağaza web sitesinden yapacağın ürün 
                            alımlarında, kırtasiyelerden yapacağın ürün 
                            alımlarında, ürün inceleme videoları çekerek 
                            ve seviye geçişlerinde jeton kazanabilirsin. 
                            Hangi ürün alımında ne kadar jeton kazanacağın 
                            bilgisini ürün detay sayfalarında bulabilirsin. 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body" style={{ marginTop: "-30px" }}>
                    <div className="accordion">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <button
                            className={classNames("btn", {
                              collapsed: this.state.accordionSelected !== 3,
                            })}
                            onClick={() => {
                              this.setState({
                                accordionSelected:
                                  this.state.accordionSelected !== 3 ? 3 : 0,
                              });
                            }}
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Jeton bilgilerime nasıl ulaşabilirim ?
                          </button>
                        </div>

                        <div
                          id="collapseOne"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 3,
                          })}
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Jeton bilgilerine “Hesabım” sayfasındaki 
                            “Jetonlarım” bölümüne tıklayarak ulaşabilirsin. 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body" style={{ marginTop: "-30px" }}>
                    <div className="accordion">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <button
                            className={classNames("btn", {
                              collapsed: this.state.accordionSelected !== 4,
                            })}
                            onClick={() => {
                              this.setState({
                                accordionSelected:
                                  this.state.accordionSelected !== 4 ? 4 : 0,
                              });
                            }}
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            İndirimli jeton var mı nasıl satın alabilirim ?
                          </button>
                        </div>

                        <div
                          id="collapseOne"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 4,
                          })}
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Satışı yapılan jetonlara Hesabım sayfası altındaki 
                            Jetonlarım bölümünden ulaşabilirsin. Jeton sadece 
                            kredi kartı ile satın alınabilir. Havale/EFT ve Kapıda 
                            Ödeme seçenekleri ile Jeton satışı yapılmamaktadır. 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body" style={{ marginTop: "-30px" }}>
                    <div className="accordion">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <button
                            className={classNames("btn", {
                              collapsed: this.state.accordionSelected !== 5,
                            })}
                            onClick={() => {
                              this.setState({
                                accordionSelected:
                                  this.state.accordionSelected !== 5 ? 5 : 0,
                              });
                            }}
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Jetonların avantajı ve özellikleri nelerdir ?
                          </button>
                        </div>

                        <div
                          id="collapseOne"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 5,
                          })}
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Sadece online işlemlerde ödemeler jetonla yapılabilir, 
                            Satın alınan jetonlar bir sonraki yıla devreder, 
                            jetonlar silinmez ve hediye jetonlar 31 Aralık’ta 
                            sıfırlanmaktadır.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body" style={{ marginTop: "-30px" }}>
                    <div className="accordion">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <button
                            className={classNames("btn", {
                              collapsed: this.state.accordionSelected !== 6,
                            })}
                            onClick={() => {
                              this.setState({
                                accordionSelected:
                                  this.state.accordionSelected !== 6 ? 6 : 0,
                              });
                            }}
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Jetonları nasıl kullanabilirim ?
                          </button>
                        </div>

                        <div
                          id="collapseOne"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 6,
                          })}
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Hesabındaki jetonları Tonguç Mağaza’dan yaptığın 
                            alışverişlerde ödeme yapmak için kullanabilirsin. 
                            Bunun için “Ödeme“ sayfasındaki “Ödeme Yöntemleri”	
                            bölümünden “Jetonla Öde” seçmen gereklidir. Eğer 
                            yeterli jetonun varsa alışverişinin tamamını jetonla 
                            ödeyebilirsin. Ya da bir kısmını jetonla ödedikten 
                            sonra kalan tutarı kredi kartı ile de tamamlayabilirsin.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-header" onClick={() => this.collapse(10)}>
                  <div className="card-header-icon">
                    <img
                      src={imgPuan}
                      alt=""
                      style={{ filter: "grayscale(1)" }}
                    />
                  </div>

                  <h5 className="title">Puan</h5>

                  <span
                    className={classNames("collapse-button", {
                      "rotate-180": selected === 10,
                    })}
                  >
                    <Icon icon="iChevronDown" />
                  </span>
                </div>

                <div
                  className={classNames("collapse", {
                    show: selected === 10,
                  })}
                >
                  <div className="card-body" style={{ marginTop: "-30px" }}>
                    <div className="accordion">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <button
                            className={classNames("btn", {
                              collapsed: this.state.accordionSelected !== 1,
                            })}
                            onClick={() => {
                              this.setState({
                                accordionSelected:
                                  this.state.accordionSelected !== 1 ? 1 : 0,
                              });
                            }}
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Puan nedir ?
                          </button>
                        </div>

                        <div
                          id="collapseOne"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 1,
                          })}
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Puan; seviyeni belirleyen bir göstergedir.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body" style={{ marginTop: "-30px" }}>
                    <div className="accordion">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <button
                            className={classNames("btn", {
                              collapsed: this.state.accordionSelected !== 2,
                            })}
                            onClick={() => {
                              this.setState({
                                accordionSelected:
                                  this.state.accordionSelected !== 2 ? 2 : 0,
                              });
                            }}
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Puan nasıl kazanılır ?
                          </button>
                        </div>

                        <div
                          id="collapseOne"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 2,
                          })}
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                           Kitap satın alarak, kitap aktivasyonu yaparak, 
                           aktif ettiğin kitabındaki soruları çözerek ve 
                           üniteleri tamamladıktan sonra seviyeleri geçerek, 
                           sitede karşılaştığınız hataları bize bildirerek, 
                           sitemize yorum yazarak, inceleme videoları göndererek, 
                           telefon doğrulaması yaparak, e-posta doğrulaması yaparak 
                           ve kırtasiyeleri değerlendirerek puan kazanabilirsiniz.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-2">
                <div className="card-header" onClick={() => this.collapse(12)}>
                  <div className="card-header-icon">
                    <img
                      src={imgRozet}
                      alt=""
                      style={{ filter: "grayscale(1)" }}
                    />
                  </div>

                  <h5 className="title">Rozet</h5>

                  <span
                    className={classNames("collapse-button", {
                      "rotate-180": selected === 12,
                    })}
                  >
                    <Icon icon="iChevronDown" />
                  </span>
                </div>

                <div
                  className={classNames("collapse", {
                    show: selected === 12,
                  })}
                >
                  <div className="card-body" style={{ marginTop: "-30px" }}>
                    <div className="accordion">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <button
                            className={classNames("btn", {
                              collapsed: this.state.accordionSelected !== 1,
                            })}
                            onClick={() => {
                              this.setState({
                                accordionSelected:
                                  this.state.accordionSelected !== 1 ? 1 : 0,
                              });
                            }}
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Rozet nedir, nasıl kazanabilirim ?
                          </button>
                        </div>

                        <div
                          id="collapseOne"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 1,
                          })}
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                           Rozet bir başarı göstergesidir. İlgili 
                           derslerdeki başarılarınıza göre rozetler 
                           seviyelendirir ve sen de seviyene göre bir 
                           rozet kazanabilirsin.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body" style={{ marginTop: "-30px" }}>
                    <div className="accordion">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <button
                            className={classNames("btn", {
                              collapsed: this.state.accordionSelected !== 2,
                            })}
                            onClick={() => {
                              this.setState({
                                accordionSelected:
                                  this.state.accordionSelected !== 2 ? 2 : 0,
                              });
                            }}
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Rozet satın alabilir miyim.
                          </button>
                        </div>

                        <div
                          id="collapseOne"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 2,
                          })}
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                           Rozetler sadece yukarıda açıklanan durumlarda 
                           kazanılabilir. Rozet satışı yapılmamaktadır.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-header" onClick={() => this.collapse(6)}>
                  <div className="card-header-icon">
                    <FontAwesomeIcon icon={faBug} />
                  </div>

                  <h5 className="title">Sistem Sorunları</h5>

                  <span
                    className={classNames("collapse-button", {
                      "rotate-180": selected === 6,
                    })}
                  >
                    <Icon icon="iChevronDown" />
                  </span>
                </div>

                <div
                  className={classNames("collapse", {
                    show: selected === 6,
                  })}
                >
                  <div className="card-body" style={{ marginTop: "-30px" }}>
                    <div className="accordion">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <button
                            className={classNames("btn", {
                              collapsed: this.state.accordionSelected !== 1,
                            })}
                            onClick={() => {
                              this.setState({
                                accordionSelected:
                                  this.state.accordionSelected !== 1 ? 1 : 0,
                              });
                            }}
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Sipariş oluşturamıyorum
                          </button>
                        </div>

                        <div
                          id="collapseOne"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 1,
                          })}
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Öncelikli olarak kart bilgilerini doğru 
                            girdiğinden emin ol. Desteğe ihtiyaç duyarsan; 
                            destek@tongucmagaza.com e-posta adresine mesaj 
                            göndererek veya Hesabım altındaki Yardım sayfasında 
                            bulunan iletişim formu üzerinden bize iletebilirsin.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingTwo">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 2,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 2 ? 2 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Ödeme aşamasında sorun yaşıyorum
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseTwo"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 2,
                          })}
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Öncelikli olarak kart bilgilerini doğru girdiğinden 
                            emin ol. Desteğe ihtiyaç duyarsan; destek@tongucmagaza.com 
                            e-posta adresine mesaj göndererek veya Hesabım altındaki 
                            Yardım sayfasında bulunan iletişim formu üzerinden bize iletebilirsin.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingThree">
                          <h2 className="mb-0">
                            <button
                              className={classNames("btn", {
                                collapsed: this.state.accordionSelected !== 3,
                              })}
                              onClick={() => {
                                this.setState({
                                  accordionSelected:
                                    this.state.accordionSelected !== 3 ? 3 : 0,
                                });
                              }}
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Video izleyemiyorum
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseThree"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 3,
                          })}
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Sitede yaşadığın tüm teknik sorunlar ile ilgili 
                            olarak destek@tongucmagaza.com e-posta adresine 
                            mesaj göndererek veya Hesabım altındaki Yardım 
                            sayfasında bulunan iletişim formu üzerinden 
                            bize iletebilirsin.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-header" onClick={() => this.collapse(11)}>
                  <div className="card-header-icon">
                    <FontAwesomeIcon icon={faThumbsUp} />
                  </div>

                  <h5 className="title">Teşekkür</h5>

                  <span
                    className={classNames("collapse-button", {
                      "rotate-180": selected === 11,
                    })}
                  >
                    <Icon icon="iChevronDown" />
                  </span>
                </div>

                <div
                  className={classNames("collapse", {
                    show: selected === 11,
                  })}
                >
                  <div className="card-body" style={{ marginTop: "-30px" }}>
                    <div className="accordion">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <button
                            className={classNames("btn", {
                              collapsed: this.state.accordionSelected !== 1,
                            })}
                            onClick={() => {
                              this.setState({
                                accordionSelected:
                                  this.state.accordionSelected !== 1 ? 1 : 0,
                              });
                            }}
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Bir teşekkürü hak ettiniz! :)
                          </button>
                        </div>

                        <div
                          id="collapseOne"
                          className={classNames("collapse", {
                            show: this.state.accordionSelected === 1,
                          })}
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Her türlü görüş ve önerini destek@tongucmagaza.com e-posta 
                            adresine mesaj göndererek veya Hesabım altındaki 
                            Yardım sayfasında bulunan iletişim formu üzerinden 
                            bize iletebilirsin.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3 className="fs-18 color-484848 font-weight-600 mb-2">
          Kargom Nerede?
        </h3>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 p-xs-0">
              <div className="card cargo-tracking mb-3" id="cargo-tracking">
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="shipment">Sipariş Numarası</label>
                    <div className="d-flex">
                      <input
                        type="text"
                        pattern="\d*"
                        id="shipment"
                        name="shipment"
                        className={classNames(
                          "form-control h-100 mr-3 border-radius-8",
                          isEmpty ? "is-invalid" : ""
                        )}
                        onChange={this.handleChange}
                      />
                      <button
                        className="btn btn-primary h-100 fs-16 font-weight-bold border-radius-12"
                        onClick={this.handleSearch}
                      >
                        Ara
                      </button>
                    </div>
                    {isEmpty && (
                      <div className="invalid-feedback">
                        Lütfen sipariş numarınızı giriniz.
                      </div>
                    )}
                  </div>
                  {order && (
                    <div className="row">
                      <div className="col-12 col-md-8 p-0">
                        <div className="alert alert-success">
                          {`${order.id} nolu siparişiniz ${
                            isCanceled
                              ? "iptal edilmiştir."
                              : deliveries[statusHistory.length - 1].title
                          }`}
                          {statusHistory.length === 3 && (
                            <>
                              <span>Kargo detayını görmek için</span>{" "}
                              <a
                                href={order.trackUrl + order.trackNumber}
                                target="_blank"
                              >
                                tıklayınız
                              </a>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {invalid && (
                    <div className="row">
                      <div className="col-12 col-md-8 p-0">
                        <div className="alert alert-danger">
                          {`${this.state.search} nolu siparişiniz bulunamadı.`}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <SupportFrom orders={orders} />
              <div className="row">
                <div className="col-12 col-md-6 p-xs-0">
                  <div className="card support-contact">
                    <div className="card-body d-flex align-items-center">
                      <div className="h-100 w-25 text-center">
                        <FontAwesomeIcon
                          icon={faPhoneVolume}
                          size="4x"
                          className="text-blue"
                          style={{
                            position: "relative",
                            transform: "rotate(-45deg)",
                          }}
                        />
                      </div>

                      <div>
                        <h5 className="card-title text-blue fs-16 font-weight-600">
                          Destek Hattı: 0 212 963 45 15
                        </h5>
                        <p className="card-text fs-12 font-weight-600 color-484848">
                          7 Gün 09:00 - 22:00
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Support.propTypes = {};

const mapStateToProps = ({ AccountReducer }) => ({
  orders: AccountReducer.orders,
  user: AccountReducer.user,
});

const mapDispatchToProps = {
  getOrders: actions.getOrders,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Support));
