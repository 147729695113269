import React from "react";
const FilterClasses = ({
  taxonomy,
  prefix,
  filters,
  applyFilter,
  isFilterModal,
  selectedBrandId,
  showBrandQuantity,
  firstFilteredTaxonomyId,
}) => {
  function renderTaxonName(name, i) {
    // if (name.indexOf("sınıf")) {
    //   const classes = name.split(".");
    //   if (classes[0].length < 2) {
    //     return (
    //       <h5 className="checkbox-label d-flex align-items-center">
    //         <img
    //           src={require(`../../assets/images/classes/${classes[0]}.png`)}
    //           style={{ height: 21, width: 21, marginRight: 2 }}
    //         />
    //         {name.slice(1)}
    //       </h5>
    //     );
    //   } else {
    //     return (
    //       <h5 className="checkbox-label d-flex align-items-center">
    //         <img
    //           src={require(`../../assets/images/classes/${classes[0]}.png`)}
    //           style={{ height: 21, width: 21, marginRight: 2 }}
    //         />
    //         {name.slice(2)}
    //       </h5>
    //     );
    //   }
    // } else {
    return <h5 className="checkbox-label d-flex align-items-center">{name}</h5>;
    // }
  }

  return taxonomy.taxons.length &&
    taxonomy.taxons.some((taxon) => taxon.count) &&
    (!showBrandQuantity ||
      taxonomy.taxons.some(
        (x) => x.brands && x.brands.some((b) => b.id === selectedBrandId)
      )) ? (
    <div key={taxonomy.id} className="sidebar--item">
      {!isFilterModal && <h6>{taxonomy.name}</h6>}
      <div className="sidebar--item-detail classes">
        {taxonomy.taxons
          .filter(
            (m) =>
              m.count > 0 ||
              taxonomy.taxons.some((t) => filters.some((f) => f.id == t.id))
          )
          .map(
            (taxon, i) =>
              (!showBrandQuantity ||
                taxon.brands.some((x) => x.id === selectedBrandId)) && (
                <div
                  key={taxon.id}
                  className="form-group form-check brand-checkbox"
                >
                  <input
                    onChange={(e) => applyFilter(e, false)}
                    checked={filters?.some((filter) => filter.id == taxon.id)}
                    value={taxon.id}
                    type="checkbox"
                    id={`taxon-${taxon.id}${prefix ? `-${prefix}` : ""}`}
                    name={taxon.name}
                  />
                  <label
                    htmlFor={`taxon-${taxon.id}${prefix ? `-${prefix}` : ""}`}
                    className="d-flex flex-column ml-2 mb-0"
                  >
                    {renderTaxonName(taxon.name, i)}
                  </label>
                </div>
              )
          )}
      </div>
    </div>
  ) : null;
};
export default FilterClasses;
