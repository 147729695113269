import React from "react";
import Icon from "../Icon/Index";

const Filters = ({items, onRemove, onClearAll}) => {
    return (
        items.length > 0 && (
        <>
            <div className="card-body p-0 brand-show-filter mb-4">
                <div className="d-flex flex-wrap flex-row align-center">
                    {items.map((filter) => (
                        <div className="brand-show-filter-item-btn" key={filter.id}>
                            {filter.name}
                            <button
                                onClick={() => onRemove(filter)}
                                className="close-filter">
                                    <Icon icon={"iCloseFilter"}/>
                            </button>
                        </div>
                    ))}

                    <button
                        type="button"
                        className="filter-clear-all"
                        onClick={onClearAll}>
                        <Icon className="mr-2" icon={"iFilterDelete"}/>
                        Filtreleri Temizle
                    </button>
                </div>
            </div>
        </>
        )
    );
};

export default Filters;
