import React, { PureComponent } from "react";
import {
  Preview,
  ToogleButton,
  Modal,
  ScoreBadge,
  InformationModal,
} from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import Icon from "../../components/Icon/Index";
import { toast } from "react-toastify";
import {
  MaxReviewFileSize,
  MaxReviewFileSizeMB,
} from "../../services/constants";

const FileLimitMessage =
  "En fazla " +
  MaxReviewFileSizeMB +
  "MB boyutunda dosya yükleyebilirsin. Dosyayı küçültüp tekrar denemeyi unutma.";

const ModalContent = (props) => (
  <Modal dialogClassName="modal-lg" {...props}>
    <div className="modal-header">
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={props.onCloseClick}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">
      <div className="card mb-4 shadow-sm">
        <svg
          className="bd-placeholder-img card-img-top"
          width="100%"
          height="225"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
          role="img"
          aria-label="Placeholder: Thumbnail"
        >
          <title>Placeholder</title>
          <rect width="100%" height="100%" fill="#55595c" />
          <text x="50%" y="50%" fill="#eceeef" dy=".3em">
            Thumbnail
          </text>
        </svg>
      </div>
    </div>
    <div className="modal-footer">
      <button
        type="button"
        className="btn btn-primary"
        onClick={props.onCloseClick}
      >
        Tamam
      </button>
    </div>
  </Modal>
);

const VideoGain = ({ onShow, activityType }) => (
  <>
    <div className="d-flex flex-row justify-content-center ml-2">
      <ScoreBadge
        icon="point"
        title={`+${activityType.Score}  Puan`}
        className="badge-score mr-2"
        value={activityType.Score}
        productDetailBadge
      />
      <ScoreBadge
        icon="coin"
        title={`+${activityType.Coins}  Jeton`}
        className="badge-coin"
        value={activityType.Coins}
        productDetailBadge
      />
    </div>
  </>
);

class PlayList extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inputFileLabel: "Yeni Video Yükle",
      showModal: false,
      errors: {
        videoFile: false,
        videoLink: false,
      },
      uploading: false,
    };
    this.videoLink = React.createRef();
    this.videoFile = React.createRef();
  }

  fileInputChange = () => {
    if (
      this.videoFile.current.files[0] &&
      this.videoFile.current.files[0].size > MaxReviewFileSize
    ) {
      toast.error(FileLimitMessage);
    }
    this.setState({
      inputFileLabel:
        this.videoFile.current.files[0] && this.videoFile.current.files[0].name,
    });
  };

  sendVideoReview = () => {
    if (
      this.videoFile.current.files[0] &&
      this.videoFile.current.files[0].size > MaxReviewFileSize
    ) {
      toast.error(FileLimitMessage);
      return;
    }

    if (!this.props.user.id) {
      this.props.loginRequired(true);

      return;
    }

    const errors = {
      videoFile: !this.videoFile.current.value.length,
      videoLink: !this.videoLink.current.value.length,
    };

    if (errors.videoFile && errors.videoLink) {
      this.setState({ errors });

      return;
    }

    const review = {
      userId: this.props.user.id,
      productId: this.props.product.id,
      url: this.videoLink.current.value,
      tempUrl: "",
      insertDate: new Date(),
    };

    errors.videoFile = false;
    errors.videoLink = false;

    this.setState(
      { errors, inputFileLabel: "Yeni Video Yükle", uploading: true },
      async () => {
        const { error } = await this.props.onSubmit(
          review.productId,
          this.videoFile.current.files[0],
          review
        );
        if (error) {
          this.setState({ uploading: false });
          return;
        }
        //this.videoFile.current.value = "";
        //this.videoLink.current.value = "";
      }
    );
  };

  componentDidUpdate(prevProps, prevState, uploading) {
    if (
      this.state.uploading === true &&
      prevState.uploading === this.state.uploading
    ) {
      this.setState({ uploading: false });
    }
  }

  render() {
    const {
      onPlay,
      playing,
      show,
      url,
      modalShow,
      onShow,
      onClose,
      onToggle,
      videos,
      activityType,
    } = this.props;

    const settings = {
      dots: false,
      slidesToShow: 2,
      slidesToScroll: 1,
      initialSlide: 2,
      swipeToSlide: true,
      arrows: false,
      infinite: videos.length > 2,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: videos.length > 2,
            dots: false,
          },
        },

        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
      customPaging: function (i) {
        return <div className="slide-paging" />;
      },
    };

    return (
      <>
        <div className="playlist--container">
          <div className="row">
            {/* {!show && (
            <div className="d-none-md d-none-sm d-none-lg mb-3 w-100">
              <VideoGain onShow={onShow} activityType={activityType} />
            </div>
          )} */}
            <div
              className={`col-12 col-lg-12 col-md-12 playlist-container ${
                this.props.videos.length > 0 ? "active" : ""
              }`}
            >
              <div className="playlist-text">
                {this.props.videos.length > 0 ? (
                  <>
                    Arkadaşlarına yardım etmek hem de puan ve jeton kazanmak
                    için video yükle
                  </>
                ) : (
                  <>
                    İnceleme videomuz yok. Hem arkadaşlarına yardım etmek hem de
                    puan ve jeton kazanmak için video yükle
                  </>
                )}
              </div>
              <div className="playlist-title">Video yükle kazan</div>

              <VideoGain onShow={onShow} activityType={activityType} />
              <ToogleButton
                className="btn-playlist"
                title="İnceleme Videosu Ekle"
                show={show}
                onToggle={onToggle}
                label={
                  <>
                    <div className="d-flex flex-row justify-content-center align-items-center px-3">
                      <img
                        className="mr-2"
                        width="24"
                        height="24"
                        src={require("../../assets/images/notificaitons/icon-add-video.svg")}
                        alt="video"
                      />
                      <span className="fs-12 text-capitalize">
                        {this.state.uploading ? (
                          <text>Video Yükleniyor...</text>
                        ) : (
                          <text>İnceleme Videosu Ekle</text>
                        )}
                      </span>
                    </div>
                  </>
                }
              >
                <form className="w-100 mb-4">
                  <div className="row">
                    <div className="col-12 col-lg-6 col-sm-12 mb-4">
                      <div className="custom-file">
                        <div className="form-group">
                          <label htmlFor="inputGroupFile01">
                            Yeni Video Yükle
                          </label>
                          <div className="input-group mb-3">
                            <input
                              id="inputGroupFile01"
                              type="file"
                              className="custom-file-input"
                              placeholder="Yeni Video Yükle"
                              accept="video/*"
                              ref={this.videoFile}
                              style={{ height: 48 }}
                              onChange={this.fileInputChange}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="inputGroupFile01"
                            >
                              {this.state.inputFileLabel}
                            </label>
                          </div>
                          {this.state.errors.videoFile ? (
                            <>
                              <small
                                id="commentText_help"
                                className="form-text text-muted col-sm-12"
                              />
                              <div className="invalid-feedback">
                                Video boş olamaz.
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-lg-6 col-sm-12">
                      <div className="form-group playlist-custom-input">
                        <label htmlFor="basic-url">Bağlantı Paylaş</label>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <FontAwesomeIcon icon="link" />
                            </span>
                          </div>
                          <input
                            id="basic-url"
                            type="text"
                            className="form-control"
                            placeholder="https://www.youtube.com/watch?v=<video>"
                            ref={this.videoLink}
                          />
                        </div>
                        {this.state.errors.videoLink ? (
                          <>
                            <small
                              id="commentText_help"
                              className="form-text text-muted col-sm-12"
                            />
                            <div className="invalid-feedback">
                              Bağlantı boş olamaz.
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="d-flex align-items-center">
                        <button
                          type="button"
                          className="btn btn-playlist mt-0"
                          onClick={this.sendVideoReview}
                        >
                          <div className="d-flex flex-row justify-content-center align-items-center px-3">
                            <img
                              className="mr-2"
                              width="16"
                              height="16"
                              src={require("../../assets/images/notificaitons/Video-white.svg")}
                              alt="video"
                            />
                            <span className="fs-12 text-capitalize">
                              {this.state.uploading ? (
                                <text>Video Yükleniyor...</text>
                              ) : (
                                <text>İnceleme Videosu Ekle</text>
                              )}
                            </span>
                          </div>
                        </button>
                        {/* <VideoGain onShow={onShow} activityType={activityType} /> */}
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => this.setState({ showModal: true })}
                        >
                          <Icon icon="iQuestion" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </ToogleButton>

              {/* {!show && (
              <>
                <div className="d-none-xs">
                  <VideoGain onShow={onShow} activityType={activityType} />
                </div>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => this.setState({ showModal: true })}
                >
                  <Icon icon="iQuestion" />
                </button>
              </>
            )} */}
            </div>
          </div>
          <ModalContent show={modalShow} onCloseClick={onClose} />
          {playing && window.location.href.indexOf("localhost") === -1 && (
            <div className="player-container mt-4">
              <ReactPlayer
                url={url}
                className="video-player w-100 h-100"
                playing
              />
            </div>
          )}

          <div className="row">
            <div className="col-12 p-0">
              <Slider {...settings} className="product-detail-slide">
                {videos.map((video) => (
                  <div className="card playlist-video-card" key={video}>
                    <div className="card-body">
                      <button
                        type="button"
                        className="btn btn-link w-100"
                        onClick={() => onPlay(video.url)}
                      >
                        <Preview url={video.url} light key={video.url} />
                      </button>
                      <div className="playlist-video-title">
                        <b>İncelemeyi yapan:</b> {video.userName}
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div className="row">
            <div className="col-12 p-0">
              <div className="product-detail-slide-mobile">
                {videos.map((video, i) => (
                  <div className="card playlist-video-card mb-3" key={i}>
                    <div className="card-body">
                      <button
                        type="button"
                        className="btn btn-link w-100"
                        onClick={() => onPlay(video.url)}
                      >
                        <Preview url={video.url} light key={video.url} />
                      </button>
                      <div className="playlist-video-title">
                        <b>İncelemeyi yapan:</b> {video.userName}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <InformationModal
          show={this.state.showModal}
          onCloseClick={() => this.setState({ showModal: false })}
          list={[
            "inceleme_videosu_ADIM1.jpg",
            "inceleme_videosu_ADIM2.jpg",
            "inceleme_videosu_ADIM3.jpg",
          ]}
        />
      </>
    );
  }
}

export default PlayList;
