import React, { PureComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

class Numeric extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      disabled: props.disabled ? true : false,
      value: props.defaultValue || 1,
      maxValue: props.maxValue || 999,
    };
  }

  onBlur = () => {
    let newValue = this.state.value;
  
    if (!newValue || newValue.length < 1) {
      newValue = '1';
    }
    this.setState({ value : newValue });
  };

  onChange = (event) => {
    let value = event.target.value;
    let maxValue = this.state.maxValue;

    if (!isNaN(value) && this.state.value !== value) {
      if (value !== "" && value < 1) {
        value = 1;
      }
      if (value !== "" && value > maxValue) value = maxValue;
      this.setState({ value }, () => {
        if (this.props.onChange) {
          this.props.onChange(value);
        }
      });
    }
  };
  increase = () => {
    let maxValue = this.state.maxValue;
    let currentValue = parseInt(this.state.value) + 1;
    if (currentValue > maxValue) {
      currentValue = maxValue;
    }
    this.setState({ value: currentValue }, () => {
      if (this.props.onChange) {
        this.props.onChange(currentValue);
      }
    });
  };

  decrease = () => {
    let currentValue = this.state.value > 1 ? this.state.value - 1 : 1;
    if (currentValue < 1) {
      currentValue = 1;
    }
    this.setState({ value: currentValue }, () => {
      if (this.props.onChange) {
        this.props.onChange(currentValue);
      }
    });
  };

  render() {
    return (
      <div
        className="input-group numeric-input"
        style={{
          width: this.props.statsErrorBox ? 256 : 128,
          background: "#f4f4f4",
          height: window.innerWidth > 568 ? "40px" : "40px",
          display: "flex",
          alignItems: "center",
          borderRadius: 8,
        }}
      >
        <div
          className={`input-group-prepend ${
            this.props.statsErrorBox ? "d-none" : ""
          }`}
          style={{ borderRadius: 4 }}
        >
          <button
            disabled={this.state.disabled}
            className="btn btn-light"
            type="button"
            onClick={this.decrease}
          >
            <FontAwesomeIcon icon={faMinus} />
          </button>
        </div>
        <input
          disabled={this.state.disabled}
          type="text"
          maxLength="3"
          className="form-control"
          value={this.state.value}
          onChange={this.onChange}
          style={{
            background: this.props.statsErrorBox ? "transparent" : "white",
            textAlign: "center",
            borderRadius: this.props.statsErrorBox ? 8 : 5,
            fontWeight: "600",
            color: "#484848",
            fontSize: "18px",
            height: "32px",
          }}
          onBlur={this.onBlur}
        />
        <div
          className={`input-group-append ${
            this.props.statsErrorBox ? "d-none" : ""
          }`}
        >
          <button
            disabled={this.state.disabled}
            className="btn btn-light"
            type="button"
            onClick={this.increase}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>
    );
  }
}

export default Numeric;
