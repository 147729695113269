import React from "react";
import CloseButton from "../Button/Close";
import Modal from "./Index";
import dersImages from "../../assets/images/dersler";
import { tatsApiCall } from "../../services/api";
import { HTTP } from "../../services/constants";

const ResetQuiz = (props) => {
  const resetQuiz = () => {
    tatsApiCall(
      "/StudentSigns/delTest",
      {
        testID: props.quiz.testID,
      },
      null,
      HTTP.POST,
      null,
      true
    ).then((res) => {
      props.toggleResetQuizModal(null, true);
    });
  };

  return (
    <Modal
      className="resetQuizModal modal-container non-modal-height"
      show={props.show}
      onCloseClick={props.toggleResetQuizModal}
    >
      <span className="line" />
      <CloseButton onClick={props.toggleResetQuizModal} />
      <div className="modal-body">
        <div className="quiz-inner">
          <div className="quiz-img-wrapper">
            <img src={dersImages.getIcon(props.quiz.bransAd)} alt="..." />
            <div className="success-emoji-wrapper">
              {props.quiz.success ? (
                <img
                  src={require("../../assets/images/emoji_1.svg")}
                  alt="..."
                />
              ) : (
                <img
                  src={require("../../assets/images/emoji_2.svg")}
                  alt="..."
                />
              )}
            </div>
          </div>
          <div className="quiz-title">{props.quiz.testAdi}</div>
        </div>
        <div className="quiz-content">
          <p>
            Test üzerinde yaptığınız tüm işlemleri temizlemek istiyor musunuz?
          </p>
          <button className="btn btn-submit" onClick={resetQuiz}>
            Temizle
          </button>
          <button className="btn btn-back" onClick={props.toggleResetQuizModal}>
            Vazgeç
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default ResetQuiz;
