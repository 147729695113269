import { useEffect } from "react";
import { withRouter } from "react-router";
import { useParams } from "react-router-dom";

function RedirectToFilterProduct(props) {
    const params = useParams();
    const restArray = params.rest ? params.rest.split('/') : [];
    const paramValues = Object.values(restArray).join(',');

    useEffect(() => {
        props.history.push(`/urunler/?t=redirect,${paramValues}`);
    }, [props.history]);

    return (null);
}

export default withRouter(RedirectToFilterProduct);