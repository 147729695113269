import React, { Component } from "react";
import Slider from "react-slick";
import Modal from "./Index";
import CloseButton from "../Button/Close";
//import PropTypes from 'prop-types';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  className: "",
};

class Information extends Component {
  render() {
    return (
      <Modal
        {...this.props}
        className="modal-container information-modal non-modal-height"
      >
       {/*  <div className="modal-header bt-dedede:50">
          <div className="modal-title text-uppercase fs-20 color-484848 font-weight-600">
            Text Label
          </div> 
        </div>*/}
        <CloseButton onClick={this.props.onCloseClick} />

        <div className="modal-body" id="bookModalSlider">
          <Slider {...settings}>
            {this.props.list &&
              this.props.list.map((image, index) => (
                <div className="h-100" key={index}>
                  <img
                    src={require("../../assets/images/images/" + image)}
                    className="img-fluid h-100 w-100 border-radius-8 carousel-img"
                    alt=""
                  />
                </div>
              ))}
          </Slider>
        </div>
        <div className="modal-footer fs-14 font-weight-600 color-484848 d-flex flex-column flex-sm-row justify-content-between">
          <div>
            Halen sorun yaşıyorsan 0 212 963 35 35 nolu telefondan bize
            ulaşabilirsin.
          </div>
          <div className="d-block w-100">
            <button
              type="button"
              className="btn btn-primary fs-14 font-weight-600 text-center text-capitalize mt-3 mt-sm-0 d-block float-right d-sm-inline-block"
              onClick={this.props.onCloseClick}
            >
              Tamam
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

Information.propTypes = {};

export default Information;
