import React, { PureComponent } from "react";
import Modal from "../../components/Modal/Index";
import { BaseForm } from "../../components";
import FormSchemas from "../../variables/FormSchemas";
import CloseButton from "../../components/Button/Close";

class ResendMailConfirm extends PureComponent {
    state = { formData: {} };

    resendMailConfirm = (email) => {
        this.props.resendMailConfirm(email);
    };

    render() {
        return (
            <Modal
                className="forgotPasswordModal modal-container non-modal-height"
                show={this.props.show}>
                <div className="modal-header bg-0 mx-3">
                    <h5 className="modal-title font-weight-600 color-484848">
                        Aktivasyon e-posta'sını tekrar gönder
                  </h5>
                    <CloseButton onClick={this.props.onCloseClick} />
                </div>
                <div className="modal-body">
                    {this.props.show && (
                        <BaseForm
                            schema={FormSchemas.forgotPassword.schema}
                            uiSchema={FormSchemas.forgotPassword.uiSchema}
                            formData={this.state.formData}
                            onSubmit={this.resendMailConfirm}
                            onClick={this.props.onClick}>
                            <div className="d-flex ">
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-block py-2 fs-13 border-radius-8 flex-fill">
                                    Gönder
                              </button>
                            </div>
                        </BaseForm>
                    )}
                </div>
            </Modal>
        );
    }
}
export default ResendMailConfirm;
