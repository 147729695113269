import React, { PureComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import * as actions from "../../routes/actions/Account";
import AddressForm from "./AddressForm";
//import PropTypes from 'prop-types';


const AddressView = ({
  onClick,
  address,
  deleteAddress,
  showAddressFormUpdate,
}) => (
  <div className="card d-block w-100 h-100">
    <div className="card-header border-0 d-flex align-items-center justify-content-between">
      <h6 className="card-title m-0 fs-16 color-484848 font-weight-bold">
        {address.title}
      </h6>
      <div className="btn-group btn-group-sm">
        <button
          className="btn btn-link color-6e87ff my-0 p-0 fs-13 font-weight-600 text-capitalize"
          onClick={() => showAddressFormUpdate(address)}
        >
          Düzenle
        </button>
        <button
          className="btn btn-link color-6e87ff my-0 p-0 ml-2 fs-13 font-weight-600 text-capitalize"
          onClick={() => deleteAddress(address)}
        >
          Sil
        </button>
      </div>
    </div>
    <div className="card-body pt-2 fs-14 font-weight-500">
      <div className="card-text m-0 p-0 color-4a4a4a">
        <div>
          {address.firstname} {address.lastname}
        </div>
      </div>
      <div className="card-text color-4a4a4a">
        <span className="mr-1">{address.lines}</span>
        {address.town ? address.town.name : ""}/
        {address.city ? address.city.name : ""}
        <br />
        {address.addressType === 1 && <div>Vergi No: {address.taxNumber}</div>}
        <br />
        <div>{address.mobile}</div>
      </div>
    </div>
  </div>
);

const AddView = ({ onClick }) => (
  <div className="card d-block w-100 h-100 p-0">
      <button className="btn btn-light w-100 h-100" onClick={onClick}>
        <FontAwesomeIcon icon={faPlus} /> Yeni Adres Ekle
      </button>
  </div>
);

const emptyAddress = { addressId: 0, addressType: 0 };

class Addresses extends PureComponent {
  state = { show: false, title: "", address: emptyAddress };

  componentDidMount() {
    if (!this.props.addresses.length) {
      this.props.getAddresses();
    }
  }

  showAddressFormUpdate = (address) => {
    this.setState({ address, show: true, title: address.title });
  };

  createAddress = async (address) => {
    const { success, error } = await this.props.createAddress(address);

    this.setState({ address: {}, show: false, title: "" }, () => {
      if (success) {
        toast.success(`${address.title} eklendi`);
      } else {
        if (error.message) toast.error(error.message);
      }
    });
  };

  updateAddress = async (address) => {
    const { success, error } = await this.props.updateAddress(address);

    this.setState({ address: {}, show: false, title: "" }, () => {
      if (success) {
        toast.success(`${address.title} güncellendi`);
      } else {
        if (error.message) toast.error(error.message);
      }
    });
  };

  deleteAddress = async (address) => {
    const confirmed = window.confirm(
      `(${address.title}) adresi silinecek onaylıyor musunuz?`
    );

    if (confirmed) {
      const { success, error } = await this.props.deleteAddress(address);

      this.setState({ address: {}, show: false, title: "" }, () => {
        if (success) {
          toast.success(`${address.title} silindi`);
        } else {
          toast.error(error.message);
        }
      });
    }
  };

  render() {
    const user =
      this.props.user.tckn === "0"
        ? Object.assign({}, this.props.user, { tckn: "" })
        : this.props.user;
    return (
      <>
        <h3 className="fs-18 color-484848 font-weight-600">Adres Bilgilerim</h3>
        <div className="container-fluid p-xs-0">
          <div className="row address-box">
            {this.props.addresses.map((address) => (
              <div className="col-12 col-md-6 mt-2 pb-4" key={address.id}>
                <AddressView
                  address={address}
                  showAddressFormUpdate={this.showAddressFormUpdate}
                  deleteAddress={this.deleteAddress}
                />
              </div>
            ))}

            <div className="col-12 col-md-6 mt-2 pb-4">
              <AddView
                onClick={() =>
                  this.setState({
                    address: Object.assign({}, emptyAddress),
                    show: true,
                    title: "Yeni Adres Kaydet",
                  })
                }
              />
            </div>
          </div>
        </div>
        <AddressForm
          createAddress={this.createAddress}
          updateAddress={this.updateAddress}
          address={this.state.address}
          show={this.state.show}
          isDemo={user.demo}
          onCloseClick={() => this.setState({ show: false })}
          title={this.state.title}
        />
      </>
    );
  }
}

Addresses.propTypes = {};

const mapStateToProps = ({ AccountReducer }) => ({
  addresses: AccountReducer.addresses,
  cities: AccountReducer.cities,
  towns: AccountReducer.towns,
  user: AccountReducer.user,
});

const mapDispatchToProps = {
  getAddresses: actions.getAddresses,
  createAddress: actions.createAddress,
  updateAddress: actions.updateAddress,
  deleteAddress: actions.deleteAddress,
  getCities: actions.getCities,
  getTowns: actions.getTowns,
};

export default connect(mapStateToProps, mapDispatchToProps)(Addresses);
