import React, { Component } from "react";
//import PropTypes from "prop-types";
import { SchoolSelect } from "../..";

class School extends Component {
    handleChange = (selected) => {
        const { name, onChange } = this.props;
        onChange(name, selected.value);
    };

    render() {
        const { args, valid, value, readOnly } = this.props;
        return (<>
            <SchoolSelect
                onChange={this.handleChange}
                value={value}
                valid={valid}
                cityId={args.city ? args.city.id : null}
                readOnly={readOnly}
            />
        </>
        );
    }
}

School.propTypes = {};

export default School;
