import React, { Component } from "react";
//import PropTypes from 'prop-types';
import { Breadcrumb } from "../../components";
import { setPrerenderTimeout } from "../../helpers";
import { connect } from "react-redux";
import * as actions from "../../routes/actions/Contact";

const breadcrumbs = [
  {
    title: "Anasayfa",
    url: "/",
  },
  {
    title: "İletişim",
    active: true,
  },
];

class Contract extends Component {
  componentDidUpdate() {
    setPrerenderTimeout();
  }
  componentDidMount() {
    this.props.getContact();
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 p-xs-0">
            <Breadcrumb items={breadcrumbs} />
          </div>
          <div className="col-12 p-xs-0">
            <div className="card">
              <div className="card-body">
                <h1 className="fs-20 font-weight-500 mt-2 mb-2 color-484848">
                  İletişim
                </h1>
                <div className="text-center">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3006.8367989220887!2d29.000507616135064!3d41.09441232206748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab67e817cd32f%3A0xe527bee8a6360578!2sDo%C4%9Fru%20Kaynak!5e0!3m2!1str!2str!4v1625750125525!5m2!1str!2str"
                    height={340}
                    frameBorder={0}
                    style={{ border: 0 }}
                    title="map"
                    allowFullScreen
                    className="w-100"
                  ></iframe>
                </div>
                <p className="card-text color-919191 fs-15">
                  Tonguç Mağaza'ya aşağıdaki iletişim adreslerinden kolayca
                  ulaşabilirsiniz.
                </p>
                <p className="card-text color-484848 fs-16">
                  "Müşteri Hizmetleri" servisimiz haftanın çalışma saatlerimiz
                  süresince gönderdiğiniz sorulara en hızlı şekilde yanıt verir.
                </p>
                <div className="d-flex flex-wrap">
                  <div className="w-100 w-sm-50 mb-4">
                    <h6 className="card-title fs-17 mx-0 my-1">
                      Şirket Bilgileri
                    </h6>
                    <p className="card-text mb-1 fs-15">Tonguç Mağaza</p>
                  </div>
                  <div className="w-100 w-sm-50 mb-4">
                    <h6 className="card-title fs-17 mx-0 my-1">Telefon</h6>
                    <p className="card-text mb-1 fs-15">
                      <a href={`tel:+9${this.props.contact.phoneNumber}`}>
                        {this.props.contact.phoneNumber}
                      </a>
                    </p>
                  </div>
                  <div className="w-100 w-sm-50 mb-4">
                    <h6 className="card-title fs-17 mx-0 my-1">Adres</h6>
                    <p className="card-text fs-15">
                      {this.props.contact.address}
                    </p>
                  </div>
                  <div className="w-100 w-sm-50 mb-4">
                    <h6 className="card-title fs-17 mx-0 my-1">
                      Çalışma Saatlerimiz
                    </h6>
                    <p
                      className="card-text fs-15">
                      7 Gün 09:00 - 22:00
                    </p>
                  </div>
                  <div className="w-100 w-sm-50 mb-4">
                    <h6 className="card-title fs-17 mx-0 my-1">E-Posta</h6>
                    <p className="card-text fs-15">{this.props.contact.emailAddress}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Contract.propTypes = {};

const mapStateToProps = ({ ContactReducer }) => ({
  contact: ContactReducer.contact,
});

const mapDispatchToProps = {
  getContact: actions.getContact,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contract);
