import actionTypes from "../../actions/Settings/types";

export const initialState = {
    checkMailVerification: true,
    bypassDeskMode: false,
    enableNotifyMe: true
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SETTINGS:
            return { ...state, ...action.payload, lastSettingsUpdateDate: new Date() };
        default:
            return state;
    }
};
