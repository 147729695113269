import React, {Component} from "react";
import {Switch, Route} from "react-router";
import Pay3d from "../views/Payment/Pay3d";
import Result from "../views/Payment/Result";

class FrameLayout extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/pay3d/result" component={Result}/>
                <Route exact path="/pay3d/:id/:type" component={Pay3d}/>
            </Switch>
        );
    }
}

export default FrameLayout;