import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import TooltipTrigger from "react-popper-tooltip";
import { Link } from "react-router-dom";
import "react-popper-tooltip/dist/styles.css";

class Dropdown extends PureComponent {
  render() {
    const { children, title, ...props } = this.props;

    return (
      <TooltipTrigger
        placement="bottom"
        trigger="click"
        {...props}
        tooltip={({ tooltipRef, getTooltipProps }) => (
          <div
            {...getTooltipProps({
              ref: tooltipRef,
              className: "dropdown-menu show",
            })}
          >
            {/* <h5 className="text-custom-color ml-2">{title}</h5> */}
            <div className="d-flex flex-wrap">
              {this.props.taxonomy
                ? this.props.taxonomy.taxons.map((item, i) => (
                    <Link
                      // to={`/urunler?t=${item.id}`}
                      to={`${this.props.getMetaData(
                        this.props.taxonomy,
                        item
                      )}`}
                      className="btn btn-link"
                      key={item.id}
                      data-key={item.code}
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {item.name}
                    </Link>
                  ))
                : null}
            </div>
          </div>
        )}
      >
        {({ getTriggerProps, triggerRef }) => (
          <>
            <span
              {...getTriggerProps({
                ref: triggerRef,
                className: "trigger",
              })}
            >
              <button className="btn btn-link nav-link dropdown-toggle text-uppercase">
                {this.props.title}
              </button>
            </span>
            <span className="d-none">
              {this.props.taxonomy
                ? this.props.taxonomy.taxons.map((item, i) => (
                    <Link
                      // to={`/urunler?t=${item.id}`}
                      to={`${this.props.getMetaData(
                        this.props.taxonomy,
                        item
                      )}`}
                      className="btn btn-link"
                      key={item.id}
                      data-key={item.code}
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {item.name}
                    </Link>
                  ))
                : null}
            </span>
          </>
        )}
      </TooltipTrigger>
    );
  }
}

Dropdown.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Dropdown;
