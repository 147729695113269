import actionTypes from "../../actions/Content/types";

export const initialState = {
  pages: [],
  page: {},
  pageHistory: [],
  mainPage: {},
  headers: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PAGES:
      return { ...state, pages: action.payload };
    case actionTypes.GET_MYPAGES:
      return { ...state, pages: action.payload, headers: action.headers };
    case actionTypes.GET_MAIN_PAGE:
      return { ...state, mainPage: action.payload };
    case actionTypes.GET_BY_NAME:
      return { ...state, page: action.payload };
    case actionTypes.GET_HISTORY_BY_NAME:
      return { ...state, pageHistory: action.payload };
    case actionTypes.UPDATE_BY_NAME:
      return { ...state, page: action.payload };
    case actionTypes.CREATE_PAGE:
      return { ...state, pages: [action.payload, ...state.pages] };
    case actionTypes.PUBLISH_PAGE:
      if (action.payload.slug === "anasayfa")
        return { ...state, mainPage: Object.assign({}, action.payload) };
      else return { ...state, page: Object.assign({}, action.payload) };
    case actionTypes.SAVE_PAGE:
      return { ...state, page: Object.assign({}, action.payload) };
    case actionTypes.CHANGE_PAGE:
      return { ...state, page: Object.assign({}, action.payload) };
    case actionTypes.CHANGE_MAIN_PAGE:
      return { ...state, mainPage: Object.assign({}, action.payload) };
    case actionTypes.FILE_UPLOAD:
      return { ...state, uploaded: Object.assign({}, action.payload) };
    case actionTypes.DELETE_PAGE:
      return {
        ...state,
        pages: state.pages.filter(page => page.id !== action.payload.id)
      };
    case actionTypes.SEND_FOR_APPROVAL_PAGE:
      return { ...state, page: action.payload };
    case actionTypes.CONFIRM_PAGE:
      return { ...state, page: action.payload };
    case actionTypes.CANCEL_PAGE:
      return { ...state, page: action.payload };

    default:
      return state;
  }
};
