import React, { Component } from 'react'
import queryString from "query-string";
import {connect} from "react-redux";
import * as studyActions from "../../routes/actions/Study";

class SearchResult extends Component {


    getQueryString(location) {
        const search = queryString.parse(location);
        var queried =  search.k ? search.k : "";
        return queried;
    }

    componentDidMount() {
        const query = this.getQueryString(this.props.location.search);
        this.props.studySearch(query);
    }
    
      goToDetail = (item) => {
        const {history} = this.props;
        this.setState({
            showResultBox: false
        }, () => {
            if (item.Type === 'Book') {
                history.push({
                    pathname: `/dijitalkitap/book/lessons/${item.Id}`
                })
            } else if (item.Type === 'Quiz') {
                history.push({
                    pathname: `/dijitalkitap/book/lesson/subject/quiz/answers/${item.Id}`
                })
            }
        });
    };


    render() {
        return (
            <div class="card">
                <div class="card-header"></div>
                    <div class="card-body pt-0 fs-14" ><h3 style={{marginBottom : '25px', marginLeft : '5px'}} ><strong>Arama Sonuçları</strong></h3>
                    {
                    this.props.studySearchResult.map((item) => (
                    <div>
                    <a  
                        href="javascript:;"
                        onMouseDown={(e) => this.goToDetail(item)}
                        key={item.id}
                        className="result-item pl-1"
                    >{item.Type === 'Quiz' ? item.BookName + ' -> ' + item.Name : item.Name}
                    </a>
                    <hr/>
                    </div>
                    ))
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ StudyReducer}) => ({
    studySearchResult: StudyReducer.searchResult
});

const mapDispatchToProps = {
    studySearch: studyActions.search
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchResult);
