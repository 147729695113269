import React from "react";
//import PropTypes from 'prop-types';
import draftToHtml from "draftjs-to-html";

const placeholder = {
  title: "Buraya başlık ekleyin",
  text: "Buraya metin ekleyin",
  src:
    "https://cdn.dogrukaynak.com/images/advertorial1.png",
  buttons: [{ title: "Buraya etiket ekleyin" }],
  orientation: "right"
};

const Highlight = ({ title, text, src, label, url, orientation }) => {
  const content = { __html: text ? draftToHtml(text) : placeholder.text };
  return (
    <div className="card w-100 h-100">
      <div className="card-body banner1">
        <div className="row">
          <div className={"col-md-6 col-sm-12" + (orientation == "left" ? " order-12" : orientation == "mobile-top" ? " order-xl-1 order-lg-1 order-md-1 order-12" : "")}>
            <h2 className="card-title">{title || placeholder.title}</h2>
            <p className="card-text fs-14" dangerouslySetInnerHTML={content} />
            {/*(buttons || placeholder.buttons).map((button, i) => (
              <button
                className="btn btn-primary text-capitalize py-2 px-5 border-radius-8"
                key={i}
              >
                {button.title}
              </button>
            ))*/}
            {label && (
              <a
                className="btn btn-primary text-capitalize py-2 px-5 border-radius-8"
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {label}
              </a>
            )}
          </div>
          <div className={"col-md-6 col-sm-12 d-flex align-items-center" + (orientation == "left" ? "" : orientation == "mobile-top" ? " order-xl-12 order-lg-12 order-md-12 order-1" : " order-12")}>
            <img
              className="advertorial-img"
              src={src || placeholder.src}
              alt="..."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Highlight.propTypes = {};

export default Highlight;
