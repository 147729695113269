import actionTypes from "./types";
import { HTTP } from "../../../../services/constants";
import { tatsApiCall } from "../../../../services/api";

export const getTestsQuestions =
  (testId, isVirtual = false) =>
    async (dispatch) => {

      const { data } = await tatsApiCall(
        isVirtual
          ? `/studentsigns/getrandomtest`
          : `/BookOperations/testsQuestions`,
        isVirtual
          ? {
            sanalTestNo: testId,
            isSoruImage: true,
          }
          : {
            testId,
            isSoruImage: true,
          },
        null,
        HTTP.POST,
        null,
        true
      );

      if (data) {
        let detail = {
          testAd: "",
          testID: testId,
          yayinID: data.yayınID,
          BransKod: "",
        };

        if (data.Sorular.length > 0) {
          detail.testAd = data.Sorular[0].TestAd;
          detail.BransKod = data.Sorular[0].Brans;
        }

        dispatch({
          type: actionTypes.GET_TEST_QUESTIONS,
          payload: {
            questions: data.Sorular,
            detail: detail,
          },
        });
      }
    };

export const saveTest = (data) => async (dispatch) => {
  // TODO dummy data
  var unitesEx = [
    {
      title: "Anlam Bilgisi",
      percentage: 50,
      id: 1,
      subjects: [
        {
          id: 1,
          title: "Sözcükte Anlam",
          percentage: 20,
        },
        {
          id: 2,
          title: "Cümlede Anlam",
          percentage: 20,
        },
        {
          id: 3,
          title: "Parçada Anlam",
          percentage: 10,
        },
      ],
    },

    {
      title: "Yazım ve Noktalama",
      percentage: 95,
      id: 2,
      subjects: [
        {
          id: 4,
          title: "Sözcükte Anlam",
          percentage: 20,
        },
        {
          id: 5,
          title: "Cümlede Anlam",
          percentage: 20,
        },
        {
          id: 6,
          title: "Parçada Anlam",
          percentage: 10,
        },
      ],
    },

    {
      title: "Dil Bilgisi",
      percentage: 15,
      id: 3,
      subjects: [
        {
          id: 7,
          title: "Sözcükte Anlam",
          percentage: 20,
        },
        {
          id: 8,
          title: "Cümlede Anlam",
          percentage: 20,
        },
        {
          id: 9,
          title: "Parçada Anlam",
          percentage: 10,
        },
      ],
    },
  ];

  let model = {
    bransKod: "14",
  };

  const resp = await tatsApiCall(
    `/BookOperations/bransTest`, // TODO ünite methodu
    model,
    null,
    HTTP.POST,
    null,
    true
  );

  let bransAd;

  let unites = [];
  let tests = [];
  if (resp && resp.data) {
    tests = resp.data.Testler;

    resp.data.Testler.map((test) => {
      if (!bransAd) {
        bransAd = test.bransAd;
      }

      let uix = unites.findIndex((p) => p.id === test.testUnite);
      if (uix === -1) {
        const { testUnite, testUniteAd, testKonu, testKonuAd } = test;

        unites.push({
          id: testUnite,
          title: testUniteAd,
          subjects: [
            {
              id: testKonu,
              title: testKonuAd,
            },
          ],
        });
      } else {
        let six = unites[uix].subjects.find((s) => s.id === test.testKonu);
        if (six === -1) {
          const { testKonu, testKonuAd } = test;
          unites[uix].subjects.push({
            id: testKonu,
            title: testKonuAd,
          });
        }
      }
    });
  }

  dispatch({
    type: actionTypes.GET_UNITE_INFO,
    payload: {
      unites,
      bransAd,
      tests,
    },
  });
};

export const getEmoji = () => async (dispatch) => {
  const { data } = await tatsApiCall(
    "/GeneralOperations/emoji",
    null,
    null,
    HTTP.POST,
    true
  );

  dispatch({
    type: actionTypes.GET_EMOJI,
    payload: data,
  });
};
