import actionTypes from "./types";
import { HTTP } from "../../../services/constants";
import apiCall from "../../../services/api";

export const getRecommendationProducts = (item) => async (dispatch, getState) => {
    try {
        const { data } = await apiCall("/recommendationproducts", item, null, HTTP.POST);

        dispatch({
            type: actionTypes.GET_RECOMMENDATION_PRODUCTS,
            payload: data
        });

    } catch (error) {
        console.error(error);
    }
};

export const getRecommendationCategories = (item) => async (dispatch, getState) => {
    try {
        const { data } = await apiCall("/recommendationproducts/GetGategories", item, null, HTTP.POST);

        dispatch({
            type: actionTypes.GET_RECOMMENDATION_GATEGORİES,
            payload: data
        });

    } catch (error) {
        console.error(error);
    }
};