import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../routes/actions/Account";
//import PropTypes from "prop-types";
import classNames from "classnames";
import { ScoreBadge, VerificationModal } from "../../../components/index";
import PhoneWidget from "../Widgets/Phone";
import { toast } from "react-toastify";

class PhoneField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      phone: this.props.value,
      lastSmsDate: null,
      errors: {
        code: false,
      },
    };

    this.code = React.createRef();
  }

  getSmsLimitDate = (seconds) => {
    let lastPossibleSmsDate = new Date();
    lastPossibleSmsDate.setSeconds(lastPossibleSmsDate.getSeconds() + seconds);
    return new Date(lastPossibleSmsDate);
  };

  sendVerifySms = async () => {
    if (
      this.props.value &&
      (this.props.value.replace(/ /g, "").length === 11 ||
        (String(this.props.value).indexOf("5") === 0 &&
          this.props.value.replace(/ /g, "").length === 10))
    ) {
      const state = {};

      if (!this.state.showModal) {
        state.showModal = true;
      }

      state.phone = this.props.value;

      if (
        this.state.lastSmsDate === null ||
        new Date() > this.state.lastSmsDate
      ) {
        state.lastSmsDate = this.getSmsLimitDate(60);

        await this.props.onSubmit(this.props.formData);
        const { success, error } = await this.props.sendVerifySms(
          this.props.value
        );
        if (!success) {
          toast.error(error);
          return;
        }
      }

      this.setState(state);
    } else {
      toast.error("Lütfen telefon numaranızı giriniz.");
    }
  };

  verify = async () => {
    const code = this.code.current.value;

    const errors = { code: !code || code.length < 5 };

    if (errors.code) {
      this.setState({ errors });
      return;
    }

    const model = {
      mobile: this.props.value,
      code,
    };

    const { success, error } = await this.props.verify(model);

    this.code.current.value = "";

    if (success) {
      this.setState({ showModal: false, errors }, () => {
        toast.success("Telefon numaranız doğrulanmıştır.");
      });
    } else {
      if (error && error.length) {
        this.setState({ errors }, () => {
          toast.error(error);
        });
      }
    }
  };

  render() {
    const {
      className,
      name,
      schema,
      uiSchema,
      value,
      onChange,
      readOnly,
      errors,
      accountSettings,
    } = this.props;

    const valid = !(errors && errors.length > 0);

    const widgetProps = {
      className: classNames(
        "form-control",
        valid === false ? "is-invalid" : "valid"
      ),
      name,
      schema,
      uiSchema,
      value,
      readOnly,
      onChange,
      onClick: this.sendVerifySms,
      valid,
      verified: this.props.user.isMobileVerified,
      accountSettings,
      activityTypes: this.props.activityTypes,
    };
    var activity = { Score: 0, Coins: 0 };
    if (this.props.activityTypes && this.props.activityTypes.length > 0) {
      activity = this.props.activityTypes.find(
        (x) => x.Name === "verify-mobile"
      );
    }

    return (
      <React.Fragment>
        <div className={classNames("form-group col-12", className)}>
          <div className="d-flex justify-content-between">
            <label className="mt-2" htmlFor={name}>
              {schema.title}
            </label>
          </div>
          <PhoneWidget {...widgetProps} />
          <small id={`${name}_help`} className="form-text text-muted col-sm-12">
            {uiSchema && uiSchema["ui:description"]}
          </small>
          {(errors || []).map((error) => (
            <div className="invalid-feedback">{error}</div>
          ))}
        </div>
        {this.state.showModal ? (
          <VerificationModal
            lastSmsDate={this.state.lastSmsDate}
            phone={this.state.phone}
            show={this.state.showModal}
            sendVerifySms={this.sendVerifySms}
            verify={this.verify}
            codeRef={this.code}
            errors={this.state.errors}
            onCloseClick={() => this.setState({ showModal: false })}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

PhoneField.propTypes = {};

const mapStateToProps = ({ AccountReducer, ProductReducer }) => ({
  user: AccountReducer.user,
  activityTypes: ProductReducer.activityTypes,
});

const mapDispatchToProps = {
  sendVerifySms: actions.sendVerifySms,
  verify: actions.verify,
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneField);
