import actionTypes from "./types";
import {
  HTTP,
  defaultValues,
  keys,
  settings,
  S3BucketEndPoint
} from "../../../services/constants";
import apiCall, { tatsApiCall, bucketCall } from "../../../services/api";
import storage from "../../../services/storage";
import { turkishToLower } from "../../../helpers";
import { Index } from "flexsearch";
import { toast } from "react-toastify";
export const productQuery = (id = "", brands = "", keyword = "", ids = "") => `
    query{
        products(
            taxons: [${id}],
            brands: [${brands}],
            ids: [${ids}],
            orderBy: "Sira",
            desc: false,
            page: 1,
            quantity: 500,
            keyword: "${keyword}",
            userId: 0
        ){
            brandId,
            total,
            name,
            imageUrl,
            id,
            price,
            description,
            sku,
            productType,
            freeCargo,
            discount,
            fastCargo,
            color,
            thumbnails,
            images,
            avatars,
            body,
            taxons,
            tax,
            tags,
            preSale,
            reNew,
            smartContent,
            getable,
            isNew,
            comingSoon,
            hasDigitalBook,
            searchableText,
            isInStock
        }
    }
`;

export const getBasket = () => async (dispatch, getState) => {
  const {
    ProductReducer: { basketKey },
  } = getState();

  const basketResponse = apiCall("/basket", null, { key: basketKey });
  const basket = await basketResponse;
  const user = storage.getAuth().user;
  if (user && user.id) {
    const notificationsResponse = apiCall(
      "/notifications",
      null,
      null,
      HTTP.GET,
      null,
      true
    );
    const notifications = await notificationsResponse;
    if (notifications) {
      dispatch({
        type: actionTypes.GET_BASKET,
        payload: {
          basket: basket.data || { items: [] },
          notifications: notifications.data || [],
        },
      });
    }
  } else {
    dispatch({
      type: actionTypes.GET_BASKET,
      payload: {
        basket: basket.data || [],
        notifications: [],
      },
    });
  }

  return true;
};

export const getNotifications = () => async (dispatch) => {
  const { data } = await apiCall(
    "/notifications",
    null,
    null,
    HTTP.GET,
    null,
    true
  );

  dispatch({
    type: actionTypes.GET_NOTIFICATIONS,
    payload: data,
  });
};

export const calculateProductPrice = (product) => {
  return Math.round(
    (
      product.price *
      (100 - product.discount) / 100 * // - indirim
      (100 + product.tax) / 100 // + kdv
      * 100
    ) || 0) / 100
};

export const getTaxonomies = () => async (dispatch, getStore) => {
  /*
  const taxons = apiCall("/taxonomies");
  const brandList = apiCall("/products/brands");
  const metaDataRequest = apiCall("/contents/metadata");
  */
  var currentStore = getStore();

  const metaDataRequest = settings.GET_DATA_FROM_AWS
    ? bucketCall("/metadata.json")
    : apiCall("/contents/metadata");
  const taxons = settings.GET_DATA_FROM_AWS
    ? bucketCall("/taxonomies.json")
    : apiCall("/taxonomies");
  const brandList = settings.GET_DATA_FROM_AWS
    ? bucketCall("/brands.json")
    : apiCall("/products/brands");

  const taxonomies = await taxons;
  const brands = await brandList;
  const metaData = await metaDataRequest;

  if (taxonomies || brands) {
    let newTaxons = (taxonomies.data || []).map((m) => {
      let sum = 0;
      for (let item of m.taxons) {
        sum += item.count || 0;
      }
      return {
        ...m,
        count: sum,
      };
    });

    // newTaxons = [...newTaxons, {
    //   code: "soru",
    //   id: 9992,
    //   name: "Soru Sayısı",
    //   type: "question",
    //   hideOnNavbar: true,
    //   image: S3BucketEndPoint + "/urun_grup_ikon/soru-sayisi.png",
    //   taxons: [
    //     {
    //       code: "0-200",
    //       count: 1,
    //       description: "",
    //       id: 'q1',
    //       isNew: false,
    //       name: "0-200",
    //       slug: "0-200",
    //       taxonomyId: 9992,
    //     }, {
    //       code: "200-400",
    //       count: 1,
    //       description: "",
    //       id: 'q2',
    //       isNew: false,
    //       name: "200-400",
    //       slug: "200-400",
    //       taxonomyId: 9992,
    //     }, {
    //       code: "400-600",
    //       count: 1,
    //       description: "",
    //       id: 'q3',
    //       isNew: false,
    //       name: "400-600",
    //       slug: "400-600",
    //       taxonomyId: 9992,
    //     }, {
    //       code: "600-800",
    //       count: 1,
    //       description: "",
    //       id: 'q4',
    //       isNew: false,
    //       name: "600-800",
    //       slug: "600-800",
    //       taxonomyId: 9992,
    //     }, {
    //       code: "800-1000",
    //       count: 1,
    //       description: "",
    //       id: 'q5',
    //       isNew: false,
    //       name: "800-1000",
    //       slug: "800-1000",
    //       taxonomyId: 9992,
    //     }, {
    //       code: "1000 ve üzeri",
    //       count: 1,
    //       description: "",
    //       id: 'q6',
    //       isNew: false,
    //       name: "1000 ve üzeri",
    //       slug: "1000_ve_uzeri",
    //       taxonomyId: 9992,
    //     }
    //   ],
    //   count: 0,
    //   description: "",
    //   isNew: false
    // }]

    dispatch({
      type: actionTypes.GET_TAXONOMIES,
      payload: {
        taxonomies: newTaxons,
        brands: brands ? brands.data : [],
        metadata: metaData ? metaData.data : [],
      },
    });
  }

  if (!taxonomies.data.length) {
    const t = (await settings.GET_DATA_FROM_AWS)
      ? bucketCall("/taxonomies.json")
      : apiCall("/taxonomies");
    if (t) {
      dispatch({
        type: actionTypes.GET_JUST_TAXONOMIES,
        payload: {
          taxonomies: t ? t.data : [],
        },
      });
    }
  }

  if (!currentStore.ProductReducer.allProducts.length) {
    await getAllProducts(dispatch, getStore);
  }
};

export const getFooterTaxonomies = () => async (dispatch, getStore) => {
  /*
  const taxons = apiCall("/taxonomies");
  const brandList = apiCall("/products/brands");
  const metaDataRequest = apiCall("/contents/metadata");
  */
  var currentStore = getStore();

  const metaDataRequest = settings.GET_DATA_FROM_AWS
    ? bucketCall("/metadata.json")
    : apiCall("/contents/metadata");
  const taxons = settings.GET_DATA_FROM_AWS
    ? bucketCall("/taxonomies.json")
    : apiCall("/taxonomies");
  const brandList = settings.GET_DATA_FROM_AWS
    ? bucketCall("/brands.json")
    : apiCall("/products/brands");

  const taxonomies = await taxons;
  const brands = await brandList;
  const metaData = await metaDataRequest;

  if (taxonomies || brands) {
    let newTaxons = (taxonomies.data || []).map((m) => {
      let sum = 0;
      for (let item of m.taxons) {
        sum += item.count || 0;
      }
      return {
        ...m,
        count: sum,
      };
    });

    // newTaxons = [...newTaxons, {
    //   code: "soru",
    //   id: 9992,
    //   name: "Soru Sayısı",
    //   type: "question",
    //   hideOnNavbar: true,
    //   image: S3BucketEndPoint + "/urun_grup_ikon/soru-sayisi.png",
    //   taxons: [
    //     {
    //       code: "0-200",
    //       count: 1,
    //       description: "",
    //       id: 'q1',
    //       isNew: false,
    //       name: "0-200",
    //       slug: "0-200",
    //       taxonomyId: 9992,
    //     }, {
    //       code: "200-400",
    //       count: 1,
    //       description: "",
    //       id: 'q2',
    //       isNew: false,
    //       name: "200-400",
    //       slug: "200-400",
    //       taxonomyId: 9992,
    //     }, {
    //       code: "400-600",
    //       count: 1,
    //       description: "",
    //       id: 'q3',
    //       isNew: false,
    //       name: "400-600",
    //       slug: "400-600",
    //       taxonomyId: 9992,
    //     }, {
    //       code: "600-800",
    //       count: 1,
    //       description: "",
    //       id: 'q4',
    //       isNew: false,
    //       name: "600-800",
    //       slug: "600-800",
    //       taxonomyId: 9992,
    //     }, {
    //       code: "800-1000",
    //       count: 1,
    //       description: "",
    //       id: 'q5',
    //       isNew: false,
    //       name: "800-1000",
    //       slug: "800-1000",
    //       taxonomyId: 9992,
    //     }, {
    //       code: "1000 ve üzeri",
    //       count: 1,
    //       description: "",
    //       id: 'q6',
    //       isNew: false,
    //       name: "1000 ve üzeri",
    //       slug: "1000_ve_uzeri",
    //       taxonomyId: 9992,
    //     }
    //   ],
    //   count: 0,
    //   description: "",
    //   isNew: false
    // }]

    dispatch({
      type: actionTypes.GET_FOOTER_TAXONOMIES,
      payload: {
        taxonomies: newTaxons,
        brands: brands ? brands.data : [],
      },
    });
  }

  if (!taxonomies.data.length) {
    const t = (await settings.GET_DATA_FROM_AWS)
      ? bucketCall("/taxonomies.json")
      : apiCall("/taxonomies");
    if (t) {
      dispatch({
        type: actionTypes.GET_FOOTER_TAXONOMIES,
        payload: {
          taxonomies: t ? t.data : [],
        },
      });
    }
  }

  if (!currentStore.ProductReducer.allProducts.length) {
    await getAllProducts(dispatch, getStore);
  }
};

export const getTaxonomiesByCode = () => async (dispatch) => {
  const taxons = settings.GET_DATA_FROM_AWS
    ? bucketCall("/taxonomies.json")
    : apiCall("/taxonomies");
  const { data } = await taxons;

  dispatch({
    type: actionTypes.GET_TAXONOMIES_BY_CODE,
    payload: data,
  });
};

export const getBasketTaxonomies = () => async (dispatch) => {
  const taxons = settings.GET_DATA_FROM_AWS
    ? bucketCall("/taxonomies.json")
    : apiCall("/taxonomies");
  const { data } = await taxons;
  let tax = data.filter((x) => x.code == "sinavlar" || x.code == "siniflar");
  tax = tax.filter((x) => (x.taxons = x.taxons.filter((y) => y.count > 0)));
  dispatch({
    type: actionTypes.GET_BASKET_TAXONOMIES,
    payload: tax,
  });
};

//FIXME bu nerede kullanılıyor?
//Bir alttaki metod'da
//mantıklı, ben koymuşum hatırlamıyorum :)
//ahahahaha :D :D
export const getAllProducts = async (dispatch, getStore) => {
  var currentStore = getStore();
  var mData = currentStore.ProductReducer.metadata
    ? [...currentStore.ProductReducer.metadata]
    : [];

  const productsRequest = settings.GET_DATA_FROM_AWS
    ? bucketCall("/products.json")
    : apiCall(
      `/products/search?query=${productQuery("", "", "", "")}`,
      null,
      null,
      HTTP.GET,
      null,
      true
    );
  const products = await productsRequest;

  if (!mData.length) {
    const metaDataRequest = settings.GET_DATA_FROM_AWS
      ? bucketCall("/metadata.json")
      : apiCall("/contents/metadata");
    const metadata = await metaDataRequest;
    mData = [...metadata.data];
  }
  if (products.data) {
    const newProducts = products.data.data.products.map(m => ({
      ...m,
      taxons: [
        ...m.taxons,
        ...getRatingTaxonForProduct(m),
        getQuestionTaxonForProduct(m)
      ]
    }));

    dispatch({
      type: actionTypes.GET_ALL_PRODUCTS,
      payload: {
        products: newProducts,
        metadata: mData,
      },
    });

    return newProducts;
  }
};

export const getRecentlyViewedProducts = async () => {
  var getRecentlyViewedProductsRequest = await apiCall("/products/recently-viewed-lite");
  var recentlyViewedProducts = getRecentlyViewedProductsRequest.data;
  return recentlyViewedProducts;
};

const getQuestionTaxonForProduct = ({ totalQuestion }) => {
  if (totalQuestion >= 0 && totalQuestion <= 200) {
    return 'q1';
  }
  else if (totalQuestion >= 200 && totalQuestion <= 400) {
    return 'q2';
  }
  else if (totalQuestion >= 400 && totalQuestion <= 600) {
    return 'q3';
  }
  else if (totalQuestion >= 600 && totalQuestion <= 800) {
    return 'q4';
  }
  else if (totalQuestion >= 800 && totalQuestion <= 1000) {
    return 'q5';
  }
  else if (totalQuestion >= 1000) {
    return 'q6';
  }
}

const getRatingTaxonForProduct = ({ rating }) => {
  let output = [];
  if (rating >= 1) {
    output.push('p1');
  }
  if (rating >= 2) {
    output.push('p2');
  }
  if (rating >= 3) {
    output.push('p3');
  }
  if (rating >= 4) {
    output.push('p4');
  }
  if (rating === 0) {
    output.push('p0')
  }
  return output;
}

const createIndex = (products) => {
  const options = {
    preset: "match",
    tokenize: "full",
  };
  const searchIndex = new Index(options);

  products.map((product, index) => {
    const sku = (product.sku && product.sku.length) ? product.sku.replace(/\-/g, "").replace(/ /g, "") : "";
    searchIndex.add(index, `${product.searchableText} ${sku}`);
  });

  return searchIndex;
};

export const flexSearch = (keyword) => async (dispatch, getStore) => {
  var currentStore = getStore();
  var products = currentStore.ProductReducer.allProducts.length
    ? [...currentStore.ProductReducer.allProducts]
    : await getAllProducts(dispatch, getStore);
  var searchIndex =
    currentStore.ProductReducer.searchIndex ?? createIndex(products);

  keyword = turkishToLower(keyword);
  var result = searchIndex.search(keyword, 100).map((r) => products[r]);

  dispatch({
    type: actionTypes.SEARCH,
    payload: {
      result,
      searchIndex,
      keyword,
    },
  });
};
export const getProductsById =
  (id, brands, keyword, ids, actionType) => async (dispatch, getStore) => {
    var currentStore = getStore();
    var products = [...currentStore.ProductReducer.allProducts];

    //const metaData = currentStore.ProductReducer.metaData;
    var fromLocal = false; //aramanın nereden yapılacağını belirtir.

    //store'da ürün yok ise doldur
    if (!products.length) {
      products = await getAllProducts(dispatch, getStore);
      fromLocal = true;
    }

    const filters = {
      taxonomies: id && id.length && id.split(',') || [],
      brands: brands && brands.length && brands.split(',') || [],
      keyword: "",
      productIds: ids && ids.length && ids.split(',') || [],
    };

    const taxonomies = getStore().ProductReducer.taxonomies;
    const brandList = getStore().ProductReducer.brands;
    const searchIndex =
      currentStore.ProductReducer.searchIndex ?? createIndex(products);

    //filtreden herhangi bir şey seçildi ise
    if (
      (brands && brands.length && brandList.length) ||
      (id && id.length && taxonomies.length) ||
      (keyword && keyword.length)
    ) {
      fromLocal = true;
    }

    //herhangi bir filtre seçilmediyse ve store'da varsa
    if (
      products &&
      products.length &&
      (!brands || !brands.length) &&
      (!keyword || !keyword.length) &&
      (!ids || !ids.length) &&
      (!id || !id.length)
    )
      fromLocal = true;

    // // kelime ile aramalarda api'den istekte bulunması gerekiyor.
    // if (keyword && keyword.length) fromLocal = false;

    if (fromLocal) {
      let lProducts = [...products];

      if (keyword && keyword.length) {
        keyword = turkishToLower(keyword);
        lProducts = searchIndex.search(keyword, 100).map((r) => products[r]);
      }

      if (brands && brands.length) {
        // filtrelemeden marka filtresi geldiyse önce o filtrelenir.
        const brandsArray = brands.split(",").map((b) => Number(b));
        lProducts = lProducts.filter(
          (p) =>
            brandsArray.includes(p.brandId) ||
            (p.brands && p.brands.some((b) => brandsArray.includes(b)))
        );
      }
      if (id && id.length) {
        const idArray = id.split(",");
        let lTaxonomies = taxonomies
          .filter((t) => t.taxons.some((tx) => idArray.includes(tx.id.toString()))) // seçili gelen taxonların grubunun seçilmesi
          .map((t) => {
            return {
              id: t.id,
              taxons: t.taxons.filter((tx) => idArray.includes(tx.id.toString())),
            };
          }); // seçili gelen taxonların seçilmesi

        lProducts = lProducts.filter((p) =>
          lTaxonomies.every((lt) =>
            lt.taxons.some((tx) => p.taxons.some((pt) => pt === tx.id))
          )
        ); // taxon gruplarının tamamından en az bir taxonunu tanımlı ürünlerin filtrelenmesi
      }
      if (ids && ids.length) {
        var idList = ids.split(",");
        lProducts = lProducts.filter((p) => idList.includes(id.toString()));
      }
      dispatch({
        type: actionType || actionTypes.GET_PRODUCTS_BY_ID,
        payload: {
          products: lProducts,
          result: lProducts,
          keyword,
          searchIndex,
          filters
        },
      });
    } else {
      const getDataFromAws =
        settings.GET_DATA_FROM_AWS &&
        (!id || !id.length) &&
        (!brands || !brands.length) &&
        (!keyword || !keyword.length) &&
        (!ids || !ids.length);

      const productsRequest = getDataFromAws
        ? bucketCall("/products.json")
        : apiCall(
          `/products/search?query=${productQuery(id, brands, keyword, ids)}`,
          null,
          null,
          HTTP.GET,
          null,
          true
        );

      const products = await productsRequest;

      if (products.data) {
        dispatch({
          type: actionTypes.GET_PRODUCTS_BY_ID,
          payload: {
            products: products.data.data.products,
            keyword,
            filters
          },
        });
      }
    }
  };

export const getProductById = (id) => async (dispatch, getState) => {
  try {
    const {
      ProductReducer: { basketKey },
    } = getState();

    const productRequest = await apiCall(
      `/products/${id}/${basketKey}`,
      null,
      null,
      HTTP.GET,
      null,
      true
    );

    if (productRequest.data.redirectUrl != null)
      window.location.href = productRequest.data.redirectUrl;

    const commentRequest = apiCall(
      `/products/${productRequest.data.id}/comments`
    );
    const samplePagesReq = apiCall(
      `/products/sample/${productRequest.data.id}`
    );
    const videosReq = apiCall(`/products/${id}/comments/videoreview`);

    const comments = await commentRequest;
    const samplePages = await samplePagesReq;
    const videos = await videosReq;

    dispatch({
      type: actionTypes.GET_PRODUCT_BY_ID,
      payload: {
        product: productRequest.data,
        comments: comments.data,
        samplePages: samplePages.data,
        videos: videos.data,
      },
    });
  } catch (error) {
    //eslint-disable-next-line
  }
};

//FIXME bu nerede kullanılıyor?
export const getRelatedProducts = (id, brands, keyword, ids) => (dispatch) => {
  const getDataFromAws =
    settings.GET_DATA_FROM_AWS &&
    (!id || !id.length) &&
    (!brands || !brands.length) &&
    (!keyword || !keyword.length) &&
    (!ids || !ids.length);

  let productsRequest;
  if (getDataFromAws) {
    productsRequest = bucketCall("/products.json");
  } else {
    productsRequest = apiCall(
      `/products/search?query=${productQuery(id, brands, keyword, ids)}`,
      null,
      null,
      HTTP.GET,
      null,
      true
    );
  }
  productsRequest.then((response) => {
    dispatch({
      type: actionTypes.GET_RELATED_PRODUCTS,
      payload: {
        relatedProducts: response.data.data.products,
      },
    });
  });
};
export const getPageProducts =
  (id, brands, keyword, ids) => async (dispatch) => {
    const { data } = settings.GET_DATA_FROM_AWS ?
      await bucketCall("/products.json") :
      await apiCall(
        `/products/search?query=${productQuery(id, brands, keyword, ids)}`,
        null,
        null,
        HTTP.GET,
        null,
        true
      );

    dispatch({
      type: actionTypes.GET_PAGE_PRODUCTS,
      payload: {
        products: data.data.products,
        keyword,
      },
    });
  };

export const search = (keyword) => async (dispatch, getStore) => {
  try {
    if (settings.GetProductsFromApi) {
      const { data } = await apiCall(
        `/products/search?query=${productQuery("", "", keyword)}`,
        null,
        null,
        HTTP.GET,
        null,
        true
      );

      dispatch({
        type: actionTypes.SEARCH,
        payload: {
          result: data.data.products,
          keyword,
        },
      });
    } else {
      getProductsById(
        "",
        "",
        keyword,
        "",
        actionTypes.SEARCH
      )(dispatch, getStore);
    }
  } catch (error) {
    //eslint-disable-next-line
  }
};

export const resetSearch = () => async (dispatch) => {
  dispatch({
    type: actionTypes.RESET_SEARCH,
  });
};

export const getBrands = () => async (dispatch) => {
  const { data } = await apiCall("/products/brands");

  dispatch({
    type: actionTypes.GET_BRANDS,
    payload: data,
  });
};

export const getCoins = () => async (dispatch) => {
  const coinPackages = apiCall("/coins");
  const coinWallets = apiCall("/coins/wallet");

  const coins = await coinPackages;
  const walletCoins = await coinWallets;

  dispatch({
    type: actionTypes.GET_COINS,
    payload: {
      coins: coins.data,
      wallet: walletCoins.data,
    },
  });
};

export const getComments = (productId) => async (dispatch) => {
  const { data } = apiCall(`/products/${productId}/comments`);

  dispatch({
    type: actionTypes.GET_COMMENTS,
    payload: data,
  });
};

export const postComment = (productId, comment) => async () => {
  try {
    const { data } = await apiCall(
      `/products/${productId}/comments`,
      comment,
      null,
      HTTP.POST,
      null,
      true
    );

    if (data.id <= 0) {
      return {
        success: false,
        error:
          "Bu ürüne daha önce yorum yapmışsın, başka bir ürünü değerlendirip puan kazanmaya devam edebilirsin.",
      };
    }

    return { success: true };
  } catch (error) {
    return { success: false, error: error.response.data };
  }
};

export const checkComment = (productId) => async (dispatch) => {
  const { data } = await apiCall(
    `/products/${productId}/comments/CheckComment`
  );

  return {
    success: data.success,
    error:
      "Bu ürüne daha önce yorum yapmışsın, başka bir ürünü değerlendirip puan kazanmaya devam edebilirsin.",
  };
};

export const addProductToNotifyMe = (product) => async (dispatch) => {
  const { data } = await apiCall(
    "/notifymeproduct",
    { productId: product.id },
    null,
    HTTP.POST
  );

  dispatch({
    type: actionTypes.ADD_PRODUCT_TO_NOTIFY_ME,
    payload: product,
  });

  return {
    success: data.success,
    error: data.message,
  };
};

export const addProductToBasket = (item, taxonomies) => async (dispatch) => {
  try {
    const { data } = await apiCall("/basket", item, null, HTTP.POST);
    dispatch({
      type: actionTypes.ADD_PRODUCT_TO_BASKET,
      payload: data,
    });

    if (typeof window !== "undefined") {

      //gtm - datalayer
      let productTaxonIds = Array.from(
        ((item.product?.taxons ?? item?.taxons) ?? (item.product?.[0]?.taxons)) ?? []
      ).filter(element => typeof element === 'number');


      const productsTaxonSet = new Set(productTaxonIds);

      let productTaxons = taxonomies?.filter((t) => t.taxons.some(
        (tx) => productTaxonIds.includes(tx.id)
      ))

        .flatMap((t) => {
          return t.taxons.filter(taxon => productsTaxonSet.has(taxon.id));
        });
      const productTopTaxonId = productTaxons?.[0]?.taxonomyId;
      const productTopTaxon = taxonomies?.filter((t) => t.id == productTopTaxonId);

      if (window.dataLayer != null) {
        window.dataLayer.push({
          event: "add_to_cart",
          ecommerce: {
            currency: "TRY",
            value: Number((((item?.product?.total ?? item.total) * (100 + (item?.product?.tax ?? item.tax))) / 100 || 0).toFixed(2)),
            items: [
              {
                'item_id': item.productId,
                'item_name': item?.product?.name ?? item.name,
                'currency': "TRY",
                'index': 0,
                'item_category': productTaxons?.[0]?.name,
                'item_category2': productTaxons?.[1]?.name,
                'item_category3': productTaxons?.[2]?.name,
                'item_category4': productTaxons?.[3]?.name,
                'item_category5': productTaxons?.[4]?.name,
                'item_variant': productTaxons?.[0]?.name ?? item.category,
                'item_list_id': productTopTaxon?.[0]?.id,
                'item_list_name': productTopTaxon?.[0]?.description,
                'price': Number((((item?.product?.total ?? item.total) * (100 + (item?.product?.tax ?? item.tax))) / 100 || 0).toFixed(2)),
                'quantity': item.quantity,
                'discount': 0,
              },
            ],
          },
        });
      }

      //facebook pixel
      if (window.fbq != null) {
        window.fbq("track", "AddToCart", {
          content_ids: [item.productId],
          value: item.price,
          currency: "TRY",
          content_name: item.name,
        });
      }
    }

    return { success: true };
  } catch (e) {
    if (e.status === 404) {
      try {
        item.key = defaultValues.BASKET_KEY;

        const { data } = await apiCall("/basket", item, null, HTTP.POST);
        storage.setItem(keys.Basket, data.key);

        dispatch({
          type: actionTypes.ADD_PRODUCT_TO_BASKET,
          payload: data,
        });
        return { success: true };
      } catch (e) {
        return { success: false, error: e };
      }
    } else {
      var message = e.data;
      if (e.data == null || e.data == undefined)
        var message = "Ürün sepete eklenemedi.";
      toast.error(message);
      return { success: false, error: e };
    }
  }
};

export const deleteProductToBasket = (item) => async (dispatch, getStore) => {
  try {
    var currentStore = getStore();
    var taxonomies = [...currentStore.ProductReducer.taxonomies];
    const { data } = await apiCall(
      "/basket",
      null,
      { key: item.key, productId: item.productId },
      HTTP.DELETE
    );
    dispatch({
      type: actionTypes.DELETE_PRODUCT_TO_BASKET,
      payload: data,
    });

    if (typeof window !== "undefined") {
      // gtm - datalayer
      if (window.dataLayer != null) {
        const taxonIds = new Set(item.product?.taxons?.filter(
          element => typeof element === 'number'
        ));

        const productTaxons = taxonomies.filter((t) => t.taxons.some(
          (tx) => taxonIds.has(tx.id)
        )).flatMap((t) => {
          return t.taxons.filter(taxon => taxonIds.has(taxon.id));
        });
        const productTopTaxonId = productTaxons?.[0]?.taxonomyId;
        const productTopTaxon = taxonomies?.filter((t) => t.id == productTopTaxonId);
        window.dataLayer.push({
          event: "remove_from_cart",
          ecommerce: {
            items: [
              {
                item_name: item.product.name,
                item_id: item.productId,
                currency: "TRY",
                index: 0,
                price: Number(item.total.toFixed(2)),
                item_category: item.product.category,
                quantity: item.quantity,
                item_brand: item.brand,
                item_category: productTaxons?.[0]?.name,
                item_category2: productTaxons?.[1]?.name,
                item_category3: productTaxons?.[2]?.name,
                item_category4: productTaxons?.[3]?.name,
                item_category5: productTaxons?.[4]?.name,
                item_variant: productTaxons?.[0]?.name ?? item.product.category,
                discount: 0,
                item_list_id: productTopTaxon?.[0]?.id,
                item_list_name: productTopTaxon?.[0]?.description
              },
            ]
          },
        });
      }
    }

    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const deleteAllItemInBasket = () => async (dispatch, getState) => {
  try {
    const {
      ProductReducer: { basketKey },
    } = getState();
    await apiCall("/basket/all", null, { key: basketKey }, HTTP.DELETE);

    dispatch({
      type: actionTypes.DELETE_ALL_BASKET,
    });
    storage.removeOrderCode();
    return { success: true };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const getShipping = () => async (dispatch, getState) => {
  const {
    ProductReducer: { basketKey },
  } = getState();
  const { data } = await apiCall(
    `/basket/shipping/${basketKey}`,
    null,
    null,
    HTTP.GET
  );

  dispatch({
    type: actionTypes.GET_SHIPPING,
    payload: data,
  });
};

export const sendVideoReview = (productId, file, review) => async () => {
  try {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      const {
        data: { url },
      } = await apiCall(
        "/file/video",
        formData,
        null,
        HTTP.POST,
        { "Content-Type": "multipart/form-data" },
        true
      );

      review.url = "";
      review.tempUrl = url;
    }

    await apiCall(
      `/products/${productId}/comments/videoreview`,
      review,
      null,
      HTTP.POST,
      null,
      true
    );

    return { success: true };
  } catch (error) {
    return { success: false, error: error.response.data };
  }
};

export const getStores = (townId, lines) => async (dispatch) => {
  const model = {
    townId,
    lines,
  };

  const { data } = await apiCall(
    "/store/find",
    model,
    null,
    HTTP.POST,
    null,
    true
  );

  dispatch({
    type: actionTypes.GET_STORES,
    payload: data.slice(0, 10),
  });
};

export const doReservation =
  (model, resetBasket = false) =>
    async (dispatch) => {
      try {
        const { data } = await apiCall(
          "/reservations",
          model,
          null,
          HTTP.POST,
          null,
          true
        );

        dispatch({
          type: actionTypes.DO_RESERVATION,
          payload: {
            reservation: data,
            resetBasket,
          },
        });

        return { success: true };
      } catch (error) {
        return {
          success: false,
          error: (error.response && error.response.data) || error.data,
        };
      }
    };

export const cancelReservation = (reservation) => async (dispatch) => {
  try {
    await apiCall(
      `/reservations/${reservation.id}/cancel`,
      reservation,
      null,
      HTTP.POST,
      null,
      true
    );

    dispatch({
      type: actionTypes.CANCEL_RESERVATION,
      payload: reservation,
    });
    return { success: true };
  } catch (error) {
    return { success: false, error: error.response.data };
  }
};

export const getReservationById = (id) => async (dispatch) => {
  const { data } = await apiCall(
    "/reservations",
    { id },
    null,
    HTTP.GET,
    null,
    true
  );

  dispatch({
    type: actionTypes.GET_RESERVATION,
    payload: data,
  });
};

export const getReservations = () => async (dispatch) => {
  const { data } = await apiCall(
    "/reservations",
    null,
    null,
    HTTP.GET,
    null,
    true
  );

  dispatch({
    type: actionTypes.GET_RESERVATIONS,
    payload: data,
  });
};

export const getActivityTypes = () => async (dispatch) => {
  const activityTypesRequest = settings.GET_DATA_FROM_AWS
    ? bucketCall("/activity-types.json")
    : tatsApiCall("/Score/GetActivityTypes", null, null, HTTP.GET, null, true);

  const activityTypes = await activityTypesRequest;

  dispatch({
    type: actionTypes.GET_ACTIVITY_TYPES,
    payload: activityTypes.data,
  });
};

export const getStoreReviews = (id) => async (dispatch) => {
  const { data } = await apiCall(
    `/store/review/${id}`,
    null,
    null,
    HTTP.GET,
    null,
    false
  );

  dispatch({
    type: actionTypes.GET_STORE_REVIEWS,
    payload: data,
  });
};

export const addBuyLaterProduct = (productId) => async (dispatch) => {
  try {
    await apiCall(
      `/buylaterproducts`,
      { productId },
      null,
      HTTP.POST,
      null,
      true
    );

    dispatch({
      type: actionTypes.ADD_BUY_LATER_PRODUCT,
      payload: productId,
    });
    return { success: true };
  } catch (error) {
    return { success: false, error: error.response.data };
  }
};

export const getBookAnalysis = (productId) => async (dispatch) => {
  const { data } = await apiCall(
    `/products/bookanalysis/${productId}`,
    null,
    null,
    HTTP.GET,
    null,
    true
  );

  dispatch({
    type: actionTypes.GET_BOOK_ANALYSIS,
    payload: data,
  });
};
