import React, { Component } from "react";
import Slider from "react-slick";
import * as actions from "../../routes/actions/Study";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

export class MainLibrary extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if(this.props.usersBooksForDashboard && this.props.usersBooksForDashboard.kitaplar && this.props.usersBooksForDashboard.kitaplar.length > 0)
      return;
    this.props.getUsersBooksForDashboard();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user?.id != this.props.user?.id) {
      this.props.getUsersBooksForDashboard();
    }
  }

  render() {
    if (!this.props.usersBooksForDashboard?.kitaplar) {
      return null;
    }

    var settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      swipeToSlide: true,
      centerPadding: "50px",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };
    return (
      <div className="main-library">
        <div className="library-head">
          <div className="library-head-img">
            <img
              className="img-fluid"
              src={require("../../assets/images/library/tats-logo.png")}
              alt=""
            />
          </div>
          <div className="library-head-box">
            <div>Kitabını aldın, e hadi şimdi TATS ile çöz!</div>
          </div>
        </div>

        <Slider {...settings}>
          {this.props.usersBooksForDashboard.kitaplar.map((item, i) => (
            <div className="library-item-container px-2" key={i}>
              {!item.urun.isPay && (
                <Link to={"/dijitalkitap/books"} className="library-item secret-item">
                  <div className="img-backdrop">
                    <div className="library-img-wrapper">
                      <img src={item.urun.kapakAdres} alt={item.ad} />
                    </div>
                    <img
                      className="library-lock-icon"
                      src={require("../../assets/images/library/icon-lock.svg")}
                      alt=""
                    />
                  </div>
                  <div className="library-box">
                    <div className="library-title">{item.ad}</div>
                  </div>
                </Link>
              )}
              {item.urun.isPay && item.analiz.soruCozumYuzdesi == 0 && (
                <Link
                  to={`/dijitalkitap/book/lessons/${item.id}`}
                  className="library-item"
                >
                  <div className="library-img-wrapper">
                    <img src={item.urun.kapakAdres} alt={item.ad} />
                  </div>
                  <div className="library-box">
                    <div className="library-title">{item.ad}</div>
                    <div className="btn-library-2">Hadi Başla Artık</div>
                  </div>
                </Link>
              )}
              {item.urun.isPay && item.analiz.soruCozumYuzdesi !== 0 && (
                <Link
                  to={`/dijitalkitap/book/lessons/${item.id}`}
                  className="library-item"
                >
                  <div className="library-img-wrapper">
                    <img src={item.urun.kapakAdres} alt={item.ad} />
                  </div>
                  <div className="library-box justify-content-around">
                    <div className="library-title">{item.ad} </div>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${item.analiz.soruCozumYuzdesi}%` }}
                      >
                        {item.analiz.soruCozumYuzdesi}%
                      </div>
                    </div>
                    <div className="btn-library">Devam Et</div>
                  </div>
                </Link>
              )}
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

const mapStateToProps = ({ AccountReducer, StudyReducer }) => ({
  user: AccountReducer.user,
  usersBooksForDashboard: StudyReducer.usersBooksForDashboard,
});

const mapDispatchToProps = {
  getUsersBooksForDashboard: actions.getUsersBooksForDashboard,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLibrary);
