import React, { Component } from "react";
import _remove from "lodash/remove";
//import PropTypes from 'prop-types';
import classNames from "classnames";
import {
  faPencilAlt,
  faPlusCircle,
  faCheck,
  faTimes,
  faTrash,
  faAngleDown,
  faAngleUp
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "./Index";
import CloseButton from "../Button/Close";
import { BaseForm } from "..";
import FormSchemas from "../../variables/FormSchemas";
import { move } from "../../helpers";
import { PlaceHolder80x58 } from "../../assets/images";

const ImageItem = ({ title, src }) => {
  return (
    <li className="list-group-item w-100">
      {title}
      <span
        className={classNames(
          "badge",
          src ? "badge-success" : "badge-danger",
          "badge-pill float-right"
        )}
      >
        <FontAwesomeIcon icon={src ? faCheck : faTimes} />
      </span>
    </li>
  );
};

const commands = [
  {
    name: "edit",
    title: "Düzenle",
    icon: faPencilAlt
  },
  {
    name: "movedown",
    title: "Aşağı",
    icon: faAngleDown
  },
  {
    name: "moveup",
    title: "Yukarı",
    icon: faAngleUp
  },
  {
    name: "remove",
    title: "Sil",
    icon: faTrash
  }
];

class Gallery extends Component {
  state = {
    edit: false,
    formData: {},
    index: -1
  };

  handleSubmit = formData => {
    
    const { items, onChange, page } = this.props;
    const { index } = this.state;
    items[index] = formData;
    items[index].updated = true;
    page.content.slides = items;

    onChange(page);
    
    this.setState({ edit: false, formData: {}, index: -1 });
  };

  handleItemClick = (command, item, index) => {
    const { items, onChange, page } = this.props;
    switch (command) {
      case "remove":
        const confirmed = window.confirm(
          "Görsel bileşen silinecektir, emin misiniz?"
        );
        if (confirmed) {
          _remove(items, p => p === item);
        }
        break;
      case "moveup":
        move(items, index, index - 1);
        break;
      case "movedown":
        move(items, index, index + 1);
        break;
      case "add":
        items.push({ id: items.length + 1 });
        break;
      case "edit":
        this.setState({
          edit: true,
          formData: item,
          index
        });
        return;
      default:
        break;
    }
    page.content.slides = items;
    onChange(page);
  };

  handleClose = event => {
    const { onClose } = this.props;
    this.setState({ edit: false, formData: {}, index: -1 }, () =>
      onClose(event)
    );
  };

  render() {
    const { items, show } = this.props;
    return (
      show && (
        <Modal
          show={true}
          onCloseClick={this.handleClose}
          className="modal-container galleryModal"
          dialogClassName="modal-lg"
        >
          <div className="modal-header border-0">
            <CloseButton onClick={this.handleClose} />
          </div>
          <div className="modal-body">
            {this.state.edit ? (
              <BaseForm
                schema={FormSchemas.slider.schema}
                uiSchema={FormSchemas.slider.uiSchema}
                formData={this.state.formData}
                onSubmit={this.handleSubmit}
              >
                <button
                  type="button"
                  className="btn btn-primary px-5 py-2 my-3 mr-2 fs-14 font-weight-500 text-capitalize border-radius-8"
                  onClick={() =>
                    this.setState({ edit: false, formData: {}, index: -1 })
                  }
                >
                  İptal
                </button>
                <button
                  type="submit"
                  className="btn btn-primary px-5 py-2 my-3 fs-14 font-weight-500 text-capitalize border-radius-8"
                >
                  Kaydet
                </button>
              </BaseForm>
            ) : (
                <>
                  <div
                    className="btn-toolbar"
                    role="toolbar"
                    aria-label="Toolbar with button groups"
                  >
                    <div
                      className="btn-group btn-group-sm mr-2"
                      role="group"
                      aria-label="First group"
                    >
                      <button
                        type="button"
                        className="btn btn-primary mx-2 text-capitalize"
                        onClick={() => this.handleItemClick("add", null, -1)}
                      >
                        <FontAwesomeIcon className="mr-2" icon={faPlusCircle} />
                        <span>Yeni Ekle</span>
                      </button>
                    </div>
                  </div>
                  <ul className="list-unstyled gallery-list mt-4">
                    {items.map((item, i) => (
                      <li className="media mb-3" key={i}>
                        <figure className="mr-3">
                          {item.thumbnail ? (
                            <img src={item.thumbnail} alt="..." />
                          ) : (
                              <PlaceHolder80x58 />
                            )}
                        </figure>
                        <div className="media-body">
                          <ul className="list-group list-group-horizontal mb-1 w-100">
                            <ImageItem title="Küçük Resim" src={item.thumbnail} />
                            <ImageItem title="Mobil" src={item.mobile} />
                            <ImageItem title="Web" src={item.web} />
                          </ul>
                          <div
                            className="btn-group btn-group-sm mr-2"
                            role="group"
                            aria-label="First group"
                          >
                            {commands.map(command => (
                              <button
                                type="button"
                                className="btn btn-primary btn-sm text-capitalize"
                                onClick={() =>
                                  this.handleItemClick(command.name, item, i)
                                }
                                key={command.name}
                              >
                                <FontAwesomeIcon
                                  className="mr-2"
                                  icon={command.icon}
                                />
                                <span>{command.title}</span>
                              </button>
                            ))}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
          </div>
        </Modal>
      )
    );
  }
}

Gallery.propTypes = {};

export default Gallery;
