import React, { PureComponent } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Route, Switch, Redirect } from "react-router-dom";
import { MenuItem, Breadcrumb } from "../../components";
import * as actions from "../../routes/actions/Account";

class Index extends PureComponent {
  logout = (e) => {
    e.preventDefault();
    this.props.logout(this.props.history);
  };

  render() {
    const { routes, user } = this.props;
    const landscape = window.matchMedia("(min-width: 1200px)");

    let show = true;

    const currentRoute = routes.find(
      (item) => item.path === this.props.location.pathname
    );

    const breadcrumbs = [
      {
        title: "Anasayfa",
        url: "/",
      },
    ];

    if (currentRoute) {
      breadcrumbs.push({
        title: "Hesabım",
        url: "/hesabim",
      });

      breadcrumbs.push({
        title: currentRoute.title,
        active: true,
      });

      show = landscape.matches || false;
    } else if (
      this.props.location.pathname.indexOf("/hesabim/siparislerim/") !== -1
    ) {
      const order = routes.find(
        (item) => item.path === "/hesabim/siparislerim"
      );
      const orderDetail = routes.find(
        (item) => item.path === "/hesabim/siparislerim/:id"
      );

      breadcrumbs.push({
        title: "Hesabım",
        url: "/hesabim",
      });

      breadcrumbs.push({
        title: order.title,
        url: order.path,
      });

      breadcrumbs.push({
        title: orderDetail.title,
        active: true,
      });

      show = landscape.matches || false;
    } else if (landscape.matches) {
      return <Redirect to={routes[0].path} />;
    } else {
      breadcrumbs.push({
        title: "Hesabım",
        active: true,
      });
    }

    return (
      <div className="container ">
        <div className="row">
          <div className="col-12">
            <Breadcrumb items={breadcrumbs} />
          </div>
          <div
            className={classNames("col-12 col-lg-3 p-0 mb-4 d-none", {
              "d-block": show,
            })}
          >
            <h1 className="font-weight-bold" style={{ paddingLeft: 20 }}>Hesabım</h1>
            <div className="list-group collapse account-menu show">
              {routes
                .filter(
                  (p) =>
                    !p.hidden &&
                    ((p.roles || [""]).some((p) => p !== "editor") ||
                      user.editor)
                )
                .map((item, i) => (
                  <MenuItem
                    listGroupItem
                    className={classNames({
                      active:
                        this.props.location.pathname.indexOf(item.path) === 0,
                    })}
                    to={item.path}
                    icon={item.icon}
                    key={i}
                    onClick={this.handleClick}
                  >
                    {item.title}
                  </MenuItem>
                ))}
              <MenuItem
                noRedirect={true}
                onClick={this.logout}
                icon="iExit"
                className="mb-3"
                name="logout"
              >
                Çıkış Yap
              </MenuItem>
            </div>
          </div>
          <div className="col-12 col-lg-9 p-0 mb-3">
            <Switch>
              {routes.map((item, i) => (
                <Route
                  path={item.path}
                  component={item.component}
                  exact
                  key={i}
                />
              ))}
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

Index.propTypes = {};

const mapStateToProps = ({ AccountReducer }) => ({
  user: AccountReducer.user,
});

const mapDispatchToProps = {
  logout: actions.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
