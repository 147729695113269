import React from "react";
import { Modal } from "../../components";
import CloseButton from "../../components/Button/Close";
import Icon from "../../components/Icon/Index";

const Helper = ({ show, onCloseClick, onClick }) => {
  return (
    <Modal
      className="forgotPasswordModal modal-container non-modal-height"
      show={show}
      onCloseClick={onCloseClick}
    >
      <div className="modal-header bg-0 p-0 d-flex justify-content-start align-items-center">
        <span />
        <button className="border-0 bg-transparent mr-3" onClick={onClick}>
          <Icon icon="iBackArrow" />
        </button>
        <h5 className="modal-title fs-20 font-weight-bold color-484848">
          Bize Ulaşın
        </h5>
        <CloseButton onClick={onCloseClick} />
      </div>
      <div className="modal-body">
        <div
          className="d-flex align-items-center"
          style={{ marginBottom: 43, marginTop: 40 }}
        >
          <Icon icon="iPhone" className="mr-3" />
          <div className="d-flex flex-column fs-16 font-weight-600 color-545454">
            <span>Telefon</span>
            <span>0212 963 45 15</span>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <Icon icon="iSMS" className="mr-3" />
          <div className="d-flex flex-column fs-16 font-weight-600 color-545454">
            <span>E-Posta</span>
            <span>destek@tongucmagaza.com</span>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default Helper;
