import React, { Component } from "react";
//import PropTypes from 'prop-types';
import MaskComponent from "react-input-mask";

class Mask extends Component {
  handleChange = event => {
    const { onChange } = this.props;
    const target = event.target;
    onChange(target.name, target.value);
  };

  render() {
    const { className, name, uiSchema, value, placeholder, readOnly } = this.props;
    const { enableAddRow, ...attributes } = uiSchema["ui:options"];
    return (
      <MaskComponent
      className={className}
      name={name}
        value={value || ""}
        onChange={this.handleChange}
        placeholder={placeholder}
        {...attributes}
        readOnly={readOnly}
      />
    );
  }
}

Mask.propTypes = {};

export default Mask;
