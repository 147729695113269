import React, { PureComponent } from "react";
import Modal from "../../components/Modal/Index";
import { BaseForm } from "../../components";
import FormSchemas from "../../variables/FormSchemas";
import CloseButton from "../../components/Button/Close";
import Icon from "../../components/Icon/Index";

class ForgotPassword extends PureComponent {
  state = { formData: {} };

  remindPassword = (email) => {
    this.props.remindPassword(email);
  };

  render() {
    return (
      <Modal
        className="forgotPasswordModal modal-container non-modal-height"
        show={this.props.show}
      >
        <div className="modal-header bg-0 px-0 d-flex justify-content-start">
          <span />
          <button
            className="border-0 bg-transparent mr-3"
            onClick={this.props.onClick}
          >
            <Icon icon="iBackArrow" />
          </button>
          <h5 className="modal-title font-weight-bold color-484848">
            Şifrenizi Yenileyin
          </h5>
          <CloseButton onClick={this.props.onCloseClick} />
        </div>
        <div className="modal-body">
          <p className="fs-14 font-weight-500">
            Üyeliğinizin olduğu e-posta adresini aşağıdaki kutucuğa giriniz.
            E-posta adresinize link gönderilecektir.
          </p>
          {this.props.show && (
            <BaseForm
              schema={FormSchemas.forgotPassword.schema}
              uiSchema={FormSchemas.forgotPassword.uiSchema}
              formData={this.state.formData}
              onSubmit={this.remindPassword}
              onClick={this.props.onClick}
            >
              <div className="d-flex ">
                <button
                  type="submit"
                  className="btn btn-primary btn-block py-2 fs-16 font-weight-600 border-radius-12 flex-fill"
                >
                  Gönder
                </button>
              </div>
              <div
                className="fs-12 font-weight-600 mt-4 mb-3 color-6e87ff cursor-pointer"
                onClick={this.props.showHelper}
              >
                Yardıma ihtiyacım var
              </div>
            </BaseForm>
          )}
        </div>
      </Modal>
    );
  }
}
export default ForgotPassword;
