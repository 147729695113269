import React from "react";
//import PropTypes from 'prop-types';
import ReactPlayer from "react-player";

const PlayerView = ({ url }) => {
  return (
    <div className="card mx-2">
      <div className="card-body" style={url ? { paddingTop: "56.25%" } : null}>
        {url ? (
          <ReactPlayer
            url={url}
            width="100%"
            height="100%"
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0
            }}
          />
        ) : (
          <p className="card-text">Buraya video ekleyin...</p>
        )}
      </div>
    </div>
  );
};

PlayerView.propTypes = {};

export default PlayerView;
