import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "./SearchBox";
import { LinkButton, DropdownMenu, MenuItem, Button } from "../components";
import {
  LoginModal,
  RegisterModal,
  ForgotPasswordModal,
  ResendMailConfirmModal,
  EmailActivationModal,
  ContractsModal,
  UpdateClassModal,
} from "../views";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import * as actions from "../routes/actions/Account";
import { getSettings } from "../routes/actions/Settings";
import { getBasket, search } from "../routes/actions/Product";
import Icon from "../components/Icon/Index";
import StoreModal from "../components/Modal/StoreModal";
import Helper from "../views/Account/Helper";
import Email from "../views/Verification/Email";
import ResetPassword from "../views/Password/ResetPassword";
// import { ModalConst } from "../variables/Constants";
import { keys, tongucAkademiEndpoint } from "../services/constants";
import storage from "../services/storage";

// const formatDate = (date) => {
//   const options = {
//     weekday: "long",
//     day: "numeric",
//     month: "long",
//     year: "numeric",
//     hour: "numeric",
//     minute: "numeric",
//     hour12: false,
//     timeZone: "Europe/Istanbul",
//   };
//   return new Intl.DateTimeFormat("tr-TR", options).format(new Date(date));
// };

const ProfileMenu = ({
  authenticated,
  routes,
  onClick,
  logout,
  user,
  loginRequired,
}) => {
  return authenticated ? (
    <>
      <div className="list-group dropdown-menu-list">
        {routes.map(
          (item, i) =>
            item.hidden !== true &&
            ((item.roles || [""]).some((p) => p !== "editor") ||
              user.editor) && (
              <MenuItem
                to={item.path}
                icon={item.icon}
                key={i}
                noRedirect={
                  item.path.indexOf("/hesabim/hesap-bilgilerim") > -1 &&
                  user.demo
                }
                onClick={() => {
                  if (user.demo) {
                    toast.error(
                      "Bu işlemi DEMO modunda yapamazsınız. Devam etmek için giriş yapmanız gerekiyor."
                    );
                    loginRequired(true);
                  }
                }}
              >
                {item.title}
              </MenuItem>
            )
        )}
        <MenuItem onClick={logout} icon="iExit" name="logout">
          Çıkış Yap
        </MenuItem>
      </div>
    </>
  ) : null;
};

class Navbar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      showLogin: false,
      showRegister: false,
      showForgotPassword: false,
      showResendMailConfirm: false,
      showResendMailButton: false,
      showVerification: false,
      // showResetPassword: false,
      showHelper: false,
      showUpdateClass: true,
      authenticated: props.user.id ? true : false,
      showClassrooms: "",
      isDeskMode: false,
      handleClick: false,
      storeModal: "",
      basketCount: null,
    };

    document.addEventListener("mousedown", this.handleClick, false);
    this.processing = false;
  }
  categoryStyleList = [
    {
      background: "#f5f7ff",
      color: "#000c47",
      activeBackground: "rgba(224, 180, 157, 0.6)",
    },
    {
      background: "#fff4ee",
      color: "#974009",
      activeBackground: "rgba(224, 180, 157, 0.6)",
    },
    {
      background: "#fff9fa",
      color: "#bc000d",
      activeBackground: "rgba(234, 196, 203, 0.6)",
    },
    {
      background: "#f8f8ff",
      color: "#192d8d",
      activeBackground: "rgba(152, 152, 223, 0.6)",
    },
    {
      background: "#fef8fd",
      color: "#620a56",
      activeBackground: "rgba(225, 135, 210, 0.6)",
    },
    {
      background: "#fff8e2",
      color: "#7e6307",
      activeBackground: "rgba(255, 219, 120, 0.6)",
    },
    {
      background: "#f5f7ff",
      color: "#000c47",
      activeBackground: "rgba(224, 180, 157, 0.6);",
    },
  ];

  componentDidMount() {
    this.props.getSettings();
    this.isCloseStoreModal();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.handleModal != this.props.handleModal &&
      this.props.handleModal?.length > 0
    ) {
      this.handleClick({ fnName: this.props.handleModal });
      this.props.setHandleModal("");
    }
    this.getBasketCountLS();
  }

  getBasketCountLS = () => {
    const basketCountLS = storage.getItem(keys.BasketCount);
    this.setState({ basketCount: basketCountLS });
  };

  closeStoreModal = () => {
    window.localStorage.setItem("closedModal", true);
    this.isCloseStoreModal();
  };

  isCloseStoreModal = () => {
    const isCloseModal = window.localStorage.getItem("closedModal");
    if (isCloseModal) {
      this.setState({ storeModal: true });
    }
  };

  fillBasketMenu = async () => {
    if (!this.proccessing && !this.props.basket.length) {
      this.processing = true;
      await this.props.getBasket();
      this.processing = false;
    }
  };

  handleClick = (event) => {
    const namesToControl = [
      "login",
      "register",
      "forgotten",
      "confirm",
      "verification",
    ];
    const name =
      event?.fnName || event?.currentTarget?.name || event?.target?.name;

    const state = {
      showLogin: name === "login",
      showRegister: name === "register",
      showForgotPassword: name === "forgotten",
      showResendMailConfirm: name === "confirm",
      showVerification: name === "verification",
      // showResetPassword: name === "password",

      handleClick: true,
    };

    if (namesToControl.findIndex((m) => m === name) > -1) {
      if (name === "authenticate") {
        state.authenticated = true;
      } else if (name === "logout") {
        state.authenticated = false;
      }

      this.setState(state);
    }
  };

  handleCreateOrder = async () => {
    var getableUrunCount = this.props.basket.filter(
      (p) => p.product?.getable
    ).length;
    if (getableUrunCount < 1) {
      toast.error(
        "Sepetinizde ürün yok. Lütfen ürün ekleyip öyle devam ediniz."
      );
      return;
    }
    if (this.props.user.demo) {
      toast.error(
        "Bu işlemi DEMO modunda yapamazsınız. Devam etmek için giriş yapmanız gerekiyor."
      );
      return;
    }
    let order = {};
    order.lines = this.props.basket
      .filter((x) => x.price !== 0 && x.product?.getable)
      .map((b) => {
        return {
          productId: b.productId,
          quantity: b.quantity,
          ...b,
        };
      });
    let result = await this.props.createOrder(order);
    if (result.success) {
      this.props.history.push(`/odeme/sepet/${result.data.id}`);
    } else {
      var message = result.error;
      if (result.error == null || result.error == undefined)
        message = "Lütfen giriş yaptıktan sonra tekrar deneyiniz.";

      toast.error(message);
    }
  };

  login = async (formData) => {
    const { success, error, inActive } = await this.props.login(formData);
    if (success) {
      this.setState(
        {
          authenticated: true,
          showLogin: false,
          showRegister: false,
          showForgotPassword: false,
          showResendMailConfirm: false,
          showResendMailButton: inActive,
          handleClick: true,
        },
        () => {
          toast.success(
            `Hoş geldin ${this.props.user.firstname} ${this.props.user.lastname}`
          );
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "login",
            email_permission: this.props.user.mailPreference,
            sms_permission: this.props.user.smsPreference,
            userId: parseInt(this.props.user.iamUserId),
          });
        },
        this.isCloseStoreModal()
      );
    } else {
      if (error == "UserNotActivated") {
        toast.error("Öncelikle e-posta adresini doğrulamalısınız.");
        const { success, error } = await this.props.sendActivationMail(
          formData.username
        );
        if (!success) {
          toast.error(error);
        }
        this.setState(
          {
            authenticated: false,
            showLogin: false,
            showRegister: false,
            showForgotPassword: false,
            showResendMailConfirm: false,
            showResendMailButton: false,
            showActivation: true,
            handleClick: true,
            email: formData.username,
          },
          () => {
            // toast.success("Kaydınız başarıyla tamamlandı. Lütfen giriş yapınız.");
          }
        );
      } else {
        toast.error(error);
        this.setState({
          showResendMailButton: inActive,
        });
      }
    }
  };

  emailConfirmation = async (formData) => {
    var result = await this.props.confirmEmail(formData);
    this.setState({
      status: result.success,
      message: result.message,
    });

    if (result.success) {
      this.setState({ showVerification: false, handleClick: true });
      this.setState(
        {
          authenticated: true,
          showLogin: false,
          showRegister: false,
          showForgotPassword: false,
          showResendMailConfirm: false,
          showResendMailButton: false,
          handleClick: true,
        },
        () => {
          toast(this.customToastSuccessRegister, {
            position: toast.POSITION.BOTTOM_LEFT,
            hideProgressBar: true,
            closeButton: false,
          });
          toast.success(
            `Hoş geldin ${result.data.user?.firstname || ""} ${
              result.data.user?.lastname || ""
            }`
          );
        }
      );
    } else {
      toast.error(result.message);
    }
  };

  logout = () => {
    this.props.logout(this.props.history);

    this.setState({
      authenticated: false,
      showLogin: false,
      showRegister: false,
      showForgotPassword: false,
      showResendMailConfirm: false,
      showResendMailButton: false,
      handleClick: true,
    });
  };

  register = async (user) => {
    const { success, error, data } = await this.props.register(user);
    if (success) {
      this.setState(
        {
          authenticated: false,
          showLogin: false,
          showRegister: false,
          showForgotPassword: false,
          showResendMailConfirm: false,
          showResendMailButton: false,
          showVerification: true,
          handleClick: true,
          email: user.email,
        },
        () => {
          toast.success(
            "Kaydınız başarıyla tamamlandı. Lütfen e-posta adresinizi doğrulayın."
          );
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "sign_up",
            email_permission: data.mailPreference,
            sms_permission: data.smsPreference,
            userId: data.iamUserId,
          });
        }
      );
    } else {
      toast.error(error);
      this.setState({
        showRegister: false,
        // showVerification: true,
        email: user.email,
      });
    }
  };

  remindPassword = async (email) => {
    const { success, error } = await this.props.remindPassword(email);

    if (success) {
      this.setState(
        {
          authenticated: false,
          showLogin: false,
          showRegister: false,
          showForgotPassword: false,
          showResendMailConfirm: false,
          handleClick: true,
        },
        () => {
          toast.success(
            "Hatırlatma maili gönderildi. Lütfen e-posta adresinizi kontrol ediniz."
          );
        }
      );
    } else {
      toast.error(error);
    }
  };

  resendMailConfirm = async (data) => {
    const { success, error } = await this.props.sendActivationMail(data.email);

    if (success) {
      this.setState(
        {
          authenticated: false,
          showLogin: false,
          showRegister: false,
          showForgotPassword: false,
          showResendMailConfirm: false,
          handleClick: true,
        },
        () => {
          toast.success(
            "Aktivasyon e-postası gönderildi. E-posta adresinize gelen aktivasyon linkine tıklayarak üyeliğinizi tamamlayın."
          );
        }
      );
    } else {
      toast.error(error);
    }
  };

  activateEmail = async (email, code) => {
    const { success, error } = await this.props.activateEmail(email, code);

    if (success) {
      this.setState(
        {
          authenticated: true,
          showLogin: false,
          showRegister: false,
          showForgotPassword: false,
          showResendMailConfirm: false,
          showResendMailButton: false,
          showActivation: false,
          handleClick: true,
        },
        () => {
          toast.success("E-posta doğrulama başarılı.");
        }
      );
    } else {
      toast.error(error);
    }
  };

  updateClass = async (formData) => {
    //console.log("updateClass", formData);
  };

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  onMobileCloseClick = () => {
    this.setState({
      collapsed: true,
      showExams: false,
      showClassrooms: false,
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const locationPath = window.location.pathname;
    const isDeskMode = locationPath.indexOf("/dijitalkitap") > -1;
    let showLogin = prevState.showLogin;

    if (
      !prevState.handleClick &&
      nextProps.isLoginRequired &&
      !prevState.showLogin
    ) {
      showLogin = true;
      nextProps.loginRequired(false);
    }
    //  else if (!prevState.handleClick && nextProps.history.location.state) {
    //   showLogin = nextProps.history.location.state.showLogin;
    // }
    let data = {
      ...prevState,
      isDeskMode,
      showLogin,
      authenticated:
        !prevState.handleClick && nextProps.history.location.state
          ? nextProps.user.id
            ? true
            : false
          : prevState.authenticated,
      handleClick: false,
    };
    return data;
  }

  render() {
    const brands = this.props.brands;
    const sortedBrands = brands.sort((a, b) => {
      if (a.brandType === 2 && b.brandType === 1) return -1; // 2 olanlar önce
      if (a.brandType === 1 && b.brandType === 2) return 1; // 1 olanlar sonra
      return 0; // Aynı tipse sıralamada değişiklik yapma
    });
    const { user } = this.props;
    const className =
      "navbar navbar-expand-lg d-flex mx-auto navbar-light bg-light";
    const collapsed = this.state.collapsed;

    const isProd =
      window.location.origin.indexOf("//tongucmagaza.com") > -1 ||
      window.location.origin.indexOf("//www.tongucmagaza") > -1 ||
      window.location.origin.indexOf("//store.tongucakademi.com") > -1 ||
      window.location.origin.indexOf("//v2.tongucmagaza.com") > -1;

    const showUpdateClassModal =
      (user.id && user.id > 0 && !user.demo && user.isClassUpdated === null) ||
      false;
    return (
      <div className="container-fluid nav-border p-0 bg-white">
        <div className="container">
          <header className={className}>
            <UpdateClassModal user={user} show={showUpdateClassModal} />
            <LoginModal
              show={this.state.showLogin}
              login={this.login}
              onClick={this.handleClick}
              showResend={this.state.showResendMailButton}
              onCloseClick={() =>
                this.setState({ showLogin: false, handleClick: true }, () => {
                  if (
                    window.location.href.indexOf(
                      "desk/book/lesson/subject/quiz/answers"
                    ) !== -1
                  ) {
                    window.location.href = "/";
                  }
                })
              }
            />
            <RegisterModal
              register={this.register}
              show={this.state.showRegister}
              onClick={this.handleClick}
              onCloseClick={() =>
                this.setState({ showRegister: false, handleClick: true })
              }
            />
            <ForgotPasswordModal
              show={this.state.showForgotPassword}
              remindPassword={this.remindPassword}
              showHelper={() => {
                this.setState({
                  showHelper: true,
                  showForgotPassword: false,
                });
              }}
              onClick={() =>
                this.setState({
                  showForgotPassword: false,
                  showLogin: true,
                })
              }
              onCloseClick={() =>
                this.setState({
                  showForgotPassword: false,
                  handleClick: true,
                })
              }
            />
            <ResendMailConfirmModal
              show={this.state.showResendMailConfirm}
              resendMailConfirm={this.resendMailConfirm}
              onCloseClick={() =>
                this.setState({
                  showResendMailConfirm: false,
                  handleClick: true,
                })
              }
            />
            <EmailActivationModal
              onClick={this.handleClick}
              show={this.state.showActivation}
              email={this.state.email}
              activateEmail={this.activateEmail}
              sendActivationMail={this.resendMailConfirm}
              onCloseClick={() =>
                this.setState({
                  showActivation: false,
                  handleClick: true,
                  email: "",
                })
              }
            />
            <Helper
              show={this.state.showHelper}
              onClick={() =>
                this.setState({
                  showHelper: false,
                  showForgotPassword: true,
                })
              }
              onCloseClick={() =>
                this.setState({
                  showHelper: false,
                })
              }
            />
            <Email
              userEmail={this.state.email}
              show={this.state.showVerification}
              onCloseClick={() =>
                this.setState({ showVerification: false, handleClick: true })
              }
              emailConfirmation={this.emailConfirmation}
              onClick={() =>
                this.setState({
                  showVerification: false,
                  showRegister: true,
                })
              }
            />
            <ResetPassword
            // onCloseClick={() =>
            //   this.setState({ showResetPassword: false, handleClick: true })
            // }
            />
            <ContractsModal />
            <div
              className=" d-flex align-items-center justify-content-center"
              style={{ width: "100%", justifyContent: "inherit" }}
            >
              <div className="d-flex align-items-center flex-lg-row-reverse position-relative">
                {!(window.location.pathname.indexOf("/odeme") > -1) ? (
                  <button
                    onClick={this.toggleNavbar}
                    className={classNames(
                      "navbar-toggler navbar-toggler-right float-left",
                      { collapsed: collapsed }
                    )}
                    type="button"
                    style={{ marginTop: 5, marginRight: 5 }}
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                ) : null}
                <Link
                  className="navbar-brand main-brand py-0 float-left"
                  to={this.props.light ? "/" : "/dijitalkitap"}
                >
                  <img
                    src={require("../assets/images/main/tmlogo.svg")}
                    alt="logo"
                  />
                </Link>
              </div>
              {!this.props.isPaymentPage && (
                <>
                  <Button
                    className={
                      "btn-custom btn-category d-none d-lg-flex align-items-center"
                    }
                    onClick={this.toggleNavbar}
                  >
                    <Icon icon="iHamburger" />
                    Kategoriler
                  </Button>
                  <div className="float-lg-left search-box d-md-flex align-items-center ">
                    <SearchBox isDeskMode={this.state.isDeskMode} />
                  </div>
                  <div
                    className={`btn-custom login-box  btn-login align-items-center justify-content-around ${
                      this.props.user.id ? "d-none" : ""
                    }`}
                  >
                    <span onClick={() => this.handleClick({ fnName: "login" })}>
                      Giriş Yap
                    </span>
                    <div className="line" />
                    <span
                      onClick={() => this.handleClick({ fnName: "register" })}
                    >
                      Üye Ol
                    </span>
                  </div>
                  {/* <div className={`${!this.props.user.id ? "d-none" : ""}`}>
                    <div className="work-toggle d-xl-flex d-none  mt-0 flex-lg-1 flex-sm-1">
                      <button
                        onClick={async () => {
                          window.location.href = tongucAkademiEndpoint;
                        }}
                        style={{ cursor: "pointer" }}
                        className={classNames("btn")}
                      >
                        Online Eğitim1
                      </button>
                      {this.state.isDeskMode ? (
                        <button
                          onClick={async () => {
                            if (this.props.user.id && this.props.user.demo) {
                              this.props.logoutDemoUser();
                            }
                            this.props.history.push("/");
                          }}
                          style={{ cursor: "pointer" }}
                          className={classNames("btn", {
                            active: !this.state.isDeskMode,
                          })}
                        >
                          Kitap Mağazası
                        </button>
                      ) : (
                        <button
                          onClick={async () => {
                            if (!this.props.user.id) {
                              if (isProd) {
                                this.props.loginRequired(true);
                                return;
                              } else {
                                await this.props.loginAsDemoUser();
                              }
                            }
                            this.props.history.push(
                              this.props.bypassDeskMode
                                ? "/dijitalkitap/sinav-modu"
                                : "/dijitalkitap"
                            );
                          }}
                          style={{ cursor: "pointer" }}
                          className={classNames("btn", {
                            active: this.state.isDeskMode,
                          })}
                        >
                          Dijital Kitap
                        </button>
                      )}
                    </div>
                    <StoreModal
                      storeModal={this.state.storeModal}
                      closeStoreModal={this.closeStoreModal}
                    />
                  </div> */}

                  {this.props.user.id ? (
                    <div className="position-relative">
                      <DropdownMenu
                        className={"card-account-menu"}
                        body={
                          <ProfileMenu
                            authenticated={this.props.user.id}
                            logout={this.logout}
                            routes={this.props.routes}
                            user={this.props.user}
                            onClick={this.handleClick}
                            loginRequired={this.props.loginRequired}
                          >
                            <button className="btn btn-link p-2 nav-link text-nowrap" />
                          </ProfileMenu>
                        }
                      >
                        {this.props.user.id ? (
                          this.props.user.avatar ? (
                            <img
                              className="btn-profile"
                              src={this.props.user.avatar.path}
                              alt=""
                              width="28"
                              height="28"
                            />
                          ) : (
                            <div className="default-avatar btn-profile">
                              {user.firstname && user.lastname
                                ? user.firstname.charAt(0).toUpperCase() +
                                  user.lastname.charAt(0).toUpperCase()
                                : "DK"}
                            </div>
                          )
                        ) : null}
                      </DropdownMenu>
                    </div>
                  ) : null}

                  <div className="d-flex">
                    <LinkButton
                      to="/sepetim"
                      className={`btn-custom btn-basket d-flex align-items-center justify-content-center ${
                        this.state.basketCount > 0 ? "active" : ""
                      }`}
                    >
                      <span
                        className="basket-quantity"
                        style={{ left: this.state.basketCount > 9 ? 25 : 29 }}
                      >
                        {this.state.basketCount}
                      </span>
                      <Icon icon="iBasket" />
                      <span className="d-none d-sm-block ml-2">Sepetim</span>
                    </LinkButton>
                  </div>

                  {!collapsed ? (
                    <div
                      className="menu-mobile-backdrop"
                      onClick={this.onMobileCloseClick}
                    />
                  ) : null}

                  <div
                    className={classNames("menuMobile", {
                      active: !collapsed,
                    })}
                  >
                    <Button
                      className="closeMenuMobile"
                      onClick={this.onMobileCloseClick}
                    >
                      <Icon icon={"iCloseMenu"} title="Kapat" />
                    </Button>
                    <ul className="menu-user-container">
                      <li className="menu-user-item">
                        <div className="menu-user-box">
                          {this.state.authenticated ? (
                            this.props.user.avatar ? (
                              <img
                                className="btn-profile"
                                src={this.props.user.avatar.path}
                                alt=""
                                width="28"
                                height="28"
                              />
                            ) : (
                              <div className="default-avatar btn-profile d-xl-flex d-none">
                                {user.firstname && user.lastname
                                  ? user.firstname.charAt(0).toUpperCase() +
                                    user.lastname.charAt(0).toUpperCase()
                                  : "DK"}
                              </div>
                            )
                          ) : // <Icon icon="iUser" />
                          null}
                          <div className="mr-1">
                            Hoş geldin {this.props.user.firstname}
                          </div>
                          <div
                            className={`btn-custom login-box-mobile btn-login align-items-center justify-content-center ${
                              this.state.authenticated ? "d-none" : ""
                            }`}
                          >
                            <span
                              onClick={() =>
                                this.handleClick({ fnName: "login" })
                              }
                            >
                              Giriş Yap&nbsp;
                            </span>
                            /
                            <span
                              onClick={() =>
                                this.handleClick({ fnName: "register" })
                              }
                            >
                              &nbsp;Üye Ol
                            </span>
                          </div>
                        </div>
                      </li>

                      <li
                        className={`menu-user-item ${
                          !this.state.authenticated ? "d-none" : ""
                        }`}
                      >
                        {/* <div className="work-toggle w-100 mt-0 flex-lg-1 flex-sm-1">
                          <button
                            onClick={async () => {
                              window.location.href = tongucAkademiEndpoint;
                            }}
                            style={{ cursor: "pointer" }}
                            className={classNames("btn")}
                          >
                            Online Eğitim
                          </button>
                          {this.state.isDeskMode ? (
                            <button
                              onClick={async () => {
                                if (
                                  this.props.user.id &&
                                  this.props.user.demo
                                ) {
                                  this.props.logoutDemoUser();
                                }
                                this.props.history.push("/");
                              }}
                              style={{ cursor: "pointer" }}
                              className={classNames("btn", {
                                active: !this.state.isDeskMode,
                              })}
                            >
                              Kitap Mağazası
                            </button>
                          ) : (
                            <button
                              onClick={async () => {
                                if (!this.props.user.id) {
                                  if (isProd) {
                                    this.props.loginRequired(true);
                                    return;
                                  } else {
                                    await this.props.loginAsDemoUser();
                                  }
                                }
                                this.props.history.push(
                                  this.props.bypassDeskMode
                                    ? "/dijitalkitap/sinav-modu"
                                    : "/dijitalkitap"
                                );
                              }}
                              style={{ cursor: "pointer" }}
                              className={classNames("btn", {
                                active: this.state.isDeskMode,
                              })}
                            >
                              Dijital Kitap
                            </button>
                          )}
                        </div> */}
                      </li>
                      <li
                        className={`menu-user-item px-3 ${
                          !this.state.authenticated ? "d-none" : ""
                        }`}
                      >
                        <Link
                          to="/hesabim"
                          className="d-flex align-items-center menu-operations"
                          onClick={this.onMobileCloseClick}
                        >
                          <div className="menu-user-img">
                            <img
                              className="img-fluid"
                              src={require("../assets/images/icons/icon-hesabim.svg")}
                              alt=""
                            />
                          </div>
                          Hesabım
                        </Link>
                      </li>
                      <li
                        className={`menu-user-item px-3 ${
                          !this.state.authenticated ? "d-none" : ""
                        }`}
                      >
                        <Link
                          to="/hesabim/siparislerim"
                          className="d-flex align-items-center menu-operations"
                          onClick={this.onMobileCloseClick}
                        >
                          <div className="menu-user-img">
                            <img
                              className="img-fluid"
                              src={require("../assets/images/icons/icon-siparislerim.svg")}
                              alt=""
                            />
                          </div>
                          Siparişlerim
                        </Link>
                      </li>
                    </ul>
                    <h6 className="menu--category">Kategoriler</h6>
                    <ul className="menuMobileList">
                      <li>
                        <a href="#top" className="d-inline">
                          <img
                            className="logo"
                            src={require("../assets/images/main/tmlogo.svg")}
                            alt=""
                          />
                        </a>
                      </li>

                      {this.state.isDeskMode ? (
                        <>
                          <li className="tats-list-item">
                            <Link
                              to="/dijitalkitap/profile"
                              onClick={this.onMobileCloseClick}
                            >
                              <img
                                src={require("../assets/images/icons/icon-menu-profilim.svg")}
                                alt="Profil"
                              />
                              <span>Profil</span>
                            </Link>
                          </li>
                          <li className="tats-list-item">
                            <Link
                              to="/dijitalkitap/books"
                              onClick={this.onMobileCloseClick}
                            >
                              <img
                                src={require("../assets/images/icons/icon-menu-kutuphanem.svg")}
                                alt="Kütüphanem"
                              />

                              <span>Kütüphanem</span>
                            </Link>
                          </li>
                          {/* <li className="tats-list-item">
                            <Link
                              to={"/dijitalkitap/sinav-modu"}
                              onClick={this.onMobileCloseClick}
                            >
                              <img
                                src={require("../assets/images/icons/icon-menu-sinavmodu.svg")}
                                alt="Sınav Modu"
                              />

                              <span>Sınav Modu</span>
                            </Link>
                          </li> */}
                          {/* <li className="tats-list-item">
                            <Link
                              to={isProd ? "/dijitalkitap" : "/dijitalkitap/programci"}
                              onClick={this.onMobileCloseClick}
                              style={{
                                pointerEvents: isProd ? "none" : "unset",
                              }}
                            >
                              <img
                                src={require("../assets/images/icons/icon-menu-programci.svg")}
                                alt="Programcı"
                              />
                              <span>Programcı</span>
                              {isProd && (
                                <small
                                  style={{
                                    marginLeft: "5px",
                                    wordBreak: "unset",
                                  }}
                                >
                                  (Yakında)
                                </small>
                              )}
                            </Link>
                          </li>
                          <li className="tats-list-item">
                            <Link
                              to={isProd ? "/dijitalkitap" : "/dijitalkitap/analizci"}
                              onClick={this.onMobileCloseClick}
                              style={{
                                pointerEvents: isProd ? "none" : "unset",
                              }}
                            >
                              <img
                                src={require("../assets/images/icons/icon-menu-analizci.svg")}
                                alt="Analizci"
                              />

                              <span>Analizci</span>
                              {isProd && (
                                <small
                                  style={{
                                    marginLeft: "5px",
                                    wordBreak: "unset",
                                  }}
                                >
                                  (Yakında)
                                </small>
                              )}
                            </Link>
                          </li> */}
                        </>
                      ) : (
                        <>
                          <li
                            style={{
                              backgroundColor:
                                this.categoryStyleList[0].background,
                            }}
                          >
                            <Link
                              to="/urunler"
                              onClick={this.onMobileCloseClick}
                            >
                              <div className="menu-mobile-container">
                                <div className="menu-mobile-img">
                                  <img
                                    className="img-fluid"
                                    src={require("../assets/images/topSlider/tum-urunler.svg")}
                                    alt=""
                                  />
                                </div>
                                <div className="menu-mobile-box">
                                  <h6
                                    style={{
                                      color: this.categoryStyleList[0].color,
                                    }}
                                  >
                                    Tüm Ürünler
                                  </h6>
                                  <div
                                    className="menu-mobile-text"
                                    style={{
                                      color: this.categoryStyleList[0].color,
                                    }}
                                  >
                                    Tüm Kitaplarımız burada (
                                    {this.props.taxonomies.length > 0
                                      ? this.props.taxonomies[0]
                                          .totalProductCount
                                      : "..."}{" "}
                                    ürün)
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </li>
                          {this.props.taxonomies.map((item, i) =>
                            item.taxons.some((taxon) => taxon.count > 0) ? (
                              <li
                                key={i}
                                className="dropdown ddMblLesson"
                                style={{
                                  backgroundColor:
                                    this.state.showClassrooms === item.id
                                      ? this.categoryStyleList[
                                          i % this.categoryStyleList.length
                                        ].activeBackground
                                      : this.categoryStyleList[
                                          i % this.categoryStyleList.length
                                        ].background,
                                }}
                              >
                                <a
                                  href="javascript:;"
                                  onClick={() => {
                                    this.state.showClassrooms === item.id
                                      ? this.setState({
                                          showClassrooms: "",
                                          showExams: false,
                                        })
                                      : this.setState({
                                          showClassrooms: item.id,
                                          showExams: false,
                                        });
                                  }}
                                >
                                  <div
                                    className={`menu-mobile-container
                                     ${
                                       this.state.showClassrooms === item.id
                                         ? "active"
                                         : ""
                                     }
                                    `}
                                  >
                                    <div className="menu-mobile-img">
                                      <img
                                        className="img-fluid"
                                        src={item.image}
                                        alt=""
                                      />
                                    </div>
                                    <div
                                      className="menu-mobile-box"
                                      style={{
                                        color:
                                          this.categoryStyleList[
                                            i % this.categoryStyleList.length
                                          ].color,
                                      }}
                                    >
                                      <h6
                                        style={{
                                          color:
                                            this.categoryStyleList[
                                              i % this.categoryStyleList.length
                                            ].color,
                                        }}
                                      >
                                        {item.name}
                                      </h6>
                                      {item.isNew && (
                                        <div className="category-badge">
                                          yeni
                                        </div>
                                      )}
                                      <div className="menu-mobile-text">
                                        {item.description}
                                        {`(${item.count || 0} ürün)`}
                                      </div>
                                    </div>
                                    <FontAwesomeIcon
                                      icon={faChevronRight}
                                      className={`menu-mobile-arrow ${
                                        this.state.showClassrooms === item.id
                                          ? "active"
                                          : ""
                                      }`}
                                      // style={{
                                      //   fill: this.categoryStyleList[
                                      //     i + (1 % this.categoryStyleList.length)
                                      //   ].color,
                                      // }}
                                    />
                                  </div>
                                </a>

                                <div
                                  className={`menu-dropdown ${
                                    this.state.showClassrooms === item.id
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <h6>{item.name}</h6>
                                  <ul>
                                    {item.taxons.map((taxon) =>
                                      taxon.count > 0 ||
                                      item.taxons.some((t) =>
                                        this.props.filters.taxonomies.includes(
                                          t.id.toString()
                                        )
                                      ) ? (
                                        <li
                                          key={taxon.id}
                                          style={{
                                            backgroundColor:
                                              window.innerWidth < 1200
                                                ? this.categoryStyleList[
                                                    i %
                                                      this.categoryStyleList
                                                        .length
                                                  ].background
                                                : "transparent",
                                          }}
                                        >
                                          <Link to={`/urunler?t=${taxon.id}`}>
                                            <div
                                              style={{
                                                color:
                                                  this.categoryStyleList[
                                                    i %
                                                      this.categoryStyleList
                                                        .length
                                                  ].color,
                                              }}
                                              onClick={this.onMobileCloseClick}
                                            >
                                              {taxon.name}
                                            </div>
                                          </Link>
                                        </li>
                                      ) : null
                                    )}
                                  </ul>
                                </div>
                              </li>
                            ) : null
                          )}

                          <li
                            key={55}
                            className="dropdown ddMblLesson markalar-menu-item"
                            style={{
                              backgroundColor:
                                this.state.showClassrooms === 55
                                  ? this.categoryStyleList[
                                      1 % this.categoryStyleList.length
                                    ].activeBackground
                                  : this.categoryStyleList[
                                      1 % this.categoryStyleList.length
                                    ].background,
                            }}
                          >
                            <a
                              href="javascript:;"
                              onClick={() => {
                                this.state.showClassrooms === 55
                                  ? this.setState({
                                      showClassrooms: "",
                                      showExams: false,
                                    })
                                  : this.setState({
                                      showClassrooms: 55,
                                      showExams: false,
                                    });
                              }}
                            >
                              <div
                                className={`menu-mobile-container
                                     ${
                                       this.state.showClassrooms === 55
                                         ? "active"
                                         : ""
                                     }
                                    `}
                              >
                                <div className="menu-mobile-img">
                                  <img
                                    className="img-fluid"
                                    src={require("../assets/images/topSlider/markalarhamburger2.png")}
                                    alt=""
                                  />
                                </div>
                                <div
                                  className="menu-mobile-box"
                                  style={{
                                    color:
                                      this.categoryStyleList[
                                        1 % this.categoryStyleList.length
                                      ].color,
                                  }}
                                >
                                  <h6
                                    style={{
                                      color:
                                        this.categoryStyleList[
                                          1 % this.categoryStyleList.length
                                        ].color,
                                    }}
                                  >
                                    Markalar
                                  </h6>
                                  <div className="menu-mobile-text">
                                    Markalar
                                    {`(${this.props.brands.reduce(
                                      (total, brand) => total + brand.count,
                                      0
                                    )} ürün)`}
                                  </div>
                                </div>
                                <FontAwesomeIcon
                                  icon={faChevronRight}
                                  className={`menu-mobile-arrow ${
                                    this.state.showClassrooms === 55
                                      ? "active"
                                      : ""
                                  }`}
                                  // style={{
                                  //   fill: this.categoryStyleList[
                                  //     i + (1 % this.categoryStyleList.length)
                                  //   ].color,
                                  // }}
                                />
                              </div>
                            </a>

                            <div
                              className={`menu-dropdown ${
                                this.state.showClassrooms === 55 ? "active" : ""
                              }`}
                            >
                              <h6>Markalar</h6>
                              <ul>
                                {sortedBrands.map((brand) =>
                                  brand.count > 0 ? (
                                    <li
                                      key={brand.id}
                                      style={{
                                        backgroundColor:
                                          window.innerWidth < 1200
                                            ? this.categoryStyleList[
                                                1 %
                                                  this.categoryStyleList.length
                                              ].background
                                            : "transparent",
                                      }}
                                    >
                                      <Link to={`/urunler?marka=${brand.id}`}>
                                        <div
                                          style={{
                                            color:
                                              this.categoryStyleList[
                                                1 %
                                                  this.categoryStyleList.length
                                              ].color,
                                          }}
                                          onClick={this.onMobileCloseClick}
                                        >
                                          {brand.name}
                                        </div>
                                      </Link>
                                    </li>
                                  ) : null
                                )}
                              </ul>
                            </div>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </>
              )}
            </div>
          </header>
        </div>
        <div
          onClick={this.closeStoreModal}
          className={`main-backdrop ${
            !this.state.authenticated ||
            (this.state.storeModal && this.state.authenticated)
              ? "none"
              : ""
          }`}
        ></div>
      </div>
    );
  }
}

Navbar.propTypes = {
  light: PropTypes.bool,
};

const mapStateToProps = ({
  AccountReducer,
  ProductReducer,
  SettingsReducer,
}) => ({
  isLoginRequired: AccountReducer.loginRequired,
  user: AccountReducer.user,
  basket: ProductReducer.basket,
  basketCount: ProductReducer.basketCount,
  taxonomies: ProductReducer.taxonomiesFooter,
  brands: ProductReducer.allBrands,
  searchResult: ProductReducer.searchResult,
  notifications: ProductReducer.notifications,
  bypassDeskMode: SettingsReducer.bypassDeskMode,
  handleModal: AccountReducer.handleModal,
  filters: ProductReducer.filters,
});

const mapDispatchToProps = {
  login: actions.login,
  loginAsDemoUser: actions.loginAsDemoUser,
  logoutDemoUser: actions.logoutDemoUser,
  logout: actions.logout,
  loginRequired: actions.loginRequired,
  register: actions.register,
  remindPassword: actions.remindPassword,
  sendActivationMail: actions.sendActivationMail,
  activateEmail: actions.activateEmail,
  getBasket: getBasket,
  search: search,
  createOrder: actions.createOrder,
  getSettings,
  setHandleModal: actions.setHandleModal,
  confirmEmail: actions.confirmEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
