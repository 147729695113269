import React from "react";
//import PropTypes from 'prop-types';
import classNames from "classnames";

const Selection = ({title, value, selectedValue, onSelect}) => (
    <button
        className={classNames("btn", {
            active: selectedValue === value
        })}
        onClick={() => onSelect(value)}>
        {title}
    </button>
);

Selection.propTypes = {};

export default Selection;
