import React, { Component } from "react";
import PropTypes from "prop-types";
import { PlyrComponent } from "plyr-react";

const defaultOptions = {
  controls: [
    "play",
    "progress",
    "current-time",
    "duration",
    "volume",
    "settings",
    "fullscreen",
  ],
  speed: { selected: 1, options: [0.5, 1, 1.25, 1.5, 1.75, 2] },
  i18n: {
    restart: "Yeniden Başlat",
    rewind: "Geri Sar {seektime}s",
    play: "Oynat",
    pause: "Duraklat",
    fastForward: "İleri Sar {seektime}",
    seek: "Atla",
    seekLabel: "{currentTime} / {duration}",
    played: "Başladı",
    buffered: "Yüklendi",
    currentTime: "Mevcut Süre",
    duration: "Süre",
    volume: "Ses",
    mute: "Sesi Kapat",
    unmute: "Sesi aç",
    enableCaptions: "Altyazıyı aç",
    disableCaptions: "Altyazıyı kapat",
    download: "İndir",
    enterFullscreen: "Tam Ekrana Gir",
    exitFullscreen: "Tam Ekrandan Çık",
    frameTitle: "Player for {title}",
    captions: "Altyazı",
    settings: "Ayarlar",
    menuBack: "Önceki menüye dön",
    speed: "Hız",
    normal: "Normal",
    quality: "Kalite",
    loop: "Döngü başlat",
  },
};
class ReactPlyr extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // setTimeout(() => {
    //     this.player = new Plyr('.plyr__video-embed' + this.props.id, this.props.options);
    // }, 1000);
  }

  componentWillUnmount() {
    // if (this.player) { this.player.destroy(); }
  }

  render() {
    const { url, type, poster, id, options } = this.props;
    const sources = {
      type: "video",
      sources:
        type == "video"
          ? [
              {
                src: url,
                source: "video/mp4",
              },
            ]
          : [
              {
                src: url,
                provider: type,
              },
            ],
      poster,
    };
    return url ? (
      <PlyrComponent
        key={id}
        options={{ ...defaultOptions, ...options }}
        sources={sources}
      />
    ) : null;
  }
}

ReactPlyr.propTypes = {
  url: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.object,
  sources: PropTypes.object,
  poster: PropTypes.string,
};

export default ReactPlyr;
