import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import apiCall from "../../services/api";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as actions from "../../routes/actions/Product";
import { replaceDotWithComma } from "../../helpers";

const ProductDetailSlider = ({ title, productId, ...props }) => {
  const [slider, setSlider] = useState([]);
  useEffect(() => {
    if (productId) {
      apiCall(`/products/getproductidsofset/${productId}`).then((res) => {
        setSlider(res.data);
      });
    }
  }, [productId]);



  const addProductToBasket = async (product) => {
    const item = {
      key: props.basketKey,
      productId: product.id,
      quantity: 1,
      update: false,
      ...product,
      isDigital: product.isDigital,
    };

    const { success, error } = await props.addProductToBasket(
      item,
      props.allTaxonomies
    );

    if (success) {
      toast.success("Ürün sepetinize eklendi.");
    } else {
      toast.error(error.message);
    }
  };
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slider.length < 4 ? slider.length : 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: slider.length < 2 ? slider.length : 2,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      {slider.length !== 0 && (
        <div className="main-book-slider product-detail--slider">
          <div className="d-flex align-items-center">
            <h2>{title}</h2>
          </div>
          <Slider {...settings}>
            {slider.map((product) => (
              <div
                className="book-detail-container"
                key={product.id}
                title={product.name}
              >
                <div className="book-detail-box">
                  <a className="img-box" href={`/urunler/detay/${product.id}`}>
                    <img
                      className="img-fluid"
                      src={product.imageUrl}
                      alt={product.name}
                    />
                  </a>
                  <div className="book-detail-body px-2">
                    <div>{product.name}</div>
                    <div className="d-flex book-sub-detail align-items-center">
                    <div className="h-auto">

                          {product.discount > 0 && (
                            <div className="slide-price-old   h-auto">
                              {replaceDotWithComma((product.total / (1 - product.discount / 100) || 0).toFixed(2))} ₺
                            </div>
                          )}

                       <div className="h-auto">
                         <span>
                      {" "}
                      {replaceDotWithComma(product.total.toFixed(2))} ₺{" "}
                        </span>
                       </div>
                    </div>
                      <button
                        className="btn"
                        onClick={() =>
                          addProductToBasket(product, props.allTaxonomies)
                        }
                      >
                        Sepete Ekle
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </>
  );
};
const mapStateToProps = ({ ProductReducer }) => ({
  basketKey: ProductReducer.basketKey,
  allTaxonomies: ProductReducer.taxonomies,
});

const mapDispatchToProps = {
  addProductToBasket: actions.addProductToBasket,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDetailSlider);
