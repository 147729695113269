import React, { Component } from "react";
//import PropTypes from 'prop-types';
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { Breadcrumb } from "../../components";
import { customStyles } from "../../helpers";
import { iconAvatar } from "../../assets/images/icons";
import { createUniqueId, formatDate } from '../../helpers'
import classNames from "classnames";
import { connect } from 'react-redux';
import { getBrands } from "../../routes/actions/Product";
import { loginRequired } from "../../routes/actions/Account";
import { getByName, updateByName, addCommentToWall } from "../../routes/actions/Content";


const options = [
    {
        value: 2019,
        label: "2019"
    },
    {
        value: 2018,
        label: "2018"
    },
    {
        value: 2017,
        label: "2017"
    }
];

const breadcrumbs = [
    {
        title: "Anasayfa",
        url: "/"
    },
    {
        title: "Gri Duvar",
        active: true
    }
];
const brandId = 15302;


class Board extends Component {
    state = {
        year: 2019,
        show: false,
        showForm: false
    };

    componentDidMount() {
        this.props.getByName('gri-koc-duvar');

    }

    handleSubmit = async () => {
        const { user, addCommentToWall, page, loginRequired } = this.props;

        if (!user.id) {
            // toast.error('Yorum yapmak için üye girişi yapmanız gerekiyor.');
            loginRequired(true);
            return;
        }

        let message = {
            content: this.state.message,
            userId: user.id,
            name: `${user.firstname} ${user.lastname}`,
            date: new Date(),
            id: createUniqueId(),
            avatar: 'https://i.pravatar.cc/24'
        }
        const { success, error } = await addCommentToWall(page.name, message);
        if (success) {
            toast.success("Duvar yazınız Gri Koç'a gönderilmiştir");
            this.setState({ showForm: false, message: '' });
        }
        else {
            toast.error(error);
        }
    };

    changeStatus = async (id, status) => {
        let { updateByName, page } = this.props;
        let { boardItems } = page.content;
        var index = boardItems.findIndex(x => x.id === id);
        boardItems[index].status = status;
        await updateByName(page.name, page.content);

    }

    render() {

        const brand = this.props.brands.find(x => x.id === brandId);
        const { showForm, year } = this.state;
        var { page, user } = this.props;

        var boardItems = page && page.content && page.content.boardItems ? page.content.boardItems.map(x => (Object.assign(x, { date: new Date(x.date) }))).filter(x => x.date.getFullYear() === year) : [];
        var editable = false;
        if (user.editor === true && (!user.brandId || user.brandId === brandId)) {
            editable = true;
        }

        return (
            <>
                <div className="bg-board" />
                <Breadcrumb items={breadcrumbs} />

                <div className="row">
                    <div className="col-md-12">
                        {brand && (<div className="row border overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative border-radius-16 bg-white">
                            <figure className="w-100 mb-2" style={{ position: "relative" }}>
                                <img
                                    src={brand.banner}
                                    alt="..."
                                    className="brand-show-layer-3"
                                />
                            </figure>
                            <div className="col-auto d-lg-block ml-2">
                                <figure>
                                    <img
                                        className="brand-show-mark-logo"
                                        src={brand.logo}
                                        alt="..."
                                    />
                                </figure>
                            </div>
                            <div className="col d-flex flex-column brand-show-top-box">
                                <h3>{brand.name}</h3>
                                <p
                                    className="fs-14"
                                    style={{
                                        width: "80%"
                                    }}>
                                    {brand.description}
                                </p>
                                {/* <button
                                    className="btn btn-link position-absolute"
                                    style={{ right: "10px", top: "-10px" }}
                                    title="Favorilere ekle">
                                    <img src={iconHearth} alt="..." />
                                </button> */}
                            </div>
                        </div>)}
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div
                            style={{ float: "right", width: 190 }}
                            className="border-radius-8 mb-4">
                            <Select
                                isSearchable={false}
                                placeholder="Filtrele"
                                options={options}
                                styles={customStyles}
                                onChange={(e) => this.setState({ year: e.value })}
                            />
                        </div>
                    </div>

                    {!editable && (<div className="col-md-3" style={{ marginBottom: "8rem" }}>
                        <div className="card mb-2" style={{ background: "#fff" }}>
                            <div className="card-body board white-item">
                                {showForm ? (
                                    <>
                                        <textarea rows="3"
                                            className="form-control clear fs-14"
                                            autoFocus="true"
                                            value={this.state.message}
                                            onChange={e => this.setState({ message: e.target.value })} />
                                        <button
                                            className="btn btn-primary text-capitalize fs-16 font-weight-500 my-2 float-right"
                                            onClick={() => this.handleSubmit()}>
                                            Gönder
                                          </button>
                                    </>
                                ) : (
                                        <p className="card-text text-dark fs-13 text-center font-weight-500">
                                            <p className="my-3">{boardItems.length} Duvar yazısı var</p>
                                            <h5
                                                className="my-4"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => this.setState({ showForm: true })}>
                                                Sen de yaz
                                          </h5>
                                        </p>
                                    )}

                                <div
                                    style={{
                                        position: "absolute",
                                        bottom: "-65px",
                                        left: "50px",
                                        color: "black",
                                        fontSize: "12px",
                                        height: 35
                                    }}
                                    className="d-flex flew-row">
                                    <img
                                        src={iconAvatar}
                                        alt="..."
                                        width="30px"
                                        height="30px"
                                        style={{ borderRadius: 30 }}
                                    />
                                    <div className="ml-2">
                                        {user && user.firstname && (<span>{`${user.firstname} ${user.lastname}`}</span>)}
                                        <p>{formatDate.shortTime(new Date())}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}

                    {boardItems.map((item) => (editable || item.status) && (
                        <div
                            className="col-xl-3 col-lg-4 col-md-6 px-2"
                            style={{ marginBottom: "8rem" }}
                            key={item.id}>
                            <div className="card mb-2" style={{ background: "#fff5d4" }}>
                                <div className="card-body board">
                                    <p className="card-text text-dark fs-13">{item.content}</p>
                                    {editable && (<div>
                                        <button className={classNames({ "btn mr-2  border-radius-8": true, "btn-success": item.status === true, "btn-outline-success": item.status !== true })} onClick={() => this.changeStatus(item.id, true)}>
                                            <FontAwesomeIcon icon={faCheck} className="mr-1" />
                                            Onayla
                                        </button>
                                        <button className={classNames({ "btn border-radius-8": true, "btn-danger": item.status === false, "btn-outline-danger": item.status !== false })} onClick={() => this.changeStatus(item.id, false)}>
                                            <FontAwesomeIcon icon={faTimes} className="mr-1" />
                                            Reddet
                                        </button>

                                    </div>)}
                                    <div
                                        style={{
                                            position: "absolute",
                                            bottom: "-65px",
                                            left: "50px",
                                            color: "black",
                                            fontSize: "12px",
                                            height: 35
                                        }}
                                        className="d-flex flew-row">
                                        <img
                                            src={item.avatar}
                                            alt="..."
                                            width="30px"
                                            height="30px"
                                            style={{ borderRadius: 30 }}
                                        />
                                        <div className="ml-2">
                                            <span>{item.name}</span>
                                            <p>{formatDate.fromNow(item.date)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    {/* <div
                className="card text-white bg-warning mb-2"
                style={{ maxWidth: "18rem" }}
              >
                <div className="card-body">
                  <p className="card-text">
                    Selamlar güzel insanlar ufak bir hatırlatma yapmak istedik. 23
                    Mart Cumartesi günü Malatya'da sizlerle olucaz. 11. , 12.
                    sınıflar ve mezunlar bekliyoruz
                </p>
                  <p className="card-text d-flex justify-content-between">
                    <small className="text-muted">1 gün önce</small>
                    <small className="text-muted">Oya Gökay</small>
                  </p>
                  <div className="d-flex justify-content-between">
                    <button type="button" className="btn btn-primary">
                      <FontAwesomeIcon icon={faCheck} /> Onayla
                  </button>
                    <button type="button" className="btn btn-danger">
                      <FontAwesomeIcon icon={faBan} /> Reddet
                  </button>
                  </div>
                </div>
              </div> */}
                    {/* <Comments year={this.state.year} /> */}
                </div>
            </>
        );
    }
}

Board.propTypes = {};
const mapStateToProps = ({ AccountReducer, ContentReducer, ProductReducer }) => ({
    ...ContentReducer,
    user: AccountReducer.user,
    brands: ProductReducer.brands
});

const mapDispatchToProps = {
    getBrands,
    getByName,
    updateByName,
    addCommentToWall,
    loginRequired
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Board);
