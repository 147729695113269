import React, { PureComponent } from "react";
//import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getBrands } from "../../routes/actions/Product";
import * as actions from "../../routes/actions/Account";
import { Breadcrumb } from "../../components";
import { iconHearth, iconHearthOuter } from "../../assets/images/icons";
import { favoriteTypes } from "../../services/constants";
import Icon from "../../components/Icon/Index";

const breadcrumbs = [
  {
    title: "Anasayfa",
    url: "/",
  },
  {
    title: "Markalar",
    active: true,
  },
];

const BrandIcon = ({
  brand,
  addToFavorites,
  removeFromFavorites,
  favorite,
}) => {
  const brandId = brand.id;
  return (
    <div className="card h-100 card-brand-small mb-4 text-center">
      <Link to={`/markalar/detay/${brand.code}?b=${brandId}`}>
        <figure className="w-100 mt-4">
          <img className="logo" src={brand.logo} alt="..." />
        </figure>
        <div className="card-body pt-2 pb-4">
          <h5 className="card-title mb-0">{brand.name}</h5>
        </div>
      </Link>
      <button
        className="btn btn-link btn-action"
        onClick={() =>
          favorite ? removeFromFavorites(brand) : addToFavorites(brand)
        }
        title={favorite ? "Favorilerden çıkart" : "Favorilere ekle"}
      >
        <img src={favorite ? iconHearth : iconHearthOuter} alt={brand.name} />
      </button>
    </div>
  );
};


const BrandCards = ({
  brands,
  addToFavorites,
  isFavorite,
  removeFromFavorites,
}) =>
  brands.map((brand, i) => {
    const favorite = isFavorite(brand);
    return (

      <>
      <div className="col-6 col-sm-6 col-md-6 col-lg-3 mb-4 feature-brand" key={i}>
        <div className="card card-brand h-100 text-center ">
          <Link
            to={`/markalar/detay/${brand.code}?b=${brand.id}`}
            className="text-center"
          >
            <figure className="mx-auto mt-4" style={{ width: 120, height: 120 }}>
              <img src={brand.logo} alt={brand.name} />
            </figure>
            <div className="card-body pt-2 pb-4">
              <h5 className="card-title mb-0">{brand.name}</h5>
            </div>
          </Link>
          <button
            className="btn btn-link btn-action"
            onClick={() =>
              favorite ? removeFromFavorites(brand) : addToFavorites(brand)
            }
            title={favorite ? "Favorilerden çıkart" : "Favorilere ekle"}
          >
            <img
              src={favorite ? iconHearth : iconHearthOuter}
              alt={brand.name}
            />
          </button>
        </div>
      </div>

      </>

    );
  });

class List extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    if (!this.props.brands || !this.props.brands.length) {
      this.props.getBrands();
    }
    const authenticated = this.props.user.id ? true : false;
    if (!this.props.showCloseButton && authenticated && !this.props.favorites) {
      this.props.getFavorites();
    }
  }

  addToFavorites = async (brand) => {
    if (!this.props.user.id) {
      this.props.loginRequired(true);
      return;
    }

    const { success, error } = await this.props.addToFavorites(
      brand,
      favoriteTypes.brand
    );

    if (success) {
      toast(this.customToastAddFavourite({ success, error }), {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        closeButton: false,
      });
    } else {
      toast.error(error.message);
    }
  };

  customToastAddFavourite = () => (
    <div className="custom-toast">
      <Icon icon="iSuccess" className="mr-3" />
      <div className="d-flex flex-column">
        <div>Marka listenize eklendi. </div>
        <a href="/hesabim/favorilerim">Listelerime Git</a>
      </div>
    </div>
  );
  removeFromFavorites = async (brand) => {
    const { success, error } = await this.props.removeFromFavorites(
      brand,
      favoriteTypes.brand
    );

    if (success) {
      toast.success(`${brand.name} favorilerinizden çıkarıldı`);
    } else {
      toast.error(error.message);
    }
  };

  isFavorite = (brand) => {
    const { favorites } = this.props;
    const brands = favorites && favorites.brands ? favorites.brands : [];
    return brands.some((p) => p.id === brand.id);
  };

  render() {
    const featuredList = this.props.brands;//.filter((p) => p.brandType === 2);
    const brandList = this.props.brands.filter((p) => p.brandType === 1);
    return (
      <>
     <div className="container brand-list">
      <div className="row">
        <div className="col-12">
        <div className="row mb-4">
          <div className="col-12 ">
            <Breadcrumb items={breadcrumbs} />
          </div>
          <div className="col-12">
            {/* <h1>Markalar</h1> */}
          </div>
          <BrandCards
            brands={featuredList}
            isFavorite={this.isFavorite}
            addToFavorites={this.addToFavorites}
            removeFromFavorites={this.removeFromFavorites}
          />


          {brandList.map((brand) => (
            <div key={brand.id} className="col-6 col-sm-6  col-md-6 col-lg-3 mb-4">
              <BrandIcon
                brand={brand}
                favorite={this.isFavorite(brand)}
                addToFavorites={this.addToFavorites}
                removeFromFavorites={this.removeFromFavorites}
              />
            </div>
          ))}
        </div>
        </div>
      </div>
     </div>
      </>
    );
  }
}

//List.propTypes = {};

const mapStateToProps = ({ AccountReducer, ProductReducer }) => ({
  user: AccountReducer.user,
  brands: ProductReducer.brands,
  favorites: AccountReducer.favorites,
});

const mapDispatchToProps = {
  getBrands: getBrands,
  loginRequired: actions.loginRequired,
  addToFavorites: actions.addToFavorites,
  removeFromFavorites: actions.removeFromFavorites,
  getFavorites: actions.getFavorites,
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
