import React, { Component } from "react";
//import PropTypes from 'prop-types';
import { Breadcrumb } from "../../components";
import { setPrerenderTimeout } from "../../helpers";

const breadcrumbs = [
  {
    title: "Anasayfa",
    url: "/",
  },
  {
    title: "Biz Kimiz?",
    active: true,
  },
];

class About extends Component {
  componentDidUpdate() {
    setPrerenderTimeout();
  }

  render() {
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12 p-xs-0">
              <Breadcrumb items={breadcrumbs} />
            </div>
            <div className="col-12 p-xs-0">
              <div className="card">
                <div className="card-body">
                  <h1 className="fs-20 font-weight-500 mt-2 mb-2 color-484848">
                    Biz Kimiz?
                  </h1>
                  <p className="card-text color-484848 fs-16">
                    Tonguç Mağaza, ders çalışmayı bir zorunluluk halinden
                    çıkarıp, ortaokul ve lise öğrencilerini anlayamadıkları
                    konuları sıkılmadan, keyif alarak öğrenmelerine yardımcı
                    olan “ <b>Tonguç</b> ” ile buluşturmak amacıyla 2017 yılında
                    yılında kuruldu.
                  </p>
                  <p className="card-text color-484848 fs-16">
                    Alanında uzman ve deneyimli öğretmen kadrosuyla hazırlanan
                    kitaplar ve video içerikleriyle desteklenen yayınlarla,
                    zaman içinde Türkiye’nin her bölgesinde öğrencilerin en çok
                    tercih ettiği önemli bir motivasyon kaynağı halini alarak
                    büyük bir aileye dönüştü.
                  </p>
                  <p className="card-text color-484848 fs-16">
                    Tonguç Mağaza, kurulduğu tarihten bu yana öğrencilerin
                    eğitime dair ihtiyaç duydukları her alanda en büyük
                    destekçisi olma ve bu sayede elde ettiği başarıyı daha ileri
                    taşıma amacı güderek özverili çalışmalarına devam
                    etmektedir.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

About.propTypes = {};

export default About;
