import React, { PureComponent } from 'react'
import ReactPlyr from '../../components/Video/ReactPlyr'

export default class VideoList extends PureComponent {
    render() {
        const { videos } = this.props;
        return (
            <>
                <h3 className="detail-title">Tanıtım Videoları</h3>
                <div className="row">
                    {videos.map(video => {
                        let videoId = video.split('?v=').pop();
                        return (
                            <div className="col-md-12 mt-3">
                                <ReactPlyr
                                    id={videoId}
                                    url={videoId}
                                    type={'youtube'} />
                            </div>
                        )
                    })}
                </div>
            </>
        )
    }
}
