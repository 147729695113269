import React, { Component } from "react";
//import PropTypes from "prop-types";
import { TownSelect } from "../..";

class Town extends Component {
    handleChange = (selected) => {
        const { name, onChange } = this.props;
        onChange(name, selected.value ? { id: selected.value, name: selected.label } : undefined);
    };

    render() {
        const { args, valid, value, readOnly } = this.props;
        const townId = value ? value.id : null;
        return (
            <TownSelect
                onChange={this.handleChange}
                value={townId}
                valid={valid}
                cityId={args.city ? args.city.id : null}
                readOnly={readOnly}
            />
        );
    }
}

Town.propTypes = {};

export default Town;
