import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";
import { NumericInput, Button, ShoppingButton } from "../../components";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import * as actions from "../../routes/actions/Product";
import { setHandleModal } from "../../routes/actions/Account";
import { replaceDotWithComma, splitPrice } from "../../helpers";
import { getSettings } from "../../routes/actions/Settings";
import * as actionsA from "../../routes/actions/Account";

import {
  createOrder,
  addToFavorites,
  removeFromFavorites,
  getShippingOptions,
} from "../../routes/actions/Account";
import { Helmet } from "react-helmet";
import Icon from "../../components/Icon/Index";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShoppingSlider from "./ShoppingSlider";
import RemoveModal from "./RemoveModal";
import { ModalConst } from "../../variables/Constants";
import { keys } from "../../services/constants";
import storage from "../../services/storage";

const calculatePrice = (item) => (item.total * item.quantity).toFixed(2);

const calculateOldPrice = (item) =>
  (item.priceWithTax * item.quantity).toFixed(2);

const formatPrice = (item) => {
  const price = splitPrice(item);

  return (
    <>
      {formatNumber(price[0])},<small>{price[1]}₺</small>
    </>
  );
};
const formatNumber = (number) => {
  if (isNaN(number)) return number;

  const formattedNumber = new Intl.NumberFormat("tr-TR").format(number);
  return formattedNumber;
};
class ShoppingCart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPriceDetail: false,
      showRemoveModal: false,
      productName: "",
      product: "",
      shippingOption: 1,
      discount: 0.0,
    };
    this.buyLaterSliderRef = React.createRef();
  }

  closeRemoveModal = () => {
    this.setState({ showRemoveModal: false });
  };
  toggleShow = () => {
    this.setState({ showPriceDetail: !this.state.showPriceDetail });
  };
  componentDidMount() {
    this.props.getShipping();
    this.props.getBasketTaxonomies();
    if (!this.props.basket.length) this.props.getBasket();
    this.props.getShippingOptions();
    // var discountData = storage.getItem(keys.CouponDiscount);
    // discountData = JSON.parse(discountData)
    // const discount = discountData?.toplamKuponIndirimi || 0.0;
    // const couponExpireDate = discountData?.couponExpireDate || "";
    // const expireDate = new Date(couponExpireDate);
    // const now = new Date();
    // if(now > expireDate){
    //   this.setState({ discount: 0.0});
    // }else{
    //   this.setState({ discount: discount});
    // }
  }
  handleButtonClick = async () => {
    window.location.href = "/urunler";
  };
  handleCreateOrder = async () => {
    var getableUrunCount = this.props.basket.filter(
      (p) => p.product?.getable
    ).length;

    if (getableUrunCount < 1) {
      // if (this.props.basket.length < 1) {

      toast.error(
        "Sepetinizde ürün yok. Lütfen ürün ekleyip öyle devam ediniz."
      );
      return;
    }
    if (this.props.user.demo) {
      toast.error(
        "Bu işlemi DEMO modunda yapamazsınız. Devam etmek için giriş yapmanız gerekiyor."
      );
      return;
    }
    let order = {};
    order.lines = this.props.basket
      .filter((x) => x.price !== 0 && x.product?.getable)
      .map((b) => {
        return {
          productId: b.productId,
          quantity: b.quantity,
          isDigital: b.isDigital,
          ...b,
        };
      });

    let result = await this.props.createOrder(order);
    if (result.success) {
      this.props.history.push(`/odeme/sepet/${result.data.id}`);
      const GA4Products = [];
      {
        this.props.basket &&
          this.props.basket.forEach((item, index) => {
            const taxonIds = new Set(
              item.product.taxons.filter(
                (element) => typeof element === "number"
              )
            );

            const productTaxons = this.props.allTaxonomies
              ?.filter((t) => t.taxons.some((tx) => taxonIds.has(tx.id)))
              .flatMap((t) => {
                return t.taxons.filter((taxon) => taxonIds.has(taxon.id));
              });

            const productTopTaxonId = productTaxons?.[0]?.taxonomyId;
            const productTopTaxon = this.props.allTaxonomies?.filter(
              (t) => t.id == productTopTaxonId
            );
            GA4Products.push({
              item_id: item.productId,
              item_name: item.product.name,
              currency: "TRY",
              index: index.toString(),
              item_category: productTaxons?.[0]?.name,
              item_category2: productTaxons?.[1]?.name,
              item_category3: productTaxons?.[2]?.name,
              item_category4: productTaxons?.[3]?.name,
              item_category5: productTaxons?.[4]?.name,
              item_variant: productTaxons?.[0]?.name ?? item.category,
              item_list_id: productTopTaxon?.[0]?.id,
              item_list_name: productTopTaxon?.[0]?.description,
              price: Number(
                ((item.total * (100 + item.taxTotal)) / 100 || 0).toFixed(2)
              ),
              quantity: item.quantity,
              discount: 0,
            });
          });
      }
      window.dataLayer.push({ ecommerce: null });

      window.dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
          currency: "TRY",
          value: Number(result.data.total.toFixed(2)),
          items: GA4Products,
        },
      });
    } else {
      var message = result.error;
      if (result.error == null || result.error == undefined)
        message = "Lütfen giriş yaptıktan sonra tekrar deneyiniz.";

      toast.error(message);
    }
  };

  isCloseStoreModal = () => {
    const isCloseModal = window.localStorage.getItem("closedModal");
    if (isCloseModal) {
      this.setState({ storeModal: true });
    }
  };

  updateProduct = async (product, value) => {
    const quantity = value;

    if (product.quantity === 1 && quantity === 1) {
      return;
    }

    const item = {
      key: this.props.basketKey,
      productId: product.productId,
      update: true,
      ...product,
      quantity: quantity > 0 ? quantity : 1,
    };

    const { success, error } = await this.props.addProductToBasket(
      item,
      this.props.allTaxonomies
    );

    if (success) {
      toast.success("Ürün sayısı güncellendi");
    } else {
      toast.error(error.message);
    }
  };

  addProductToNotifyMe = async (product) => {
    if (!this.props.user.id) {
      this.props.loginRequired(true);
      return;
    }
    const { success, error } = await this.props.addProductToNotifyMe(product);

    if (success) {
      toast.success(`${product.name} haber verilecek listenize eklendi`);
    } else {
      toast.error(error);
    }
  };

  deleteProductToBasket = async (product) => {
    const item = {
      key: this.props.basketKey,
      productId: product.productId,
      ...product,
    };

    const { success, error } = await this.props.deleteProductToBasket(item);

    if (success) {
      this.props.getBasket();
      toast.success("Ürün sepetten çıkarıldı");
    } else {
      toast.error(error.message);
    }
  };

  selectedProduct = async (product) => {
    this.setState({ showRemoveModal: true });
    this.setState({ product: product });
    this.setState({ productName: product.product.name });
    return product;
  };

  addBuyLaterProduct = async (product) => {
    if (!this.props.user.id) {
      this.props.loginRequired(true);
      return;
    }
    const { success, error } = await this.props.addBuyLaterProduct(
      product.productId
    );
    if (success) {
      toast(this.customToastComment({ success, error }), {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        closeButton: false,
      });

      this.buyLaterSliderRef.current.refresh();
      this.props.deleteProductToBasket({
        key: this.props.basketKey,
        productId: product.productId,
        ...product,
      });
      this.setState({ showRemoveModal: false });
    } else {
      toast.error(error.message);
    }
  };

  addToFavorites = async (product) => {
    if (!this.props.user.id) {
      this.props.loginRequired(true);
      return;
    }

    const { success, error } = await this.props.addToFavorites(product);

    if (success) {
      toast(this.customToastComment({ success, error }), {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        closeButton: false,
      });
    } else {
      toast.error(error.message);
    }
  };

  customToastComment = () => (
    <div className="custom-toast">
      <Icon icon="iSuccess" className="mr-3" />
      <div className="d-flex flex-column">
        <span>Ürün daha sonra al listene eklendi.</span>
      </div>
    </div>
  );

  render() {
    const { shippingOptions } = this.props;
    const GA4Products = [];
    const price = this.props.basket.reduce(
      (a, c) => {
        if (c.product?.getable) {
          a.productTotal += c.total * c.quantity - c.taxTotal * c.quantity;
          a.taxTotal += c.taxTotal * c.quantity;
        }
        return a;
      },
      { productTotal: 0, taxTotal: 0 }
    );

    {
      this.props.basket &&
        this.props.basket.forEach((item, index) => {
          const taxonIds = new Set(
            item.product.taxons.filter((element) => typeof element === "number")
          );

          const productTaxons = this.props.allTaxonomies
            ?.filter((t) => t.taxons.some((tx) => taxonIds.has(tx.id)))
            .flatMap((t) => {
              return t.taxons.filter((taxon) => taxonIds.has(taxon.id));
            });

          const productTopTaxonId = productTaxons?.[0]?.taxonomyId;
          const productTopTaxon = this.props.allTaxonomies?.filter(
            (t) => t.id == productTopTaxonId
          );
          GA4Products.push({
            item_id: item.productId,
            item_name: item.product.name,
            currency: "TRY",
            index: index,
            price: Number(
              ((item.total * (100 + item.taxTotal)) / 100 || 0).toFixed(2)
            ),
            item_category: productTaxons?.[0]?.name,
            item_category2: productTaxons?.[1]?.name,
            item_category3: productTaxons?.[2]?.name,
            item_category4: productTaxons?.[3]?.name,
            item_category5: productTaxons?.[4]?.name,
            item_variant: productTaxons?.[0]?.name ?? item.category,
            item_list_id: productTopTaxon?.[0]?.id,
            item_list_name: productTopTaxon?.[0]?.description,
            quantity: item.quantity,
            item_brand: item.brand,
            discount: 0,
          });
        });
    }

    let selectedShippingOption = shippingOptions.find(
      (x) => x.id === this.state.shippingOption
    );

    let _shippingPrice = 0;
    let shippingPrice = 0;
    let _isFreeShipping = 0;

    const calculateShippingPrice = () => {
      let shippingPrice = 0;
      let allDigital = this.props.basket.every((p) => p.isDigital);
      if (price.productTotal >= selectedShippingOption.minimum || allDigital) {
        shippingPrice = 0;
      } else {
        shippingPrice = Math.min(...shippingOptions.map((item) => item.price));
      }
      return shippingPrice;
    };

    const calculateFreeShipping = () => {
      let isFreeShipping = 0;
      if (
        price.productTotal + price.taxTotal <=
        selectedShippingOption.minimum
      ) {
        isFreeShipping =
          selectedShippingOption.minimum -
          (price.productTotal + price.taxTotal);
      }
      return isFreeShipping;
    };

    if (selectedShippingOption) {
      _shippingPrice = calculateShippingPrice();
      shippingPrice = selectedShippingOption.price;
      _isFreeShipping = calculateFreeShipping();
    }

    let ids = [];
    return (
      <>
        <Helmet>
          <title>Tonguç'la çalış, Başarıya alış!</title>
          <link rel="canonical" href="https://tongucmagaza.com/" />
          <script>
            {`gtag('event', 'page_view', {
                    'send_to':'AW-596855146',
                    'ecomm_pagetype': 'cart',
                    'ecomm_prodid': [${ids.join(",")}],
                    'ecomm_totalvalue': ${
                      this.props.basket.length
                        ? (price.productTotal + price.taxTotal).toFixed(2)
                        : (0).toFixed(2)
                    },
                  });`}
          </script>
          <script>
            {`
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
              event: "view_cart",
              ecommerce: {
                currency: "TRY",
                value: ${Number(
                  (
                    price.productTotal +
                    price.taxTotal +
                    _shippingPrice
                  ).toFixed(2)
                )},
                items: ${JSON.stringify(GA4Products)}
              }
            });
        `}
          </script>
        </Helmet>
        <div className="container">
          <div className="row shopping-body">
            <div className="col-xl-9">
              {this.props.basket.length > 0 ? (
                <div className="shopping-cart">
                  <div className="shopping-info">
                    <h5>
                      Sepetim&nbsp;
                      <span>({this.props.basket.length} Ürün)</span>
                    </h5>
                    {!this.props.basket.every((p) => p.isDigital) && (
                      <div
                        className="cargo-container ml-lg-auto mb-0"
                        style={
                          this.props.basket.length === 0
                            ? { display: "none" }
                            : {}
                        }
                      >
                        <Icon icon="iTruck" />
                        <div className="cargo-text">
                          <span>Hemen</span> sipariş verirseniz en geç{" "}
                          <span>24 saat</span> içinde kargoda (pazar ve resmi
                          tatiller dışında)
                        </div>
                      </div>
                    )}
                  </div>

                  <ul className="list-group list-group-flush shopping-list">
                    {this.props.basket &&
                      this.props.basket.map((item) => (
                        <li
                          className="list-group-item list-group-item"
                          key={item.productId}
                        >
                          <div className="shopping-item-container">
                            <figure className="image-sm">
                              <img
                                src={item.product.imageUrl}
                                alt={item.product.name}
                              />
                            </figure>
                            <div className="w-100">
                              <div className="d-flex flex-column justify-content-around h-100">
                                <Link
                                  to={`/urunler/detay/${item.productId}`}
                                  className="d-flex text-decoration-none text-dark shopping-item"
                                >
                                  <div className="shopping-item-name">
                                    {item.product.name}
                                    {item.price === 0 ? (
                                      <>
                                        <br />
                                        <small
                                          className="fs-12 font-weight-500"
                                          style={{ color: "#ff0000" }}
                                        >
                                          <i>Hediye</i>
                                        </small>
                                      </>
                                    ) : null}
                                    {/* digital check */}
                                    {item.product.isDigital ? (
                                      <>
                                        <br />
                                        <small
                                          className="fs-12 font-weight-500"
                                          style={{ color: "#ff0000" }}
                                        >
                                          <i>Dijital Kitap</i>
                                        </small>
                                      </>
                                    ) : null}
                                    {!item.product.isInStock &&
                                    this.props.enableNotifyMe ? (
                                      <>
                                        <br />
                                        <small
                                          className="fs-12 font-weight-500"
                                          style={{ color: "#ff0000" }}
                                        >
                                          <i>Ürün stoğu kalmamıştır</i>
                                        </small>
                                      </>
                                    ) : null}
                                  </div>
                                  <div className="ml-md-auto d-block ">
                                    {/* {`${replaceDotWithComma(
                                    calculatePrice(item)
                                  )} ₺`} */}

                                    {item.product.discount !== 0 && (
                                      <div className="price-old-card">
                                        {formatPrice(calculateOldPrice(item))}
                                      </div>
                                    )}

                                    <div>
                                      <strong className="color-484848">
                                        {formatPrice(calculatePrice(item))}
                                      </strong>
                                    </div>
                                  </div>
                                  {/* <div className="d-lg-none d-md-none mt-2">
                                <div className="d-flex flex-row">
                                  <div className="mr-5">
                                    <h6 className="color-919191 text-uppercase fs-14">
                                      Fiyat
                                    </h6>
                                    <strong>{item.total.toFixed(2)} ₺</strong>
                                  </div>
                                  <div>
                                    <h6 className="color-919191 text-uppercase fs-14">
                                      Toplam
                                    </h6>
                                    <strong>
                                      {(item.total * item.quantity).toFixed(2)}{" "}
                                      ₺
                                    </strong>
                                  </div>
                                </div>
                              </div> */}
                                </Link>
                                <div className="shopping-sub-box">
                                  {item.price !== 0 && (
                                    <div>
                                      <NumericInput
                                        disabled={
                                          !item.product?.getable ||
                                          item.product?.isDigital
                                        }
                                        onChange={(value) =>
                                          this.updateProduct(item, value)
                                        }
                                        defaultValue={item.quantity}
                                      />
                                    </div>
                                  )}

                                  <div className="shopping-operations-container">
                                    <div
                                      className="shopping-operations"
                                      style={{ marginRight: 16 }}
                                      // onClick={() =>
                                      //   this.deleteProductToBasket(item)
                                      // }
                                      onClick={() => this.selectedProduct(item)}
                                    >
                                      Kaldır
                                      {/* <span aria-hidden="true">&times;</span> */}
                                    </div>
                                    <div
                                      className="shopping-operations"
                                      onClick={() =>
                                        this.addBuyLaterProduct(item)
                                      }
                                    >
                                      Daha Sonra Alacağım
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <div className="text-center d-md-block d-sm-none d-xs-none">
                            <strong className="color-484848">
                              {(item.total + item.taxTotal || 0).toFixed(2)} ₺
                            </strong>
                          </div> */}
                          </div>
                          {!item.product.isInStock &&
                          this.props.enableNotifyMe ? (
                            <>
                              <a
                                className="fs-12 font-weight-500"
                                onClick={() =>
                                  this.addProductToNotifyMe(item.product)
                                }
                                style={{
                                  color: "#ff0000",
                                  cursor: "pointer",
                                  zIndex: 1,
                                  position: "relative",
                                }}
                              >
                                <i>Haber ver</i>
                              </a>
                            </>
                          ) : null}
                        </li>
                      ))}
                  </ul>
                  <RemoveModal
                    show={this.state.showRemoveModal}
                    closeRemoveModal={this.closeRemoveModal}
                    deleteProductToBasket={this.deleteProductToBasket}
                    selectedProduct={this.state.product}
                    selectedProductName={this.state.productName}
                    addBuyLaterProduct={this.addBuyLaterProduct}
                  />
                </div>
              ) : (
                <div className="shopping-cart">
                  <div className="shopping-info">
                    <p>
                      Sepetiniz Şu Anda Boş. &nbsp;{" "}
                      <Link
                        to={"/urunler"}
                        style={{ textDecoration: "underline" }}
                      >
                        Alışverişe devam et{" "}
                      </Link>
                    </p>
                  </div>
                </div>
              )}

              {this.props.basket.some((p) => p.isDigital) && (
                <div className="col-md-12">
                  <p className="fs-14 font-weight-500 cardInfoText mt-3">
                    <b>UYARI:</b> Sepetinizde bulunan Dijital Kitabınıza ait
                    aktivasyon kodu üyelik e-posta adresinize ve kayıtlı telefon
                    numaranıza sms yolu ile iletilecektir.
                    <br></br>
                    <b>
                      Dijital Kitap satın alımlarında kargo ile kitap gönderimi
                      yapılmamaktadır.
                    </b>
                  </p>
                </div>
              )}
              {!this.props.user.id ? (
                <div className="shopping-login-box">
                  <h5>Daha Sonra Alacaklarım</h5>
                  <div className="shopping-login-text">
                    Daha Sonra Al listeni görmek için giriş yapın veya üye olun.
                  </div>
                  <div className="shopping-login-btn">
                    <span
                      onClick={() =>
                        this.props.setHandleModal(ModalConst.LOGIN)
                      }
                    >
                      Giriş Yap&nbsp;
                    </span>
                    /
                    <span
                      onClick={() =>
                        this.props.setHandleModal(ModalConst.REGISTER)
                      }
                    >
                      &nbsp;Üye Ol
                    </span>
                  </div>
                </div>
              ) : null}

              {this.props.user.id && (
                <ShoppingSlider
                  title={"Daha Sonra Alacaklarım"}
                  url={"/buylaterproducts"}
                  ref={this.buyLaterSliderRef}
                />
              )}
              {this.props.user.id && (
                <ShoppingSlider
                  title={
                    this.props.basket.length > 0
                      ? "Sepetindekileri Alanlar Bunları da Aldı"
                      : "Bu Ürünler İlgini Çekebilir"
                  }
                  eventType={3}
                  productId={0}
                />
              )}

              <ShoppingSlider
                title={"Son Baktıklarım"}
                url={"/products/recently-viewed"}
                loggedIn={this.props.user.id > 0}
              />
            </div>

            <div className="col-xl-3">
              <div className="shopping-total-container">
                <div className="card-body p-4">
                  <h5>Sipariş Özeti</h5>
                  <div className="total-price">
                    Toplam: &nbsp;
                    {this.props.basket.length
                      ? formatPrice(
                          (
                            price.productTotal +
                            price.taxTotal +
                            _shippingPrice -
                            this.state.discount
                          ).toFixed(2)
                        )
                      : formatPrice(replaceDotWithComma((0).toFixed(2)))}
                  </div>
                  <div className="total-price-detail">
                    <div className="d-flex justify-content-between align-items-center my-3">
                      <div className="fs-16 color-545454 font-weight-500">
                        Ürünler:
                      </div>
                      <span className="color-202124 fs-16 font-weight-500">
                        {formatPrice(price.productTotal.toFixed(2))}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center my-3">
                      <div className="fs-16 color-545454 font-weight-500">
                        KDV:
                      </div>
                      <span className="color-202124 fs-16 font-weight-500">
                        {formatPrice(price.taxTotal.toFixed(2))}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center my-3">
                      <div className="fs-16 color-545454 font-weight-500">
                        Kupon İndirimi:
                      </div>
                      <span className="color-202124 fs-16 font-weight-500">
                        {formatPrice(this.state.discount.toFixed(2))}
                      </span>
                    </div>
                    {this.props.basket.length !== 0 &&
                      !this.props.basket.every((p) => p.isDigital) && (
                        <div className="d-flex justify-content-between align-items-center my-3">
                          <div className="fs-16 color-545454 font-weight-500">
                            Kargo:
                          </div>
                          {_shippingPrice === 0 ? (
                            <div>
                              <span className="color-202124 fs-16 font-weight-500 mr-3">
                                Bedava
                              </span>
                              <span className="color-545454 fs-16 font-weight-500">
                                <del>
                                  {formatPrice(shippingPrice.toFixed(2))}
                                </del>
                              </span>
                            </div>
                          ) : (
                            <span className="color-202124 fs-16 font-weight-500">
                              {formatPrice(_shippingPrice.toFixed(2))}
                            </span>
                          )}
                        </div>
                      )}
                  </div>
                  {_isFreeShipping !== 0 &&
                  !this.props.basket.every((p) => p.isDigital) ? (
                    <div className="total-price-text">
                      Sepetinize {formatPrice(_isFreeShipping.toFixed(2))} ₺’lik
                      ürün daha ekleyin kargo bedava olsun
                    </div>
                  ) : null}
                  {this.props.basket.length > 0 ? (
                    <Button
                      className="btn btn-total-price"
                      onClick={() => this.handleCreateOrder()}
                    >
                      Alışverişi Tamamla
                      <FontAwesomeIcon icon={faChevronRight} />
                    </Button>
                  ) : (
                    <Button
                      className="btn btn-total-price"
                      onClick={() => this.handleButtonClick()}
                    >
                      Mağazaya Dön
                      <FontAwesomeIcon icon={faChevronRight} />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="shopping-total-mobile-container">
          <div
            className={`shopping-total-mobile d-flex d-sm-none ${
              this.state.showPriceDetail ? "show" : ""
            }`}
          >
            <div className="shopping-mobile-detail">
              <span>Sipariş Özeti</span>
              <div
                className="d-flex align-items-center"
                onClick={() => this.toggleShow()}
              >
                {this.props.basket.length
                  ? formatPrice(
                      (
                        price.productTotal +
                        price.taxTotal +
                        _shippingPrice
                      ).toFixed(2)
                    )
                  : (0).toFixed(2)}
                ₺
                <div className="icon-box">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
            </div>
            <ShoppingButton handleClick={this.handleCreateOrder} />
          </div>
          <div
            className={`shopping-mobile-sub-detail ${
              this.state.showPriceDetail ? "show" : ""
            }`}
          >
            <div className="shopping-mobile-item">
              <div className="color-545454">Ürünler:</div>
              <span className="color-202124">
                {formatPrice((price.productTotal + price.taxTotal).toFixed(2))}{" "}
                ₺
              </span>
            </div>
            {this.props.basket.length !== 0 && (
              <div className="shopping-mobile-item">
                <div className="color-545454">Kargo:</div>
                {_shippingPrice === 0 ? (
                  <div>
                    <span className="color-202124 fs-16 font-weight-500 mr-3">
                      Bedava
                    </span>
                    <span className="color-545454 fs-16 font-weight-500">
                      <del>{formatPrice(shippingPrice.toFixed(2))} ₺</del>
                    </span>
                  </div>
                ) : (
                  <span className="color-202124 fs-16 font-weight-500">
                    {formatPrice(_shippingPrice.toFixed(2))} ₺
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

ShoppingCart.propTypes = {};

const mapStateToProps = ({
  ProductReducer,
  AccountReducer,
  SettingsReducer,
}) => ({
  basket: ProductReducer.basket,
  basketKey: ProductReducer.basketKey,
  shipping: ProductReducer.shipping,
  taxonomies: ProductReducer.basketTaxonomies,
  user: AccountReducer.user,
  shippingOptions: AccountReducer.shippingOptions,
  enableNotifyMe: SettingsReducer.enableNotifyMe,
  products: ProductReducer.allProducts,
  allTaxonomies: ProductReducer.taxonomies,
});

const mapDispatchToProps = {
  getBasket: actions.getBasket,
  getShipping: actions.getShipping,
  addProductToBasket: actions.addProductToBasket,
  deleteProductToBasket: actions.deleteProductToBasket,
  addProductToNotifyMe: actions.addProductToNotifyMe,
  getBasketTaxonomies: actions.getBasketTaxonomies,
  addToFavorites: addToFavorites,
  removeFromFavorites: removeFromFavorites,
  createOrder,
  getShippingOptions,
  addBuyLaterProduct: actions.addBuyLaterProduct,
  setHandleModal: setHandleModal,
  getSettings,
  loginRequired: actionsA.loginRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShoppingCart));
