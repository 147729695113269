import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../routes/actions/Account";
import { ModalConst } from "../../variables/Constants";

class MainHelperLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="main-helper-links">
          <div className="helper-links-container">
            <Link
              to={"/hesabim/yardim#cargo-tracking"}
              className="helper-links-box"
            >
              <img
                className="img-fluid"
                src={require("../../assets/images/helperLinks/icon-siparislerim-2.svg")}
              />
              <div className="helper-text">Kargom Ne Zaman Gelir</div>
            </Link>
          </div>
          <div className="helper-links-container">
            <Link to="/iletisim" className="helper-links-box">
              <img
                className="img-fluid"
                src={require("../../assets/images/helperLinks/icon-call.svg")}
              />
              <div className="helper-text">Çözüm Merkezi</div>
            </Link>
          </div>
          <div className="helper-links-container">
            <Link to={"/hesabim/yardim#13"} className="helper-links-box">
              <img
                className="img-fluid"
                src={require("../../assets/images/helperLinks/icon-card.svg")}
              />
              <div className="helper-text">Ödeme Seçenekleri</div>
            </Link>
          </div>
          <div className="helper-links-container">
            <Link to={"/dijitalkitap"} className="helper-links-box">
              <img
                className="img-fluid"
                src={require("../../assets/images/helperLinks/icon-tats.png")}
              />
              <div className="helper-text">TATS modu</div>
            </Link>
          </div>
          {!this.props.user.id && (
            <div className="helper-links-container">
              <div
                className="helper-links-box cursor-pointer"
                onClick={() => this.props.setHandleModal(ModalConst.REGISTER)}
              >
                <img
                  className="img-fluid"
                  src={require("../../assets/images/helperLinks/icon-hesabim.svg")}
                />
                <div className="helper-text">Üyelik Oluşturma</div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ AccountReducer }) => ({
  user: AccountReducer.user,
});

const mapDispatchToProps = {
  login: actions.login,
  register: actions.register,
  setHandleModal: actions.setHandleModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MainHelperLinks));
