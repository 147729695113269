
const GET_BOOKS_BRANS_TEST = "BOOKS/GET_BOOKS_BRANS_TEST";
const GET_BOOKS_BRANS = "BOOKS/GET_BOOKS_BRANS";
const CREATE_RANDOM_TEST = "BOOKS/CREATE_RANDOM_TEST";
const GET_RANDOM_TEST = "BOOKS/GET_RANDOM_TEST";

export default {
    GET_BOOKS_BRANS_TEST,
    GET_BOOKS_BRANS,
    CREATE_RANDOM_TEST,
    GET_RANDOM_TEST,
};