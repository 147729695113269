import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MetricHorizontal, ProgressBar, Breadcrumb } from "../../components";
import { connect } from "react-redux";
import { formatDate } from "../../helpers/formatDatetime";
import BadgeList from "./BadgeList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { imgPuan, imgJeton } from "../../assets/images";
import * as actions from "../../routes/actions/Desk/ProfileLesson";
import * as actionsChapters from "../../routes/actions/Desk/Chapters";
import StatsErrorBox from "./StatsErrorBox";

class ProfileLesson extends Component {
  constructor(props) {
    super(props);
    const bransKod = this.props.match.params || 0;
    this.bransKod = parseInt(bransKod, 10);

    this.state = {
      isShowAll: false,
      bransKod: this.bransKod,
      activities: this.props.activities.slice(0, 4),
      showPopup: false,
      testLoaded:false,
      test:null,
    };
  }
  compareValues(key, orderByAsc = true) {
    return function (a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return orderByAsc ? comparison : comparison * -1;
    };
  }

  showAllActivity = () => {
    this.setState({
      isShowAll: !this.state.isShowAll,
      activities: this.state.isShowAll
        ? this.props.activities
        : this.props.activities.slice(0, 4),
    });
  };

  handleButtonClick = () => {
    this.setState({ showPopup: true });
  };

  handleHataOkClick = async (data) => {
    const result = await this.props.createRandomTest(data);
    if (result) {
      const { testId } = this.props;
      this.props.history.push(
        `/dijitalkitap/book/lesson/subject/quiz/${testId}?v=true`
      );
    }
  };

  componentDidMount() {
    const { bransKod } = this.props.match.params || 0;
    this.setState({
      bransKod,
    });

    this.props.fetchProfileLesson(bransKod);
    this.props.getBransWithAllTests();
  }

  componentDidUpdate(prevProps) {
    if (this.props.test !== prevProps.test && !this.state.testLoaded) {
      this.setState({
        testLoaded: true,
        test: this.props.test, 
      });
    }
  }

  render() {
    const breadcrumbs = [
      {
        title: "Anasayfa",
        url: "/dijitalkitap",
      },
      {
        title: "Profil",
        url: "/dijitalkitap/profile",
      },
      {
        title: this.props.branch,
        active: true,
      },
    ];

    this.state.activities = this.state.isShowAll
      ? this.props.activities.sort(this.compareValues("CreateDate", false))
      : this.props.activities
          .sort(this.compareValues("CreateDate", false))
          .slice(0, 3);
    return (
      <div className="row activities">
        <div className="col-12 p-xs-0">
          <Breadcrumb items={breadcrumbs} />
          <h1 className="font-weight-600 color-484848">{this.props.branch}</h1>
        </div>
        <div className="col-xl-6 p-xs-0">
          <MetricHorizontal
            title={this.props.branch}
            series={Number(this.props.score && this.props.score.CurrentLevel)}
            score={this.props.score && this.props.score.CurrentScore}
            trueCount={this.props.test && this.props.test.dogru}
            emptyCount={this.props.test && this.props.test.bos}
            falseCount={this.props.test && this.props.test.yanlis}
            netCount={this.props.test && this.props.test.net}
            currentScore={this.props.score && this.props.score.CurrentScore}
            totalScore={
              this.props.score &&
              this.props.score.CurrentScore + this.props.score.DueScore
            }
            awardCount={this.props.badges && this.props.badges.length}
            onButtonClick={this.handleButtonClick}
          />
        </div>
        <div className="col-xl-6 p-xs-0 last-activity">
          <div className="card activities-card h-100 mt-2">
            <div className="card-body">
              <h5 className="fs-20 color-484848 font-weight-600">
                Son Aktiviteler
              </h5>
              <ul className="list-group mb-4 font-weight-600 color-919191">
                {this.state.activities.map((activity, i) => (
                  <li
                    className="list-group-item justify-content-between fs-14 border-0 d-flex flex-column flex-md-row align-items-md-center px-0"
                    key={i}
                  >
                    <div className="d-flex justify-content-between justify-content-sm-start mb-3 mb-sm-0">
                      <div
                        className="text-muted activities-date fs-14 mr-4"
                        style={{ width: 80 }}
                      >
                        {formatDate.fromNow(activity.CreateDate)}
                      </div>

                      <div className="activities-text" style={{ width: 180 }}>
                        <span className="text-muted fs-14">
                          {activity.Description}
                        </span>
                      </div>
                    </div>

                    <div className="mb-2">
                      <span className="img-badge small">
                        <img src={imgPuan} alt="..." />
                        <span className="position-relative text-left">
                          +{activity.GainedScore} Puan
                        </span>
                      </span>

                      <span
                        className="img-badge small ml-2 mt-1"
                        style={{ background: "#fff1da" }}
                      >
                        <img src={imgJeton} alt="..." />
                        <span
                          className="position-relative text-left"
                          style={{ color: "#f28b00" }}
                        >
                          +{activity.GainedCoins} Jeton
                        </span>
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
              <Link
                className="fs-13 font-weight-bold text-blue all-btn"
                onClick={this.showAllActivity}
              >
                {!this.state.isShowAll ? "Tümünü Gör" : "Gizle"}
                <FontAwesomeIcon
                  icon={!this.state.isShowAll ? faChevronDown : faChevronUp}
                  className="ml-1"
                  style={{ height: 12, width: 12 }}
                />
              </Link>
            </div>
          </div>
        </div>

        <div className="col-12 p-xs-0 mt-3 mt-md-5">
          <h5 className="fs-20 color-484848 font-weight-500 mt-1">Konular</h5>
        </div>

        {this.props.test &&
          this.props.test.Testler.map((chapter, i) => (
            <div className="col-lg-3 col-md-4 col-6 p-xs-0 mt-3" key={i}>
              <div className="card chapter-lesson-item h-100">
                {/* <Link to={`/dijitalkitap/profile/chapters/${this.state.bransKod}/${chapter.testUnite}`}> */}
                <Link
                  to={`/dijitalkitap/profile/chapters/${this.state.bransKod}?uniteId=${chapter.testUnite}&showAll=true`}
                >
                  <div className="card-body">
                    <h5 className="card-title fs-20 color-484848 font-weight-600">
                      {chapter.testUniteAd}
                    </h5>
                    <p className="card-text fs-16 color-9b9b9b font-weight-500">
                      {chapter.toplamDogru +
                        chapter.toplamYanlis +
                        chapter.toplamBos}
                      /{chapter.testSoruSayisi}
                    </p>
                    <ProgressBar
                      className="mb-2"
                      value={Number(
                        (
                          ((chapter.toplamDogru +
                            chapter.toplamYanlis +
                            chapter.toplamBos) /
                            chapter.testSoruSayisi) *
                          100
                        ).toFixed(0)
                      )}
                    />
                  </div>
                </Link>
              </div>
            </div>
          ))}

        <div className="col-12 p-xs-0 mt-4">
          <h5 className="fs-20 color-484848 font-weight-500">Rozetler</h5>
          <div className="row">
            {this.props.badges.length !== 0 ? (
              <BadgeList list={this.props.badges} />
            ) : (
              <div className="col-12">Rozetiniz Bulunmamaktadır.</div>
            )}
          </div>
        </div>
        {this.state.testLoaded &&  
        <StatsErrorBox
          show={this.state.showPopup}
          onCloseClick={() => this.setState({ showPopup: false })}
          onOkClick={this.handleHataOkClick}
          branslar={this.props.branslar}
          unites={this.props.unites}
          bransId={this.state.bransKod}
          falseAndEmptyCount={this.state.test.yanlis + this.state.test.bos}
        />}
      </div>
    );
  }
}

ProfileLesson.propType = {};

const mapStateToProps = ({ ProfileLessonReducer, ChaptersReducer }) => ({
  score: ProfileLessonReducer.score,
  test: ProfileLessonReducer.test,
  activities: ProfileLessonReducer.activities,
  badges: ProfileLessonReducer.badges,
  branch: ProfileLessonReducer.branch,
  branslar: ChaptersReducer.branslar,
  unites: ChaptersReducer.unites,
  testId: ChaptersReducer.testId,
});

const mapDispatchToProps = {
  fetchProfileLesson: actions.fetchProfileLesson,
  getBransWithAllTests: actionsChapters.getBransWithAllTests,
  createRandomTest: actionsChapters.createRandomTest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileLesson);
