import React, { Component } from "react";
//import PropTypes from "prop-types";
import { Modal, BaseForm } from "../../components";
import FormSchemas from "../../variables/FormSchemas";
import CloseButton from "../../components/Button/Close";
import classNames from "classnames";

class AddressForm extends Component {
  state = {
    addressType: 0,
    addressId: 0,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.changed || nextProps.address.id === prevState.addressId) {
      return { changed: false };
    }
    return {
      addressType: nextProps.address.addressType,
      addressId: nextProps.address.id,
    };
  }

  handleClose = () => {
    this.props.onCloseClick();
    this.setState(
      { addressType: 0, addressId: 0, changed: true },
      this.props.onCloseClick
    );
  };

  handleSubmit = (formData) => {
    const { address, createAddress, updateAddress, isDemo } = this.props;

    formData.addressType = this.state.addressType;
    if (address && address.id > 0) {
      updateAddress(formData);
    } else {
      createAddress(formData);
    }
  };

  toggle = (addressType) => this.setState({ addressType, changed: true });

  render() {
    const { address, show, title, isDemo } = this.props;
    let formTitle = "Bireysel";
    if (this.state.addressType === 1) {
      formTitle = "Kurumsal";
    }
    return (
      <Modal show={show} onCloseClick={this.handleClose} className="modal-container address-modal">
        <div className="modal-header border-0 flex-column">
          <h4 className="fs-20 font-weight-bold color-202124">Adres Ekle</h4>
          <div className="modal-tab-bar">
            <button
              className={classNames("btn", {
                active: this.state.addressType === 0,
              })}
              onClick={() => this.toggle(0)}
            >
              Bireysel
            </button>
            <button
              className={classNames("btn", {
                active: this.state.addressType === 1,
              })}
              onClick={() => this.toggle(1)}
            >
              Kurumsal
            </button>
          </div>
          <span/>
          <CloseButton onClick={this.handleClose} />
        </div>
        {show && (
          <div className="modal-body">
            {/* {title ? <h5>{title}</h5> : <h5>{`Yeni ${formTitle} Adres Ekle`}</h5>} */}
            {this.state.addressType === 0 && (
              <BaseForm
                schema={
                  isDemo
                    ? FormSchemas.demoAddress.schema
                    : FormSchemas.address.schema
                }
                uiSchema={
                  isDemo
                    ? FormSchemas.demoAddress.uiSchema
                    : FormSchemas.address.uiSchema
                }
                formData={address}
                onSubmit={this.handleSubmit}
              />
            )}
            {this.state.addressType === 1 && (
              <BaseForm
                schema={
                  isDemo
                    ? FormSchemas.demoBusinessAddress.schema
                    : FormSchemas.businessAddress.schema
                }
                uiSchema={
                  isDemo
                    ? FormSchemas.demoBusinessAddress.uiSchema
                    : FormSchemas.businessAddress.uiSchema
                }
                formData={address}
                onSubmit={this.handleSubmit}
              />
            )}
          </div>
        )}
      </Modal>
    );
  }
}

AddressForm.propTypes = {};

export default AddressForm;
