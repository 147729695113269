import React, {Component} from "react";
//import PropTypes from "prop-types";

class Multiline extends Component {
  handleChange = ({target: {name, value}}) => {
      this.props.onChange(name, value);
  };

  render() {
      const {className, name, uiSchema, value} = this.props;
      const options = uiSchema["ui:options"];
      return (
          <textarea
              id={name}
              className={className}
              name={name}
              value={value || ""}
              onChange={this.handleChange}
              {...options}
          />
      );
  }
}

Multiline.propTypes = {};

export default Multiline;
