import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../routes/actions/Product";

const MultiCard = (props) => {
  const [filterClasses, setFilterClasses] = useState([]);
  const [filterLessons, setFilterLessons] = useState([]);
  const [userClass, setUserClass] = useState("");

  useEffect(() => {
    props.getTaxonomies();
  }, []);

  useEffect(() => {
    const filterClasses = props.taxonomies?.filter((item) => {
      return item.code == "siniflar";
    });
    const classList = filterClasses[0]?.taxons?.filter((item) => {
      return item;
    });
    setFilterClasses(classList);
   
 
    const filterLessons = props.taxonomies?.filter((item) => {
      return item.code == "dersler";
    });
    const selectedLessons = filterLessons[0]?.taxons.filter((item) => {
      return (
        item.code == "turkce" ||
        item.code == "matematik" ||
        item.code == "fen" ||
        item.code == "sosyal" ||
        item.code == "ingilizce" ||
        item.code == "din" ||
        item.code == "inkilap" ||
        item.code == "tarih" ||
        item.code == "edebiyat" ||
        item.code == "cografya" ||
        item.code == "fizik" ||
        item.code == "kimya" ||
        item.code == "biyoloji" ||
        item.code == "problemler" ||
        item.code == "geometri"
      );
    });
 
    if(props.user.id && classList && classList.length > 0 && selectedLessons && selectedLessons.length > 0){
      const stringClassId = props.user.classId.toString();
      const selectedClassProductIds = classList
                                      .filter(c => c.name.startsWith(stringClassId))
                                      .map(c => c.productIds)
                                      .flat();
 
      const filteredLessons = selectedLessons.filter(lesson => lesson.productIds.some(id => selectedClassProductIds.includes(id)));
      const lessonsToFilter = filteredLessons || [];
      const shuffledLessons = lessonsToFilter.filter((c) => c.count > 1).sort(() => Math.random() - 0.5);
      const randomFourLessons = shuffledLessons.slice(0, 4);
      setFilterLessons(randomFourLessons);
    }else{
      const selectedLessonsToFilter = selectedLessons || [];
      const shuffledSelectedLessons = selectedLessonsToFilter.filter((c) => c.count > 1).sort(() => Math.random() - 0.5);
      const randomFourSelectedLessons = shuffledSelectedLessons.slice(0, 4);
      setFilterLessons(randomFourSelectedLessons);
    }
 
  }, [props.taxonomies, props.user]);

  useEffect(() => {
    const filterSelectedClass = filterClasses?.findIndex((item) => {
      return item.code == `${props.user.classId}-sinif`;
    });
    setUserClass(filterSelectedClass);
  }, [filterClasses]);

  return (
    <div className="col-lg-4 col-md-6 col-12 mb-4">
      <div className="main-card">
        <div className="main-card-header multi-header">
          <h2>
            {props.user.id
              ? `${props.user.classId}. Sınıf Dersleri`
              : "Dersler"}
          </h2>
          <div className="more-arrow"></div>
        </div>

        <ul className="classes-list">
          {filterLessons?.map((lesson) => (
            <li key={lesson.id}>
              <Link
                to={
                  props.user.id
                    ? `/urunler?sinif=${filterClasses[userClass]?.id}&ders=${lesson.id}&i=n `
                    : `/urunler?ders=${lesson.id}&i=n `
                }
                className="card-item"
              >
                <img className="img-fluid card-img" src={lesson.image} />
                <span>{lesson.name}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = ({ ProductReducer }) => ({
  taxonomies: ProductReducer.taxonomies,
});

const mapDispatchToProps = { getTaxonomies: actions.getTaxonomies };

export default connect(mapStateToProps, mapDispatchToProps)(MultiCard);
