import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useMemo } from "react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { slugify } from "../../helpers";

const Accordion = ({ taxonomies, contracts }) => {
  const [active, setActive] = useState("");

  const footer = useMemo(() => {
    return [
      {
        code: "custom-magaza",
        id: 9996,
        name: "Kategoriler",
        type: "categories",
        item: [taxonomies],
      },
      {
        code: "custom-magaza",
        id: 9999,
        name: "Tonguç Mağaza",
        type: "store",
        item: [],
      },
      {
        code: "custom-sozlesmeler",
        id: 9998,
        name: "Sözleşmeler",
        type: "contracts",
        item: contracts,
      },
      {
        code: "custom-exams",
        id: 9997,
        name: "Yardım",
        type: "help",
        item: [],
      },
    ];
  }, [taxonomies, contracts]);

  function returnFooterItems(footerItem) {
    if (footerItem.type === "categories") {
      return (
        <div>
          {footerItem.item[0].map((itemm, i) => (
            <span key={i}>
              {itemm.taxons
                .filter((taxon) => taxon.count > 0)
                .map((taxon, index, arr) => (
                  <span>
                    <Link
                      to={`/urunler?t=${slugify(taxon.id)}`}
                      style={{ display: "inline-block" }}
                    >
                      {taxon.name}
                    </Link>
                    {index < arr.length - 1 && " | "}
                  </span>
                ))}
            </span>
          ))}
        </div>
      );
    }

    if (footerItem.type === "store") {
      return (
        <li>
          <Link className="btn-link text-left" to="/satis-noktalarimiz">
            <img
              src={require("../../assets/images/footer/icon-maps.svg")}
              alt=""
            />
            <div className="footer-link-item">Tonguç Hangi Kırtasiyede?</div>
          </Link>
          <Link to="/iletisim" className="btn-link text-left">
            <img
              src={require("../../assets/images/footer/icon-call-center.svg")}
              alt=""
            />

            <div className="footer-link-item">Yardım</div>
          </Link>
          <Link to="/biz-kimiz" className="btn-link text-left">
            <img
              src={require("../../assets/images/footer/icon-info.svg")}
              alt=""
            />

            <div className="footer-link-item">Hakkımızda</div>
          </Link>
          <Link className="btn-link text-left" to="/">
            <img
              src={require("../../assets/images/footer/icon-login.svg")}
              alt=""
            />

            <div className="footer-link-item">Kırtasiye girişi</div>
          </Link>
        </li>
      );
    }
    if (footerItem.type === "contracts") {
      return footerItem.item.map((item, i) => (
        <li key={i}>
          <Link
            to={`/sozlesmeler/${slugify(
              item.contractName + " " + item.contractTypeId
            )}`}
          >
            {item.contractName}
          </Link>
        </li>
      ));
    }
    if (footerItem.type === "help") {
      return (
        <li>
          <Link className="btn-link text-left" to={"/hesabim/yardim"}>
            <div className="footer-link-item">Sıkça Sorulan Sorular</div>
          </Link>
          <Link
            to={"/hesabim/yardim#siparis-bilgi"}
            className="btn-link text-left"
          >
            <div className="footer-link-item">Yardım</div>
          </Link>
        </li>
      );
    }
  }

  const accordionItem = footer.map((item) => (
    <li
      key={item.id}
      className={`accordion-item ${active === item.id ? "show" : ""}`}
      onClick={() => (active === item.id ? setActive("") : setActive(item.id))}
    >
      <div className="accordion-head">
        <h6>{item.name}</h6>
        <FontAwesomeIcon icon={faChevronRight} className="accordion-icon" />
      </div>

      <div className="accordion-body">
        <ul>{returnFooterItems(item)}</ul>
      </div>
    </li>
  ));
  return (
    <div className="footer-accordion-container">
      <ul className="footer-accordion">{accordionItem}</ul>
      <div className="d-flex flex-column mt-4">
        <h6>Uygulamamızı İndirin</h6>
        <div className="footer-app-download">
          <div className="footer-app-item">
            <a
              href="https://play.google.com/store/apps/details?id=com.tonguc.doktor&hl=tr"
              target="_blank"
            >
              <img
                className="img-fluid"
                src={require("../../assets/images/footer/google-play.png")}
                alt=""
              />
            </a>
          </div>
          <div className="footer-app-item">
            <a
              href="https://apps.apple.com/tr/app/tats/id1233987029?l=tr"
              target="_blank"
            >
              <img
                className="img-fluid"
                src={require("../../assets/images/footer/app-store.png")}
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
