export const move = (arr, old_index, new_index) => {
  while (old_index < 0) {
    old_index += arr.length;
  }
  while (new_index < 0) {
    new_index += arr.length;
  }
  if (new_index >= arr.length) {
    new_index = new_index - arr.length;
    // aşağıdaki kod ne işe yarıyor anlamadım ama undefined değer eklemesi nesneye erişen yerlerde hata verdiriyordu. new_index'i yukarıdaki gibi yaptım - yasin
    // k = new_index - arr.length;
    // while (k-- + 1) {
    //   arr.push(undefined);
    // }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};
export const moveSlider = (arr, index, command) => {


  if(command == "moveup"){
    let oldValue = arr[index - 1];
    arr[index - 1] = arr[index];
    arr[index] = oldValue;
  }

  if(command == "movedown"){
    let oldValue = arr[index + 1];
    arr[index + 1] = arr[index];
    arr[index] = oldValue;
  }
  
  return arr;
};
