import React, { useState, useEffect, useMemo } from "react";
import Modal from "./Index";
import CloseButton from "../Button/Close";
import apiCall from "../../services/api";
import * as actions from "../../routes/actions/Content";
import { connect } from "react-redux";
import Select from "react-select";
import { customStyles, move } from "../../helpers";
import Icon from "../Icon/Index";

const EditableStory = (props) => {
  const [page, setPage] = useState([]);
  const [selectedStory, setSelectedStory] = useState(0);
  const [mode, setMode] = useState("");

  useEffect(() => {
    if (props.show) {
      apiCall(`/contents/content/${props.editableStoryName}/1`).then((res) => {
        setPage(res.data);
      });
    }
  }, [props.editableStoryName, props.show]);

  const taxonList = useMemo(() => {
    let output = [];
    props.taxonomies.map((taxon) =>
      taxon.taxons.map((t) => {
        output.push(t);
      })
    );
    return output;
  }, [props.taxonomies]);

  var options =
    taxonList &&
    taxonList.map((x) => ({ value: x.id, label: x.name, image: x.image }));

  const addOrRemoveProduct = (selectedStory) => {
    let { content } = { ...page };
    if (!content.stories) {
      content.stories = [];
    }
    let { stories } = content;
    if (stories.some((x) => x === selectedStory)) {
      stories = stories.splice(
        stories?.findIndex((x) => x === selectedStory),
        1
      );
    } else {
      stories.push(selectedStory);
    }

    setPage({
      ...page,
      content,
    });
    setMode("changed");
  };

  const changeProductOrder = (command, index) => {
    const storyList = page.content.stories;
    switch (command) {
      case "moveup":
        move(storyList, index, index - 1);
        setMode("changed");
        break;
      case "movedown":
        move(storyList, index, index + 1);
        setMode("changed");
        break;

      default:
        break;
    }
    setPage({
      ...page,
      content: {
        ...page.content,
        stories: storyList,
      },
    });
  };

  const handleAdd = () => {
    if (selectedStory !== 0) {
      addOrRemoveProduct(selectedStory);
    }
  };

  const savePage = async () => {
    await props.savePage(page.id, page);
    setMode("saved");
  };

  const publishPage = () => {
    props.publishPage(page.id);
    props.onClose();
    props.onPublish(page);
  };

  return (
    <Modal
      className="editableStoryModal"
      show={props.show}
      onCloseClick={props.onClose}
    >
      <CloseButton onClick={props.onClose} />
      <div className="modal-body">
        <div className="product-list">
          {page.content?.stories?.length > 0 &&
            page.content?.stories.map((story, i) => (
              <div className="product-list-item" key={i}>
                <div className="product-content">
                  <span className="product-index">{i + 1}.</span>
                  <div className="product-img">
                    <img className="img-fluid" src={story?.image} alt="" />
                  </div>
                  <div className="product-name text-truncate">
                    {story?.label}
                  </div>
                </div>
                <div className="product-operations">
                  {i > 0 && (
                    <button
                      className="btn"
                      onClick={() => changeProductOrder("moveup", i)}
                    >
                      <Icon icon="iUp" />
                    </button>
                  )}
                  {i < page.content?.stories.length - 1 && (
                    <button
                      className="btn"
                      onClick={() => changeProductOrder("movedown", i)}
                    >
                      <Icon icon="iDown" />
                    </button>
                  )}
                  <button
                    className="btn"
                    onClick={() => addOrRemoveProduct(story)}
                  >
                    <Icon icon="iDelete" />
                  </button>
                </div>
              </div>
            ))}
        </div>
        <div className="product-select-container">
          <Select
            options={options}
            styles={customStyles}
            placeholder="Hikaye Seçiniz"
            className="w-100"
            isSearchable
            onChange={(item) => setSelectedStory(item)}
          />
          <button type="button" className="btn w-100" onClick={handleAdd}>
            <Icon icon="iPlusWhite" />
            Hikaye ekle
          </button>
        </div>
        <div className="modal-operations">
          <button
            type="button"
            className="btn btn-cancel"
            onClick={props.onClose}
          >
            İptal
          </button>
          <button
            type="button"
            className="btn btn-save"
            onClick={savePage}
            disabled={mode !== "changed"}
          >
            Kaydet
          </button>
          <button
            type="button"
            className="btn btn-save"
            onClick={publishPage}
            disabled={mode !== "saved"}
          >
            Yayınla
          </button>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ ProductReducer }) => ({
  taxonomies: ProductReducer.taxonomies,
});

const mapDispatchToProps = {
  savePage: actions.savePage,
  publishPage: actions.publishPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditableStory);
