const LOGOUT = "ACCOUNT/LOGOUT";
const GET_ACTIVITY_TYPES = "STUDY/GET_ACTIVITY_TYPES";
const GET_ACTIVITIES = "STUDY/GET_ACTIVITIES";
const GET_SCORES = "STUDY/GET_SCORES";
const GET_BADGES = "STUDY/GET_BADGES";
const GET_USER_BADGES = "STUDY/GET_USER_BADGES";
const GET_BRANS_TEST = "STUDY/GET_BRANS_TEST";
const GET_USER_PRODUCT = "STUDY/GET_USER_PRODUCT";
const GET_LIBRARY = "STUDY/GET_LIBRARY";
const ACTIVATE_BOOK = "STUDY/ACTIVATE_BOOK";
const GET_ANALIZES = "STUDY/GET_ANALIZES";
const SEARCH_BY_KEYWORD = "STUDY/SEARCH_BY_KEYWORD";
const TESTS_QUESTIONS = "STUDY/TESTS_QUESTIONS";
const FETCH_DESK = "STUDY/FETCH_DESK";
const FETCH_PROFILE = "STUDY/FETCH_PROFILE";
const UPDATE_USER_PROFILE = "ACCOUNT/UPDATE_USER_PROFILE";
const GET_BOOK_DETAILS = "STUDY/GET_BOOK_DETAILS";
const GET_AVATARS = "STUDY/GET_AVATARS";
const GET_BOOK_DOCS = "STUDY/GET_BOOK_DOCS";
const GET_USER_BRANCHES = "STUDY/GET_USER_BRANCHES";
const GET_USERS_BOOKS_FOR_DASHBOARD = "STUDY/GET_USERS_BOOKS_FOR_DASHBOARD";
const SET_INITIAL_BOOKS = "STUDY/SET_INITIAL_BOOKS";

export default {
    LOGOUT,
    GET_ACTIVITY_TYPES,
    GET_ACTIVITIES,
    GET_SCORES,
    GET_BADGES,
    GET_USER_BADGES,
    GET_BRANS_TEST,
    GET_USER_PRODUCT,
    GET_LIBRARY,
    ACTIVATE_BOOK,
    GET_ANALIZES,
    SEARCH_BY_KEYWORD,
    TESTS_QUESTIONS,
    FETCH_DESK,
    FETCH_PROFILE,
    GET_BOOK_DOCS,
    UPDATE_USER_PROFILE,
    GET_BOOK_DETAILS,
    GET_AVATARS,
    GET_USER_BRANCHES,
    GET_USERS_BOOKS_FOR_DASHBOARD,
    SET_INITIAL_BOOKS
};