import actionTypes from "./types";
import { HTTP, settings, S3BucketEndPoint } from "../../../services/constants";
import apiCall, { tatsApiCall, bucketCall } from "../../../services/api";
import storage from "../../../services/storage";
import { goBack } from "connected-react-router";
import { toast } from "react-toastify";
export const productQuery = (id = "", brands = "", keyword = "", ids = "") => `
    query{
        allProducts(
            userId: 0
        ){
            brandId,
            name,
            imageUrl,
            id,
            color,
            reNew,
            smartContent,
            isNew,
            hasDigitalBook,
            digitalGroupId,
            isBought,
            digitalBookId,
            class,
            boughtDate,
            total,
            price,
            tax
        }
    }
`;

export const isTest = (id) => async () => {
  const { data } = await tatsApiCall(
    `/BookOperations/testsQuestions`,
    { testId: id },
    null,
    HTTP.POST,
    null,
    true
  );

  return data;
};

export const questionOptions = (classId) => {
  var options = ["A", "B", "C", "D", "E"];
  try {
    var c = Number(classId);
    if (c === -2) {
      return options.slice(0, 3);
    } else if (c === -3) {
      return options.slice(0, 4);
    } else if (c === -4) {
      return options;
    } else if (c < 4) return options.slice(0, 3);
    else if (c < 9) return options.slice(0, 4);
    else return options;
  } catch (error) {
    return options;
  }
};

export const questionOptions2 = (cevapSecenek) => {
  var options = ["A", "B", "C", "D", "E"];
  return cevapSecenek ? options.slice(0, cevapSecenek) : options;
};

export const fetchStudyHome = () => async () => { };

export const getBookDocs = (id) => async (dispatch, getState) => {
  const docsRequest = tatsApiCall(
    `/BookOperations/booksDocuments`,
    { yayinId: id },
    null,
    HTTP.POST,
    null,
    true
  );
  const docsResponse = await docsRequest;
  const docs = { ...docsResponse.data };
  dispatch({
    type: actionTypes.GET_BOOK_DOCS,
    payload: docs,
  });

  if (docsResponse.data && docsResponse.data.status == false) {
    toast.error(docsResponse.data.errorMessage);
  }

};
export const getBookDetails = (id, grupId) => async (dispatch, getState) => {
  // const productRequest = apiCall(`/products/digital/${id}`);

  const bransRequest = tatsApiCall(
    `/BookOperations/booksBrans`,
    { yayinId: id, grupId: grupId },
    null,
    HTTP.POST,
    null,
    true
  );

  // const productResponse = await productRequest;
  const bransResponse = await bransRequest;

  const unit = { ...bransResponse.data };

  dispatch({
    type: actionTypes.GET_BOOK_DETAILS,
    payload: unit,
  });

  // const books = getState().StudyReducer.books;

  // if(books.length === 0) {
  //     getPurchasedBooks()(dispatch);
  // }
};

export const getUserBranches = () => async (dispatch, getState) => {
  const { data } = await tatsApiCall(
    `/userlibrary/getuserbranches`,
    {},
    null,
    HTTP.POST,
    null,
    true
  );

  dispatch({
    type: actionTypes.GET_USER_BRANCHES,
    payload: data,
  });
};

export const updateUserProfile = (user) => async (dispatch) => {
  try {
    await apiCall(`/account/${user.id}`, user, null, HTTP.PUT, null, true);

    storage.setUser(user);

    dispatch({
      type: actionTypes.UPDATE_USER_PROFILE,
      payload: user,
    });

    return { success: true };
  } catch (error) {
    if (error.status === 422) {
      return { success: false, error: error.data.error };
    }
    return { success: false, error: error.response.data };
  }
};

export const getErrorBox = () => async (dispatch) => {
  const { data } = await tatsApiCall(
    "/UserLibrary/ErrorBox",
    null,
    null,
    HTTP.POST,
    null,
    true
  );
};

export const getAvatars = () => async (dispatch) => {
  const { data } = await apiCall(
    "/account/avatars",
    null,
    null,
    HTTP.GET,
    null,
    true
  );

  dispatch({
    type: actionTypes.GET_AVATARS,
    payload: data,
  });
};

export const getPurchasedBooks = () => async (dispatch) => {
  const bookStatus = {
    activated: "Activated",
    bought: "Bought",
  };

  const getUserBooksRequest = tatsApiCall(
    "/UserLibrary/GetUserBooks",
    null,
    null,
    HTTP.GET,
    null,
    true
  );

  const allBooksRequest = settings.GET_DATA_FROM_AWS
    ? bucketCall("/library-book-metadata.json")
    : apiCall(
      "/products/search?query=query{ allProductsForLibrary{ brandId, total, name, imageUrl, id, class, price, hasDigitalBook, digitalBookId, digitalGroupId } }"
    );

  const userBooks = await getUserBooksRequest;
  const allBooksData = await allBooksRequest;

  //null, empty, array checks...
  userBooks.data =
    userBooks.data &&
      userBooks.data.productIds &&
      userBooks.data.productIds.length
      ? userBooks.data.productIds
      : [];

  const allBooks = allBooksData.data.data.allProductsForLibrary;

  let books = allBooks.filter((book) => book.digitalBookId > 0);

  books = books.filter((obj, pos, arr) => {
    return (
      obj["digitalGroupId"] == null ||
      obj["digitalGroupId"] == 0 ||
      arr
        .map((mapObj) => mapObj["digitalGroupId"])
        .indexOf(obj["digitalGroupId"]) === pos
    );
  });

  const booksArray = [];

  userBooks.data.forEach((b) => {
    // if((b.yayinId === 0 && b.grupId)) {
    //   return;
    // }

    let book = books.find(
      (all) =>
        all.digitalBookId === b.yayinId ||
        (all.digitalGroupId === b.grupId &&
          (b.yayinId === 0 || b.yayinId === null))
    );

    //kitabın dk için satış kanalı yok
    if (!book) {
      return;
    }

    // let isActivated = false;

    // if(b.type === bookStatus.activated) {
    //   isActivated = true;
    // }
    // else if(b.grupId) {
    //   const groupBook = userBooks.data.find((gp) => gp.yayinId === 0 && gp.grupId === b.grupId);
    //   if(groupBook && groupBook.type === bookStatus.activated) {
    //     isActivated = true;
    //   }
    // }

    book.isActivated = b.type === bookStatus.activated;
    book.progress = 0;
    book.baskiId = b.baskiId;
    book.dosyaVarmi = b.dosyaVarmi;
    if (typeof book.class === 'string' || book.class instanceof String)
      book.class = book.class.replace(/ /g, "").split(",");
    if (book.isActivated) {
      const solvedTotal = b.kitapBilgisi.analiz.aktifYayinCozulenToplamSoru;
      const total = b.kitapBilgisi.analiz.aktifYayinToplamSoru;
      book.progress =
        solvedTotal && total ? Math.round((solvedTotal / total) * 100) : 0;
      book.activatedDate = new Date(b.aktivasyonTarihi);
    }
    book.bookName = b.yayinAdi;

    //book.imageUrl = `${S3BucketEndPoint}${book.imageUrl}`;
    book.imageUrl = b.kapakAdres;

    booksArray.push(book);
  });

  const boughtBooks = booksArray
    .filter((b) => b.isActivated)
    .sort((a, b) => {
      if (b.dosyaVarmi === a.dosyaVarmi) {
        return b.activatedDate - a.activatedDate;
      }
      return b.dosyaVarmi > a.dosyaVarmi ? 1 : -1
    });
  const activatedBooks = booksArray
    .filter((b) => !b.isActivated)
    .sort((a, b) => {
      if (b.dosyaVarmi === a.dosyaVarmi) {
        return b.activatedDate - a.activatedDate;
      }
      return b.dosyaVarmi > a.dosyaVarmi ? 1 : -1
    });
  dispatch({
    type: actionTypes.GET_LIBRARY,
    payload: {
      books: activatedBooks.concat(boughtBooks),
    },
  });
};

export const getGroupBooks = (groupId) => async (dispatch) => {
  const groupBooksRequest = tatsApiCall(
    "/BookOperations/bookGroup",
    { grupId: groupId },
    null,
    HTTP.POST,
    null,
    true
  );
  const groupBooksData = await groupBooksRequest;
  const groupBooks = groupBooksData.data;

  if (groupBooks.kitaplar.length) {
    const digitalBookIds = [];

    groupBooks.kitaplar.forEach((b) => {
      digitalBookIds.push({
        id: b.urun.yayinID,
        yayinId: b.urun.yayinID,
        name: b.urun.yayinAdi,
        analiz: b.analiz,
        imageUrl: b.urun.kapakAdres,
        dosyaVarMi: b.urun.dosyaVarMi,
        isDogruKaynak: b.urun.isDogruKaynak,
        grupId: b.grupID

      });
    });
    // const productRequest = apiCall(
    //   `/products/digital/${digitalBookIds[0].yayinId}`
    // );

    // const productData = await productRequest;
    // const product = productData.data;

    const books = [];

    digitalBookIds.forEach((book) => {
      var product = { boughtDate: new Date() };
      product.id = book.id;
      product.digitalBookId = book.yayinId;
      product.name = book.name;
      product.imageUrl = book.imageUrl;
      product.isActivated = true;
      product.isBought = true;
      product.digitalGroupId = null;
      product.dosyaVarmi = book.dosyaVarMi;
      product.hasDigitalBook = book.isDogruKaynak;
      product.grupId = book.grupId;

      const solvedTotal = book.analiz.aktifYayinCozulenToplamSoru;
      const total = book.analiz.aktifYayinToplamSoru;
      product.progress =
        solvedTotal && total ? Math.round((solvedTotal / total) * 100) : 0;

      books.push({ ...product });
    });

    dispatch({
      type: actionTypes.GET_LIBRARY,
      payload: {
        books: books,
      },
    });
  } else {
    dispatch({
      type: actionTypes.GET_LIBRARY,
      payload: {
        books: [],
      },
    });
  }

  //const allProductsResponse = apiCall("/products/search", { Query: productQuery("", "", "", "") }, null, HTTP.POST, null, true);

  //const productRequest = apiCall(`/products/digital/${id}`);

  // const allProducts = await allProductsResponse;
  // const activated = await activatedBooksResponse;

  // console.log(activated.data);

  // activated.data = (activated.data && activated.data.productIds && activated.data.productIds.length) ? activated.data.productIds : [];
  // const books = allProducts.data.data.allProducts.filter((book) => book.hasDigitalBook);
  // books.forEach((book) => {
  //     const activatedBook = activated.data.find((a) => a.yayinId === book.digitalBookId || (a.yayinId === 0 && a.grupId === book.digitalGroupId));
  //     book.isActivated = activatedBook ? true : false;
  //     book.progress = 0;
  //     if (book.isActivated) {
  //         const solvedTotal = activatedBook.kitapBilgisi.analiz.aktifYayinCozulenToplamSoru;
  //         const total = activatedBook.kitapBilgisi.analiz.aktifYayinToplamSoru;
  //         book.progress = solvedTotal ? Math.round((solvedTotal / total) * 100) : 0
  //         book.activatedDate = new Date(activatedBook.aktivasyonTarihi);
  //     }
  //     book.boughtDate = book.boughtDate === null ? null : new Date(book.boughtDate);
  // });
  // const boughtBooks = books.filter(b => b.isActivated).sort((a, b) => b.activatedDate - a.activatedDate);
  // const activatedBooks = books.filter(b => b.isBought && !b.isActivated).sort((a, b) => b.boughtDate - a.boughtDate);

  // dispatch({
  //     type: actionTypes.GET_LIBRARY,
  //     payload: {
  //         books: activatedBooks.concat(boughtBooks)
  //     }
  // });
};

export const activateBook = (key) => async (dispatch, getState) => {
  const {
    AccountReducer: { token },
  } = getState();

  //KEY: YUSUF112
  const model = {
    aktivasyonKey: key,
    bearer: `Bearer ${token}`,
  };

  const activated = await tatsApiCall(
    "/BookOperations/booksDkActivation",
    model,
    null,
    HTTP.POST,
    null,
    true
  );

  return activated;
};

export const fetchDesk = () => async (dispatch, getState) => {
  // ###### burası çalışma modu anasayfasında çalışıyor.
  const {
    StudyReducer: { scores, taxonomies, avatars },
  } = getState();

  const scoreRequest =
    !scores.length &&
    tatsApiCall("/Score/GetScores", null, null, HTTP.GET, null, true);
  const taxonomiesRequest =
    !taxonomies.length &&
    (settings.GET_DATA_FROM_AWS
      ? bucketCall("/taxonomies.json")
      : apiCall("/taxonomies"));
  const avatarsRequest =
    !avatars.length &&
    apiCall("/account/avatars", null, null, HTTP.GET, null, true);
  const taxonomiesData = await taxonomiesRequest;
  const scoresData = await scoreRequest;
  const avatarsData = await avatarsRequest;

  dispatch({
    type: actionTypes.FETCH_DESK,
    payload: {
      scores: !scores.length ? scoresData.data : scores,
      taxonomies: !taxonomies.length ? taxonomiesData.data : taxonomies,
      avatars: !avatars.length ? avatarsData.data : avatars,
    },
  });
};

export const fetchProfile = () => async (dispatch, getState) => {
  // ###### burası çalışma modu profil sayfasında çalışıyor.
  const {
    StudyReducer: { scores, taxonomies, activities, badges, wallet },
  } = getState();

  const taxonomiesRequest =
    !taxonomies.length &&
    (settings.GET_DATA_FROM_AWS
      ? bucketCall("/taxonomies.json")
      : apiCall("/taxonomies"));
  const walletRequest = !wallet.length && apiCall("/coins/wallet");
  const activitiesRequest =
    !activities.length &&
    tatsApiCall("/Score/GetActivities", null, null, HTTP.POST, null, true);
  const scoreRequest =
    !scores.length &&
    tatsApiCall("/Score/GetScores", null, null, HTTP.GET, null, true);
  const badgeRequest =
    !badges.length &&
    tatsApiCall("/Score/GetBadges", null, null, HTTP.GET, null, true);

  const taxonomiesData = await taxonomiesRequest;
  const walletData = await walletRequest;
  const scoresData = await scoreRequest;
  const badgesData = await badgeRequest;
  let activitiesData = await activitiesRequest;

  if (activitiesData && activitiesData.data.length) {
    activitiesData.data.forEach((a) => {
      a.CreateDate = new Date(a.CreateDate);
    });
    activitiesData.data.sort((a, b) => b.Id - a.Id);
  }

  dispatch({
    type: actionTypes.FETCH_PROFILE,
    payload: {
      taxonomies: !taxonomies.length ? taxonomiesData.data : taxonomies,
      activities: !activities.length ? activitiesData.data : activities,
      scores: !scores.length ? scoresData.data : scores,
      badges: !badges.length ? badgesData.data : badges,
      wallet: !wallet.length ? walletData.data : wallet,
    },
  });
};

export const getActivityTypes = () => async (dispatch) => {
  const activityTypesRequest = settings.GET_DATA_FROM_AWS
    ? bucketCall("/activity-types.json")
    : tatsApiCall("/Score/GetActivityTypes", null, null, HTTP.GET, null, true);

  const activityTypes = await activityTypesRequest;

  dispatch({
    type: actionTypes.GET_ACTIVITY_TYPES,
    payload: activityTypes.data,
  });
};

export const getActivities = () => async (dispatch) => {
  const { data } = await tatsApiCall(
    "/Score/GetActivities",
    null,
    null,
    HTTP.GET,
    null,
    true
  );

  dispatch({
    type: actionTypes.GET_ACTIVITIES,
    payload: data,
  });
};

export const getScores = () => async (dispatch) => {
  const { data } = await tatsApiCall(
    "/Score/GetScores",
    null,
    null,
    HTTP.GET,
    null,
    true
  );

  dispatch({
    type: actionTypes.GET_SCORES,
    payload: data,
  });
};

export const getBadges = () => async (dispatch) => {
  const { data } = await tatsApiCall(
    "/Score/GetBadges",
    null,
    null,
    HTTP.GET,
    null,
    true
  );

  dispatch({
    type: actionTypes.GET_BADGES,
    payload: data,
  });
};

export const getUserBadges = () => async (dispatch) => {
  const { data } = await tatsApiCall(
    "/Score/GetUserBadges",
    null,
    null,
    HTTP.POST,
    null,
    true
  );

  dispatch({
    type: actionTypes.GET_USER_BADGES,
    payload: data,
  });
};

export const getBransTest = () => async (dispatch) => {
  const { data } = await tatsApiCall(
    "/BookOperations/BransTest",
    null,
    null,
    HTTP.POST,
    null,
    true
  );
  dispatch({
    type: actionTypes.GET_BRANS_TEST,
    payload: data,
  });
};

export const getTestsQuestions = (testId, isVirtual = false) => async (
  dispatch,
  getState
) => {
  let result = {};
  const {
    AccountReducer: { user },
    StudyReducer,
  } = getState();

  if (StudyReducer.testId == testId) {
    result = StudyReducer.testsQuestions;
  }

  if (result.status && !!user.id) {
    return dispatch({
      type: actionTypes.TESTS_QUESTIONS,
      payload: {
        testId: !user.id ? 0 : testId,
        testsQuestions: result,
      },
    });
  }

  const model = isVirtual
    ? {
      sanalTestNo: testId,
    }
    : {
      testId,
    };

  const path = isVirtual
    ? `/studentsigns/getrandomtest`
    : `/BookOperations/testsQuestions`;

  const { data } = await tatsApiCall(path, model, null, HTTP.POST, null, true);

  dispatch({
    type: actionTypes.TESTS_QUESTIONS,
    payload: {
      testId: !user.id ? 0 : testId,
      testsQuestions: data,
    },
  });
};

export const getAnalizes = () => async (dispatch) => {
  const analyzeRequest = tatsApiCall(
    "/bookoperations/appanalysisV2",
    null,
    null,
    HTTP.POST,
    null,
    true
  );
  const taxonomiesRequest = settings.GET_DATA_FROM_AWS
    ? bucketCall("/taxonomies.json")
    : apiCall("/taxonomies");

  const analyze = await analyzeRequest;
  const taxonomies = await taxonomiesRequest;

  dispatch({
    type: actionTypes.GET_ANALIZES,
    payload: {
      analize: analyze.data.analiz,
      taxonomies: taxonomies.data,
    },
  });
};

export const search = (keyword) => async (dispatch) => {
  const { data } = await tatsApiCall(
    "/bookoperations/search",
    { keyword },
    null,
    HTTP.POST,
    null,
    true
  );

  dispatch({
    type: actionTypes.SEARCH_BY_KEYWORD,
    payload: { data, keyword },
  });
};

export const getUsersBooksForDashboard = () => async (dispatch) => {
  const { data } = await tatsApiCall(
    "/userlibrary/getusersbooksfordashboard",
    null,
    null,
    HTTP.GET,
    null,
    true
  );

  dispatch({
    type: actionTypes.GET_USERS_BOOKS_FOR_DASHBOARD,
    payload: data,
  });
};

export const setInitialBook = () => async (dispatch) => {

  dispatch({
    type: actionTypes.SET_INITIAL_BOOKS
  });
};