import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../routes/actions/Content";

class TopMenuBlog extends PureComponent {
  componentDidMount() {
    this.props.getPages();
  }

  distinct(value, index, self) {
    return self.indexOf(value) === index;
  }

  render() {
    const { pages } = this.props;
    const blogs = pages.filter(
      (item) =>
        item.isBlog === true && item.active === 1 && item.published === 1
    );

    var categories = blogs.map((item) => {
      return item.blogOptions.category;
    });

    const uniqueCategories = categories.filter(this.distinct);
    return (
      <div className="container-fluid mb-4 bt-dedede bg-white">
        <style>
          {
            "\
                .nav-scroller .nav li .nav-link:hover{\
                    background-color: black;\
                }\
            "
          }
        </style>
        <div className="row">
          <div className="nav-scroller bg-white container ml-auto mr-auto mb-1 mt-4 d-none d-lg-block">
            {!this.props.isPaymentPage && (
              <ul
                className="nav nav-underline"
                id="classes"
                style={{
                  backgroundColor: "#000000",
                  height: "50px",
                  paddingBottom: "0",
                }}
              >
                {uniqueCategories.map((item, index) => {
                  return (
                    item !== "" && (
                      <li className="nav-item" key={index}>
                        <Link
                          className="nav-link text-uppercase"
                          to={"/blog/" + item.toLowerCase()}
                        >
                          <span style={{ color: "white" }}>{item}</span>
                        </Link>
                      </li>
                    )
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ ContentReducer }) => ({
  pages: ContentReducer.pages,
});

const mapDispatchToProps = {
  getPages: actions.getPages,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopMenuBlog);

//export default TopMenuBlog;
