import React, { Component } from "react";
//import PropTypes from "prop-types";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor as HtmlEditor } from "react-draft-wysiwyg";
import "draft-js/dist/Draft.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class Editor extends Component {
  state = {
    editorState: this.props.value
      ? EditorState.createWithContent(convertFromRaw(this.props.value))
      : EditorState.createEmpty()
  };

  onEditorStateChange = editorState => {
    const { name, onChange } = this.props;
    this.setState({ editorState });
    onChange(name, convertToRaw(editorState.getCurrentContent()));
  };

  render() {
    const { editorState } = this.state;
    return (
      <div>
        <HtmlEditor
          editorState={editorState}
          wrapperClassName="html-editor-wrapper"
          editorClassName="html-editor"
          onEditorStateChange={this.onEditorStateChange}
        />
      </div>
    );
  }
}

Editor.propTypes = {};

export default Editor;
