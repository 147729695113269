import React from "react";
import CloseButton from "../Button/Close";
import Modal from "./Index";

const DislikeModal = (props) => {
  return (
    <Modal className="dislikeModal modal-container non-modal-height" show={props.show}  onCloseClick={props.onCloseClick}>
      <span className="line" />
      <CloseButton onClick={props.onCloseClick} />
      <div className="modal-body">
        <h6 className="m-0 fs-20 font-weight-600 color-484848">
          Bu videoyu neden beğenmedin?
        </h6>
        <ul className="dislike-comment">
          <li>
            <input type="radio" name="" /> <span>Video Açılmıyor</span>
          </li>
          <li>
            <input type="radio" name="" /> <span>Video Açılmıyor</span>
          </li>
          <li>
            <input type="radio" name="" /> <span>Video Açılmıyor</span>
          </li>
          <li>
            <input type="radio" name="" /> <span>Video Açılmıyor</span>
          </li>
          <li>
            <input type="radio" name="" /> <span>Video Açılmıyor</span>
          </li>
        </ul>
        <div className="d-flex flex-column flex-sm-row">
          <button className="btn border-radius-12 color-ffffff fs-16 font-weight-600 mb-3 mb-sm-0 mr-0 mr-sm-3 px-5 bg-6e87ff">
            Gönder
          </button>
          <button className="btn btn-cancel border-radius-12 color-202124 fs-16 font-weight-bold" onClick={props.onCloseClick}>
            Vazgeç
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default DislikeModal;
