/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/button-has-type */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import {
  FilterClasses,
  FilterLesson,
  FilterQuestion,
  FilterRating,
  Modal,
} from "../../components/index";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const FilterModal = (props) => {
  const [active, setActive] = useState("");

  function _renderTaxonItems(taxonomy) {
    if (taxonomy.type === "point") {
      return <FilterRating
          taxonomy={taxonomy}
          prefix={props.prefix}
          filters={props.filters}
          applyFilter={props.applyFilter}
          isFilterModal />;
    }

    if (taxonomy.type === "question") {
      return <FilterQuestion
          taxonomy={taxonomy}
          prefix={props.prefix}
          filters={props.filters}
          applyFilter={props.applyFilter}
          isFilterModal />;
    }
    if (taxonomy.code === "dersler") {
      return (
        <FilterLesson
          isFilterModal
          taxonomy={taxonomy}
          prefix={props.prefix}
          filters={props.filters}
          applyFilter={props.applyFilter}
        />
      );
    }
    if (taxonomy.code === "siniflar") {
      return (
        <FilterClasses
          taxonomy={taxonomy}
          prefix={props.prefix}
          filters={props.filters}
          applyFilter={props.applyFilter}
          isFilterModal
        />
      );
    }
    return taxonomy.taxons.map((taxon) => (
      <div key={taxon.id} className="form-group form-check brand-checkbox">
        <input
          onChange={props.applyFilter}
          checked={props.filters?.some((filter) => filter.id == taxon.id)}
          value={taxon.id}
          type="checkbox"
          id={`taxon-${taxon.id}${props.prefix ? `-${props.prefix}` : ""}`}
          name={taxon.name}
        />
        <label
          htmlFor={`taxon-${taxon.id}${props.prefix ? `-${props.prefix}` : ""}`}
          className="d-flex flex-column ml-2 mb-0"
        >
          <h5 className="checkbox-label">{taxon.name}</h5>
        </label>
      </div>
    ));
  }

  function _renderFilteredText(taxons) {
    const activeFilters = taxons.filter(
      (m) => props.filters.findIndex((f) => f.id === m.id) > -1
    );

    return activeFilters.map((item, i) => {
      return (
        <div className="filtered-item" key={i}>
          {item.name}
          {activeFilters.length !== i + 1 && `, `}
        </div>
      );
    });
  }

  return (
    <Modal
      onCloseClick={props.onCloseClick}
      show={props.show}
      className="filter-modal"
    >
      {/* <div>{filterList}</div> */}
      <div
        className="modal-header align-items-center"
        style={{ justifyContent: "left" }}
      >
        <h5 className="modal-title">Filtrele</h5>
        {props.filters.length > 0 && (
          <div className="ml-auto">
            <button
              type="button"
              onClick={props.clearFilters}
              className="btn btn-link p-0 color-f34854 fs-15 font-weight-500"
            >
              Temizle
            </button>
          </div>
        )}
      </div>
      <div className="modal-body">
        {props.taxonomies.map((taxonomy) => (
          <div
            key={taxonomy.id}
            className={`filter-accordion-item ${
              active === taxonomy.id ? "show" : ""
            }`}
          >
            <div className="filter-border-bottom">
              <div
                className="d-flex align-items-center"
                onClick={() =>
                  active === taxonomy.id
                    ? setActive("")
                    : setActive(taxonomy.id)
                }
              >
                <h6 className="filter-title">{taxonomy.name}</h6>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="filter-accordion-icon ml-auto"
                />
              </div>
              <div>
                <div className="d-flex flex-wrap">
                  {_renderFilteredText(taxonomy.taxons)}
                </div>

                <div className="brand-checkbox-container">
                  {_renderTaxonItems(taxonomy)}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default FilterModal;
