import React, { Component } from "react";

import { MainLogo } from "../../assets/images";
import FormSchemas from "../../variables/FormSchemas";
import * as actions from "../../routes/actions/Account";
import { toast } from "react-toastify";
import { BaseForm, Modal } from "../../components";
import { connect } from "react-redux";
import Icon from "../../components/Icon/Index";
import CloseButton from "../../components/Button/Close";

class EmailVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        code: "",
      },
    };
  }

  customToastSuccessRegister = () => (
    <div className="custom-toast">
      <Icon icon="iSuccess" className="mr-3" />
      <p className="mb-0">Teşekkürler. Üyeliğiniz aktifleştirildi.</p>
    </div>
  );

  render() {
    return (
      <Modal
        className="mailVerificationModal modal-container non-modal-height"
        show={this.props.show}
      >
        <div className="modal-header">
          <span />
          <button
            className="border-0 bg-transparent mr-3"
            onClick={this.props.onClick}
          >
            <Icon icon="iBackArrow" />
          </button>
          <h6 className="mb-0">E-Posta Adresini Doğrula</h6>
          <CloseButton onClick={this.props.onCloseClick} />
        </div>
        <div className="modal-body">
          <BaseForm
            schema={FormSchemas.verification.schema}
            uiSchema={FormSchemas.verification.uiSchema}
            formData={this.state.data}
            onSubmit={(data) =>{
              
              this.props.emailConfirmation({
                code: data.code,
                email: this.props.userEmail,
              });
            }}
          >
            <div className="d-flex ">
              <button
                type="submit"
                className="btn btn-primary btn-block py-2 fs-16 font-weight-600 border-radius-8"
              >
                Doğrula
              </button>
            </div>
          </BaseForm>
          <p className="fs-12 font-weight-500 mt-4 mb-0">
            Üyeliğini tamamlamak için {this.props.userEmail} alıcısına doğrulama kodu
            gönderildi. <br /> <br /> Gönderilen e-postadaki kodu buraya yazarak
            üyeliğini tamamla. <br /> <br />
            <strong>Not: Doğrulama e-postamızı almadıysanız,</strong>
            <br /> <br />- E-posta adresinizi doğru şekilde yazdığınıza emin
            olun. <br /> - Spam veya junk (istenmeyen) e-posta klasörünü kontrol
            edin.
          </p>
        </div>
      </Modal>
    );
  }
}

EmailVerification.propTypes = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  confirmEmail: actions.confirmEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification);
