export function getSearched() {
    if(typeof window === "undefined") {
        return [];
    }

    const data = window.localStorage.getItem("SEARCHED");
    return data ? JSON.parse(data) : [];
}

export function setSearched(item) {
    if(typeof window === "undefined") {
        return;
    }

    const searched = getSearched();

    const hasItemIndex = searched.findIndex(m => m === item);
    if(hasItemIndex > -1) {
        searched.splice(hasItemIndex, 1);
    }

    searched.push(item);
    window.localStorage.setItem("SEARCHED", JSON.stringify(searched));
}