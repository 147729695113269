import React, {Component} from "react";
import {notFound} from "../../variables/General";
import {LinkButton} from "../../components";
//import PropTypes from 'prop-types';

class NotFound extends Component {
    render() {
        return (
            <div className="row text-center">
                <div className="col-xl-12 justify-content-center">
                    <img src={notFound} alt="..." style={{maxWidth: 300}}/>
                </div>
                <div className="col-12">
                    <h2>Üzgünüm aradığınızı bulamadım :(</h2>

                    <p className="text-muted">Lütfen ana sayfaya dönüp arama yapın.</p>
                </div>
                <div className="col-12">
                    <LinkButton className="btn-primary" to="/">
            Ana Sayfa
                    </LinkButton>
                </div>
            </div>
        );
    }
}

NotFound.propTypes = {};

export default NotFound;
