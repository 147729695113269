import React, { PureComponent } from "react";
import { connect } from "react-redux";
import moment from "moment";
//import PropTypes from 'prop-types';
import { Breadcrumb } from "../../components";
//import { formatDate } from "../../helpers/formatDatetime";
import RadialChart from "../../components/Charts/RadialChart";
import ProgressCard from "../../components/Visual/ProgressCard";
import { ScoreBadge } from "../../components";
import * as actions from "../../routes/actions/Study";
import * as accountActions from "../../routes/actions/Account";
import lessonIcons from "../../assets/images/dersler";
import { toast } from "react-toastify";

const breadcrumbs = [
  {
    title: "Anasayfa",
    url: "/dijitalkitap",
  },
  {
    title: "Profil",
    active: true,
  },
];

class Profile extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      pageItemCount: 5,
    };
  }

  componentDidMount() {
    this.props.fetchProfile();
    if (!this.props.userBadges || this.props.userBadges.length === 0)
      this.props.getUserBadges();
  }

  showMore = () => {
    this.setState({
      page: this.state.page + 1,
    });
  };

  getLessonLevels = () => {
    const { scores, userBadges } = this.props;
    const lessonLevels = [];
    if (scores) {
      for (let i = 0; i < scores.length; i++) {
        const score = scores[i];
        if (score.Branch) {
          let lessonLevel = {};
          lessonLevel.title = score.Branch.Name;
          lessonLevel.prefix = lessonLevel.title.charAt(0).toUpperCase();
          lessonLevel.score = score.Score.CurrentScore;
          lessonLevel.level = score.Score.CurrentLevel;
          lessonLevel.totalScore =
            score.Score.CurrentScore + score.Score.DueScore;
          lessonLevel.badgeCount =
            userBadges && Array.isArray(userBadges)
              ? userBadges.filter((bdg) => bdg.Branch.Id == score.Branch.Id)
                  .length
              : 0;
          lessonLevel.icon =
            lessonIcons[lessonLevel.title.toLowerCase().charAt(0)] ||
            lessonIcons.d;
          lessonLevel.id = score.Branch.Id;
          lessonLevels.push(lessonLevel);
        }
      }
    }

    return lessonLevels;
  };

  handleGoToAccountInfo = () => {
    if (this.props.user.demo) {
      toast.error(
        "Bu işlemi DEMO modunda yapamazsınız. Devam etmek için giriş yapmanız gerekiyor."
      );
      this.props.loginRequired(true);
      return;
    }
    this.props.history.push("/hesabim/hesap-bilgilerim");
  };

  render() {
    const userClass = this.props.user.classId
      ? { name: `${this.props.user.classId}. Sınıf` }
      : {};
    const activities = this.props.activities.slice(
      0,
      this.state.pageItemCount * this.state.page
    );

    const level = (this.props.scores &&
      this.props.scores.find((s) => s.Branch === null)) || {
      Score: {},
    };

    const lessonLevels = this.getLessonLevels();

    const mainLevel = (this.props.scores &&
      this.props.scores.find((s) => s.Branch === null)) || {
      Score: {},
    };

    const badges = this.props.userBadges || [];

    return (
      <>
        <div className="row">
          <div className="col-12 p-xs-0">
            <Breadcrumb items={breadcrumbs} />
          </div>
        </div>

        <div className="row">
          <div className="col-12 p-xs-0">
            <div className="card">
              <div className="card-body text-center">
                <div className="row">
                  <div className="col-12 col-sm-6 d-flex br-c6c6c6 br-md-none p-4  profile-section">
                    <img
                      className="desk-profile-img"
                      src={
                        this.props.user.avatar
                          ? this.props.user.avatar.path
                          : "https://cdn.dogrukaynak.com/avatars/default/default.svg"
                      }
                      alt=""
                    />

                    <div className="ml-4 text-left">
                      <h2 className="fs-20 color-484848 font-weight-600">
                        {this.props.user.firstname} {this.props.user.lastname}
                      </h2>
                      {this.props.user.city &&
                      (this.props.user.city.name ||
                        this.props.user.town.name) ? (
                        <div className="fs-14 color-919191 font-weight-600 mt-1">
                          {this.props.user.town.name}/
                          {this.props.user.city.name}
                        </div>
                      ) : null}
                      <div className="fs-14 color-919191 font-weight-600 mt-1">
                        {this.props.user.schoolName}
                      </div>
                      <div className="fs-14 color-919191 font-weight-600 mt-1">
                        {userClass.name}
                      </div>

                      <button
                        onClick={() => this.handleGoToAccountInfo()}
                        target="_blank"
                        className="btn btn-clear mt-3 p-1 pl-0 box-shadow-none text-blue fs-13 font-weight-600"
                      >
                        <img
                          src={require("../../assets/images/icons/pencil.svg")}
                          className="mr-2"
                          style={{ height: 16 }}
                          alt="..."
                        />
                        Hesap Bilgilerim
                      </button>
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 p-xs-0 flex-sm-column flex-lg-row d-flex mt-3 mt-sm-0 align-items-center">
                    <div className="profile-chart">
                      {level.Score.CurrentLevel ? (
                        <RadialChart
                          current={level.Score.CurrentScore}
                          max={level.Score.CurrentScore + level.Score.DueScore}
                          labels={["Tecrübe"]}
                          label={level.Score.CurrentLevel}
                          chartHeight={window.innerWidth > 568 ? 240 : 180}
                          size={90}
                        />
                      ) : null}
                    </div>

                    <div className="d-flex flex-column ml-3">
                      <ScoreBadge
                        removePrefix={true}
                        icon="point"
                        className="badge-score-custom sm-small my-2 text-truncate"
                        value={`+${mainLevel.Score.TotalScore}`}
                      />
                      <ScoreBadge
                        title={`${
                          this.props.wallet.coins + this.props.wallet.bonus
                        } Jeton`}
                        icon="coin"
                        className="badge-coin sm-small my-2"
                        value={
                          this.props.wallet.coins + this.props.wallet.bonus
                        }
                      />
                      <ScoreBadge
                        title={`${badges.length || 0} Rozet`}
                        value={badges.length || 0}
                        icon="badge"
                        className="badge-rosette sm-small my-2"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12 p-xs-0">
            <div className="card activities-card">
              <div className="card-header">
                <h5 className="color-484848 fs-20 font-weight-600">
                  Son Aktiviteler
                </h5>
              </div>

              <div className="card-body">
                <ul className="list-group">
                  {activities.map((activity, i) => {
                    return (
                      <li
                        key={activity.Id + i}
                        className="list-group-item border-none d-flex flex-column flex-md-row align-items-md-center pb-3 pb-sm-0 justify-content-between color-919191 fs-16 p-0 p-sm-2 mb-3"
                      >
                        <div className="d-flex fs-16 font-weight-600 flex-sm-row align-items-sm-center w-100 flex-2">
                          <div className="color-919191 min-w-100 activities-date">
                            {moment(activity.CreateDate).fromNow()}
                          </div>
                          <div className="ml-0 ml-sm-3 color-919191 activities-text">
                            {activity.TypeId === 10
                              ? activity.Name
                              : activity.Title}
                          </div>
                        </div>
                        <div className="text-md-right flex-2 mt-1 mt-sm-0">
                          {activity.GainedScore > 0 ? (
                            <ScoreBadge
                              title={`+${activity.GainedScore}`}
                              value={activity.GainedScore}
                              icon="point"
                              className="badge-score-custom small mr-1"
                            />
                          ) : null}
                          {activity.GainedCoins > 0 ? (
                            <ScoreBadge
                              title={`+${activity.GainedCoins}`}
                              value={activity.GainedCoins}
                              icon="coin"
                              className="badge-coin small mr-1"
                            />
                          ) : null}
                          {activity.GainedCoins > 0 ? (
                            <ScoreBadge
                              title="+32"
                              value={32}
                              icon="badge"
                              className="badge-rosette small"
                            />
                          ) : null}
                        </div>
                      </li>
                    );
                  })}
                </ul>
                {this.props.activities.length >
                this.state.page * this.state.pageItemCount ? (
                  <div>
                    <div
                      className="more text-center d-none d-sm-block"
                      onClick={this.showMore}
                    >
                      <div
                        className="text-center text-uppercase fs-13 font-weight-600 bt-dedede:50 cursor-pointer border-bottom-c6c6c6"
                        style={{ color: "#c0cbfc" }}
                      >
                        Daha Fazla
                      </div>
                      <div>
                        <svg
                          className="cursor-pointer"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#6e87ff"
                            fillRule="nonzero"
                            d="M21.63 6.364c.594.603.608 1.583.022 2.19l-8.626 9.06a1.499 1.499 0 0 1-2.155.024l-8.832-8.85a1.567 1.567 0 0 1-.456-1.107c0-.404.15-.79.432-1.084a1.497 1.497 0 0 1 2.154-.024l7.74 7.756 7.566-7.943a1.495 1.495 0 0 1 2.155-.022z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="fs-16 w-100 font-weight-200 d-block d-sm-none flex-column align-items-center"
                      onClick={this.showMore}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <span className="color-6c83ff fs-13 font-weight-600">
                          DAHA FAZLA
                        </span>

                        <div style={{ height: 27 }}>
                          <svg
                            className="cursor-pointer"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#6e87ff"
                              fillRule="nonzero"
                              d="M21.63 6.364c.594.603.608 1.583.022 2.19l-8.626 9.06a1.499 1.499 0 0 1-2.155.024l-8.832-8.85a1.567 1.567 0 0 1-.456-1.107c0-.404.15-.79.432-1.084a1.497 1.497 0 0 1 2.154-.024l7.74 7.756 7.566-7.943a1.495 1.495 0 0 1 2.155-.022z"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {lessonLevels.length ? (
          <>
            <div className="row mt-3">
              <div className="col-12">
                <h5
                  className="color-484848 font-weight-500"
                  style={{ fontSize: 22 }}
                >
                  Dersler
                </h5>
              </div>
            </div>

            <div className="row d-flex justify-content-center">
              {lessonLevels.map((lesson, i) =>
                lesson.level && lesson.level > 0 ? (
                  <div className="col-6 col-md-4 col-lg-3 p-xs-5 mt-3" key={i}>
                    <ProgressCard
                      series={[lesson.level]}
                      labels={["Seviye"]}
                      cardText={lesson.title}
                      lesson={lesson}
                    />
                  </div>
                ) : null
              )}
            </div>
          </>
        ) : null}

        <div className="row mt3">
          <div className="col-12">
            <h5
              className="color-484848 font-weight-500 fs-22"
              style={{ fontSize: 22 }}
            >
              Rozetler
            </h5>
          </div>
        </div>

        {this.props.userBadges.length ? (
          <>
            <div className="row">
              {this.props.userBadges.map((b) => (
                <div key={b.Id} className="col-6 col-sm-4 col-md-3 mt-3">
                  <div className="card text-center">
                    <div className="card-body">
                      <h4 className="fs-20 color-484848 my-2">
                        {b.Badge.Name}
                      </h4>
                      <img src={b.Image} alt={b.Badge.Name} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="row">
              <div className="col-12">
                <div className="fs-16 color-484848 my-2">
                  Rozet kazanmak için soru çözmelisin.
                </div>
              </div>
            </div>
          </>
        )}

        <div className="row mt-3">
          <div className="col-12">
            <h5
              className="color-484848 font-weight-500"
              style={{ fontSize: 22 }}
            ></h5>
          </div>
        </div>
      </>
    );
  }
}

Profile.propTypes = {};

const mapStateToProps = ({ AccountReducer, StudyReducer }) => ({
  user: AccountReducer.user,
  taxonomies: StudyReducer.taxonomies,
  activities: StudyReducer.activities,
  scores: StudyReducer.scores,
  badges: StudyReducer.badges,
  userBadges: StudyReducer.userBadges,
  wallet: StudyReducer.wallet,
});

const mapDispatchToProps = {
  fetchProfile: actions.fetchProfile,
  getUserBadges: actions.getUserBadges,
  loginRequired: accountActions.loginRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
