import { formatDate } from "./";
import { replaceDotWithComma } from "./formatPrice";

const companyInfo = {
  ticari_unvan: "Doğru Kaynak Dağıtım Teknoloji AŞ.",
  ticari_adres: "Yeşilce Mah. Göktürk Cad. No:20 K.2 Kağıthane/İstanbul",
  ticari_telefon: "0 212 963 35 35",
  ticari_mersis: "0308 0926 8100 0001",
  ticari_email: "destek@dogrukaynak.com",
  domain: "www.dogrukaynak.com",
  ticari_ad: "Doğru Kaynak",
  ticari_banka_ad: "Türkiye İş Bankası",
  ticari_hesap_no: "1107-1025751",
  ticari_iban: "TR62 0006 4000 0011 1071 0257 51",
};

const paymentType = [
  {
    id: 1,
    name: "Kredi Kartı",
  },
  {
    id: 2,
    name: "Havale - Eft",
  },
  {
    id: 3,
    name: "Kapıda Ödeme",
  },
  {
    id: 4,
    name: "Jeton ile Ödeme",
  },
];

const getOrderTable = ({ order, shippingInfo }) => {
  if (!order || !order.total) return "";
  var table = document.createElement("table");
  table.style.width = "100%";
  table.setAttribute("border", "1");
  table.className = "contract-product-table";

  const header = ["Ad", "Adet", "KDV Dahil Fiyatı", "Ara Toplam"];

  var thead = document.createElement("thead");
  for (let i = 0; i < header.length; i++) {
    const h = header[i];
    let td = document.createElement("td");
    td.appendChild(document.createTextNode(h));
    thead.appendChild(td);
  }
  table.appendChild(thead);

  var tbody = document.createElement("tbody");
  if (order.lines) {
    order.lines.forEach((line) => {
      var tr = document.createElement("tr");

      let nameTd = document.createElement("td");
      nameTd.appendChild(document.createTextNode(line.product.name));
      tr.appendChild(nameTd);

      let quantityTd = document.createElement("td");
      quantityTd.appendChild(document.createTextNode(line.quantity));
      tr.appendChild(quantityTd);

      let priceTd = document.createElement("td");
      priceTd.appendChild(
        document.createTextNode(
          replaceDotWithComma((line.price + line.taxTotal / line.quantity).toFixed(2))
        )
      );
      tr.appendChild(priceTd);

      let totalTd = document.createElement("td");
      totalTd.appendChild(
        document.createTextNode(replaceDotWithComma((line.total + line.taxTotal).toFixed(2)))
      );
      tr.appendChild(totalTd);

      tbody.appendChild(tr);
    });
  }

  var shippingRow = document.createElement("tr");
  var shippingName = document.createElement("td");
  shippingName.setAttribute("colspan", "2");
  shippingName.appendChild(
    document.createTextNode("Sipariş İşlem ve Gönderim Ücretleri")
  );
  shippingRow.appendChild(shippingName);
  var shippingValue = document.createElement("td");
  shippingValue.setAttribute("colspan", "2");

  let shippingPrice = 0;
  const isAllDigitalProduct = order.lines.every(
    (p) => p.isDigital
  );
  const calculateShippingPrice = () => {
    if (order.paymentMethod === 3) {
      if (
        shippingInfo.doorPaymentMinimum >= 0 &&
        shippingInfo.doorPaymentDiscountedMinimum >= 0 &&
        shippingInfo.doorPaymentDiscountedPrice >= 0 &&
        order.itemTotal + order.taxTotal >=
        shippingInfo.doorPaymentDiscountedMinimum
      ) {
        shippingPrice = shippingInfo.doorPaymentDiscountedPrice;
      }
      else {
        if (
          shippingInfo.doorPaymentMinimum >= 0 &&
          shippingInfo.doorPaymentOptionFee >= 0
        ) {
          shippingPrice = shippingInfo.doorPaymentOptionFee;
        } else {
          shippingPrice = 0;
        }
      }
    } else if (order.itemTotal >= shippingInfo.minimum) {
      shippingPrice = 0;
    } else {
      shippingPrice = shippingInfo.price;
    }
    if (isAllDigitalProduct) {
      shippingPrice = 0;
    }
  };

  if (shippingInfo) {
    calculateShippingPrice();
  } else {
    shippingPrice = order.shipTotal - order.adjustmentTotal;
  }

  shippingValue.appendChild(document.createTextNode(replaceDotWithComma(shippingPrice.toFixed(2))));
  shippingRow.appendChild(shippingValue);
  tbody.appendChild(shippingRow);

  var paymentRow = document.createElement("tr");
  var paymentName = document.createElement("td");
  paymentName.setAttribute("colspan", "2");
  paymentName.appendChild(document.createTextNode("Ödeme Şekli"));
  paymentRow.appendChild(paymentName);
  var paymentValue = document.createElement("td");
  paymentValue.setAttribute("colspan", "2");
  var pt = paymentType.find((x) => x.id == order.paymentMethod) || { name: "" };
  paymentValue.appendChild(document.createTextNode(pt.name));
  paymentRow.appendChild(paymentValue);
  tbody.appendChild(paymentRow);

  var totalRow = document.createElement("tr");
  var totalName = document.createElement("td");
  totalName.setAttribute("colspan", "2");
  totalName.appendChild(document.createTextNode("Toplam (KDV Dahil)"));
  totalRow.appendChild(totalName);
  var totalValue = document.createElement("td");
  totalValue.setAttribute("colspan", "2");

  let total = order && order.total && order.total;
  if (shippingInfo) {
    total += shippingPrice;
  }

  totalValue.appendChild(document.createTextNode(replaceDotWithComma(total.toFixed(2))));
  totalRow.appendChild(totalValue);
  tbody.appendChild(totalRow);

  table.appendChild(tbody);
  return table.outerHTML;
};

const updateFields = (fields, { user, order, shippingInfo }) => {
  if (
    fields.some((f) => f.kesy === "[kullanici_ad_soyad]") &&
    user &&
    user.firstname
  )
    fields[
      fields.findIndex((f) => f.kesy === "[kullanici_ad_soyad]")
    ].value = `${user.firstname} ${user.lastname}`;
  if (
    fields.some((f) => f.kesy === "[kullanici_telefon]") &&
    user &&
    user.mobile
  )
    fields[
      fields.findIndex((f) => f.kesy === "[kullanici_telefon]")
    ].value = `${user.mobile}`;
  if (fields.some((f) => f.kesy === "[kullanici_email]") && user && user.email)
    fields[
      fields.findIndex((f) => f.kesy === "[kullanici_email]")
    ].value = `${user.email}`;
  if (
    fields.some((f) => f.kesy === "[kullanici_vergi_no]") &&
    user &&
    user.tckn
  )
    fields[
      fields.findIndex((f) => f.kesy === "[kullanici_vergi_no]")
    ].value = `${user.tckn}`;

  if (
    fields.some((f) => f.kesy === "[kullanici_satis_bedeli]") &&
    order &&
    order.total
  )
    fields[
      fields.findIndex((f) => f.kesy === "[kullanici_satis_bedeli]")
    ].value = `${order.total}`;
  if (fields.some((f) => f.kesy === "[tarih]")) {
    var date = (order && order.date) || new Date();
    fields[
      fields.findIndex((f) => f.kesy === "[tarih]")
    ].value = `${formatDate.date(date)}`;
  }

  if (
    fields.some((f) => f.kesy === "[kullanici_adres]") &&
    order &&
    order.billAddress &&
    order.billAddress.town &&
    order.billAddress.city
  )
    fields[
      fields.findIndex((f) => f.kesy === "[kullanici_adres]")
    ].value = `${order.billAddress.lines} ${order.billAddress.town.name}-${order.billAddress.city.name}`;
  if (
    fields.some((f) => f.kesy === "[kullanici_fatura_adres]") &&
    order &&
    order.billAddress &&
    order.billAddress.town &&
    order.billAddress.city
  )
    fields[
      fields.findIndex((f) => f.kesy === "[kullanici_fatura_adres]")
    ].value = `${order.billAddress.lines} ${order.billAddress.town.name}-${order.billAddress.city.name}`;
  if (
    fields.some((f) => f.kesy === "[kullanici_teslim_adresi]") &&
    order &&
    order.deliveryAddress &&
    order.deliveryAddress.town &&
    order.deliveryAddress.city
  )
    fields[
      fields.findIndex((f) => f.kesy === "[kullanici_teslim_adresi]")
    ].value = `${order.deliveryAddress.lines} ${order.deliveryAddress.town.name}-${order.deliveryAddress.city.name}`;

  if (
    fields.some((f) => f.kesy === "[kullanici_urun_ad]") &&
    order &&
    order.lines
  ) {
    let products = order.lines
      .map((x) => `<p>${x.product.name}  (${x.quantity} adet) </p>`)
      .join(" ");
    fields[
      fields.findIndex((f) => f.kesy === "[kullanici_urun_ad]")
    ].value = products;
  }
  if (fields.some((f) => f.kesy === "[urun_tablo]") && order && order.lines) {
    let html = order ? getOrderTable({ order, shippingInfo }) : "";
    fields[fields.findIndex((f) => f.kesy === "[urun_tablo]")].value = html;
  }

  if (fields.some((f) => f.kesy === "[ticari_unvan]"))
    fields[fields.findIndex((f) => f.kesy === "[ticari_unvan]")].value =
      companyInfo.ticari_unvan;
  if (fields.some((f) => f.kesy === "[ticari_adres]"))
    fields[fields.findIndex((f) => f.kesy === "[ticari_adres]")].value =
      companyInfo.ticari_adres;
  if (fields.some((f) => f.kesy === "[ticari_telefon]"))
    fields[fields.findIndex((f) => f.kesy === "[ticari_telefon]")].value =
      companyInfo.ticari_telefon;
  if (fields.some((f) => f.kesy === "[ticari_mersis]"))
    fields[fields.findIndex((f) => f.kesy === "[ticari_mersis]")].value =
      companyInfo.ticari_mersis;
  if (fields.some((f) => f.kesy === "[ticari_email]"))
    fields[fields.findIndex((f) => f.kesy === "[ticari_email]")].value =
      companyInfo.ticari_email;
  if (fields.some((f) => f.kesy === "[domain]"))
    fields[fields.findIndex((f) => f.kesy === "[domain]")].value =
      companyInfo.domain;
  if (fields.some((f) => f.kesy === "[ticari_ad]"))
    fields[fields.findIndex((f) => f.kesy === "[ticari_ad]")].value =
      companyInfo.ticari_ad;

  if (fields.some((f) => f.kesy === "[ticari_iban]"))
    fields[fields.findIndex((f) => f.kesy === "[ticari_iban]")].value =
      companyInfo.ticari_iban;
  if (fields.some((f) => f.kesy === "[ticari_hesap_no]"))
    fields[fields.findIndex((f) => f.kesy === "[ticari_hesap_no]")].value =
      companyInfo.ticari_hesap_no;
  if (fields.some((f) => f.kesy === "[ticari_iban]"))
    fields[fields.findIndex((f) => f.kesy === "[ticari_iban]")].value =
      companyInfo.ticari_iban;

  return fields;
};

const fillContractFields = (contract, fields) => {
  if (!contract) return "";
  fields.forEach((field) => {
    contract = contract.split(field.kesy).join(field.value);
  });
  return contract;
};

export const contractHelper = {
  updateFields,
  fillContractFields,
};
