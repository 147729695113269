import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { LinkButton, Breadcrumb, Modal } from "../../components";
import CloseButton from "../../components/Button/Close";
import * as actions from "../../routes/actions/Desk/Answer";
import * as studyActions from "../../routes/actions/Study";
import * as accountActions from "../../routes/actions/Account";
import CompleteModal from "./CompleteModal";
import { toast } from "react-toastify";
import queryString from "query-string";
import Icon from "../../components/Icon/Index";

class Answers extends PureComponent {
  constructor(props) {
    super(props);
    const { testId } = this.props.match.params || 0;
    this.testId = parseInt(testId, 10);
    this.state = {
      show: false,
      testId: this.testId,
      isDisable: true,
      questions: props.questions || [],
      showModal: false,
    };
    this.key = React.createRef();
  }

  async componentDidMount() {
    this.getTestQuestions();

    if (!this.hasLogin()) {
      this.props.loginRequired(true);
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const { testId } = this.props.match.params || 0;

    this.testId = parseInt(testId, 10);

    if (prevState.testId !== this.testId) {
      this.setState({ testId: this.testId });
      const data = await this.props.getTestsQuestions(this.testId);
      if (!data.success) {
        toast.error(data.error);
        if (
          data.error ===
          "Testi görebilmeniz için öncelikle kitap aktivasyonu yapmalısınız."
        ) {
          this.showModal();
        }
      }
    } else if (prevProps.user.id !== this.props.user.id) {
      this.getTestQuestions();
    }

    //if(prevState.showModal != this.state.showModal && )
  }

  hasLogin = () => {
    return this.props.user.id && !this.props.user.demo ? true : false;
  };

  getTestQuestions = async () => {
    const search = queryString.parse(this.props.location.search);
    const isVirtual = search.v || false;
    this.setState({
      isVirtual,
    });

    const data = await this.props.getTestsQuestions(this.testId, isVirtual);
    if (!data.success) {
      toast.error(data.error);
      if (
        data.error ===
        "Testi görebilmeniz için öncelikle kitap aktivasyonu yapmalısınız."
      ) {
        this.showModal();
      }
    }
  };

  activateBook = async (event) => {
    event.preventDefault();

    const key = this.key.current.value;

    if (this.props.user.demo) {
      toast.error(
        "Bu işlemi DEMO modunda yapamazsınız. Devam etmek için giriş yapmanız gerekiyor."
      );
      this.props.loginRequired(true);
      return;
    }
    if (key.length < 6) {
      toast.error("Aktivasyon kodu en az 6 haneli olmalıdır.");
      return;
    }

    this.props
      .activateBook(key)
      .then(async (response) => {
        if (response.data.status === false) {
          toast.error(response.data.errorMessage);
        } else {
          //await this.props.getPurchasedBooks();
          //this.setState({ showSuccess: true, bookName: response.data.bookName });
          this.key.current.value = "";
          this.closeModal();
          this.getTestQuestions();
        }
      })
      .catch((err) => alert(err));
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = (explicit = false) => {
    if (explicit) {
      window.location.href = "/dijitalkitap/books";
    } else {
      this.setState({ showModal: false });
    }
  };
  handleQuestionOrder = (q) => {
    this.setState({ question: q.No })
    this.props.history.push(q.isDijital === 1 ? `/dijitalkitap/book/lesson/subject/quiz/${this.testId}?q=${q.No}` :
    `/dijitalkitap/book/lesson/subject/quiz/play/${this.testId}?q=${this.state.isVirtual ? q.NewNo : q.No}${this.state.isVirtual ? "&v=true" : ""}`)
  }
  handleSaveClick = async () => {
    let answers = {};
    answers = {
      yayinId: this.props.yayinID,
      testSure: "00:00:00",
      test: {
        testID: +this.testId,
        BransKod: this.props.questions[0].Brans,
        durum: "2",
      },
    };
    answers.test.sorular = this.state.questions.map((b) => {
      return {
        konuKod: b.KonuKod,
        KazanimKod: b.KazanimKod,
        ACevap: b.ACevap,
        OCevap: b.OCevap,
        SoruKey: b.Key,
        No: b.No,
        testId: b.TestID,
      };
    });
    const { success, error } = await this.props.saveTestsQuestionsAnswer(
      answers
    );
    if (success) {
      this.setState(
        {
          show: false,
          isActive: false,
        },
        () => {
          this.props.history.push(
            `/dijitalkitap/book/lesson/subject/quiz/summary/${this.testId}${
              this.state.isVirtual ? "?v=true" : ""
            }`
          );
        }
      );
    } else {
      if (error.length) {
        toast.error(error);
      }
    }
  };

  handleChange = (question, value) => {
    if (question === null || value === null) return;
    if (
      this.state.questions.find((x) => x.Key === question.Key).OCevap === value
    ) {
      const ques = (this.state.questions.find(
        (x) => x.Key === question.Key
      ).OCevap = "");
      this.setState({
        isDisable:
          this.props.questions.filter((x) => x.OCevap === "").length ===
          this.props.questions.length,
        questions: ques,
      });
    } else {
      const ques = (this.state.questions.find(
        (x) => x.Key === question.Key
      ).OCevap = value);
      this.setState({
        isDisable: false,
        questions: ques,
      });
    }
  };
  handleEvaluateClick = () => {
    if (this.props.user.demo) {
      toast.error(
        "Bu işlemi DEMO modunda yapamazsınız. Devam etmek için giriş yapmanız gerekiyor."
      );
      this.props.loginRequired(true);
      return;
    }
    this.setState({ show: true });
  };

  render() {
    this.state.questions = this.props.questions || [];
    const breadcrumbs = [
      {
        title: "Anasayfa",
        url: "/dijitalkitap",
      },
      {
        title: "Kütüphanem",
        url: "/dijitalkitap/books",
      },
    ];

    if (this.state.isVirtual) {
      breadcrumbs.push({
        title: "Hata Kutusu Testi",
        active: true,
      });
    } else {
      if (this.state.questions.length > 0) {
        if (this.state.questions[0]?.YayinAd) {
          breadcrumbs.push({
            title: this.state.questions[0].YayinAd,
            url: `/dijitalkitap/book/lessons/${this.state.questions[0].yayinID}`,
          });
        }
        if (this.state.questions[0]?.TestAd) {
          breadcrumbs.push({
            title: this.state.questions[0].TestAd,
            active: true,
          });
        }
      }
    }

    // const questionOptions = studyActions.questionOptions(this.props.classId);
    // let classId = this.props.classId;
    // if (this.state.questions && this.state.isVirtual) {
    //     classId = Math.max(...this.state.questions.map(p => p.sinif));
    // }
    // const questionOptions = studyActions.questionOptions(classId);
    const maxCevapSecenek =
      (this.state.questions &&
        Math.max(...this.state.questions.map((p) => p.cevapSecenek))) ||
      0;
    const questionOptions = studyActions.questionOptions2(maxCevapSecenek);
    return (
      <div className="row">
        <div className="col-12 p-xs-0">
        <Breadcrumb items={breadcrumbs} />
        </div>
        <div className="col-12 mb-3">
          <h3>Optik Form</h3>
        </div>
        <div style={{ width: 750 }}>
          <div className="card p-0 py-3 px-sm-2">
            <div className="card-header pb-0">
              <h5 className="mb-0">Cevaplarını Gir</h5>
            </div>
            <div className="card-body p-0 p-sm-2">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: 25 }}>
                      #
                    </th>
                    {questionOptions.map((opt, i) => (
                      <th
                        scope="col"
                        className="pl-3"
                        style={{ width: 80 }}
                        key={i}
                      >
                        {opt}
                      </th>
                    ))}
                    <th scope="col" className="text-right">
                      İzle
                    </th>
                  </tr>
                </thead>
                <tbody>

                  {this.state.questions?.length
                    ? this.state.questions.map((q, i) => (

                      < tr key={q.Key + "" + i} className={q.isDijital === 1 ? "digital-question-container" : ""}>


                        {q.isDijital === 0 ? <td>{this.state.isVirtual ? q.NewNo : q.No}</td> :
                          <td style={{ padding: "0" }}>
                            <div className="digital-question-number-container">
                              <span className="digital-question-number">{this.state.isVirtual ? q.NewNo : q.No}</span>
                              <span className="digital-question-number-text">Dijital Soru</span>
                            </div>
                          </td>}
                        {
                          q.isDijital === 0 ? 
                            questionOptions.map((title, i) => (
                              <td key={i}>
                                {i <
                                  studyActions.questionOptions2(q.cevapSecenek)
                                    .length && (
                                    <label className="radio-container answers-custom-checkbox">
                                      <input
                                        type="radio"
                                        name={`answer_${q.No}`}
                                        value={title}
                                        checked={q.OCevap === title}
                                        onChange={() => { }}
                                        onClick={() => this.handleChange(q, title)}
                                      />
                                      {q.isCevapMetin !== true ?
                                  <span className="radio-checkmark" />
                                  :
                                  null
                                }
                                    </label>
                                  )}
                              </td>
                            ))
                            : q.isDijital === 1 && q.OCevap.length === 0 ? 

                            <td colSpan={ q.cevapSecenek} style={{ textAlign: "center", padding: "0" }}>
                              <button
                                onClick={() => this.handleQuestionOrder(q)}
                                className="text-capitalize px-4 py-1 mr-2 fs-14 digital-question-btn"
                                style={{ border: "none" }}>
                                <span className="digital-question-btn-text">Önce Çöz</span>
                              </button>
                            </td> 
                            :  q.isDijital === 1 && q.OCevap.length > 0 ?
                            questionOptions.map((title, i) => (
                              <td key={i}>
                                {i <
                                  studyActions.questionOptions2(q.cevapSecenek)
                                    .length && (
                                    <label className="radio-container answers-custom-checkbox">
                                      <input
                                        type="radio"
                                        name={`answer_${q.No}`}
                                        value={title}
                                        checked={q.OCevap === title}
                                        onChange={() => { }}
                                        onClick={() => this.handleChange(q, title)}
                                      />
                                      <span className="radio-checkmark"></span>
                                    </label>
                                  )}
                              </td>
                            )) 
                            : <span />
                        }
                       {
                          q.isDijital === 0 ?
                            (q.videoLink !== "" && q.videoLink != null) ?
                              <td className="text-right" style={{ display: (q.videoLink === "") || q.videoLink == null ? "table-column" : "table-cell" }} >
                                <Link
                                  to={q.isDijital === 1 ? `/dijitalkitap/book/lesson/subject/quiz/${this.testId}` :
                                    `/dijitalkitap/book/lesson/subject/quiz/play/${this.testId}?q=${this.state.isVirtual ? q.NewNo : q.No}${this.state.isVirtual ? "&v=true" : ""}`
                                  }
                                  onClick={(e) =>
                                    q.videoLink === "" ? e.preventDefault() : true
                                  }
                                >
                                  {
                                    q.isDijital === 1 && (
                                      <div>
                                        <span className="fs-12  d-none d-sm-none d-md-inline" style={{ marginRight: "10px", "color": "rgb(178, 2, 2)", "fontWeight": "600", "display": "flex", textDecoration: "underline" }}>
                                        Dijital Soru
                                        </span>
                                        <span className="fs-12 text-dark d-none d-sm-none d-md-inline">
                                          Online Çöz
                                        </span>
                                      </div>)
                                  }
                                  {
                                    q.isDijital === 0 && 
                                    (
                                      <div>
                                      {
                                        q.isCevapMetin !== true ?
                                        <span className="fs-12 text-dark d-none d-sm-none d-md-inline">
                                          Çözüm İzle 
                                          </span>
                                        :
                                          <span className="fs-12 text-dark d-none d-sm-none d-md-inline">
                                            (Açık Uçlu Soru) Çözüm İzle
                                          </span>
                                      }
                                      
                                      <img
                                        src={require("../../assets/images/icons/video-yt.svg")}
                                        alt="..."
                                        width="20"
                                        className="ml-2"
                                      />
                                    </div>)
                                  }
                                </Link>
                              </td>
                            :
                            <td className="text-right" style={{ display: (q.pdfLink === "") || q.pdfLink == null ? "table-column" : "table-cell" }} >
                              <Link
                                to={
                                  "/dijitalkitap/book/lessons/"+
                                  q.yayinID +
                                  "/read?file=" +
                                  btoa(q.pdfLink)
                                }
                                onClick={(e) =>
                                  q.pdfLink === "" ? e.preventDefault() : true
                                }
                              >
                                {
                                  q.isDijital === 1 && (
                                    <div>
                                      <span className="fs-12  d-none d-sm-none d-md-inline" style={{ marginRight: "10px", "color": "rgb(178, 2, 2)", "fontWeight": "600", "display": "flex", textDecoration: "underline" }}>
                                      Dijital Soru
                                      </span>
                                      <span className="fs-12 text-dark d-none d-sm-none d-md-inline">
                                        Online Çöz
                                      </span>
                                    </div>)
                                }
                                {
                                  q.isDijital === 0 && 
                                  (
                                    <div>
                                    {
                                        q.isCevapMetin !== true ?
                                        <span className="fs-12 text-dark d-none d-sm-none d-md-inline">
                                          Çözüm Gör 
                                          </span>
                                        :
                                          <span className="fs-12 text-dark d-none d-sm-none d-md-inline">
                                            (Açık Uçlu Soru) Çözüm Gör
                                          </span>
                                      }
                                    <img
                                      src={require("../../assets/images/icons/pdf-svgrepo-com.svg")}
                                      alt="..."
                                      width="20"
                                      className="ml-2"
                                    />
                                  </div>)
                                }
                              </Link>
                            </td>
                          :
                            q.isDijital === 1 && q.OCevap.length > 0 ? 
                            <td className="text-right" style={{ display: (q.videoLink === "") || q.videoLink == null ? "table-column" : "table-cell" }}>
                              <Link
                                to={`/dijitalkitap/book/lesson/subject/quiz/play/${this.testId}?q=${this.state.isVirtual ? q.NewNo : q.No}${this.state.isVirtual ? "&v=true" : ""}`
                                }
                                onClick={(e) =>
                                  q.videoLink === "" ? e.preventDefault() : true
                                }
                              >
                                <span className="fs-12 text-dark d-none d-sm-none d-md-inline">
                                    Çözüm İzle
                                </span>
                                    <img
                                      src={require("../../assets/images/icons/video-yt.svg")}
                                      alt="..."
                                      width="20"
                                      className="ml-2"
                                    />
                              </Link>
                            </td>
                          :
                            <td></td>
                        }
                      </tr>

                    ))
                    : null}
                </tbody>
              </table>

              <button
                type="button"
                className="btn btn-outline-dark text-capitalize fs-14 py-1 answers-evaluate-btn"
                disabled={this.state.isDisable}
                onClick={() => this.handleEvaluateClick()}
              >
                Değerlendir
              </button>
              <LinkButton
                to={`/dijitalkitap/book/lesson/subject/quiz/${this.state.testId}${this.state.isVirtual ? "?v=true" : ""
                  }`}
                className="btn-primary text-capitalize px-4 py-1 mr-2 fs-14 float-right answers-online-btn"
              >
                Online Çöz
              </LinkButton>
            </div>
          </div>
        </div >
        <div className="col-xl-12 text-center">
          <CompleteModal
            show={this.state.show}
            onCloseClick={() => this.setState({ show: false })}
            onSaveClick={this.handleSaveClick}
            isBlank={this.state.questions.find((x) => x.OCevap === "") != null}
            dialogClassName="modal-md"
          />
        </div>
        <Modal
          show={this.state.showModal}
          onCloseClick={() => this.closeModal(true)}
          className="modal-container modal-md non-modal-height"
        >
          <div className="modal-header bt-dedede:50">
            <h5>Kitap Aktivasyonu</h5>
            <CloseButton onClick={() => this.closeModal(true)} />
          </div>
          <div className="modal-body">
            <form
              className="input-container bg-f4f4f4 w-100"
              onSubmit={this.activateBook}
            >
              <input
                type="text"
                className="form-control fs-14"
                placeholder="Aktivasyon Kodu"
                name={`book_${this.props.id}`}
                ref={this.key}
              />
              <button type="submit" className="btn btn-blue w-25">
                <img
                  src={require("../../assets/images/icons/chevron-right.svg")}
                  alt=""
                />
              </button>
            </form>
          </div>
        </Modal>
      </div >
    );
  }
}

Answers.propTypes = {};

const mapStateToProps = ({ AnswerReducer, AccountReducer }) => ({
  questions: AnswerReducer.questions,
  yayinID: AnswerReducer.yayinID,
  classId: AnswerReducer.classId,
  user: AccountReducer.user,
});

const mapDispatchToProps = {
  getTestsQuestions: actions.getTestsQuestions,
  saveTestsQuestionsAnswer: actions.saveTestsQuestionsAnswer,
  loginRequired: accountActions.loginRequired,
  activateBook: studyActions.activateBook,
};

export default connect(mapStateToProps, mapDispatchToProps)(Answers);
