import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../routes/actions/Account";
import { getActivityTypes } from "../../../routes/actions/Product";
//import PropTypes from "prop-types";
import classNames from "classnames";
import SchoolWidget from "../Widgets/School";
import { ScoreBadge } from "../..";

class SchoolField extends Component {
    async componentWillMount() {
        if (!this.props.activityTypes || this.props.activityTypes.length === 0) {
            this.props.getActivityTypes()
        }
    }
    render() {
        const {
            className,
            name,
            schema,
            uiSchema,
            errors,
            value,
            readOnly
        } = this.props;

        const valid = !(errors && errors.length > 0);
        const widgetProps = {
            ...this.props,
            className: classNames("form-control", valid === false ? "is-invalid" : "valid")
        };

        var activity = { Score: 0, Coins: 0 };
        if (this.props.activityTypes && this.props.activityTypes.length > 0) {
            activity = this.props.activityTypes.find(x => x.Name === 'add-school');
        }


        return (
            <div className={classNames("form-group col-12", className)}>
                <div className="d-flex justify-content-between">
                    <label className="mt-2" htmlFor={name}>
                        {schema.title}
                    </label>
                    {
                        !value ? (
                            <ScoreBadge icon="point" value={activity.Score} className="badge-score mb-1" />
                        ) : null
                    }
                </div>
                <SchoolWidget {...widgetProps} readOnly={readOnly} />
                <small id={`${name}_help`} className="form-text text-muted col-sm-12">
                    {uiSchema && uiSchema["ui:description"]}
                </small>
                {(errors || []).map((error) => (
                    <div className="invalid-feedback">{error}</div>
                ))}
            </div>
        );
    }
}

SchoolField.propTypes = {};

const mapStateToProps = ({ AccountReducer, ProductReducer }) => ({
    user: AccountReducer.user,
    activityTypes: ProductReducer.activityTypes
});

const mapDispatchToProps = {
    sendVerifyEmail: actions.sendVerifyEmail,
    verify: actions.verify,
    getActivityTypes
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SchoolField);
