import React, { Component } from "react";
//import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  iconHearth,
  iconHearthOuter,
  iconProductClose,
} from "../../assets/images/icons";
import * as actions from "../../routes/actions/Account";
import { addProductToBasket } from "../../routes/actions/Product";
import { favoriteTypes } from "../../services/constants";
import Icon from "../Icon/Index";
import { replaceDotWithComma } from "../../helpers";
import { addProductToNotifyMe } from "../../routes/actions/Product";
import { getSettings } from "../../routes/actions/Settings";
class Item extends Component {
  constructor(props) {
    super(props);
  }

  addProductToBasket = async (product) => {
    const item = {
      key: this.props.basketKey,
      productId: product.id,
      quantity: 1,
      update: false,
      ...product,
    };

    const { success, error } = await this.props.addProductToBasket(
      item,
      this.props.allTaxonomies
    );

    if (success) {
      toast.success("Ürün sepetinize eklendi.");
    } else {
      toast.error(error.message);
    }
  };

  addToFavorites = async (product) => {
    if (!this.props.user.id) {
      this.props.loginRequired(true);
      return;
    }

    const { success, error } = await this.props.addToFavorites(
      product,
      favoriteTypes.product
    );

    if (success) {
      toast(this.customToastAddFavourite({ success, error }), {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        closeButton: false,
      });
    } else {
      toast.error(error.message);
    }
  };

  addProductToNotifyMe = async (product) => {
    if (!this.props.user.id) {
      this.props.loginRequired(true);
      return;
    }

    if (!this.props.enableNotifyMe) return;

    const { success, error } = await this.props.addProductToNotifyMe(product);

    if (success) {
      toast.success(`${product.name} haber verilecek listenize eklendi`);
    } else {
      toast.error(error);
    }
  };

  customToastAddFavourite = () => (
    <div className="custom-toast">
      <Icon icon="iSuccess" className="mr-3" />
      <div className="d-flex flex-column">
        <div>Ürün listene eklendi. </div>
        <a href="/hesabim/favorilerim">Listelerime Git</a>
      </div>
    </div>
  );
  removeFromFavorites = async (product) => {
    const item = {
      id: product.id,
      type: favoriteTypes.product,
    };

    const { success, error } = await this.props.removeFromFavorites(item);

    if (success) {
      toast.success(`${product.name} favorilerinizden çıkarıldı`);
    } else {
      toast.error(error.message);
    }
  };

  componentDidMount() {
    this.props.getSettings();
  }

  render() {
    const { product, favorite, showCloseButton, handleRemove } =
      this.props;
    return (
      product &&
       (
        <div id={this.props.id} className="card product-card">
          <div className="product-card-inner">
            <div className="product-features">
              {product.badges?.map((badge, i) => (
                <div className={`product-badge ${badge.className}`} key={i}>
                  {badge.textToDisplay}
                </div>
              ))}
              {!showCloseButton && (
                <div className="favorite-icon-box">
                  <img
                    className="favorite-icon my-1"
                    src={favorite === true ? iconHearth : iconHearthOuter}
                    onClick={() =>
                      favorite
                        ? this.removeFromFavorites(product)
                        : this.addToFavorites(product)
                    }
                    title={favorite ? "Favorilerden çıkart" : "Favorilere ekle"}
                    alt=""
                  />
                </div>
              )}
              {/* {product.isNew ? (
                <span className="badge new-product-badge my-1">Yeni</span>
              ) : null}
              {product.hasDigitalBook === true ? (
                <span className="support-icons">
                  <img
                    src={require("../../assets/images/OkumaModu.svg")}
                    alt=""
                  />
                </span>
              ) : null} */}
            </div>
            <Link to={`/urunler/detay/${product.id}`}>
              <figure>
                <img
                  src={product.imageUrl}
                  className="product-img"
                  alt={product.name}
                  title={product.name}
                  loading="lazy"
                />
              </figure>
            </Link>
            {showCloseButton === true && (
              <button
                className="btn btn-link favories-close-button"
                style={{ width: "38px", height: "38px" }}
                onClick={() =>
                  handleRemove
                    ? handleRemove(product.id)
                    : this.removeFromFavorites(product, "product")
                }
              >
                <img
                  src={iconProductClose}
                  alt=""
                  style={{
                    backgroundColor: "white",
                    borderRadius: "32px",
                    MozBorderRadius: "32px",
                    WebkitBorderRadius: "32px",
                  }}
                />
              </button>
            )}
            <div className="card-footer">
              <Link to={`/urunler/detay/${product.id}`}>
                <p className="product-title">{product.name}</p>
              </Link>

              {!product.isInStock && this.props.enableNotifyMe ? (
                <div className="product-operations">
                 <div className="product-prices flex-2">
                  {product.discount > 0 && (
                    <div className="price-with-tax">
                      {replaceDotWithComma((product.total / (1 - product.discount / 100) || 0).toFixed(2))} ₺
                    </div>
                  )}
                  <div className="price ">
                    {replaceDotWithComma((product.total || 0).toFixed(2))} ₺
                  </div>
                </div>
                  <button
                    className="btn add-to-notify-me flex-3"
                    onClick={() => this.addProductToNotifyMe(product)}
                  >
                    Haber ver
                  </button>
                </div>
              ) : (
                <div className="product-operations">

                <div className="product-prices flex-2">
                  {product.discount > 0 && (
                    <div className="price-with-tax">
                      {replaceDotWithComma((product.total / (1 - product.discount / 100) || 0).toFixed(2))} ₺
                    </div>
                  )}
                  <div className="price ">
                    {replaceDotWithComma((product.total || 0).toFixed(2))} ₺
                  </div>
                </div>

                  <button
                    className="btn add-to-cart flex-3"
                    onClick={() => this.addProductToBasket(product)}
                  >
                    SEPETE EKLE
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )
    );
  }
}

Item.propTypes = {};

const mapStateToProps = ({ ProductReducer, SettingsReducer }) => ({
  basketKey: ProductReducer.basketKey,
  enableNotifyMe: SettingsReducer.enableNotifyMe,
  allTaxonomies: ProductReducer.taxonomies,
});

const mapDispatchToProps = {
  loginRequired: actions.loginRequired,
  addProductToBasket: addProductToBasket,
  addToFavorites: actions.addToFavorites,
  removeFromFavorites: actions.removeFromFavorites,
  addProductToNotifyMe: addProductToNotifyMe,
  getSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(Item);
