// Wraps the react-select to use a better filter method, current one relies on indexOf which isn't great for searching large lists
// New custom search matches if all words in box are found anywhere in the option.label, case in-sensitive
export const customFilterOption = (option, rawInput) => {
    const words = rawInput.split(" ");
    return words.reduce((acc, cur) => {
        return (
            acc &&
      option.label &&
      option.label.toLocaleLowerCase("TR").indexOf(cur.toLocaleLowerCase("TR") === 0)
        );
    }, true);
};
export const customFilterOptionIncludesOption = (option, rawInput) => {
    const words = rawInput.split(" ");
    return words.reduce((acc, cur) => {
        return (
            acc &&
      option.label &&
      option.label.includes(words)
        );
    }, true);
};
