import React from "react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ShoppingButton({handleClick}) {
  return (
    <button
      className="btn btn-total-price ml-auto"
      onClick={() => handleClick()}
    >
      Alışverişi Tamamla
      <FontAwesomeIcon icon={faChevronRight} />
    </button>
  );
}
