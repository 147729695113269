import React from "react";
//import PropTypes from "prop-types";
import { PlaceHolder540x328, PlaceHolder1110x328 } from "../../assets/images";

const ImageView = ({ url, size }) => {
  return (
    <div className="card w-100">
      <figure className="text-center rounded-4" style={{ minHeight: 100 }}>
        {url ? (
          <img src={url} className="w-100 h-100" alt="..." />
        ) : size === 12 ? (
          <PlaceHolder1110x328 />
        ) : (
          <PlaceHolder540x328 />
        )}
      </figure>
    </div>
  );
};

ImageView.propTypes = {};

export default ImageView;
